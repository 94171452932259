import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAnswers: [],
  recordings: {},
};

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    setSubQuestionAnswer: (state, action) => {
      const { subQuestionId, selectedAnswer } = action.payload;
      const existingAnswer = state.allAnswers.find(
        (item) => item.subQuestionId === subQuestionId
      );
      if (existingAnswer) {
        existingAnswer.valid_response.value = selectedAnswer;
      } else {
        state.allAnswers.push({
          subQuestionId,
          valid_response: { value: selectedAnswer },
        });
      }
    },
    setRecording: (state, action) => {
      const { subQuestionId, recording } = action.payload;
      state.recordings[subQuestionId] = recording; 
    },
  },
});

export const { setSubQuestionAnswer, setRecording } = audioSlice.actions;
export default audioSlice.reducer;