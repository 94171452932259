import React, { useState, useRef } from "react";
import { Layout, Space, Avatar, Popover, Button } from "antd";
import {
  BellOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SaveFilled,
  SaveOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import UserProfileForm from "../UserProfile/UserProfilForm.component";
import ResetPasswordForm from "../UserProfile/ResetPasswordForm.component";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import "./MainHeader.styles.scss";
import Notifications from "../../icons/Notifications";
import Divider from "../../icons/Divider";
import Accessibility from "../../icons/Accessibility";
import WhiteSearch from "../../icons/WhiteSearch";
import Logo from "studio/icons/Logo";
import UploadIcon from "studio/icons/UploadIcon";
import Play from "studio/icons/Play";
import SaveIcon from "studio/icons/SaveICon";
import { useLocation } from "react-router-dom";

export type IHeaderType = "assessment" | "library" | "player";

const MainHeader: React.FC<{
  title: string;
  type: IHeaderType;
  handleSave?: () => void;
  handlePublish?: () => void;
}> = ({ title, type = "library", handleSave, handlePublish }) => {
  let user_name = useSelector((state: RootState) => state.assessments?.userData?.data?.user_name || '');

  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [isAvatarClicked, setIsAvatarClicked] = useState(false);
  const location = useLocation();

  const avatarRef = useRef<HTMLDivElement>(null);
  const state = location?.state as any;

  const {
    user = {}
  } = useSelector((state: RootState) => state?.authentication || {});

  const userData = {
    firstName: user?.first_name || "",
    lastName: user?.last_name || "",
    email: user?.email || "",
    organization: user?.school_name || "",
    roles: user?.roles || [],
  };



  const showResetModal = () => {
    setIsAvatarClicked(false);
    setTimeout(() => setIsResetModalVisible(true), 200);
  };

  const closeResetModal = () => setIsResetModalVisible(false);

  const profileContent = (
    <div className="popover-content">
      <UserProfileForm
        userData={userData}
        onResetPassword={showResetModal}
        onClose={() => setIsAvatarClicked(false)}
      />
    </div>
  );

  const displayName = type !== 'player' ? (userData?.firstName || "") : user_name;

  const avatarInitial = user_name
      ? user_name.charAt(0).toUpperCase()
      : userData?.firstName && userData?.firstName.charAt(0).toUpperCase() || "A";

  return (
    <header className="main-header col-span-2 pr-6 max-[650px]:pr-0 xs:pr-0 sm:pr-0 md:pr-0">
      <div className="header-content">
        <div className="left-section flex items-center gap-5 h-full">
          {type === "library" && (
            <>
              <Logo />
              <p className="text-white text-xl">Obeassess</p>
              <div className="search-container ">
                <WhiteSearch className="hidden md:block" />
              </div>
            </>
          )}
          {type === "player" && (
            <>
              <Logo />
              <p className="text-white text-xl">Assessment Player</p>
            </>
          )}
          {type === "assessment" && (
            <>
              <Logo />
              <p className="text-white text-xl">Studio</p>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                size={"small"}
                style={{ width: "1.75rem", height: "1.75rem" }}
                className="bg-light-dark-gray"
              ></Button>
              {/* <UploadIcon className="fill-light-gray-200 text-light-gray-200 cursor-pointer" /> */}
              <Play
                className="fill-light-gray-200 text-light-gray-200 cursor-pointer"
                fill="#E9E9E9"
              />
              {/* <SaveIcon className="fill-light-gray-200 text-light-gray-200 cursor-pointer"  /> */}
              <Button
                type="primary"
                icon={<SaveOutlined style={{ fontSize: 20 }} />}
                size={"small"}
                className="bg-light-dark-gray h-8 px-4"
                style={{ width: "1.75rem"}}
                onClick={() => {
                  handleSave();
                }}
              ></Button>
              <div className="pl-6 border-l border-light-dark-gray">
                <Button
                  type="primary"
                  icon={<UploadIcon className="text-light-gray-200 w-2" />}
                  size={"small"}
                  className="bg-light-dark-gray py-4 px-4"
                  onClick={() => {
                    handlePublish();
                  }}
                >
                  {state?.status === false || state?.access === false ? "Unpublished" : "Published"}
                </Button>
              </div>
            </>
          )}
        </div>

        <div className="center-section self-center hidden md:block">
          <h1 className="page-title">{title || "Obeassess"}</h1>
        </div>

        <div className="right-section">
          <Space size={24}>
            {type != "player" && <Notifications className="hidden md:block" />}
            <Accessibility className="hidden md:block" />
            <Space
              size={12}
              className={`user-info ${isAvatarClicked ? "active" : ""}`}
              ref={avatarRef}
            >
              <span
                className="user-greeting"
                style={{
                  color: isAvatarClicked ? "#333" : "rgba(255, 255, 255, 0.85)",
                  marginRight: "8px",
                  fontWeight: 500,
                }}
              >
                Hi, {displayName}
              </span>
              <Popover
                content={profileContent}
                trigger="click"
                placement="bottomRight"
                getPopupContainer={() => avatarRef.current || document.body}
                open={isAvatarClicked}
                onOpenChange={(visible) => {
                  if (user_name === '') {
                    setIsAvatarClicked(visible);
                  }
                }}
              >
                <Avatar className="user-avatar">
                  {avatarInitial}
                </Avatar>
              </Popover>
            </Space>
          </Space>
        </div>
      </div>

      {/* Reset Password Modal */}
      <ResetPasswordForm
        visible={isResetModalVisible}
        onClose={closeResetModal}
      />
    </header>
  );
};

export default MainHeader;
