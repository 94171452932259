export const Messages = {
  Copyright: "Copyright © 2025 OBEASSESS. All rights reserved.",
  LoginSuccess: " login successful. Welcome to the dashboard!",
  LoginInvalid: "Invalid credintials or missing permissions",
  ForgotPasswordTitle: "Forgot Your Password?",
  ForgotPasswordSubTitle:
    "No worries! Enter your email, and we’ll send you a link to reset your password.",
  ForgotEmail: "Forgot Your Email? Contact Your Admininstrator",
  ResetPasswordLinkSent: "Reset password email send successfully",
  ResetPasswordLinkFailed: "Reset password email is wrong!",
  EnterEmail: "Please enter your email!",
  EnterValidEmail: "Please enter a valid email!",
  EnterUserName: "Please enter your username!",
  EnterPassword: "Please enter your password!",
  // Messages from ResetPasswordForm
  OldPasswordRequired: "Please enter your old password",
  NewPasswordRequired: "Please enter a new password",
  NewPasswordMinLength: "Password must be at least 4 characters",
  ConfirmPasswordRequired: "Please confirm your new password",
  PasswordsDoNotMatch: "Passwords do not match!",
  PasswordResetSuccess: "Password reset successfully!",
  PasswordResetFailure: "Failed to reset password.",
  PasswordResetError: "An error occurred while resetting the password.",
};
