export const ROUTES = Object.freeze({
  login: "/login",
  forgotPassword: "/forgot-password",
  questionsBank: "/questions-bank",
  assessmentLibrary: "/assessment-library",
  assessmentPlayer: "/obs-service/player/:id",
  assessmentQuestionPlayer: "/assessment-questions-player",
  userManagement: "/user-management",
  questionEdit: "/question-edit/:id",
  createAssessment: "/create-assessment",
  editAssessment: "/editCreated-assessment/:id",
});
