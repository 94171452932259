import { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { Action } from 'redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import {setListDropSubjects, setSubjects} from '../redux/slices/SubjectsSlice';

export const addSubject = (
  subject
): ThunkAction<void, RootState, null, Action<string>> => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subjects`,
        subject,
        {
          headers: {
            Accept: 'application/json',
          },
        }
      );
      if (response.status === 200) {
        toast.success('Subject added successfully');
      }
    } catch (error) {
      toast.error('Create subject failed!');
    }
  };
};

export const getSubjectsListing = (
  page,
  perPage,
  filter
): ThunkAction<void, RootState, null, Action<string>> => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/subject/datatable`,
        {
            params: {
                page,
                per_page: perPage,
                ...filter // Include additional filter parameters here
            },
            headers: {
                Accept: 'application/json',
          },
        }
      );
      if (response.status === 200) {
        dispatch(
          setSubjects({
            items: response?.data?.items,
            pagination: response?.data.pagination,
            filters: response?.data.filters
          })
        );
      }
    } catch (error) {
      toast.error('get subjects failed!');
    }
  };
};

export const getListDropSubjects = (): ThunkAction<void, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios(
                `${process.env.REACT_APP_API_URL}/api/subjects`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                //console.log('get list drop subjects', response)
                dispatch(setListDropSubjects(response?.data));
            }
        } catch (error) {
            dispatch(setListDropSubjects([]));
            //toast.error('get subjects List failed!');
        }
    };
};

export const getObeListDropSubjects = (token): ThunkAction<void, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios(
                `${process.env.REACT_APP_OBE_APP_API_URL}/subject`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                //console.log('get list drop subjects', response)
                dispatch(setListDropSubjects(response?.data));
            }
        } catch (error) {
            dispatch(setListDropSubjects([]));
            //toast.error('get subjects List failed!');
        }
    };
};
