import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";
import { Action } from "redux";
import axios from "axios";
import { toast } from "react-toastify";
import { setKeyValue, setQuestionValues } from "../redux/slices/QuestionSlice";
import { usersListingRequest } from "../redux/slices/UserManagementSlice";
import {setEditKeyValue} from "../redux/slices/EditSubQuestionSlice";
import { message } from "antd";



export const uploadImage = (
    file: File,
    key: string,
    actionKey: string,
    width: number,
    height: number
): ThunkAction<void, RootState, null, Action<string>> => {
    console.log(actionKey, width, height)
    return async (dispatch, getState) => {
        try {

            // Access the token from the state
            const localtoken = localStorage.getItem('token');
            const tokenUser = getState().assessments.config.tokenUser;
            let  uploadUrl = `${process.env.REACT_APP_API_URL}/api/formupload`;
            let headers: Record<string, string> = {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            };
             if (!localtoken && tokenUser) {
                 uploadUrl = `${process.env.REACT_APP_OBE_APP_API_URL}/formupload`;
                 headers = {
                    ...headers, // Include existing headers
                    Authorization: `Bearer ${tokenUser}`, // Add token to headers
                };
            }
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(
                uploadUrl,
                formData,
                {
                    headers: headers,
                }
            );

            if (response.status === 200) {
                const srcValue = response.data?.item?.src;
                let img = new Image();
                img.src = srcValue;
                let imgNaturalWidth = 100;
                let imgNaturalHeight = 100;
                img.onload = () => {
                    imgNaturalWidth = img.naturalWidth;
                    imgNaturalHeight = img.naturalHeight;
                    if(actionKey == 'create'){
                        dispatch(setKeyValue({key, subKey: 'width', value: width ? width : imgNaturalWidth}))
                        dispatch(setKeyValue({key, subKey: 'height', value: height ? height : imgNaturalHeight}))
                    }else {
                        dispatch(setEditKeyValue({key, subKey: 'width', value: width ? width : imgNaturalWidth}))
                        dispatch(setEditKeyValue({key, subKey: 'height', value: height ? height : imgNaturalHeight}))
                    }
                };
                if(actionKey == 'create') dispatch(setKeyValue({key, subKey: 'source', value: srcValue}));
                else if (actionKey == 'edit') dispatch(setEditKeyValue({key, subKey: 'source', value: srcValue}));
            }
        } catch (error) {
            console.error('Error uploading or fetching image:', error);
            toast.error('Upload failed!');
        }
    };
};

export const getQuestionById = (
    id: string
): ThunkAction<Promise<any>, RootState, null, Action<string>> => {
    return async (dispatch) => {
        dispatch(usersListingRequest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/question/${id}`,
                {
                    headers: {
                        'Accept': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                console.log('Response for question by ID:', response);
                dispatch(setQuestionValues(response?.data?.item));
            }
        } catch (error) {
            console.error('Error fetching question:', error);
            toast.error('Fetch question failed!');
        }
    };
};

export const duplicateQuestions = (
    questions: any
): ThunkAction<void, RootState, null, Action<string>> => {
    return async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/question/duplicate`,
                { questions },
                {
                    headers: {
                        Accept: 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                message.success('Duplicate Questions done successfully');
            }
        } catch (error) {
            console.error('Error duplicating questions:', error);
            const errorResponse = error.response?.data;

            if (errorResponse && errorResponse.errors) {
                Object.keys(errorResponse.errors).forEach((key) => {
                    const errorMessages = errorResponse.errors[key].join('\n');
                    message.error(errorMessages);
                });
            } else {
                message.error('Duplicate Questions failed!');
            }
        }
    };
};

export const uploadImageMoreOptionsShading = (
    file: File,
    key: string,
    actionKey: string,
    width: number,
    height: number,
    newMoreOptions: any
): ThunkAction<void, RootState, null, Action<string>> => {
    console.log('eeeeeeeeeeeeeeeee', newMoreOptions);
    return async (dispatch, getState) => {
        try {
            const localtoken = localStorage.getItem('token');
            const tokenUser = getState().assessments.config.tokenUser;
            let uploadUrl = `${process.env.REACT_APP_API_URL}/api/formupload`;
            let headers: Record<string, string> = {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            };

            if (!localtoken && tokenUser) {
                uploadUrl = `${process.env.REACT_APP_OBE_APP_API_URL}/formupload`;
                headers = {
                    ...headers,
                    Authorization: `Bearer ${tokenUser}`,
                };
            }

            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(uploadUrl, formData, { headers });

            if (response.status === 200) {
                const srcValue = response.data?.item?.src;
                const img = new Image();
                img.src = srcValue;

                img.onload = () => {
                    const imgNaturalWidth = img.naturalWidth;
                    const imgNaturalHeight = img.naturalHeight;

                    // Update newMoreOptions with the src and dimensions
                    newMoreOptions.image.source = srcValue; // Set the source from the upload response
                    newMoreOptions.image.width = width || imgNaturalWidth;
                    newMoreOptions.image.height = height || imgNaturalHeight;

                    if (actionKey === 'create') {
                        dispatch(setKeyValue({ key, subKey: 'more_options', value: newMoreOptions }));
                    } else {
                        dispatch(setEditKeyValue({ key, subKey: 'more_options', value: newMoreOptions }));
                    }
                };
            }
        } catch (error) {
            console.error('Error uploading or fetching image:', error);
            toast.error('Upload failed!');
        }
    };
};


