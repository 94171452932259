import { Tabs } from "antd";
import type { TabsProps } from "antd";
import QuestionsList from "./QuestionList";
import FieldsMenuWrapper from "./FieldsMenusWrapper";
import AssessmentStudio from "./AssessmentStudio";
import { addQuestionRequest } from "api-rest/questions";
import { DefaultQuestionsData } from "studio/constants/defaultQuestionData.constants";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DndContext,
  closestCenter,
  closestCorners,
  pointerWithin,
  rectIntersection,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  CollisionDetection,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { v4 as uuidv4 } from "uuid";
import { iconLookup, menuGroupsList } from "./questionsList";
import { useLocation } from "react-router-dom";
import {
  getAssessmentByIdRequest,
  getPreviewQuestionsRequest,
} from "api-rest/assessments";
import { Sizes } from "studio/theme/Sizes.theme";
import { Colors } from "studio/theme/colors.theme";
import { MCQTypes } from "studio/constants/questionTypes.constants";
import QuestionPreviewForMCQ from "../QuestionEdit/MCQ/components/questionPreviewForMCQ.component";
interface CreateAssessmentComponentProps {
  onQuestionListChange?: (questionList: any[]) => void;
}

const CreateAssessmentComponent: React.FC<CreateAssessmentComponentProps> = ({
  onQuestionListChange,
}) => {
  const [questionList, setQuestionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const state = location.state as any;
  const { id, name } = state;
  const transformQuestions = (questionItems, templates) => {
    const template = templates[questionItems.groupKey]?.[questionItems.itemKey];

    if (template) {
      return {
        ...template,
        key: questionItems.key,
        icon: questionItems.icon,
        text: questionItems.text,
        templateTitle: questionItems.templateTitle,
        groupKey: questionItems.groupKey,
        itemKey: questionItems.itemKey,
        trackId: questionItems.trackId,
      };
    }
  };
  const getQuestionTemplate = (item) => {
    console.log(item, 'item')
    const qtype = item?.sub_questions[0]?.type;

    if (
      [
        MCQTypes.BLOCKLAYOUT,
        MCQTypes.MULTIPLEANSWERS,
        MCQTypes.SINGLEANSWER,
        MCQTypes.TRUEFALSE,
      ].includes(qtype)
    ) {
      return <QuestionPreviewForMCQ data={item?.sub_questions[0]} />;
    }
  };
  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
      try {
        setIsLoading(true);
        const { error, data } = await getAssessmentByIdRequest(id);

        if (error) {
          console.error("Error fetching assessment questions:", error);
          return;
        }

        if (data && data.item) {
          // Transform the API response to match your question format
          const transformedQuestions = data.item.questions.map((item) => ({
            trackId: uuidv4(),
            templateTitle:
              item.sub_questions[0].question || "Untitled Question",
            icon: iconLookup[item.sub_questions[0].type],
            contextData: item,
            template: getQuestionTemplate(item) || null,
          }));
          setQuestionList(transformedQuestions);
        }
      } catch (err) {
        console.error("Error in fetchAssessmentQuestions:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchAssessmentQuestions();
    }
  }, [id]);

  useEffect(() => {
    if (onQuestionListChange) {
      onQuestionListChange(questionList);
    }
  }, [questionList, onQuestionListChange]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  const handleDragStart = useCallback((event) => {
    document.body.classList.add("dragging-from-menu");
  }, []);
  const handleDragEnd = async (event) => {
    document.body.classList.remove("dragging-from-menu");
    const { active, over } = event;

    // If dropped anywhere in the questions area (even if not over a specific item)
    const questionsArea = document.getElementById("questions-area");
    const activeRect = active.rect.current.translated;

    if (questionsArea && activeRect) {
      const containerRect = questionsArea.getBoundingClientRect();
      const isWithinContainer =
        activeRect.top < containerRect.bottom &&
        activeRect.bottom > containerRect.top &&
        activeRect.left < containerRect.right &&
        activeRect.right > containerRect.left;

      // For new items
      if (isWithinContainer && active.data.current?.fromMenu) {
        const newItem = active.data.current;
        if (!questionList.some((q) => q.trackId === newItem.trackId)) {
          const assessmentData = {
            assessment_id: id,
            sub_questions: [transformQuestions(newItem, DefaultQuestionsData)],
            name: `${name} - ${questionList.length + 1}`
          };
          const { error, data, status } = await addQuestionRequest(
            assessmentData
          );

          if (error) {
            return;
          }
          console.log(data);
          if (status) {
            const newQuestion = {
              ...newItem,
              contextData: data.item,
            };
            setQuestionList((prev) => [...prev, newQuestion]);
          }
        }
        return;
      }

      // For sorting existing items
      if (over && active.id !== over.id) {
        setQuestionList((items) => {
          const oldIndex = items.findIndex(
            (item) => item.trackId === active.id
          );
          const newIndex = items.findIndex((item) => item.trackId === over.id);

          if (oldIndex !== -1 && newIndex !== -1) {
            return arrayMove(items, oldIndex, newIndex);
          }
          return items;
        });
      }
    }
  };
  const handleItemSelect = async (item) => {
    const assessmentData = {
      assessment_id: id,
      sub_questions: [transformQuestions(item, DefaultQuestionsData)],
      name: `${name} - ${questionList.length + 1}`
    };
    const { error, data, status } = await addQuestionRequest(assessmentData);

    if (error) {
      return;
    }
    console.log(data);
    if (status) {
      const newQuestion = {
        ...item,
        trackId: uuidv4(),
        contextData: data.item,
      };
      setQuestionList((prev) => [...prev, newQuestion]);
    }
  };

  const menuGroups = menuGroupsList.map((group) => ({
    title: group.title,
    items: group.items.map((item) => ({
      ...item,
      trackId: uuidv4(),
    })),
  }));
  const createCustomCollisionDetection = (): CollisionDetection => {
    return (args) => {
      const { active, droppableContainers, collisionRect } = args;

      // Always check if we're within the droppable container first
      const droppableContainer = droppableContainers.find(
        (container) => container.id === "questions-area"
      );

      if (!droppableContainer) return [];

      const containerRect = droppableContainer.rect.current;
      const isWithinDroppable =
        collisionRect.left < containerRect.right &&
        collisionRect.right > containerRect.left &&
        collisionRect.top < containerRect.bottom &&
        collisionRect.bottom > containerRect.top;

      if (!isWithinDroppable) return [];

      // For new items being dragged from the menu
      if (active.data.current?.fromMenu) {
        return [{ id: "questions-area" }];
      }

      // For sorting existing items
      if (active.data.current?.sortable) {
        // Use a more tolerant approach that combines strategies
        const pointerCollisions = pointerWithin(args);
        if (pointerCollisions.length > 0) return pointerCollisions;

        const rectCollisions = rectIntersection(args);
        if (rectCollisions.length > 0) return rectCollisions;

        // Fallback: if we're in the container but didn't hit anything specific
        return [{ id: "questions-area" }];
      }

      // Default case
      return [{ id: "questions-area" }];
    };
  };
  const customCollisionDetection = useMemo(
    () => createCustomCollisionDetection(),
    []
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "FIELDS",
      children: (
        <FieldsMenuWrapper
          menuData={menuGroups}
          onItemSelect={handleItemSelect}
        />
      ),
    },
    {
      key: "2",
      label: "QUESTIONS",
      children: (
        <QuestionsList
          questionList={questionList}
          setQuestionList={setQuestionList}
        />
      ),
    },
  ];

  return (
    <div className="grid grid-cols-[288px_1fr] h-full w-fullmx-auto assessment-library-page">
      <DndContext
        sensors={sensors}
        collisionDetection={
          questionList.length > 0 ? customCollisionDetection : closestCenter
        }
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        {/* Left sidebar */}
        <div className="h-full border border-mid-gray bg-light-gray px-3 py-3">
          {/* <Tabs defaultActiveKey="1" items={items} / */}
          <Tabs
            tabBarStyle={{ width: "100%", marginBottom: Sizes.padding}}
            color={Colors.textBlack}
            className="question_right_tabs"
            defaultActiveKey="1"
            items={items}
            centered
          />
        </div>

        {/* Main content */}

        <div className="right-sidebar">
          <AssessmentStudio
            questionList={questionList}
            setQuestionList={setQuestionList}
          />
        </div>
      </DndContext>
    </div>
  );
};

export default CreateAssessmentComponent;
