import React from 'react';

const BlackAssign = ({ style = {} }) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="16" height="17.904" viewBox="0 0 16 17.904">
            <path id="assignment_turned_in_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M153.925-878.552l6.33-6.33-.955-.955-5.375,5.375-2.7-2.7-.955.955Zm-5.013,4.536a1.581,1.581,0,0,1-1.16-.481,1.581,1.581,0,0,1-.481-1.16v-12.717a1.58,1.58,0,0,1,.481-1.16,1.581,1.581,0,0,1,1.16-.481h4.319a1.934,1.934,0,0,1,.625-1.346,1.978,1.978,0,0,1,1.415-.557,1.989,1.989,0,0,1,1.424.557,1.9,1.9,0,0,1,.616,1.346h4.319a1.581,1.581,0,0,1,1.16.481,1.58,1.58,0,0,1,.481,1.16v12.717a1.581,1.581,0,0,1-.481,1.16,1.581,1.581,0,0,1-1.16.481Zm0-1.345h12.717a.282.282,0,0,0,.2-.093.282.282,0,0,0,.093-.2v-12.717a.282.282,0,0,0-.093-.2.282.282,0,0,0-.2-.093H148.911a.283.283,0,0,0-.2.093.282.282,0,0,0-.093.2v12.717a.282.282,0,0,0,.093.2A.282.282,0,0,0,148.911-875.361Zm6.359-13.9a.7.7,0,0,0,.517-.2.7.7,0,0,0,.2-.517.7.7,0,0,0-.2-.517.7.7,0,0,0-.517-.2.7.7,0,0,0-.517.2.7.7,0,0,0-.2.517.7.7,0,0,0,.2.517A.7.7,0,0,0,155.27-889.26Zm-6.655,13.9v0Z"
                  transform="translate(-147.27 891.92)" fill="#000000"/>
        </svg>
    );
};

export default BlackAssign;
