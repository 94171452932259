// BasicGridded.tsx
import React from 'react';
import { GriddedBase } from './GriddedBase';
import { TableCell } from "./TableCell";
import { FontSize } from "./types";

interface BasicGriddedProps {
    columns: number;
    range: { min: number; max: number };
    fontSize: FontSize;
    onChange: (column: number, value: string) => void;
    disabled?: boolean;
    selectedValues?: string[];
    hasFixedDecimal?: boolean;
    decimalColumn?: number | string | null;
    hasFractionSlash?: boolean;
    hasFloatingDecimal?: boolean;
}

export const BasicGridded: React.FC<BasicGriddedProps> = ({
    columns,
    range,
    fontSize,
    onChange,
    disabled,
    selectedValues = [],
    hasFixedDecimal = false,
    decimalColumn = null,
    hasFractionSlash = false,
    hasFloatingDecimal = false,
    }) => {
    const length = range.max > range.min
        ? range.max - range.min + 1
        : range.min - range.max + 1;

    const numbers = Array.from(
        { length },
        (_, i) => `${Math.min(range.min, range.max) + i}`
    );

    const parsedDecimalColumn = decimalColumn !== null ? parseInt(decimalColumn.toString()) : null;

    const handleCellChange = (colIndex: number, value: string) => {
        if (value === '.' && !(hasFixedDecimal && parsedDecimalColumn === colIndex)) {
            const decimalIndex = selectedValues.indexOf('.');
            if (decimalIndex !== -1 && decimalIndex !== parsedDecimalColumn) {
                onChange(decimalIndex, '');
            }
        }
        onChange(colIndex, value);
    };

    const renderCell = (value: string, colIndex: number, rowIndex: number, isSpecial: boolean = false) => {
        if (hasFixedDecimal && parsedDecimalColumn === colIndex + 1) {
            return (
                <td
                    key={`decimal-${colIndex}`}
                    className="w-[60px] text-center bg-gray-50 border border-gray-200 font-bold"
                >
                    .
                </td>
            );
        }

        return (
            <TableCell
                key={`cell-${colIndex}`}
                value={value}
                columnIndex={colIndex}
                rowIndex={rowIndex}
                fontSize={fontSize}
                disabled={disabled || (isSpecial && value === "")}
                onChange={(value) => handleCellChange(colIndex, value)}
                selectedValue={selectedValues[colIndex] || ''}
                isSpecial={isSpecial}
            />
        );
    };

    const renderRow = (cells: JSX.Element[]) => (
        <tr>{cells}</tr>
    );

    const renderFractionSlashRow = () => renderRow(
        Array(columns).fill(null).map((_, colIndex) => {
            const isEdge = colIndex === 0 || colIndex === columns - 1;
            return renderCell(isEdge ? "" : "/", colIndex, 0, true);
        })
    );

    const renderFloatingDecimalRow = () => renderRow(
        Array(columns).fill(null).map((_, colIndex) =>
            renderCell(".", colIndex, 0, true)
        )
    );

    const getRowIndex = (index: number) => {
        let baseIndex = index;
        if (hasFractionSlash) baseIndex += 1;
        if (hasFloatingDecimal) baseIndex += 1;
        return baseIndex;
    };

    return (
        <GriddedBase>
            {hasFractionSlash && renderFractionSlashRow()}
            {hasFloatingDecimal && renderFloatingDecimalRow()}
            {numbers.map((num, rowIndex) => (
                <tr key={rowIndex}>
                    {Array(columns).fill(null).map((_, colIndex) =>
                        renderCell(num, colIndex, getRowIndex(rowIndex))
                    )}
                </tr>
            ))}
        </GriddedBase>
    );
};
