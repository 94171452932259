import { Input, Space, Typography } from "antd";

const { Text } = Typography;

const AdvancedTab = () => {
  const tabItems = [
    { label: "Acknowledgements", name: "acknowledgements" },
    { label: "Distractor rationale", name: "distractor_rationale" },
    { label: "Rubric reference", name: "rubric_reference" },
    { label: "Stimulus (review only)", name: "stimulus_review" },
    { label: "Instructor stimulus", name: "instructor_stimulus" },
    { label: "Sample answer", name: "sample_answer" },
  ];
  return (
    <Space size={[16, 16]} direction="vertical" className="w-full">
      {tabItems.map((item) => (
        <Space.Compact block key={item.name} direction="vertical">
          <Text>{item.label}</Text>
          <Input className="w-full" />
        </Space.Compact>
      ))}
    </Space>
  );
};

export default AdvancedTab;
