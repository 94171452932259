import React from 'react';
import styled from 'styled-components';
import { FontSize } from './types';

const InputWrapper = styled.div`
  display: flex;
  gap: 2px;
  margin-bottom: 8px;
`;

const Input = styled.input<{ $fontSize: FontSize }>`
  width: 60px;
  height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: ${props => props.$fontSize === 'small' ? '14px' : props.$fontSize === 'large' ? '20px' : '16px'};

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
`;

const SpecialCharInput = styled(Input)`
  background-color: #f8f9fa;
  border-color: #eee;
  font-weight: bold;
  color: #666;
`;

interface PlusMinusGriddedInputProps {
    columns: number;
    values: string[];
    fontSize: FontSize;
    onChange: (values: string[]) => void;
    disabled?: boolean;
    hasPlusMinus?: boolean;
    hasFloatingDecimal?: boolean;
    hasFixedDecimal?: boolean;
    hasFractionSlash?: boolean;
    decimalColumn?: number | string | null;
}

export const PlusMinusGriddedInput: React.FC<PlusMinusGriddedInputProps> = ({
                                                                                columns,
                                                                                values,
                                                                                fontSize,
                                                                                onChange,
                                                                                disabled,
                                                                                hasPlusMinus = false,
                                                                                hasFloatingDecimal = false,
                                                                                hasFixedDecimal = false,
                                                                                hasFractionSlash = false,
                                                                                decimalColumn = null,
                                                                            }) => {
    const parsedDecimalColumn = decimalColumn !== null ? parseInt(decimalColumn.toString()) : null;

    const handleInputChange = (index: number, value: string) => {
        const newValues = [...values];

        if (value === '.' || value === '/') {
            const symbolType = value;

            let wasSymbolPresent = false;
            newValues.forEach((val, i) => {
                if (val === symbolType) {
                    newValues[i] = '';
                    wasSymbolPresent = true;
                }
            });

            if (wasSymbolPresent && newValues[index] === '') {
                onChange(newValues);
                return;
            }

            if (
                (symbolType === '.' && !(hasFixedDecimal && parsedDecimalColumn === index)) ||
                (symbolType === '/' && hasFractionSlash)
            ) {
                newValues[index] = symbolType;
            }
        } else {
            newValues[index] = value;
        }

        onChange(newValues);
    };

    const renderInputs = () => {
        const inputs = [];

        //let num = hasFixedDecimal || !( hasPlusMinus && hasFixedDecimal ) ? columns + 1 : columns + 1;
        let num = columns + 1;
        for (let i = 0; i < num; i++) {
            const isDecimalPosition = hasFixedDecimal && parsedDecimalColumn === i;

            if (isDecimalPosition) {
                inputs.push(
                    <SpecialCharInput
                        key={`decimal-${i}`}
                        type="text"
                        value="."
                        readOnly
                        disabled
                        $fontSize={fontSize}
                        aria-label="Decimal point"
                    />
                );
            } else {
                const isPlusMinus = i === 0;
                inputs.push(
                    <Input
                        key={`input-${i}`}
                        type="text"
                        maxLength={1}
                        value={values[i] || ''}
                        placeholder={isPlusMinus ? "+" : ""}
                        onChange={(e) => handleInputChange(i, e.target.value)}
                        disabled={disabled}
                        $fontSize={fontSize}
                        aria-label={isPlusMinus ? "Plus/Minus input" : `Column ${i} input`}
                        readOnly={true}
                    />
                );
            }
        }

        return inputs;
    };

    return (
        <InputWrapper>
            {renderInputs()}
        </InputWrapper>
    );
};
