import React, { useEffect, useState, useRef, Fragment } from "react";
import { setClosePreview } from "../../../redux/slices/PreviewSlice";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useAppDispatch";
import PreviewWrapper from "components/PreviewQuestions/PreviewWrapper/PreviewWrapper";
import { generateWords } from "utils/questionUtils";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";

const PreviewClozeWithText = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "assessment",
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;
  const [responses, setResponses] = useState({});
  const [words, setWords] = useState<any[]>([]);
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const [answerStatus, setAnswerStatus] = useState({});

  const handleResponseChange = (fieldId, value) => {
    setResponses({ ...responses, [fieldId]: value });
  };

  useEffect(() => {
    if (showAnswer || localShowAnswer) {
      if (Array.isArray(currentQuestion?.correct_answer)) {
        // setElements(currentQuestion?.correct_answer);
        setWords(currentQuestion?.correct_answer);
      } else if (
        currentQuestion?.correct_answer &&
        currentQuestion?.correct_answer?.valid_response &&
        Array.isArray(currentQuestion?.correct_answer?.valid_response?.value)
      ) {
        setWords(currentQuestion?.correct_answer?.valid_response?.value);
      }
    } else {
      const newWords = generateWords(currentQuestion?.template_response);
      setWords(newWords);
      // setElements(modifiedFilledWords);
    }
  }, [currentQuestion, showAnswer, localShowAnswer]);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleCheckAnswer = () => {
    const userResponsesArray = formatUserResponses(words, responses);
    const correctAnswerArray =
      currentQuestion.correct_answer?.valid_response?.value || [];
    const filteredCorrectAnswers = correctAnswerArray.filter(
      (answer) => answer.type === "Response"
    );
    const status = {};
    userResponsesArray.forEach((userResponse, index) => {
      if (userResponse.type === "Response") {
        const correctAnswer = filteredCorrectAnswers.find(
          (answer) =>
            answer.id === userResponse.id &&
            answer.position === userResponse.position
        );

        if (correctAnswer) {
          let cleanedCorrectAnswer, cleanedUserResponse;
          if (currentQuestion?.more_options?.scoring?.case_sensitive) {
            cleanedCorrectAnswer = String(correctAnswer.value)
              .trim()
              .toLowerCase();
            cleanedUserResponse = String(userResponse.content)
              .trim()
              .toLowerCase();
          } else {
            cleanedCorrectAnswer = correctAnswer.value;
            cleanedUserResponse = userResponse.content;
          }
          status[`response-${index}`] =
            cleanedCorrectAnswer === cleanedUserResponse;
        } else {
          status[`response-${index}`] = false;
        }
      }
    });
    setAnswerStatus(status);
  };

  // Function to format user responses with id and position based on elements array
  const formatUserResponses = (elements, responses) => {
    return elements.map((element, index) => {
      if (element.type === "Response") {
        return {
          ...element,
          content: responses[`response-${index}`] || "", // Get the user's response or empty if none provided
        };
      }
      return element; // For non-response elements, return them unchanged
    });
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #D9D9D9",
          padding: "24px",
          marginBottom: "10px",
          backgroundColor: "#f0f0f0",
        }}
      >
        <div
          style={
            more_options && more_options?.layout.fontSize
              ? {
                  fontSize: more_options?.layout.fontSize,
                  lineHeight: "normal",
                  width: "100%",
                }
              : { width: "100%" }
          }
        >
          {words.map((res, index) => (
            <Fragment key={index}>
              {index > 0 && res.id === words[index - 1].id && (
                <span>&nbsp;</span>
              )}
              {index > 0 && res.id !== words[index - 1].id && <br />}
              {res.content === "Response" ? (
                <span key={index} className="align-center justify-center ml-1">
                  {showAnswer || localShowAnswer ? (
                    <span
                      className="underline font-bold"
                      style={handleResponseStyle(more_options, words, index)}
                    >
                      {res.value}
                    </span>
                  ) : (
                    <InputWithButton
                      more_options={more_options}
                      index={index}
                      showAnswer={showAnswer}
                      localShowAnswer={localShowAnswer}
                      responses={responses}
                      res={res}
                      handleResponseChange={handleResponseChange}
                      elements={words}
                      answerStatus={answerStatus}
                    />
                  )}
                </span>
              ) : res.type === "img" ? (
                res.imgposition === "" ? (
                  <img
                    key={index}
                    src={res.content}
                    width={res.width}
                    height={res.height}
                    alt={res.content}
                  />
                ) : (
                  <div style={{ textAlign: res.imgalignment }}>
                    <img
                      key={index}
                      src={res.content}
                      width={res.width}
                      height={res.height}
                      alt={res.alttext}
                      style={{ margin: "auto", display: "inline" }}
                    />
                  </div>
                )
              ) : res.type === "table" ? (
                <span
                  className="answertblclass"
                  dangerouslySetInnerHTML={{ __html: res.content }}
                />
              ) : (
                <span>{res.content}</span>
              )}
            </Fragment>
          ))}
          {currentQuestion?.more_options?.ui_style?.instant_feedback && (
            <div style={{ marginTop: "20px" }}>
              <button
                onClick={handleCheckAnswer}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Check Answer
              </button>
            </div>
          )}
        </div>
      </div>
    </PreviewWrapper>
  );
};

export default PreviewClozeWithText;

const InputWithButton = ({
  more_options,
  index,
  showAnswer,
  localShowAnswer,
  responses,
  res,
  handleResponseChange,
  elements,
  answerStatus,
}) => {
  const [showBtn, setShowBtn] = useState(false);
  const [showSpecialChars, setShowSpecialChars] = useState(false);
  const inputRef = useRef(null);

  const setInputValue = (newValue) => {
    if (inputRef.current) {
      inputRef.current.value = newValue;
      handleResponseChange(`response-${index}` ,newValue);
    }
  };
  const getInputType = () => {
    
    const allRes = elements.filter(ca => ca?.type == 'Response');
    const currentResIndex = allRes.findIndex(respo => respo?.id == res.id && respo?.position == res.position);
    let inputType = "text";
    if (more_options && more_options?.response_options?.input_type) {
      inputType = more_options?.response_options?.input_type;
    }
    if (
      more_options &&
      Array.isArray(more_options?.response_options_individaul)
    ) {
      more_options?.response_options_individaul.forEach((op, idx) => {
        if (idx === currentResIndex) {
          inputType = op?.input_type ? op.input_type : "text";
        }
      });
    }
    return inputType;
  };
  const multipleLine = !!more_options?.layout?.multiple_line;
  const borderColor =
    answerStatus[`response-${index}`] === undefined
      ? "gray" // Default color if answer has not been checked yet
      : answerStatus[`response-${index}`]
      ? "green" // If the answer is correct
      : "red"; // If the answer is incorrect
      const characterMaps = more_options && more_options?.layout && Array.isArray(more_options?.layout[`character_map`]) ?
      more_options.layout.character_map : []
  return (
    <div className="inline-block relative mx-6">
      {multipleLine && getInputType() === "text" ? (
        <textarea
          placeholder={handleResProperty(
            more_options,
            elements,
            index,
            "placeholder",
            ""
          )}
          ref={inputRef}
          id={`response-${index}`}
          value={
            !showAnswer || !localShowAnswer
              ? responses[`response-${index}`]
                ? responses[`response-${index}`]
                : ""
              : res?.value || ""
          }
          spellCheck={
            more_options && more_options?.layout?.spellcheck
              ? more_options?.layout?.spellcheck == true
              : false
          }
          onChange={(e) =>
            handleResponseChange(`response-${index}`, e.target.value)
          }
          className=" rounded px-3 align-middle border-none focus:border-none"
          style={handleResponseStyle(more_options, elements, index)}
          onFocus={() => {
            setShowBtn(true);
          }}
          onBlur={(e) => {
            if (!e.relatedTarget || e.relatedTarget.tagName !== "BUTTON") {
              setShowBtn(false);
              setShowSpecialChars(false);
            }
          }}
        />
      ) : (
        <input
        ref={inputRef}
        type={getInputType()}
          placeholder={handleResProperty(
            more_options,
            elements,
            index,
            "placeholder",
            ""
          )}
          id={`response-${index}`}
          spellCheck={
            more_options &&
            more_options?.layout?.spellcheck &&
            more_options?.layout?.spellcheck
          }
          value={
            !showAnswer || !localShowAnswer
              ? responses[`response-${index}`]
                ? responses[`response-${index}`]
                : ""
              : res?.value || ""
          }
          onChange={(e) =>
            handleResponseChange(`response-${index}`, e.target.value)
          }
          className="rounded px-3 py-2 mb-2"
          style={{
            border: `2px solid ${borderColor}`,
            ...handleResponseStyle(more_options, elements, index),
          }}
          onFocus={() => {setShowBtn(true)}}
          onBlur={(e) => {
            if (!e.relatedTarget || e.relatedTarget.tagName !== 'BUTTON') {
              setShowBtn(false);
              setShowSpecialChars(false);
            }
          }}
        />
      )}
      {characterMaps.length > 0 && (
          <>
            <button
              type="button"
              className="bg-gray-300 h-[30px] w-6 text-black rounded"
              title="Special Characters"
              aria-label="Special Characters"
              style={{
                display: showBtn ? 'inline-block' : 'none',
                opacity: 1,
                margin: '0px 0px 0px -23px',
                position: 'absolute',
                zIndex: 100,
              }}
              onMouseDown={(e) => e.preventDefault()} // Prevent input blur
              onClick={() => {
                inputRef.current.focus();
                setShowBtn(true);
                setShowSpecialChars(true);
              }}
            >
              á
            </button>
            <div
              className=" absolute bg-slate-50 text-black p-4 z-50"
              style={{
                display: showSpecialChars ? 'block' : 'none',
                top: 30,
                border: '1px solid black',
              }}
            >
              <p>Special Characters: </p>
              <div className="flex ">
                {characterMaps.map((char, index) => (
                  <button
                    key={index}
                    className="bg-gray-400 h-[30px] w-6 text-black"
                    onMouseDown={(e) => e.preventDefault()} // Prevent input blur
                    onClick={() => {
                      inputRef.current.focus();
                      setShowBtn(true);
                      setShowSpecialChars(true);
                      setInputValue(inputRef.current.value + char);
                    }}
                  >
                    {char}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

const handleResponseStyle = (moreOptions, correctAnswers, index) => {
  let style = {
    width: "fit-content",
    height: "fit-content",
    fontSize: "inherit",
    lineHeight: "normal",
    minWidth: "fit-content",
  };

  // Apply global response options
  if (moreOptions?.response_options) {
    style = {
      ...style,
      ...moreOptions.response_options,
      width: moreOptions.response_options.width
        ? `${moreOptions.response_options.width}px`
        : "fit-content",
      height: moreOptions.response_options.height
        ? `${moreOptions.response_options.height}px`
        : "fit-content",
      fontSize: moreOptions?.layout?.fontSize
        ? `${moreOptions.layout.fontSize}`
        : "inherit",
    };
  }

  // Apply individual response options
  const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
  if (resOptionsIndividaul.length > 0) {
    const allRes = correctAnswers.filter((ca) => ca?.type === "Response");
    const currentResFromCorrectAnswers = correctAnswers[index];
    const currentResIndex = allRes.findIndex(
      (res) => currentResFromCorrectAnswers === res
    );

    if (currentResIndex > -1) {
      const currentResOptions = resOptionsIndividaul[currentResIndex];
      if (currentResOptions) {
        style = {
          ...style,
          width: currentResOptions?.width
            ? `${currentResOptions.width}px`
            : style.width,
          height: currentResOptions?.height
            ? `${currentResOptions.height}px`
            : style.height,
          fontSize: currentResOptions?.fontSize || style.fontSize,
        };
      }
    }
  }

  return style;
};
const handleResProperty = (
  moreOptions,
  correctAnswers,
  index,
  propName,
  defaultValue = ""
) => {
  let val =
    moreOptions && moreOptions?.response_options[`${propName}`]
      ? moreOptions?.response_options[`${propName}`]
      : defaultValue;

  const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
  if (moreOptions && resOptionsIndividaul.length > 0) {
    const allRes = correctAnswers.filter((ca) => ca?.type === "Response");
    const currentResFromCorrectAnswers = correctAnswers[index];
    const currentResIndex = allRes.findIndex(
      (res) => currentResFromCorrectAnswers === res
    );
    if (currentResIndex > -1) {
      const currentResOptions = resOptionsIndividaul.find(
        (_item, idx) => idx === currentResIndex
      );
      if (currentResOptions) {
        val = currentResOptions[`${propName}`]
          ? currentResOptions[`${propName}`]
          : val;
      }
    }
  }

  return val;
};
