import React from "react";
import { Button, Select } from "antd";
import {
  FirstPageIcon,
  PrevPageIcon,
  NextPageIcon,
  LastPageIcon,
  BookIcon,
} from "../../icons/PaginationIcons";
import "./CustomPagination.styles.scss";
import Divider from "../../icons/Divider";

interface CustomPaginationProps {
  current: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  totalSelectedRows?: number;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  current,
  pageSize,
  total,
  onChange,
  onPageSizeChange,
  totalSelectedRows,
}) => {
  const totalPages = Math.ceil(total / pageSize);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onChange(newPage);
    }
  };

  return (
    <div className="custom-pagination">
      <div className="pagination-left">
        <span className="selected-count">
          {totalSelectedRows ? totalSelectedRows : "0"} Selected
        </span>
      </div>
      <div className="pagination-center">
        <div className="pagination-controls">
          <Button
            icon={<FirstPageIcon />}
            disabled={current === 1}
            onClick={() => handlePageChange(1)}
            className="pagination-button"
          />
          <Button
            icon={<PrevPageIcon />}
            disabled={current === 1}
            onClick={() => handlePageChange(current - 1)}
            className="pagination-button"
          />

          <div className="page-info">
            <input
              type="number"
              value={current}
              onChange={(e) => handlePageChange(Number(e.target.value))}
              className="page-input"
            />
            <span className="page-arrow">→</span>
          </div>

          <Button
            icon={<NextPageIcon />}
            disabled={current === totalPages}
            onClick={() => handlePageChange(current + 1)}
            className="pagination-button"
          />
          <Button
            icon={<LastPageIcon />}
            disabled={current === totalPages}
            onClick={() => handlePageChange(totalPages)}
            className="pagination-button"
          />
        </div>
      </div>
      <div className="pagination-right">
        <BookIcon />
        <div className="page-count">
          <span>
            {current} of {totalPages}
          </span>
        </div>
        <Divider />
        <div className="rows-per-page">
          <span>Rows per page</span>
          <Select
            value={pageSize}
            onChange={onPageSizeChange}
            options={[
              { value: 10, label: "10" },
              { value: 20, label: "20" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
            ]}
            className="page-size-select"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPagination;
