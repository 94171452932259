import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import Loading from '../Loading';
import InputWithLabel from '../InputWithLabel';
import { RootState } from '../../redux/store';
import { getQuestion } from '../../redux/slices/showQuestionSlice';
import { editVal } from '../../redux/slices/EditQuestionSlice';
import { Block, ErrorMessage } from '../styled';
import * as PreviewComponents from '../../pages/Assessments/PreviewComponents';
import PreviewMatchList from 'components/PreviewQuestions/PreviewMatchList/PreviewMatchList';
import PreviewClassification from 'components/PreviewQuestions/PreviewClassification/PreviewClassification';
import PreviewSortList from 'components/PreviewQuestions/PreviewSortList/PreviewSortList';
import PreviewOrderList from 'components/PreviewQuestions/PreviewOrderList/PreviewOrderList';
import { ChartQuestionTypes, DrawingTypes, ChemistryTypes } from 'pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants';
import { MultiSelect } from 'react-multi-select-component';
import { getListTagTypes } from 'services/tags';
import { getSubjectsRequest } from 'api-rest/subjects';

const EditBasicDetails: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isInitialLoad = useRef(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subQuestionId, setSubQuestionId] = useState();
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagTypes, setTagTypes] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const {  formState: { errors } } = useForm();

  const { name, subject, grade, tags: questionTags } = useSelector(
    (state: RootState) => state?.showQuestion?.currentQuestion ?? {}
  );
  const { currentQuestion } = useSelector((state: RootState) => state.showQuestion);
  const editQuestion = useSelector((state: RootState) => state.editQuestion);

  // Get the selected tag type ID from Redux
  const selectedTagTypeId = editQuestion?.tagTypeId || null;

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: currentQuestion?.name || '',
      subject_id: currentQuestion?.subject?.id || '',
      grade_id: currentQuestion?.grade?.id || '',
      tagTypeId: currentQuestion?.tagTypeId || null,
      tags: currentQuestion?.tags || [],
    },
  });
  useEffect(() => {
    if (currentQuestion) {
      setValue('name', currentQuestion.name);
      setValue('subject_id', currentQuestion.subject?.id || '');
      setValue('grade_id', currentQuestion.grade?.id || '');
      setValue('tagTypeId', currentQuestion.tagTypeId || null);
      setValue('tags', currentQuestion.tags || []);
    }
  }, [currentQuestion, setValue]);
  const subjectValue = watch('subject_id');
  const gradeValue = watch('grade_id');
  useEffect(() => {
    if (editQuestion.name) {
      setValue('name', editQuestion.name);
    }
  }, [editQuestion.name, editQuestion.tags, tagTypes]);
  const handleDropdownChange = (
    type: 'subject' | 'grade',
    value: string
  ) => {
    if (type === 'subject') {
      if (value === "") {
        setGrades([]);
        setValue('grade_id', '');
        dispatch(editVal({ key: 'subject', val: null }));
        dispatch(editVal({ key: 'grade', val: null }));
        resetTagStates();

      } else {
        const selectedSubject = allSubjects.find((s) => s.id === Number(value));
        if (selectedSubject) {
          dispatch(editVal({ key: 'subject', val: selectedSubject }));
          dispatch(editVal({ key: 'grade', val: null }));
          setValue('subject_id', selectedSubject.id);
          setValue('grade_id', '');
          setGrades(selectedSubject.grades || []);
          resetTagStates();
        }
      }
    } else if (type === 'grade' && editQuestion?.subject?.grades) {
      if (value === "") {
        setGrades([]);
        setValue('grade_id', '');
        dispatch(editVal({ key: 'grade', val: null }));
        resetTagStates();

      }
      const selectedGrade = editQuestion.subject.grades.find((g) => g.id === Number(value));
      if (selectedGrade) {
        dispatch(editVal({ key: 'grade', val: selectedGrade }));
        setValue('grade_id', selectedGrade.id);
        resetTagStates();
      }
    }
  };

  const handleTagTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTagTypeId = e.target.value;
    dispatch(editVal({ key: 'tagTypeId', val: selectedTagTypeId }));

    const selectedTagType = tagTypes.find((tagType) => tagType.id.toString() === selectedTagTypeId);
    if (selectedTagType) {
      const tagOptions = selectedTagType.tags?.map((tag) => ({
        label: tag.name,
        value: tag.id,
      })) || [];
      setTags(tagOptions);
      setSelectedTags([]);
      dispatch(editVal({ key: 'tags', val: [] }));
    } else {
      setTags([]);
      setSelectedTags([]);
      dispatch(editVal({ key: 'tags', val: [] }));
    }
  };

  const handleTagSelectionChange = (selected: any) => {
    setSelectedTags(selected);
    const tagIds = selected.map((tag: any) => tag.value);
    dispatch(editVal({ key: 'tags', val: tagIds }));
  };

  const resetTagStates = () => {
    setTagTypes([]);
    setTags([]);
    setSelectedTags([]);
    dispatch(editVal({ key: 'tags', val: [] }));
    dispatch(editVal({ key: 'tagTypeId', val: null }));
  };

  useEffect(() => {
    if (!isDataLoaded) return;

    const gradeId = currentQuestion?.grade_id || gradeValue;
    const subjectId = currentQuestion?.subject_id || subjectValue;

    if (gradeId && subjectId) {
      getListTagTypes({ subjectId, gradeId })
        .then((res) => {
          const tagTypesList = res?.data.items || [];

          const filteredTagTypes = tagTypesList.filter((tagType) => tagType.question === true);
          setTagTypes(filteredTagTypes);

          if (isInitialLoad.current && questionTags?.length) {
            const relevantTagType = filteredTagTypes.find((tagType) =>
              tagType.tags?.some((tag) => questionTags.some((t) => t.id === tag.id))
            );

            if (relevantTagType) {
              dispatch(editVal({ key: 'tagTypeId', val: relevantTagType.id }));
              const tagOptions = relevantTagType.tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })) || [];
              setTags(tagOptions);
              setSelectedTags(tagOptions.filter((tag) => questionTags.some((t) => t.id === tag.value)));
            }
            isInitialLoad.current = false;
          } else {
            dispatch(editVal({ key: 'tagTypeId', val: null }));
            setTags([]);
            setSelectedTags([]);
          }
        })
        .catch((err) => console.log('Error fetching tag types:', err));
    }
  }, [currentQuestion, subjectValue, gradeValue, questionTags, isDataLoaded]);
  useEffect(() => {
    getSubjectsRequest()
      .then((res) => {
        const subjectsList = res?.data.data || [];
        setAllSubjects(subjectsList);
      })
      .catch((err) => console.log('Error fetching subjects:', err));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getQuestion(id) as any)
        .then(() => {
          setIsDataLoaded(true);
        })
        .catch((err) => console.log('Error fetching question:', err));
    }
  }, [id]);

  useEffect(() => {
    if (!isDataLoaded || !currentQuestion) return;
    if (subjectValue && gradeValue) {
      getListTagTypes({ subjectId: subjectValue, gradeId: gradeValue })
        .then((res) => {
          const tagTypesList = res?.data.items || [];
          const filteredTagTypes = tagTypesList.filter((tagType) => tagType.question === true);
          setTagTypes(filteredTagTypes);
        })
        .catch((err) => console.log('Error fetching tag types:', err));
    }
  }, [subjectValue, gradeValue, isDataLoaded, currentQuestion]);
  useEffect(() => {
    if (editQuestion) {
      setValue('name', editQuestion.name);
      setValue('subject_id', editQuestion.subject?.id || '');
      setValue('grade_id', editQuestion.grade?.id || '');
      setValue('tagTypeId', editQuestion.tagTypeId || null);
      setValue('tags', editQuestion.tags || []);
    }
  }, [editQuestion, setValue]);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 5000);
  }, []);

  const renderQuestionPreview = (question: any) => {
    const PreviewMap: Record<string, React.FC<any>> = {
      mcs: PreviewComponents.PreviewMultipleStandard,
      mcmr: PreviewComponents.PreviewMultipleResponses,
      taf: PreviewComponents.PreviewTrueFalse,
      mcbl: PreviewComponents.PreviewBlockLayout,
      cms: PreviewComponents.PreviewMatrixStandard,
      cmi: PreviewComponents.PreviewMatrixStandard,
      cml: PreviewComponents.PreviewMatrixStandard,
      cmsnl: PreviewComponents.PreviewMatrixStandard,
      cwdad: PreviewComponents.PreviewClozeDragDrop,
      cwdd: PreviewComponents.PreviewClozeDropDown,
      cwt: PreviewComponents.PreviewClozeWithText,
      math: PreviewComponents.PreviewMath,
      clozmat: PreviewComponents.PreviewClozeMath,
      clozmwIm: PreviewComponents.PreviewClozeImageMath,
      liwdd: PreviewComponents.PreviewImageDragDrop,
      liwt: PreviewComponents.PreviewImageWithText,
      liwddo: PreviewComponents.PreviewImageDropDown,
      machli: PreviewMatchList,
      class: PreviewClassification,
      mewrt: PreviewComponents.PreviewMathEssayWithText,
      ewrt: PreviewComponents.PreviewWrittenRecordedMathEssayRT,
      st: PreviewComponents.PreviewShortText,
      liwm: PreviewComponents.PreviewLabelmageMath,
      ewpt: PreviewComponents.PreviewWrittenRecordedMathEssayPT,
      ordlis: PreviewOrderList,
      ar: PreviewComponents.PreviewAudioRecorder,
      sortlis: PreviewSortList,
      fileup: PreviewComponents.PreviewOtherFileUpload,
      graph: PreviewComponents.PreviewGraphingUnit,
      shad: PreviewComponents.PreviewShading,
      imup: PreviewComponents.PreviewImageAnnotation,
      draw: PreviewComponents.PreviewDrawing,
      [ChartQuestionTypes.BARCHART]: PreviewComponents.PreviewSimpleChart,
      [ChartQuestionTypes.HISTOGRAM]: PreviewComponents.PreviewHistogram,
      [ChartQuestionTypes.LINECHART]: PreviewComponents.PreviewLineChart,
      [ChartQuestionTypes.DOTPLOT]: PreviewComponents.PreviewDotPlot,
      [ChartQuestionTypes.LINEPLOT]: PreviewComponents.PreviewLinePlot,
      [DrawingTypes.TOKEN_HIGHLIGHT]: PreviewComponents.PreviewTokenHighlight,
      [ChemistryTypes.CHEM_FORMULA]: PreviewComponents.PreviewChemistryFormula,
      rating: PreviewComponents.PreviewRating,
      grid: PreviewComponents.PreviewGridded,
    };

    const Component = PreviewMap[question?.type];
    return Component ? (
      <Component
        key={question?.id}
        currentQuestion={question}
        showAnswer={false}
        editMode={true}
        parentMode="question"
      />
    ) : (
      <div>{`Preview not available for type: ${question.type}`}</div>
    );
  };
if (!isDataLoaded || !currentQuestion) {
  return <Loading />;
}
  return (
    <>
      {isLoading ? (
        <div style={{ marginTop: '-150px' }}>
          <Loading />
        </div>
      ) : (
        <>
          <h2 className="font-bold mb-4">Question: {name || ''}</h2>
          <div className="flex gap-5 mt-5 pb-4">
            <Block>
              <InputWithLabel
                {...register('name', { required: true })}
                label="Question Name*"
                placeholder="Enter Question Name*"
                error={errors?.name}
                onChange={(e) => dispatch(editVal({ key: 'name', val: e.target.value }))}
                value={editQuestion?.name ?? name ?? ''}
                 width="350px"
              />
            </Block>

            <Block>
              <div className="align-center justify-center mt-1">
                <p className="font-semibold">Subjects*</p>
                <select
                  onChange={(e) => handleDropdownChange('subject', e.target.value)}
                  value={editQuestion?.subject?.id || ''}
                  className="h-10 mt-1 rounded-md p-2 border border-solid"
                >
                  <option value="" >Select Subject</option>
                  {allSubjects.map((s) => (
                    <option key={s?.id} value={s?.id}>
                      {s?.name}
                    </option>
                  ))}
                </select>
                {error && <ErrorMessage>Subject is required.</ErrorMessage>}
              </div>
            </Block>

            <Block>
              <div className="align-center justify-center mt-1">
                <p className="font-semibold">Grades</p>
                <select
                  onChange={(e) => handleDropdownChange('grade', e.target.value)}
                  value={editQuestion?.grade?.id || ''}
                  className="h-10 mt-1 rounded-md p-2 border border-solid"
                >
                  <option value="">Select Grade</option>
                  {editQuestion?.subject?.grades?.map((g) => (
                    <option key={g?.id} value={g?.id}>
                      {g?.label || g?.name}
                    </option>
                  ))}
                </select>
              </div>
            </Block>
            <Block>
              <div className="align-center justify-center mt-1">
                <p className="font-semibold">Tag Types</p>
                <select
                  onChange={handleTagTypeChange}
                  value={selectedTagTypeId || ''}
                  className="h-10 mt-1 rounded-md p-2 border border-solid"
                >
                  <option value="">Select Tag Type</option>
                  {tagTypes.map((tagType) => (
                    <option key={tagType.id} value={tagType.id}>
                      {tagType.name}
                    </option>
                  ))}
                </select>
              </div>
            </Block>

            <Block>
              <div className="align-center justify-center mt-1">
                <p className="font-semibold">Tags</p>
                <div style={{ position: 'absolute', zIndex: 1000, width: '17%' }}>                  <MultiSelect
                    options={tags}
                    value={selectedTags}
                    onChange={handleTagSelectionChange}
                    labelledBy="Select Tags"
                    className="multi-select"
                  />
                </div>
              </div>
            </Block>
          </div>

          {currentQuestion?.sub_questions?.map(renderQuestionPreview)}

          {isModalOpen && (
            <Modal
              setIsModalOpen={setIsModalOpen}
              id={subQuestionId}
              isModalOpen={isModalOpen}
              api="api/subQuestion"
              type="Sub Question"
              method={async () => dispatch(getQuestion(id) as any)}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditBasicDetails;
