interface IconProps {
  fill?: string;
  className?: string;
}
const ImportIcon = ({ fill = "currentColor", className = "" }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M15.3069 0V18.2664L9.99034 12.9498L7.85661 15.106L16.835 24.0844L25.8237 15.106L23.6905 12.9498L18.374 18.2664V0H15.3069ZM0.834961 22.4297V28.262C0.834961 29.2986 1.19872 30.1808 1.92622 30.9088C2.65408 31.6362 3.53653 32 4.57352 32H29.0964C30.1334 32 31.0158 31.6362 31.7437 30.9088C32.4712 30.1808 32.835 29.2986 32.835 28.262V22.4297H29.7678V28.2581C29.7678 28.4269 29.6977 28.5817 29.5573 28.7224C29.4167 28.8627 29.2619 28.9329 29.0931 28.9329H4.57681C4.40799 28.9329 4.25325 28.8627 4.11256 28.7224C3.97226 28.5817 3.9021 28.4269 3.9021 28.2581V22.4297H0.834961Z"
      fill={fill} className={className}
    />
  </svg>
);
export default ImportIcon;
