import React from "react";
import EditPen from "studio/icons/EditPen";
import AssessmentStudioAdd from "../../icons/AssessmentStudioAdd";
import ImportIcon from "studio/icons/ImportIcon";
import { Dropdown, Radio, MenuProps } from "antd";
import More from "studio/icons/More";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './createAssessment.css';
import BlackDelete from "studio/icons/BlackDelete";
import BlackEdit from "studio/icons/BlackEdit";

interface Question {
  trackId: string;
  templateTitle: string;
  template?: React.ReactNode;
}

export interface LocationState {
  name: string;
  subjects: {
    name: string;
    id?: string;
  };
  id?: string;
  grades: {
    name: string;
    id?: string;
  };
  tags: Array<{
    name: string;
    id?: string;
  }>;
}

interface SortableItemProps {
  id: string;
  question: Question;
  index: number;
  onDelete: (id: string) => void;
  onEdit: (item: any) => void;
}

interface AssessmentStudioProps {
  questionList: Question[];
  setQuestionList: React.Dispatch<React.SetStateAction<Question[]>>;
}

const getMenuItems = (
  questionId: string,
  onEdit: (item: any) => void,
  onDelete: (id: string) => void,
  question: Question
): MenuProps["items"] => [
  {
    // label: "Edit",
    key: "edit",
    
    onClick: () => onEdit(question),
    label: (
      <div
          className="dropdown-item"
          onClick={(e) => {
              e.stopPropagation();
              onEdit(question);
          }}
      >
          <BlackEdit /> Edit
      </div>
  ),
  },
  {
    key: "delete",
    // onClick: () => onDelete(questionId),
    label: (
      <div
          className="dropdown-item"
          onClick={(e) => {
              e.stopPropagation();
              onDelete(questionId);
          }}
      >
          <BlackDelete /> Delete
      </div>
  ),
  },
];

function stripHtmlTags(htmlString) {
  return htmlString.replace(/<[^>]*>?/gm, '');
}

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  onEdit,
  question,
  index,
  onDelete,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
    isDragging,
  } = useSortable({
    id,
    animateLayoutChanges: () => true,
    data: {
      sortable: true,
      // Mark items as sortable
    },
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || "transform 200ms ease-in-out",
    position: "relative",
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : 0,
    backgroundColor: isDragging ? "rgba(245, 245, 245, 0.8)" : "transparent",
    boxShadow: isDragging ? "0 2px 4px rgba(0,0,0,0.1)" : "none",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      data-draggable="sortable"
      className={`flex flex-col flex-grow gap-3 py-3 border-b border-b-gray-light ${
        isDragging ? "cursor-grabbing" : "cursor-grab"
      }`}
    >
      <div className="flex flex-col items-start justify-between">
        <p className="text-dark-gray text-base font-medium">
          Q{index + 1}. {stripHtmlTags(question.templateTitle)}
        </p>
        {/* <Dropdown
          menu={{
            items: getMenuItems(id, onEdit, onDelete, question),
          }}
          trigger={["click"]}
        >
          <div className="cursor-pointer" onClick={(e) => e.stopPropagation()}>
            <More />
          </div>
        </Dropdown> */}
      </div>
      {question.template !== null ? (
        question.template
      ) : (
        <Radio.Group
          className="flex flex-col gap-3 px-4"
          value={""}
          options={[
            { value: 1, label: "Option A" },
            { value: 2, label: "Option B" },
            { value: 3, label: "Option C" },
            { value: 4, label: "Option D" },
          ]}
        />
      )}
    </div>
  );
};

const AssessmentStudio: React.FC<AssessmentStudioProps> = ({
  questionList = [],
  setQuestionList,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as LocationState;
  console.log('statestate',state);
  
  const { name, subjects, id, grades, tags } = state;

  const { setNodeRef, isOver } = useDroppable({
    id: "questions-area",
  });

  const tagsList = tags?.map((tag) => tag.name).join(", ");

  const handleDeleteQuestion = (questionId: string) => {
    setQuestionList((prevList) =>
      prevList.filter((q) => q.trackId !== questionId)
    );
  };
  const handleEditQuestion = (question: any) => {
    console.log('questionEdit',question);
    navigate(`/question-edit/${question.contextData.id}`);
  };

  return (
    <div className="w-full flex flex-col  pt-6">
      <header className="flex items-center justify-between pb-3 border-b border-b-gray-light mr-36 ml-12">
        {state && <div className="flex flex-col gap-2 flex-grow">
          <p className="text-lg font-medium text-dark-gray flex items-center gap-4 mb-2 justify-between">
            {name}{" "}
            <Link to={`/edit-assessment/${id}`}>
              <EditPen className="cursor-pointer" />
            </Link>
          </p>
          {tagsList && (
            <span className=" text-dark-gray text-xs">{tagsList}</span>
          )}
          <div className="flex items-center gap-1 text-xs">
            <div className="py-2 px-3 bg-dark-gray rounded-full border border-light-dark-gray text-white text-xs">
              {subjects?.name}
            </div>
            {grades && grades?.name && (
              <div className="py-2 px-3 bg-white rounded-full border border-light-dark-gray text-dark-gray text-xs">
                {grades?.name}
              </div>
            )}
          </div>
        </div>}
        {/* <History /> */}
      </header>
      <main
        ref={setNodeRef}
        data-droppable="questions-area"
        data-sortable="sortable"
        id="questions-area"
        className={`flex-grow mr-28 ml-6 pl-6 pr-14 ${
          isOver
            ? "bg-gray-50 rounded-md border border-dashed border-light-dark-gray "
            : ""
        } `}
      >
        <SortableContext
          items={questionList.map((q) => q.trackId)}
          strategy={verticalListSortingStrategy}
        >
          {questionList.length > 0
            ? questionList.map((question, index) => (
              <div className="flex items-center justify-between"> 
                <SortableItem
                  key={question.trackId}
                  id={question.trackId}
                  question={question}
                  index={index}
                  onDelete={handleDeleteQuestion}
                  onEdit={handleEditQuestion}
                />
                  <Dropdown
                    menu={{
                      items: getMenuItems(id, handleEditQuestion, handleDeleteQuestion, question),
                    }}
                    trigger={["click"]}
                    className="self-start py-3 ml-6 px-0"
                    placement="bottomRight"
                  >
                    <div className="cursor-pointer" onClick={(e) => e.stopPropagation()}>
                      <More />
                    </div>
                  </Dropdown>
              </div>
              ))
            : <p className="text-base text-dark-gray text-balance text-center py-4">Drop questions here</p>}
        </SortableContext>
      </main>
      <footer className="flex items-center justify-start gap-3 py-5 pr-36 pl-12">
        <div className="basis-2/3 py-12 px-14 lg:px-24 bg-gray-50 rounded-md custom-dashed-border text-dark-gray text-xs flex flex-col gap-4 items-center justify-center w-[550px]">
          <AssessmentStudioAdd />
          <p className="text-base text-dark-gray text-balance text-center">
            Double-click the field in the{" "}
            <span className="font-bold">Fields</span> pane or drag and drop it
            here to start editing.
          </p>
        </div>
        <div className="py-12 px-12 bg-gray-light-200  rounded-md custom-dashed-border text-dark-gray text-xs flex flex-col gap-4 items-center justify-center w-[384px] cursor-pointer">
          <ImportIcon />
          <p className="text-base text-dark-gray text-balance text-center">
            Import a question from questions bank
          </p>
        </div>
      </footer>
    </div>
  );
};

export default AssessmentStudio;
