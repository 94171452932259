import { Divider } from "antd";
import ScoringSection from "./ScoringSection.component";
import LayoutPropertiesSection from "./LayoutPropertiesSection.component";

const PropertiesTab = ({
  data,
  handleChangeValue,
}: {
  data: any;
  handleChangeValue: (key: string, subKey: string, value: any) => void;
}) => {
  return (
    <>
      <ScoringSection data={data} handleChangeValue={handleChangeValue} />
      <Divider />
      <LayoutPropertiesSection
        data={data}
        handleChangeValue={handleChangeValue}
      />
    </>
  );
};

export default PropertiesTab;
