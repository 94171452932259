import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ShadingCell } from "./ShadingCell";
import { ShadingGridProps, GridContainerProps } from "./types";
import { toast } from "react-toastify";
import Tooltip from "./Tooltip";

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$columnCount}, 1fr);
  width: ${(props) => (props.isOverflow ? "inherit" : "fit-content")};
  overflow-x: ${(props) => (props.isOverflow ? "scroll" : "visible")};
  margin: 0 auto;
  background-color: #e6f2fc;
  position: relative;

  ${(props) =>
    props.$style &&
    `
        border-left: ${props.$style.left?.border_width}px ${props.$style.left?.border_style} ${props.$style.left?.border_color};
        border-top: ${props.$style.top?.border_width}px ${props.$style.top?.border_style} ${props.$style.top?.border_color};
        border-right: ${props.$style.right?.border_width}px ${props.$style.right?.border_style} ${props.$style.right?.border_color};
        border-bottom: ${props.$style.bottom?.border_width}px ${props.$style.bottom?.border_style} ${props.$style.bottom?.border_color};
    `}
`;

const BackgroundImage = styled.img<{
  opacity?: number;
  width?: number;
  height?: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => `${props.width}%` || "100%"};
  height: ${(props) => `${props.height}%` || "100%"};
  max-width: 100%;
  max-height: 100%;
  position-area: center;
  object-fit: cover;
  opacity: ${(props) => props.opacity || 1};
`;

// Helper function to get correct cells for byCount method
const getCorrectCellsByCount = (
  rowCount: number,
  columnCount: number,
  targetCount: number,
  excludeCells: [number, number][]
): [number, number][] => {
  const result: [number, number][] = [];
  let count = 0;

  for (let row = 0; row < rowCount && count < targetCount; row++) {
    for (let col = 0; col < columnCount && count < targetCount; col++) {
      if (!excludeCells.some(([r, c]) => r === row && c === col)) {
        result.push([row, col]);
        count++;
      }
    }
  }

  return result;
};

const ShadingGrid: React.FC<ShadingGridProps> = ({
  rowCount,
  columnCount,
  cellWidth,
  cellHeight,
  shadedCells,
  hideCells,
  lockShadedCells,
  possibleResponses,
  correctAnswer,
  showAnswer,
  image,
  title,
  alt,
  canvasStyle,
  borderType,
  onCellClick,
  isHoverState,
  maxSelection,
  backgroundImage,
  opacity,
  width,
  height,
  backgroundImageAlt,
  backgroundImageTitle,
}) => {
  // Store user selections separately from the default selections
  const [userSelectedCells, setUserSelectedCells] =
    useState<[number, number][]>(possibleResponses);
  const [previousSelectedCells, setpreviousSelectedCells] =
    useState<[number, number][]>(possibleResponses);

  // Effect to handle show answer state changes
  useEffect(() => {
    if (showAnswer && correctAnswer) {
      if ("method" in correctAnswer && correctAnswer.method === "byLocation") {
        // Keep user selections and add correct answers
        const correctCells = correctAnswer.value as [number, number][];
        setpreviousSelectedCells([...userSelectedCells]);
        setUserSelectedCells([...userSelectedCells, ...correctCells]);
      } else if (
        "method" in correctAnswer &&
        correctAnswer.method === "byCount"
      ) {
        const targetCount = parseInt(correctAnswer.value as string, 10);
        setpreviousSelectedCells([...userSelectedCells]);
        setUserSelectedCells(userSelectedCells.slice(0, targetCount));
      } else if (Array.isArray(correctAnswer)) {
        setpreviousSelectedCells([...userSelectedCells]);
        setUserSelectedCells([...userSelectedCells, ...correctAnswer]);
      }
    } else {
      // When showAnswer becomes false, restore user selections
      setUserSelectedCells([...previousSelectedCells]);
    }
  }, [showAnswer, correctAnswer]);

  const isShaded = (row: number, col: number) =>
    shadedCells.some(([r, c]) => r === row && c === col);

  const correctCellsByCount = useMemo(() => {
    if (
      correctAnswer &&
      "method" in correctAnswer &&
      correctAnswer.method === "byCount"
    ) {
      const targetCount = parseInt(correctAnswer.value as string, 10);
      return getCorrectCellsByCount(rowCount, columnCount, targetCount, []);
    }
    return [];
  }, [correctAnswer, rowCount, columnCount]);

  const isCorrect = useMemo(
    () =>
      (row: number, col: number): boolean => {
        if (!correctAnswer) return false;

        if (
          "method" in correctAnswer &&
          correctAnswer.method === "byLocation"
        ) {
          return (correctAnswer.value as [number, number][]).some(
            ([r, c]) => r === row && c === col
          );
        } else if (
          "method" in correctAnswer &&
          correctAnswer.method === "byCount"
        ) {
          return correctCellsByCount.some(([r, c]) => r === row && c === col);
        }
        return false;
      },
    [correctAnswer, correctCellsByCount]
  );

  const isSelected = useCallback(
    (row: number, col: number) => {
      return userSelectedCells.some(([r, c]) => r === row && c === col);
    },
    [userSelectedCells]
  ); // Dependencies: selectedCells

  const handleItemClick = (row: number, column: number) => {
    const isShaded = shadedCells.some(([r, c]) => r === row && c === column);
    const isLocked = isShaded && lockShadedCells;
    
    if (isLocked || showAnswer) return;
    // Count only user-selected cells for maxSelection check
    const userOnly = userSelectedCells?.filter(
      (cell) =>
        !possibleResponses?.some(
          (item) => item[0] == cell[0] && item[1] == cell[1]
        )
    );
    const userSelectedCount = userOnly.length;

    const indexPair: [number, number] = [row, column];
    const alreadySelected = userSelectedCells.some(
      ([r, c]) => r === row && c === column
    );

    if (alreadySelected) {
      setUserSelectedCells((prev) =>
        prev.filter(([r, c]) => !(r === row && c === column))
      );
    } else {
      if (
        typeof maxSelection === "number" &&
        userSelectedCount >= maxSelection
      ) {
        toast.warning("Maximum selection limit reached!");
        return;
      }
      setUserSelectedCells((prev) => [...prev, indexPair]);
    }

    if (onCellClick) {
      onCellClick(row, column);
    }
  };

  const totalWidth = columnCount * cellWidth;
  const isOverflow = totalWidth > window.innerWidth;

  return (
    <GridContainer
      $columnCount={columnCount}
      isOverflow={isOverflow}
      $style={canvasStyle}
      backgroundImage={backgroundImage}
      opacity={opacity}
      width={width}
      height={height}
    >
      {/* Background Image with alt and title */}
      {backgroundImage && (
        <BackgroundImage
          src={backgroundImage}
          alt={backgroundImageAlt }
          title={backgroundImageTitle }
          opacity={opacity}
          width={width}
          height={height}
        />
      )}

      {Array.from({ length: rowCount * columnCount }).map((_, index) => {
        const row = Math.floor(index / columnCount);
        const col = index % columnCount;

        const isFirstRow = row === 0;
        const isFirstCol = col === 0;
        const isLastRow = row === rowCount - 1;
        const isLastCol = col === columnCount - 1;

        const isHidden = hideCells?.some(([r, c]) => r === row && c === col);
        const shaded = isShaded(row, col);
        const selected = isSelected(row, col);
        const correct = showAnswer && isCorrect(row, col);
        const incorrect = showAnswer && selected && !isCorrect(row, col);

        const isLocked = shaded && lockShadedCells;
        const shouldShowImage =
          image &&  selected;
        return (
          <ShadingCell
            key={index}
            width={cellWidth}
            height={cellHeight}
            isShaded={shaded}
            isSelected={selected || (showAnswer && (correct || incorrect))}
            isLocked={isLocked}
            isHidden={isHidden}
            isHoverState={isHoverState || false}
            showAnswer={showAnswer}
            isCorrect={correct}
            isIncorrect={incorrect}
            borderType={borderType}
            $isFirstRow={isFirstRow}
            $isFirstCol={isFirstCol}
            $isLastRow={isLastRow}
            $isLastCol={isLastCol}
            onClick={() => !isHidden && handleItemClick(row, col)}
            backgroundImage={backgroundImage}
          >
            {shouldShowImage && !isHidden && (
              <img
                title={title}
                src={image}
                alt={alt}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
            {showAnswer &&
              (correct ? (
                <FaCheck
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30px",
                    height: "30px",
                    color: "green",
                  }}
                />
              ) : (
                incorrect &&
                !isHidden && (
                  <FaTimes
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "30px",
                      height: "30px",
                      color: "red",
                    }}
                  />
                )
              ))}
          </ShadingCell>
        );
      })}
    </GridContainer>
  );
};

export default ShadingGrid;
