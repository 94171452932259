import { Card, Flex, Radio, Typography } from "antd";
import "../../../QuestionsPlayer/QuestionsStartPlayer-styles.scss";
import { useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { getResponseFontSize } from "utils/generalUtils";

const { Text } = Typography;

const SingleAnswerTab = ({
  options,
  more_options,
  activeTab,
  handleRadioCheck,
  layoutType,
}: {
  options: any[];
  more_options: any;
  handleRadioCheck: (id: string) => void;
  activeTab: any;
  layoutType?: string;
}) => {
  const textRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    options?.map((item, i) => {
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
        if (window.MathJax && window.MathJax.typesetPromise) {
          window.MathJax.typesetPromise([textRef.current[i]])
            .then(() => {})
            .catch((err: Error) => {
              console.error("MathJax typesetting failed:", err);
            });
        }
      }
      return textRef.current[i].innerHTML;
    });
  }, [options]);

  const validationStemNumeration =
    more_options?.layout?.validation_stem_numeration || "numerical";

  const getAlphaLabel = (i: number, numeration: string) => {
    if (numeration === "numerical") {
      return (i + 1).toString();
    }
    const baseCharCode = numeration === "uppercase" ? 65 : 97;
    const alphabetLength = 26;

    if (i < alphabetLength) {
      return String.fromCharCode(baseCharCode + i);
    } else {
      let adjustedIndex = i - alphabetLength;
      let letter = String.fromCharCode(
        baseCharCode + (adjustedIndex % alphabetLength)
      );
      return letter + letter;
    }
  };

  const renderChild = (label: string, index: number) => (
    <Flex align="center">
      <Text style={{ fontSize }} className="mr-1">
        {label}.
      </Text>
      <Text
        style={{ fontSize }}
        className="self-center"
        // @ts-ignore
        ref={(el) => (textRef.current[index] = el)}
      />
    </Flex>
  );

  const getCurrentValue = () => {
    let val = "";
    if (Array.isArray(activeTab?.value)) {
      if (activeTab?.value?.length) {
        val = activeTab?.value[0]?.id;
      }
    }
    return val;
  };

  const fontSize = getResponseFontSize(more_options, true);

  const columns = more_options?.layout?.columns || "1";
  const gridTemplateColumns = `repeat(${columns}, auto)`;

  return (
    <Radio.Group
      value={getCurrentValue()}
      onChange={(e) => {
        handleRadioCheck(e.target.value);
      }}
      className="answer-options grid gap-2"
      style={{
        gridTemplateColumns,
        maxWidth: "100%",
        whiteSpace: "nowrap",
      }}
    >
      {options.map((option, index) => (
        <div key={option.id} className="answer-option-item">
          <Card
            style={{ width: "100%" }}
            className={` option-card ${
              getCurrentValue() === option.id ? "option-card--selected" : ""
            }`}
          >
            {layoutType === "block" ? (
              <div
                style={{ width: "100%", cursor: "pointer" }}
                className="option-card__content"
                onClick={() => {
                  handleRadioCheck(option.id);
                }}
              >
                <div className="option-card__block">
                  {renderChild(
                    getAlphaLabel(index, validationStemNumeration),
                    index
                  )}
                </div>
              </div>
            ) : (
              <div className="option-card__content">
                <div className="option-card__radio">
                  <Radio value={option.id} />
                </div>
                <div>
                  {renderChild(
                    getAlphaLabel(index, validationStemNumeration),
                    index
                  )}
                </div>
              </div>
            )}
          </Card>
        </div>
      ))}
    </Radio.Group>
  );
};

export default SingleAnswerTab;
