import axios from 'axios';
import { createMathliveButton } from './mathliveButton';



export const readonlyMathConfig = {
    readonly: true,
    height: 200,
    toolbar: false,
    buttons: [],
};
export const joditConfig = {
    readonly: false,
    toolbar: true,
    buttons: [
        'bold', 'italic', 'underline', 'strikethrough', 'eraser', '|', 'superscript', 'subscript', '|', 'ul', 'ol', 'indent', 'outdent', '|', 'font', 'fontsize', 'paragraph', '|',  'table', '|', 'align', 'undo', 'redo',  '|', 'hr',  '|',
        {
            name: 'uploadImage',
            iconURL: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy_ZxV2ImKF9V0GRXWBfOTuY76TfKqoDa-O4zb7_lUrINJGSLY&s', // URL for your custom icon
            exec: (editor) => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.click();
                input.onchange = () => {
                    const file = input.files[0];
                    if (file) {
                        console.log(file);
                        handleFileUpload(file, editor,"image");
                    }
                };
            }
        },
        {
          name: 'uploadAudio',
          iconURL: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB19euuLHwG_8JZDad5i0txPbNZfqkHdfBJLb3F9SiEfQWXgCa&s', // URL for your custom icon
          exec: (editor) => {
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = 'audio/*';
              input.click();
              input.onchange = () => {
                  const file = input.files[0];
                  if (file) {
                      console.log(file);
                      handleFileUpload(file, editor, "audio");
                  }
              };
          }
      },
        '|',
        {
            name: 'math',
            iconURL: 'https://img.icons8.com/ios/50/000000/sigma.png',
            exec: (editor: any) => {
              const button = createMathliveButton(editor);
              button.exec();
            },
          },
        '|',
        {
            name: 'Response',
            //iconURL: 'https://img.icons8.com/ios/50/000000/sigma.png', // Use any math icon
            // or text
            tooltip: 'Response',
            exec: function (editor) {

                const span = document.createElement('span');
                span.style.border = '1px  black'; 
                span.style.padding = '5px'; // Add padding for spacing
                span.style.userSelect = "none";
                span.style.pointerEvents = "none";
                span.style.backgroundColor="rgb(238, 238, 238)";
                span.contentEditable ="false";
                span.innerHTML="<b>Response</b>";
                span.id="btnresponse";


             //   editor.s.insertHTML('<b>Response</b>');
              editor.s.insertHTML(span);
            }
        },
       '|', 'source'
    ],
   /* events:{
        afterInit: (instance) => {
           // instance.events.on('click',handleSpanClick(instance));
           addEventListeners(instance);
            // This can be left empty because we'll handle setting the instance in the main component
          }
    }*/
    // Add other configuration options here
};

/*export const joditConfig = (includeResponseButton = false) => {

    const buttons = [
        'bold', 'italic', 'underline', '|',
        createMathliveButton(), // Include the Mathlive button
        'ul', 'ol', '|', 'left', 'center', 'right', 'justify', '|', 'link', '|', 'source'
      ];
    
      if (includeResponseButton) {
        buttons.push(responseOption()); // Conditionally add the optional button
      }

    return {
        readonly: false,
        height: 400,
        toolbar: true,
        buttons,
      };
}*/


const handleFileUpload = async (file, editor,type) => {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "Accept": "application/json",
            'content-type': 'multipart/form-data',
        },
    };

    try {
        const formData = new FormData();
        formData.append('file', file, file.name);
        console.log(formData);

        let uploadURL;
        if(type === "image")
        {
          uploadURL = `${process.env.REACT_APP_API_URL}/api/formupload`;
        } else if(type === "audio")
        {
          uploadURL = `${process.env.REACT_APP_API_URL}/api/fileupload`;
        }
       

        const response = await axios.post(
            uploadURL,
            formData,
            config
        );
        console.log(response);

        if(response.status)
        {
            if(type==="image")
            {
              const image = editor.selection.j.createInside.element('img');
              image.setAttribute('src', response.data.item.src);
              editor.s.insertNode(image);
              
              //editor.s.insertHTML(`<img src="${response.data.item.src}" alt="Uploaded Image"/>`);
            } else if(type==="audio")
            {
              const audioElement = `<audio controls><source src="${response.data.item.src}" type="${file.type}"></audio>`;
              editor.selection.insertHTML(audioElement);
            }
           
            
        }
    } catch (err) {
        console.log(err);
    }
};

/* Currently not using this code */
/*
const addEventListeners = (editorInstance) => {
const handleSpanClick = (event) => {
    const spanNode = event.target;
    if (spanNode.nodeName === 'SPAN') {
      selectSpan(spanNode,editorInstance);
    }
  };

  if (editorInstance) {
    console.log('Editor instance:', editorInstance);
    editorInstance.events.on('click', handleSpanClick);

    // Clean up event listener when the editor is destroyed
    editorInstance.events.on('beforeDestruct', () => {
      editorInstance.events.off('click', handleSpanClick);
    });
  }
};
*/
 /* const selectSpan = (spanNode,editorInstance) => {
    clearSelection(editorInstance);
    spanNode.style.border = '1px dashed blue'; // Highlight the span
    spanNode.setAttribute('data-selected', 'true');
    setNonEditableSelection(spanNode,editorInstance);
  };*/
/*
  const setNonEditableSelection = (spanNode,editorInstance) => {
    if (editorInstance) {
      const range = editorInstance.s.createRange();
      range.selectNode(spanNode);
      editorInstance.s.removeAllRanges();
      editorInstance.s.addRange(range);

      // Prevent keyboard cursor
      editorInstance.container.focus();
      editorInstance.setEditorValue(editorInstance.getEditorValue());
    }
  };

  const clearSelection = (editorInstance) => {
    if (editorInstance) {
      const selectedSpans = editorInstance.editor.querySelectorAll('span[data-selected="true"]');
      selectedSpans.forEach(span => {
        span.style.border = '';
        span.removeAttribute('data-selected');
      });
    }
  };*/
  /* Currently not using this code */