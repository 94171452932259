import React from 'react'

const TabInput = (
    {isActive, handleItemClicked, item, children, otherStyle, label} : 
    {isActive: boolean; handleItemClicked: Function; item: any;children: React.ReactNode; otherStyle?: any; label?: string}) => 
        {
        return(
            <div className='flex items-center'>
            {
                label && 
                <div 
                    className={`h-[60%] w-12 rounded-md flex items-center justify-center 
                         border-r-gray-500  text-white
                        ${isActive ? 'bg-[#0080ff] opacity-90' : 'bg-gray-500'}
                        `}
                >
                    {label}
                </div>
            }
            
            <div
                id="block"
                className={`mt-5 mb-5 p-5 flex basis-full items-center gap-3 cursor-pointer ${
                    isActive ? 'bg-[#0080ff] text-white': ''}`}
                    style={{
                        minHeight: '45px',
                        border: '1px solid #eee',
                        borderRadius: '6px',
                        ...otherStyle,
                        ...(isActive && { backgroundColor: '#0080ff' }),
                    }}
                    onClick={() => {handleItemClicked(item?.id)}}
                    >
                {children}
            </div>
            </div>
        )
}

export default TabInput;