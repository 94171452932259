import { useLocation } from "react-router-dom";
import MainLayout from "../../layouts/main/Main.layout";
import CreateAssessmentComponent from "studio/components/CreateAssessment/CreateAssessment";
import { LocationState } from "studio/components/CreateAssessment/AssessmentStudio";
import { useState } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {DefaultQuestionsData} from "studio/constants/defaultQuestionData.constants";
import { editAssessment } from "api-rest/assessments";
import { Modal } from 'antd';

const CreateAssessment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const { subjects, grades, id, name } = state;
  const title = grades?.name ? `${subjects?.name} assessment - ${grades?.name}` : `${subjects?.name}`;
  const [questions, setQuestions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handlePublishConfirmed = async () => {
    console.log('Publish clicked');
    try {
      console.log(questions, 'Save clicked');
      console.log(DefaultQuestionsData, 'deffs');
      const assessmentData = {
        name: name,
        subject_id: subjects?.id,
        status:1,
        access: 1,
        grade_id: grades?.id ? grades?.id : '',
        questions: questions.map((question, index) => ({
          id: question.contextData.id,
          order: index + 1
        }))
      };
      
      const { error } = await editAssessment(id, assessmentData);

      if (error) {
        toast.error(error);
        return;
      }

      toast.success('Assessment published with success!', {
        position: 'top-right',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setIsModalVisible(false);
      // Optional: navigate away after successful save
      navigate('/assessment-library');
    } catch (error) {
      toast.error('An error occurred while saving the assessment');
    } finally {
      setIsSaving(false);
    }
  }
  const handleSaveConfirmed = async () => {
    // setIsSaving(true);
    try {
      console.log(questions, 'Save clicked');
      console.log(DefaultQuestionsData, 'deffs');
      const assessmentData = {
        name: name,
        subject_id: subjects?.id,
        grade_id: grades?.id ? grades?.id : '',
        questions: questions.map((question, index) => ({
          id: question.contextData.id,
          order: index + 1
        }))
      };
      
      const { error } = await editAssessment(id, assessmentData);

      if (error) {
        toast.error(error);
        return;
      }

      toast.success('Assessment created with success!', {
        position: 'top-right',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setIsModalVisible(false);
      // Optional: navigate away after successful save
      // navigate('/some-path');
    } catch (error) {
      toast.error('An error occurred while saving the assessment');
    } finally {
      // setIsSaving(false);
    }
  };

  return (
    <MainLayout title={title} headerType="assessment" handleSave={handleSaveConfirmed} handlePublish={showModal}>
      <div className="h-full w-full max-w-[1648px]">
        <CreateAssessmentComponent onQuestionListChange={(questionList) => setQuestions(questionList)} />
        
        <Modal
          title="Confirm Publish"
          open={isModalVisible}
          onOk={handlePublishConfirmed}
          onCancel={handleCancel}
          confirmLoading={isSaving}
          okText="Publish"
          cancelText="Cancel"
        >
          <p>Are you sure you want to publish this assessment?</p>
          {questions.length === 0 && (
            <p className="text-red-500">Warning: This assessment has no questions.</p>
          )}
        </Modal>
      </div>
    </MainLayout>
  );
};

export default CreateAssessment;