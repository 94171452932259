import { useState } from "react";
import { useDispatch } from "react-redux";
import type { FormProps } from "antd";
import { Button, Flex, Form, Input, Space, Typography } from "antd";
import { Messages } from "../../constants/messages.constant";
import { Colors } from "../../theme/colors.theme";
import { sendforgetPasswordEmail } from "../../../services/authentication";
import { Sizes } from "studio/theme/Sizes.theme";
import BackArrowIcon from "../ui/BackArrowIcon.component";

const { Title, Paragraph } = Typography;

type FieldType = {
  email?: string;
};

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish: FormProps<FieldType>["onFinish"] = async ({ email }) => {
    setLoading(true);
    // @ts-ignore
    const res = await dispatch(sendforgetPasswordEmail({ email }));
    if (res) setLoading(false);
    else setLoading(false);
  };

  return (
    <Form
      name="basic"
      size="large"
      wrapperCol={{ span: 24 }}
      style={{ width: "60%", maxWidth: 434 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Space direction="vertical">
        <BackArrowIcon
          style={{
            marginBottom: Sizes.headerPadding + 10,
            fontSize: 32,
            width: 32,
            height: 32,
          }}
        />
        <Title
          style={{
            fontWeight: "normal",
            marginBottom: Sizes.base,
            color: Colors.textBlack,
          }}
          level={2}
        >
          {Messages.ForgotPasswordTitle}
        </Title>
        <Paragraph style={{ lineHeight: "32px", marginBottom: 44 }}>
          {Messages.ForgotPasswordSubTitle}
        </Paragraph>
        <Form.Item<FieldType>
          label={null}
          name="email"
          rules={[
            { required: true, message: Messages.EnterEmail },
            { type: "email", message: Messages.EnterValidEmail },
          ]}
          style={{ marginBottom: 53 }}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item label={null} style={{ marginBottom: 48 }}>
          <Button
            loading={loading}
            variant="solid"
            block
            color="default"
            htmlType="submit"
            className="hover:opacity-90"
          >
            SEND RESET LINK
          </Button>
        </Form.Item>

        <Flex justify="center" align="center">
          <Paragraph>{Messages.ForgotEmail}</Paragraph>
        </Flex>
      </Space>
    </Form>
  );
};

export default ForgotPasswordForm;
// nothing
