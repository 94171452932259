export const Sizes = {
  base: 16,
  padding: 24,
  headerPadding: 27,
  height: 46,
  borderRadius: 6,
  inputBorderRadius: 6,
  fontSize: 14,
  verticalPadding: 16,
  iconFontSize: 22,
  iconFontWeight: 300,
  headerHeight: 64,
  maxScreenWidth: 1648,
};
