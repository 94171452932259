import QuestionsStartPlayer from "../../components/QuestionsPlayer/QuestionsStartPlayer.component";
import MainLayout from "../../layouts/main/Main.layout";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

const QuestionsPlayer = () => {
    const { assessment } = useSelector(
        (state: RootState) => state.preview || {}
    );
    const title = assessment?.defaultAssessment?.data?.name;
    return (
        <MainLayout title={title} headerType={'player'}>
            <QuestionsStartPlayer />
        </MainLayout>
    );
};

export default QuestionsPlayer;
