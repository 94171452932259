import React, { useState } from "react";
import MainLayout from "../main/Main.layout";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";
import QuestionRight from "studio/components/QuestionEdit/QuestionRight.component";
import QuestionLeft from "studio/components/QuestionEdit/QuestionLeft.component";
import TitleWithBackIcon from "studio/components/QuestionEdit/TitleWithBackIcon.component";
import QuestionEditor from "studio/components/QuestionEdit/QuestionEditor.component";
import { Divider } from "antd";
import FooterQuestionEdit from "studio/components/QuestionEdit/FooterQuestionEdit.component";
import PreviewQuestionModal from "studio/modals/PreviewQuestion/PreviewQuestion.modal";
import QuestionPreviewForEdit from "studio/components/QuestionEdit/QuestionPreviewForEdit.component";

interface IQuestionEditLayout {
  children: React.ReactNode;
  data: any;
  setData: (newData: any) => void;
  label: string;
  questionName: string;
  handleSaveChanges: () => void;
}

const QuestionEditLayout = ({
  children,
  data,
  setData,
  label,
  questionName,
  handleSaveChanges,
}: IQuestionEditLayout) => {
  const [showPreview, setShowPreview] = useState(false);
  const handlePreviewQuestion = () => {
    setShowPreview(true);
  };

  return (
    <MainLayout title="Question demo">
      <div
        style={{ background: Colors.bgWhite, marginBottom: 80 }}
        className="flex flex-wrap min-h-full justify-between min-w-full"
      >
        <div
          className={`min-h-full w-full md:w-[233px]`}
          style={{
            background: Colors.bgLightGray,
            border: `1px solid ${Colors.grayBorder}`,
            borderLeftWidth: 0,
            borderBottomWidth: 0,
            padding: `${Sizes.padding}px ${Sizes.base}px`,
          }}
        >
          <QuestionLeft />
        </div>
        <div
          className={`min-h-full flex-1`}
          style={{
            padding: `${Sizes.padding}px ${Sizes.padding}px`,
            maxWidth: Sizes.maxScreenWidth - 540,
          }}
        >
          <div className="mb-6">
            <TitleWithBackIcon title={questionName || ""} />
          </div>
          <QuestionEditor
            label={label}
            value={data?.question || ""}
            allconfig={true}
            questionconfig={true}
            onChange={(val: any) => {
              setData({ ...data, question: val });
            }}
          />

          <Divider />

          {children}
        </div>
        <div
          className={`min-h-full w-[328px]`}
          style={{
            background: Colors.bgLightGray,
            border: `1px solid ${Colors.grayBorder}`,
            borderBottomWidth: 0,
            padding: `${Sizes.padding}px ${Sizes.base}px`,
          }}
        >
          <QuestionRight data={data} setData={setData} />
        </div>
        <FooterQuestionEdit
          onSave={handleSaveChanges}
          onPreview={handlePreviewQuestion}
        />
        <PreviewQuestionModal
          open={showPreview}
          onClose={() => {
            setShowPreview(false);
          }}
        >
          {/** data is a subQuestion not question */}
          <QuestionPreviewForEdit data={data} />
        </PreviewQuestionModal>
      </div>
    </MainLayout>
  );
};

export default QuestionEditLayout;
