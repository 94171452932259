import React from 'react';

const FlagOut = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30.908" viewBox="0 0 24 30.908">
            <path id="bookmark_flag_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M234.118-793.587h2.066v-5.68h8.128l-2.551-3.4,2.551-3.4H234.118Zm-7.658,11.765V-809.5a3.113,3.113,0,0,1,.943-2.287,3.11,3.11,0,0,1,2.285-.943h17.545a3.11,3.11,0,0,1,2.285.943,3.113,3.113,0,0,1,.943,2.287v27.679l-12-5.126Zm2.649-4.049,9.351-4.035,9.351,4.035V-809.5a.556.556,0,0,0-.182-.4.557.557,0,0,0-.4-.182H229.691a.557.557,0,0,0-.4.182.556.556,0,0,0-.182.4Zm0-24.211h0Z"
                  transform="translate(-226.46 812.73)" fill="#333"/>
        </svg>
    );
};

export default FlagOut;
