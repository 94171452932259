import React, { useState } from 'react';
import StatsCards from "../AssessmentLibrary/StatsCards";
import CreateAssessment from 'studio/modals/CreateAssessment/CreateAssessment.modal';
import BlankAssessment from 'studio/modals/BlankAssessment/BlankAssessment.modal';
import {useAppSelector} from "../../../hooks/useAppSelector";
import {RootState} from "../../../redux/store";
import ExistingAssessment from 'studio/modals/ExistingAssessment1/ExistingAssessment.modal';

interface BannerProps {
    currentStatus: string;
    onStatusChange: (status: string) => void;
}

const Banner: React.FC<BannerProps> = ({ currentStatus, onStatusChange }) => {
    const { assessmentsListing, assessmentsListingPagination } = useAppSelector(
        (state: RootState) => state.assessments
    );
    const Stats = {
        totalCreated: assessmentsListingPagination?.published_count + assessmentsListingPagination?.unpublished_count,
        published: assessmentsListingPagination?.published_count,
        drafts: assessmentsListingPagination?.unpublished_count,
        scheduled: 6,
    };
    const [isCreateAssessmentModalOpen, setIsCreateAssessmentModalOpen] = useState<boolean>(false);
    const [isBlankAssessmentModalOpen, setIsBlankAssessmentModalOpen] = useState<boolean>(false);
    const [isExistingAssessmentModalOpen, setIsExistingAssessmentModalOpen] = useState<boolean>(false);

    const openCreateAssessmentModal = () => setIsCreateAssessmentModalOpen(true);
    const closeCreateAssessmentModal = () => setIsCreateAssessmentModalOpen(false);
    const openBlankAssessmentModal = () => setIsBlankAssessmentModalOpen(true);
    const closeBlankAssessmentModal = () => setIsBlankAssessmentModalOpen(false);
    const openExistingAssessmentModal = () => setIsExistingAssessmentModalOpen(true);
    const closeExistingAssessmentModal = () => setIsExistingAssessmentModalOpen(false);
    const openModal = (input) => {
        closeCreateAssessmentModal();
        if (input === 'blank') {
            openBlankAssessmentModal();
        } else if (input === 'existing') {
            openExistingAssessmentModal();
        }
    }
    return (
        <>
            <StatsCards
                stats={Stats}
                openCreateAssessmentModal={openCreateAssessmentModal}
                currentStatus={currentStatus}
                onStatusChange={onStatusChange}
            />
            <CreateAssessment
                isOpen={isCreateAssessmentModalOpen}
                onClose={closeCreateAssessmentModal}
                openModal={openModal}

            />
            <BlankAssessment
                isOpen={isBlankAssessmentModalOpen}
                onClose={closeBlankAssessmentModal}

            />
            <ExistingAssessment
                isOpen={isExistingAssessmentModalOpen}
                onClose={closeExistingAssessmentModal}

            />
        </>
    );
};

export default Banner;