import { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Card, Progress, Divider, Spin } from "antd";
import "./QuestionsStartPlayer-styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Timer from "./Timer";
import FlagFilled from "../../icons/FlagFilled";
import QuestionHeader from "./QuestionHeader";
import SummarySection from "./SummarySection";
import ConfirmationSection from "./ConfirmationSection";
import RenderQuestionForStudent from "./RenderQuestionForStudent.component";
import axios from "axios";
import { toast } from "react-toastify";

interface QuestionStatus {
  answered: boolean;
  selectedAnswer: string | null;
  visited: boolean;
  flagged?: boolean;
}

const statusItems = [
  { label: "Answered", type: "answered" },
  { label: "Current", type: "current" },
  { label: "Skipped", type: "skipped" },
  { label: "Flagged", type: "flagged" },
];

const TOTAL_TIME = 3600;

const QuestionsStartPlayer = (): JSX.Element => {
  const { assessment } = useSelector((state: RootState) => state.preview || {});
  const [loadingSubmitAnswer, setLoadingSubmitAnswer] = useState(false);

  const { config, userData } = useSelector(
    (state: RootState) => state.assessments
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [previewSubQuestions, setPreviewSubQuestions] = useState([]);
  const [questionStatuses, setQuestionStatuses] = useState<QuestionStatus[]>(
    () =>
      assessment?.subQuestions.map((subQuestion) => ({
        answered: false,
        selectedAnswer: null,
        visited: false,
        flagged: false,
        id: subQuestion?.id,
      }))
  );
  const [timeLeft, setTimeLeft] = useState(TOTAL_TIME);
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const [isSummaryModalVisible, setIsSummaryModalVisible] = useState(false);

  const visitedCount = useMemo(
    () =>
      questionStatuses?.filter(
        (status, index) =>
          status.visited &&
          !status.answered &&
          !status.flagged &&
          index !== currentQuestionIndex
      ).length,
    [questionStatuses]
  );

  const flaggedCount = useMemo(
    () => questionStatuses?.filter((status) => status.flagged).length,
    [questionStatuses]
  );

  // Calculate summary statistics
  const summary = useMemo(() => {
    const answeredCount =
      questionStatuses?.filter((s) => s.answered).length || 0;
    const unansweredCount =
      questionStatuses?.filter((s) => !s.answered).length || 0;
    const skippedCount =
      questionStatuses?.filter(
        (s, i) => s.visited && !s.answered && i !== currentQuestionIndex
      ).length || 0;
    const flaggedCount = questionStatuses?.filter((s) => s.flagged).length || 0;
    const totalCount = questionStatuses?.length || 0;

    return {
      answered: answeredCount,
      unanswered: unansweredCount, // Ensure this is defined
      skipped: skippedCount,
      flagged: flaggedCount,
      total: totalCount,
    };
  }, [questionStatuses, currentQuestionIndex]);

  useEffect(() => {
    if (assessment?.subQuestions?.length > 0) {
      setPreviewSubQuestions(assessment?.subQuestions);
    }
  }, [assessment, assessment?.subQuestions]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const toggleFlag = useCallback(() => {
    setQuestionStatuses((prev) => {
      const newStatuses = [...prev];
      newStatuses[currentQuestionIndex] = {
        ...newStatuses[currentQuestionIndex],
        flagged: !newStatuses[currentQuestionIndex].flagged,
      };
      return newStatuses;
    });
  }, [currentQuestionIndex]);

  const handleNext = useCallback(() => {
    if (currentQuestionIndex < previewSubQuestions.length - 1) {
      setQuestionStatuses((prev) => {
        const newStatuses = [...prev];
        if (!newStatuses[currentQuestionIndex].answered) {
          newStatuses[currentQuestionIndex] = {
            ...newStatuses[currentQuestionIndex],
            visited: true,
          };
        }
        return newStatuses;
      });
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  }, [currentQuestionIndex, previewSubQuestions.length]);

  const handlePrevious = useCallback(() => {
    if (currentQuestionIndex > 0) {
      setQuestionStatuses((prev) => {
        const newStatuses = [...prev];
        if (!newStatuses[currentQuestionIndex].answered) {
          newStatuses[currentQuestionIndex] = {
            ...newStatuses[currentQuestionIndex],
            visited: true,
          };
        }
        return newStatuses;
      });
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  }, [currentQuestionIndex]);

  const updateStudentAnswer = (newValue: any) => {
    setQuestionStatuses((prev) => {
      const newStatuses = [...prev];
      newStatuses[currentQuestionIndex] = {
        ...newStatuses[currentQuestionIndex],
        answered: true,
        selectedAnswer: newValue,
        visited: false,
      };
      return newStatuses;
    });
  };

  const handleQuestionClick = useCallback(
    (index: number) => {
      if (index === currentQuestionIndex) return;

      setQuestionStatuses((prev) => {
        const newStatuses = [...prev];
        if (!newStatuses[currentQuestionIndex].answered) {
          newStatuses[currentQuestionIndex] = {
            ...newStatuses[currentQuestionIndex],
            visited: true,
          };
        }
        return newStatuses;
      });
      setCurrentQuestionIndex(index);
    },
    [currentQuestionIndex]
  );

  const progress = useMemo(
    () => ((currentQuestionIndex + 1) / previewSubQuestions.length) * 100,
    [currentQuestionIndex, previewSubQuestions.length]
  );

  const handleSubmit = useCallback(() => {
    setIsSummaryModalVisible(true);
  }, []);

  const handleConfirmSubmit = useCallback(() => {
    setIsSummaryModalVisible(false);
    setIsSubmitModalVisible(true);
  }, []);

  const submitAnswer = async () => {
    try {
      setIsSubmitModalVisible(false);
      setLoadingSubmitAnswer(true);
      const transformedData = questionStatuses?.map((item: any) => ({
        sub_question_id: item?.id,
        Answer: { value: item?.selectedAnswer },
      }));
      const assessment_id = assessment?.defaultAssessment?.data?.id || "";
      const response = await axios.post(
        `${process.env.REACT_APP_OBE_APP_API_URL}/submit-answer`,
        {
          assessment_id,
          passed_time: (TOTAL_TIME - timeLeft).toString(),
          status: 1, // Fixed value
          answers: transformedData ?? [],
        },
        {
          headers: {
            Authorization: `Bearer ${config?.tokenUser}`,
            Accept: "application/json",
          },
        }
      );

      toast.success(response.data.message);
      const referrer = userData?.data?.redirectionPath;
      window.location.href = `${referrer}/?assessment_id=${assessment_id}`;
    } catch (error) {
      console.error("Error during submission", error);
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      toast.error("Submission failed! Please try again.");
      window.parent.postMessage(
        {
          type: "FAILURE",
          payload: "Sorry your answers have not been submitted " + errorMessage,
        },
        "*"
      );
    } finally {
      setLoadingSubmitAnswer(false);
    }
  };

  return (
    <div className="assessment-container">
      {loadingSubmitAnswer && <Spin fullscreen size="large"></Spin>}
      <div className="assessment-content">
        <div className="sidebar">
          <div className="sidebar__content">
            <div className="sidebar__content-bg" />

            <div className="sidebar__title">
              <span>QUESTIONS</span>
              <div>
                {visitedCount > 0 && (
                  <span className="sidebar__title-skipped">
                    {" "}
                    {visitedCount} Skipped
                  </span>
                )}
                {flaggedCount > 0 && (
                  <span className="sidebar__title-flagged">
                    {" "}
                    {flaggedCount} Flagged
                  </span>
                )}
              </div>
            </div>

            <div className="sidebar__questions">
              <div className="sidebar__questions-wrapper">
                {previewSubQuestions.map((item, index) => (
                  <div key={item.id} className="question-item">
                    <div
                      className={`status-indicator ${
                        questionStatuses[index].flagged
                          ? "status-indicator--flagged"
                          : questionStatuses[index].answered
                          ? "status-indicator--answered"
                          : questionStatuses[index].visited &&
                            index !== currentQuestionIndex
                          ? "status-indicator--visited"
                          : index === currentQuestionIndex
                          ? "status-indicator--current"
                          : ""
                      } ${
                        questionStatuses[index].flagged
                          ? "status-indicator--flagged"
                          : ""
                      }`}
                    />
                    <Card
                      className={`question-card ${
                        index === currentQuestionIndex
                          ? "question-card--active"
                          : ""
                      }`}
                      onClick={() => handleQuestionClick(index)}
                      bodyStyle={{ padding: 0 }}
                    >
                      <div className="question-card__content">
                        <div className="question-card__number">
                          {`Q${index + 1}`}
                        </div>
                        <div className="question-card__text">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                item?.question?.length > 30
                                  ? item?.question.substring(0, 25) + "..."
                                  : item?.question,
                            }}
                          />
                          {questionStatuses[index].flagged && (
                            <FlagFilled className="question-card__flag" />
                          )}
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          <div className="main-content__header">
            <div className="progress-section">
              <div className="progress-section__text">
                {currentQuestionIndex + 1} of {previewSubQuestions.length}
              </div>
              <Progress
                percent={progress}
                showInfo={false}
                strokeColor="#fa9b31"
                className="progress-section__bar"
              />
            </div>
            <Timer timeLeft={timeLeft} totalTime={TOTAL_TIME} />
          </div>

          <div className="question-content">
            <Divider className="main-content__divider" />
            <div className="question-header">
              <div className="question-number">{`Q${
                currentQuestionIndex + 1
              }.`}</div>
              <QuestionHeader
                questionStatuses={questionStatuses}
                currentQuestionIndex={currentQuestionIndex}
                toggleFlag={toggleFlag}
              />
            </div>

            <div
              className="question-text"
              dangerouslySetInnerHTML={{
                __html: previewSubQuestions[currentQuestionIndex]?.question,
              }}
            />
            <RenderQuestionForStudent
              data={previewSubQuestions[currentQuestionIndex]}
              userResponse={
                questionStatuses[currentQuestionIndex]
                  ? questionStatuses[currentQuestionIndex]?.selectedAnswer
                  : []
              }
              setUserResponse={updateStudentAnswer}
            />
          </div>

          <div className="navigation">
            <Button
              type="default"
              className="btn btn--outline"
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </Button>
            <Button
              type="primary"
              className="btn btn--primary"
              onClick={handleNext}
              disabled={currentQuestionIndex === previewSubQuestions.length - 1}
            >
              Next
            </Button>
          </div>
        </div>
      </div>

      <footer className="footer">
        <Divider className="footer__divider" />
        <div className="footer__content">
          <div className="status-container">
            {statusItems.map((item) => (
              <div key={item.type} className="status-tag">
                <div className={`status-tag status-${item.type}`} />
                <span style={{ marginRight: "30px" }}>{item.label}</span>
              </div>
            ))}
          </div>
          <Button type="primary" className="submit-btn" onClick={handleSubmit}>
            Submit Assessment
          </Button>
        </div>
      </footer>

      {/* Assessment Summary Modal */}

      <SummarySection
        isVisible={isSummaryModalVisible}
        onCancel={() => setIsSummaryModalVisible(false)}
        onConfirm={handleConfirmSubmit}
        summary={summary}
      />

      <ConfirmationSection
        isVisible={isSubmitModalVisible}
        onCancel={() => setIsSubmitModalVisible(false)}
        onSubmit={submitAnswer}
        summary={summary}
      />
    </div>
  );
};

export default QuestionsStartPlayer;
