import React, { useId } from 'react';
import styled from 'styled-components';
import { FontSize, StyledCellProps } from './types';

const Cell = styled.td<StyledCellProps>`
    border: 1px solid #e2e8f0;
    text-align: center;
    height: 60px;
    width: 60px;
    position: relative;
    background-color: ${props => {
    if (props.$isEmpty) return '#f8f9fa';
    if (props.$isSpecial) return '#dee2e6';
    return 'white';
}};
    padding: 4px;
    transition: background-color 0.2s ease;
    font-size: ${props =>
    props.$fontSize === 'small' ? '14px' :
        props.$fontSize === 'large' ? '20px' :
            '16px'};

    &:focus-within {
        outline: none;
        box-shadow: 0 0 0 2px #3182ce;
    }

    ${props => props.$isDisabled && `
        opacity: 0.7;
        cursor: not-allowed;
    `}
`;

const RadioInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;

    &:focus + label {
        box-shadow: 0 0 0 2px #3182ce;
    }

    &:disabled + label {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const RadioLabel = styled.label<{ $isSelected: boolean; $isSpecial?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    border-radius: 50%;
    transition: all 0.2s ease;
    position: relative;
    background-color: ${props => props.$isSelected ? '#4cd964' : 'white'};
    color: ${props => {
    if (props.$isSelected) return 'white';
    if (props.$isSpecial) return '#4a5568';
    return '#2d3748';
}};
    font-weight: ${props => props.$isSpecial ? 'bold' : 'normal'};
    border: 1px solid ${props => props.$isSelected ? '#4cd964' : '#e2e8f0'};
    
    &:hover {
        background-color: ${props => props.$isSelected ? '#4cd964' : 'rgba(76, 217, 100, 0.1)'};
    }
    
    &:active {
        transform: scale(0.98);
    }
    
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

interface TableCellProps {
    value: string;
    columnIndex: number;
    rowIndex: number;
    fontSize?: FontSize;
    disabled?: boolean;
    onChange: (value: string) => void;
    selectedValue?: string;
    isSpecial?: boolean;
    isEmpty?: boolean;
}

export const TableCell: React.FC<TableCellProps> = ({
    value,
    columnIndex,
    rowIndex,
    fontSize = 'medium',
    disabled = false,
    onChange,
    selectedValue,
    isSpecial = false,
    isEmpty = false,
    }) => {
    const randomId = useId();
    const isSelected = selectedValue != '' && selectedValue === value;
    const cellId = `radio-${rowIndex}-${columnIndex}-${value}-${randomId}`;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled && !isEmpty) {
            onChange(value);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (disabled || isEmpty) return;

        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onChange(value);
        }

        const handleArrowNavigation = (direction: 'up' | 'down' | 'left' | 'right') => {
            const cell = e.currentTarget;
            const table = cell.closest('table');
            if (!table) return;

            const currentRow = cell.parentElement;
            const rows = Array.from(table.querySelectorAll('tr'));
            const currentRowIndex = rows.indexOf(currentRow! as HTMLTableRowElement);
            const cells = Array.from(currentRow!.querySelectorAll('td'));
            const currentCellIndex = cells.indexOf(cell as HTMLTableCellElement);

            let nextCell: HTMLElement | null = null;

            switch (direction) {
                case 'up':
                    if (currentRowIndex > 0) {
                        nextCell = rows[currentRowIndex - 1].querySelectorAll('td')[currentCellIndex] as HTMLElement;
                    }
                    break;
                case 'down':
                    if (currentRowIndex < rows.length - 1) {
                        nextCell = rows[currentRowIndex + 1].querySelectorAll('td')[currentCellIndex] as HTMLElement;
                    }
                    break;
                case 'left':
                    if (currentCellIndex > 0) {
                        nextCell = cells[currentCellIndex - 1] as HTMLElement;
                    }
                    break;
                case 'right':
                    if (currentCellIndex < cells.length - 1) {
                        nextCell = cells[currentCellIndex + 1] as HTMLElement;
                    }
                    break;
            }

            if (nextCell && !nextCell.hasAttribute('disabled')) {
                const input = nextCell.querySelector('input') as HTMLElement;
                if (input) {
                    input.focus();
                    e.preventDefault();
                }
            }
        };

        switch (e.key) {
            case 'ArrowUp':
                handleArrowNavigation('up');
                break;
            case 'ArrowDown':
                handleArrowNavigation('down');
                break;
            case 'ArrowLeft':
                handleArrowNavigation('left');
                break;
            case 'ArrowRight':
                handleArrowNavigation('right');
                break;
        }
    };

    return (
        <Cell
            $fontSize={fontSize}
            $isSpecial={isSpecial}
            $isEmpty={isEmpty}
            $isSelected={isSelected}
            $isDisabled={disabled}
            role="gridcell"
            data-row={rowIndex}
            data-column={columnIndex}
            data-value={value}
            data-testid={`cell-${rowIndex}-${columnIndex}`}
            data-lrn-gridded-column-wrapper={columnIndex}
        >
            <RadioInput
                type="radio"
                id={cellId}
                name={`gridded-group-${columnIndex}-${randomId}`}
                checked={isSelected}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={disabled || isEmpty}
                value={value}
                aria-label={`${value || 'Empty'} at row ${rowIndex + 1}, column ${columnIndex + 1}`}
            />
            <RadioLabel
                htmlFor={cellId}
                $isSelected={isSelected}
                $isSpecial={isSpecial}
            >
                {value}
            </RadioLabel>
        </Cell>
    );
};
