import axios from 'axios';
import { UserResult } from '../types';

export const fetchUserData = async (encryptedData: string): Promise<UserResult> => {
    const response = await axios.post(
        `${process.env.REACT_APP_OBE_APP_API_URL}/token/login`,
        {
            token: encryptedData,
            domain_name: 'development.ealpha.net',
        },
        {
            headers: {
                Accept: 'application/json',
            },
        }
    );

    return response.data.message;
};
