import { Fragment, useEffect, useState } from "react";
import { QuestionContent } from "./styled";
import { RightContent } from "../../../components/styled";
import ButtonLink from "../../../components/ButtonLink";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { Rnd } from "react-rnd";
import { setClosePreview } from "redux/slices/PreviewSlice";
import QuestiontypeModal from "components/QuestiontypeModal";
import {
  getLabelByStemNumeration,
  getResponseFontSize,
} from "utils/generalUtils";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";

const mathlive = require("mathlive");

export default function PreviewLabelmageMath({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "assessment",
  editMode = false,
}) {
  const [elements, setElements] = useState([]);
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isHovered , setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;

  const validResponse = currentQuestion?.correct_answer?.valid_response;

  useEffect(() => {
    let data =
      currentQuestion?.correct_answer &&
      currentQuestion?.correct_answer?.response_containers
        ? JSON.parse(
            JSON.stringify(currentQuestion.correct_answer?.response_containers)
          )
        : "";
    for (let i = 0; i < data.length; i++) {
      let inputString = data[i].template;
      if (inputString) {
        const parts = inputString.split("\\colorbox{#e4e4e4}{{Response}}}");
        let T = [];
        for (let i = 0; i < parts.length; i++) {
          if (parts[i] !== undefined && parts[i] !== "") {
            T.push({ type: "txt", content: parts[i] });
            if (i != parts.length - 1)
              T.push({ type: "Res", content: "Response" });
          }
        }
        data[i].template = T;
      }
    }
    setElements(data);
  }, []);

  const handleEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const itemMinWidth =
    more_options && more_options?.layout.response_min_width
      ? Number(more_options?.layout.response_min_width)
      : 70;

  return (
    <QuestionContent parentMode={parentMode === 'question'}   className={`overflow-x-scroll`} style={more_options && more_options?.layout.fontSize ? 
      {fontSize: more_options.layout.fontSize}: 
      {fontSize: 'medium'}
    }>
      {more_options?.extras?.instructor_stimulus && !editMode && (
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {more_options?.extras?.instructor_stimulus}
        </div>
      )}
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
          <div
            className="bg-light-accent flex p-5 gap-3 rounded-lg"
            style={
              more_options && more_options?.layout.fontSize
                ? { fontSize: more_options.layout.fontSize }
                : { fontSize: "medium" }
            }
          >
            <p className="font-bold">Question :&nbsp;</p>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${currentQuestion?.question}`,
              }}
            />
          </div>
        </div>

        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <Button
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />
              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <Button
                margin
                title={
                  showAnswer || localShowAnswer ? "Hide Answer" : "Show Answer"
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (setShowAnswers) {
                    setShowAnswers(!showAnswer);
                  } else {
                    setLocalShowAnswer(!localShowAnswer);
                  }
                }}
              />
              <Button
                title={"Back to edit"}
                onClick={() => {
                  // @ts-ignore
                  dispatch(setClosePreview());
                }}
              />
            </>
          )}
        </RightContent>
      </div>
      <div
        style={{
          position: "relative",
          width: currentQuestion?.image?.width
            ? `${currentQuestion.image.width}px`
            : "auto",
          height: currentQuestion?.image?.height
            ? `${currentQuestion.image.height}px`
            : "auto",
        }}
        // className="image-container"
      >
        {/* Response Element */}
        {Array.isArray(elements) &&
          elements.map((element, key) => (
            <Rnd
              key={key}
              default={{
                x: element.x,
                y: element.y,
                width: element.width ? "w-full" : "auto",
                height: element.height
                  ? element.height
                  : "fit-content !important",
              }}
              minWidth={itemMinWidth}
              // Width={itemMinWidth}

              bounds="parent"
              disableDragging={true}
              enableResizing={false}
              style={{
                position: "absolute",
                height: "fit-content !important",
                padding: element?.height ? "36px" : "0px",
                border: "1px dotted black",
                cursor: "move",
                display: "flex",
                alignItems: "center",
                fontSize: getResponseFontSize(more_options),
                backgroundColor: '#f4f5f7',
              }}
            >
              {Array.isArray(element?.template) &&
                element.template.map((elm, key) => (
                  <Fragment key={key}>
                    {editMode && elm.type === "txt" ? (
                      //@ts-expect-error
                      <math-field
                        readonly={false} // Editable in editMode
                        style={{
                          display: "inline-flex",
                          background: 'transparent',
                          // minWidth: `${itemMinWidth}px`,
                        }}
                      >
                        {elm.content}
                        {/* @ts-expect-error */}
                      </math-field>
                    ) : (
                      <div key={key}>
                        {elm.type === "txt" ? (
                          //@ts-expect-error
                          <math-field
                            readonly={true}
                            style={{
                              display: "inline-flex",
                              background: 'transparent',
                              // minWidth: `${itemMinWidth}px`,
                            }}
                          >
                            {elm.content}
                            {/* @ts-expect-error */}
                          </math-field>
                        ) : editMode ? (
                          <div
                            style={{
                              border: "1px solid #333",
                              cursor: "move",
                              display: "inline-flex",
                              background: 'transparent',
                            }}
                            className="w-fit"
                          >
                            {/* @ts-expect-error */}
                            <math-field
                              math-mode-space="\:"
                              style={{
                                // display: 'inline-flex',
                                minWidth: `${itemMinWidth}px`,
                                background: 'transparent',
                              }}
                            >
                              {/* @ts-expect-error */}
                            </math-field>
                          </div>
                        ) : (
                          <div
                            style={{
                              border: "1px solid #333",
                              cursor: "move",
                              display: "inline-flex",
                              background: 'transparent',
                            }}
                            className="w-fit"
                          >
                            {/* @ts-expect-error */}
                            <math-field
                              math-mode-space="\:"
                              style={{
                                // display: 'inline-flex',
                                minWidth: `${itemMinWidth}px`,
                                background: 'transparent',
                              }}
                            >
                              {/* @ts-expect-error */}
                            </math-field>
                          </div>
                        )}
                      </div>
                    )}
                  </Fragment>
                ))}

              <span
                style={{
                  color: "#fff",
                  position: "absolute",
                  top: "-17px",
                  left: "-10px",
                  width: "22px",
                  height: "22px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  fontWeight: "bold",
                  fontSize: "small",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getLabelByStemNumeration(
                  more_options,
                  element.id !== 0 ? element.id - 1 : element.id
                )}
              </span>
            </Rnd>
          ))}

        {/* End Response Element */}
        {currentQuestion?.image && (
          <div
            style={
              {
                // width: currentQuestion.image.width,
                // height: currentQuestion.image.height,
              }
            }
            // className='image-container'
          >
            <img
              src={currentQuestion.image.src}
              alt={currentQuestion.image.alt}
              title={currentQuestion.image.title || ""}
              // className={`h-full`}
              style={{
                width: currentQuestion.image.prevent_scale
                  ? "auto"
                  : currentQuestion.image.width,
                height: currentQuestion.image.prevent_scale
                  ? "auto"
                  : currentQuestion.image.height,
                maxWidth: currentQuestion.image?.prevent_scale
                  ? "none"
                  : "100%",
              }}
            />
          </div>
        )}
      </div>

      {(localShowAnswer || showAnswer) && (
        <ul className="w-full flex flex-col p-2">
          <p
            className=" font-semibold"
            style={{ fontSize: getResponseFontSize(more_options, true) }}
          >
            Answers:
          </p>
          {Array.isArray(validResponse?.value) &&
            validResponse?.value.map((vr, index) => (
              <li
                className="mt-4"
                style={{ fontSize: getResponseFontSize(more_options, true) }}
                key={index}
              >
                <span className="px-3 bg-slate-500 text-white">
                  {getLabelByStemNumeration(more_options, index)}
                </span>{" "}
                {/* @ts-expect-error */}
                <math-field readonly={true}>
                  {vr?.value || ""}
                  {/* @ts-expect-error */}
                </math-field>
              </li>
            ))}
        </ul>
      )}
    </QuestionContent>
  );
}
