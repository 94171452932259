import axios from "axios";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";
import { Action } from "redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setPermissions } from "../redux/slices/PermissionsSlice";
import { message } from "antd";
import { Messages } from "studio/constants/messages.constant";

export const loginRequest = async (loginPayload) => {
  const pathV = localStorage.getItem("type");
  console.log(pathV);

  console.log(loginPayload, "loginpayload");

  let type;
  let loginURL;
  if (pathV === "superadmin") {
    type = pathV;
    loginURL = `${process.env.REACT_APP_API_URL}/api/auth/super-admin-login`;
  } else {
    type = pathV === "admin" ? "author" : "admin";
    loginURL = `${process.env.REACT_APP_API_URL}/api/auth/login`;
  }

  try {
    const response = await axios.post(
      loginURL,
      {
        ...loginPayload,
        type,
      },
      { withCredentials: true }
    );

    // console.log(response);

    if (response.data.status) {
      // @ts-ignore
      localStorage.setItem("token", response?.data.access_token);
    }

    return { data: response.data, error: null };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error.response.data);
      return { data: null, error: error.response.data.message };
    } else if (error.request) {
      // The request was made but no response was received
      console.error(error.request);
      return { data: null, error: "Network error" };
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
      return { data: null, error: error.message };
    }
  }
};

export const sendforgetPasswordEmail = (
  email: { email: string } 
): ThunkAction<void, RootState, null, Action<string>> => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/email`,
        email,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        message.success(Messages.ResetPasswordLinkSent);
        return response.data; 
      }
    } catch (error: any) {
      if (error.response) {
        message.error(error.response.data.message || Messages.ResetPasswordLinkFailed);
        return error.response.data; 
      } else {
        message.error(Messages.ResetPasswordLinkFailed);
        return { status: false, message: Messages.ResetPasswordLinkFailed };
      }
    }
  };
};

export const setNewPassword = (
  data
): ThunkAction<void, RootState, null, Action<string>> => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/reset`,
        data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Reset password successfully");
        const type = localStorage.getItem("type");
        window.location.href = `/${type}-login`;
      }
    } catch (error) {
      toast.error("Reset password email failed!");
    }
  };
};

export const getRolesPermissions = (): ThunkAction<
  void,
  RootState,
  null,
  Action<string>
> => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/permission`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        dispatch(setPermissions(response.data));
      }
    } catch (error) {
      toast.error("get permission failed try logged in again!");
    }
  };
};
