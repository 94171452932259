import React, { useEffect, useRef, useState } from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 25px 0;
  
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  
  table {
    min-width: max-content;
  }
`;
const PreviewMatrixInline = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [localShowAnswer, setLocalShowAnswer] = useState(false);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  let valid_response_value = [];
  let cols = [];
  if (
    currentQuestion &&
    currentQuestion?.correct_answer &&
    currentQuestion?.correct_answer.valid_response
  ) {
    valid_response_value = currentQuestion?.correct_answer.valid_response.value;
    if (Array.isArray(valid_response_value[0].options))
      cols = valid_response_value[0].options;
  }

  const textRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    valid_response_value?.map((item, i) => {
      // parse html string
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.stem);
      }
      return textRef.current[i].innerHTML;
    });
  }, [valid_response_value]);

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
       <ScrollContainer>

      <table className="w-full text-sm text-left text-gray-500">
        <tbody>
          {valid_response_value.map((row, index) => (
            <tr key={row?.id}>
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {/* {row?.stem} */}
                <div ref={(el) => (textRef.current[index] = el)} />
              </td>
              {Array.isArray(row?.options) &&
                row?.options.map((option) => (
                  <td
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    key={option?.id}
                  >
                    <label>
                      <div className="flex items-center gap-2">
                        <input
                          type={
                            currentQuestion?.settings?.multiple_response
                              ? 'checkbox'
                              : 'radio'
                          }
                          checked={
                            showAnswer || localShowAnswer
                              ? option?.isChecked
                              : false
                          }
                        />
                        <p>{option.label}</p>
                      </div>
                    </label>
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
      </ScrollContainer>

    </PreviewWrapper>
  );
};

export default PreviewMatrixInline;
