export const Colors = {
  primary: "#FA9B31",
  bgWhite: "#FFFFFF",
  bgLightGray: "#F7F7F7",
  bgBlack: "#707070",
  textWhite: "",
  textBlack: "#333333",
  iconBlack: "#333333",
  grayBorder: "#CACACA",
  danger: "#F80000",
  inputBorder: "#E1E1E1",
};
