import React, { useState } from 'react';
import { Dropdown, Button, Modal } from 'antd';
import { MoreVertical } from 'lucide-react';
import './CustomDropdown.scss';
import Play from "../../icons/Play";
import BlackDelete from "../../icons/BlackDelete";
import BlackAssign from "../../icons/BlackAssign";
import BlackEdit from "../../icons/BlackEdit";
import BlackDuplicate from "../../icons/BlackDuplicate";

interface CustomDropdownProps {
    record: any;
    onRowClick?: (record: any) => void;
    onDelete?: (id: string) => void;
    onDuplicate?: (record: any) => void;
    onEdit?: (record: any) => void;
    onAssign?: (record: any) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
                                                                  record,
                                                                  onRowClick,
                                                                  onDelete,
                                                                  onDuplicate,
                                                                  onEdit,
                                                           onAssign
                                                              }) => {
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);

    const handlePreview = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsPreviewVisible(true);
        onRowClick?.(record);
    };

    const menuItems = [
        {
            key: '0',
            label: (
                <div className="custom-dropdown-item" onClick={handlePreview}>
                    <Play /> Preview
                </div>
            ),
        },
        {
            key: '1',
            label: (
                <div className="custom-dropdown-item" onClick={onAssign}>
                    <BlackAssign /> Assign
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    className="custom-dropdown-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete?.(record.id);
                    }}
                >
                    <BlackDelete /> Delete
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div
                    className="custom-dropdown-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        onEdit?.(record);
                    }}
                >
                    <BlackEdit /> Edit
                </div>
            ),
        },
        {
            key: '4',
            label: (
                <div
                    className="custom-dropdown-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDuplicate?.(record);
                    }}
                >
                    <BlackDuplicate /> Duplicate
                </div>
            ),
        },
    ];

    return (
        <>
            <Dropdown
                menu={{ items: menuItems }}
                trigger={['click']}
                overlayClassName="custom-dropdown"
                placement="bottomRight"
            >
                <Button
                    type="text"
                    className="custom-dropdown-trigger"
                    icon={<MoreVertical className="w-5 h-5 text-gray-600" />}
                    onClick={(e) => e.stopPropagation()}
                />
            </Dropdown>
        </>
    );
};

export default CustomDropdown;