import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

import PreviewWrapper from '../PreviewWrapper/PreviewWrapper';
import { OrderListBloc } from 'components/subquestions/OrderListBloc/OrderListBloc';
import { formatDataWithLabel } from 'utils';
import { setTypeQuestionOpenInEditMode } from 'redux/slices/EditModeSlice';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

type PreviewOrderListProps = {
  currentQuestion: any;
  showAnswer?: boolean;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
};

const PreviewOrderList = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode,
  editMode,
}: PreviewOrderListProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [subQuestion, setSubQuestion] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [orderListDraft, setOrderListDraft] = useState(null);

  const [localShowAnswer, setLocalShowAnswer] = useState(false);

  useEffect(() => {
    const stimList = currentQuestion?.correct_answer?.stimulus_list;

    setSubQuestion((prev) => (prev = currentQuestion?.correct_answer));

    setCorrectAnswers(
      (prev) =>
        (prev = formatDataWithLabel(
          currentQuestion?.correct_answer?.valid_response?.value
        ))
    );

    setOrderListDraft((prev) => (prev = formatDataWithLabel(stimList)));
  }, [currentQuestion]);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleShowAnswer = () => {
    if (setShowAnswers) {
      setShowAnswers((prev) => !prev);
    } else {
      setLocalShowAnswer((prev) => !prev);
    }
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
      // handleShowAnswer={handleShowAnswer}
    >
      {subQuestion && (
        <OrderListBloc
          orderList={orderListDraft}
          correctAnswers={correctAnswers}
          setOrderList={setOrderListDraft}
          showAnswer={showAnswer ?? localShowAnswer}
          editMode={false}
          score={currentQuestion?.correct_answer?.valid_response?.score}
        />
      )}
    </PreviewWrapper>
  );
};

export default PreviewOrderList;

PreviewOrderList.defaultProps = {
  setShowAnswers: null,
  parentMode: 'assessment',
  editMode: false,
};
