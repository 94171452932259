import { Fragment, useEffect, useState } from 'react';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { generateWords } from 'utils/questionUtils';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

const PreviewClozeDragDown = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;
  const [words, setWords] = useState<any[]>([]);
  const [_correct, set_correct] = useState([]);
  const [localShowAnswer, setLocalShowAnswer] = useState(false);

  useEffect(() => {
    const newWords = generateWords(currentQuestion?.template_response || "");
    setWords(newWords);
  }, [showAnswer, currentQuestion]);

  const handleChangeCorrectAnswer = (value, id) => {
    let newArray = [..._correct];
    if (newArray.length !== 0) {
      let index = newArray.findIndex((item) => item?.id === id);
      if (index !== -1) {
        newArray[index] = { correct: value, id };
      } else {
        newArray.push({ correct: value, id });
      }
    } else {
      newArray.push({ correct: value, id });
    }

    set_correct(newArray);
  };

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const getCurrentAnswer = (id, position) => {
    let val = '';
    const correct_answer = currentQuestion?.correct_answer;
    if(correct_answer && correct_answer?.valid_response && Array.isArray(correct_answer?.valid_response?.value)){
      const valid_res_value = correct_answer?.valid_response?.value;
      const answerIndex = valid_res_value.findIndex(item => item?.id == id && item?.position == position);
      if(answerIndex != -1) {
        val = valid_res_value[answerIndex].correct;
      }
    }
      return val
  };

  const handleResponseStyle = (moreOptions, correctAnswers, index) => {
    let style: any = {
      width: 'fit-content',
      height: 'fit-content',
      border: '1px solid #ddd',
      borderRadius: '6px',
      padding: '8px',
      margin: '10px 0',
    };
  
    // Apply global response options 
    if (moreOptions?.response_options) {
      style = {
        ...style,
        ...moreOptions.response_options,
        width: moreOptions.response_options.width ? `${moreOptions.response_options.width}px` : 'fit-content',
        height: moreOptions.response_options.height ? `${moreOptions.response_options.height}px` : 'fit-content',
        fontSize: moreOptions?.layout?.fontSize || 'inherit',
      };
    }
  
    // Check if individual response options
    const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
    if (resOptionsIndividaul.length > 0) {
      const allRes = correctAnswers.filter(ca => ca?.type === 'Response');
      const currentResFromCorrectAnswers = correctAnswers[index];
      const currentResIndex = allRes.findIndex((res, idx) => currentResFromCorrectAnswers === res);
  
      if (currentResIndex > -1) {
        const currentResOptions = resOptionsIndividaul[currentResIndex];
        if (currentResOptions) {
          style = {
            ...style,
            width: currentResOptions.width ? `${currentResOptions.width}px` : style.width,
            height: currentResOptions.height ? `${currentResOptions.height}px` : style.height,
            fontSize: currentResOptions.fontSize || style.fontSize,
          };
        }
      }
    }
  
    return style;
  };
  const handlePlaceholder = (moreOptions, correctAnswers, index) => {
    let placeholder = more_options && more_options?.response_options?.placeholder ?
        more_options?.response_options?.placeholder :
        "Select option";

    const resOptionsIndividaul = moreOptions?.response_options_individaul || [];

    if(moreOptions && resOptionsIndividaul.length > 0) {
      const allRes = correctAnswers.filter(ca => ca?.type == 'Response');
      const currentResFromcorrectanswers = correctAnswers[index];
      const currentResIndex = allRes.findIndex((res, idx) => currentResFromcorrectanswers === res);
        if(currentResIndex > -1) {
            const currentResOptions = resOptionsIndividaul.find((_item, idx) => idx == currentResIndex);
            if(currentResOptions) {
                placeholder = currentResOptions?.placeholder ? currentResOptions.placeholder : placeholder;
            }
        }
    }

    return placeholder;
  }

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #D9D9D9',
          padding: '24px',
          marginBottom: '10px',
          backgroundColor: '#f0f0f0',
          overflowX: 'hidden',
        }}
      >
        <div
          style={more_options && more_options?.layout.fontSize ?
            {fontSize:more_options?.layout.fontSize, lineHeight: 'normal', } :
          {}}
        >
          {words.map((value, index) => (
            <Fragment key={index}>
              {index > 0 &&
                words[index].id === words[index - 1].id && (
                  <span>&nbsp;</span>
                )}
              {index > 0 &&
                words[index].id !== words[index - 1].id && <br />}
              {value.content === 'Response' ? (
                <span className="align-center justify-center ml-1">
                  {showAnswer || localShowAnswer ? (
                    <span className="underline font-bold">
                      {getCurrentAnswer(value?.id, value?.position)}
                    </span>
                  ) : (
                    <select
                      onChange={(e) =>
                        handleChangeCorrectAnswer(e.target.value, value.id)
                      }
                      style={handleResponseStyle(more_options, words, index)}
                    >
                      <option value={''}>
                      {handlePlaceholder(more_options, words, index)}
                      </option>

                      {Array.isArray(currentQuestion?.options) &&
                        currentQuestion.options.map((data, key) => {
                          if (
                            data?.lineId === value.id &&
                            data?.ResponseId === value.position
                          ) {
                            return (
                              <Fragment key={key}>
                                {data?.option &&
                                  data?.option?.map((option, idx) => (
                                    <option
                                      key={idx}
                                      value={option.option}
                                      selected={
                                        (showAnswer || localShowAnswer) &&
                                        getCurrentAnswer(
                                          value.id,
                                          value.position
                                        ) === option.option
                                      }
                                    >
                                      {option.option}
                                    </option>
                                  ))}
                              </Fragment>
                            );
                          }
                        })}
                    </select>
                  )}
                </span>
              ) : value.type === 'img' ? (
                value?.imgposition === '' ? (
                  <img
                    key={index}
                    src={value.content}
                    width={value.width}
                    height={value.height}
                    alt={value.content}
                  />
                ) : (
                  <div style={{ textAlign: value.imgalignment }}>
                    <img
                      key={index}
                      src={value.content}
                      width={value.width}
                      height={value.height}
                      alt={value.alttext}
                      style={{ margin: 'auto', display: 'inline' }}
                    />
                  </div>
                )
              ) : value.type === 'table' ? (
                <span
                  className="answertblclass"
                  dangerouslySetInnerHTML={{ __html: value.content }}
                />
              ) : (
                <span>{value.content}</span>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </PreviewWrapper>
  );
};

export default PreviewClozeDragDown;
