import EditableOption from "./components/editableOption.component";
import { useRef, useState } from "react";
import uuid from "react-uuid";
import AddButton from "studio/components/general/AddButton.component";
import { Typography } from "antd";
import { Sizes } from "studio/theme/Sizes.theme";
import CorrectAnswerWrapper from "./components/correctAnswerSection.component";
import { toast } from "react-toastify";
import MultipleAnswersTab from "./components/multipleAnswerTab.component";

const { Text } = Typography;
export interface IQuestionEditProps {
  data: any;
  setData: (newData: any) => void;
}

const MultipleAnswersEdit = ({ data, setData }: IQuestionEditProps) => {
  const { options, correct_answer, more_options } = data;
  const [currentOptionId, setCurrentOptionId] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { valid_response, alt_responses } = correct_answer;
  const altResponses = alt_responses || [];

  const handleBoxCheck = (itemId: string) => {
    const selectedItem = options.find((op) => op.id === itemId);
    let max_selection = 0;
    if (
      !isNaN(Number(more_options?.layout?.max_selection)) &&
      Number(more_options?.layout?.max_selection) > 0
    ) {
      max_selection = Number(more_options?.layout?.max_selection);
    }
    let newValidResponse = { ...valid_response };
    let newAltResponses = altResponses;

    if (selectedItem) {
      if (activeTabIndex === 0) {
        newValidResponse = {
          value: valid_response.value.find((v) => v?.id === itemId)
            ? valid_response.value.filter((it) => it.id !== itemId)
            : checkResponseLimit(valid_response.value, max_selection)
            ? valid_response.value.concat({
                ...selectedItem,
                checked: true,
              })
            : valid_response.value,
          score: valid_response.score,
        };
      } else {
        newAltResponses = altResponses.map((altRes, index) => {
          return index === activeTabIndex - 1
            ? {
                ...altRes,
                value: altRes.value.find((v) => v.id === itemId)
                  ? altRes.value.filter((it) => it.id !== itemId)
                  : checkResponseLimit(altRes?.value, max_selection)
                  ? [...altRes.value, { ...selectedItem, checked: true }]
                  : altRes.value,
              }
            : altRes;
        });
      }

      setData({
        ...data,
        correct_answer: {
          valid_response: newValidResponse,
          alt_responses: newAltResponses,
        },
      });
    }
  };

  const activeTab =
    activeTabIndex == 0 || altResponses.length == 0
      ? valid_response
      : altResponses[activeTabIndex - 1];

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = () => {
    let newOptions = [...options];
    const draggedItemContent = newOptions.splice(dragItem.current, 1)[0];
    newOptions.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setData({ ...data, options: newOptions });
  };

  const handleAddItem = () => {
    const updatedOptions = [...data.options];
    const newlyAddedItem = {
      id: uuid(),
      label: "",
      checked: false,
    };
    updatedOptions.push(newlyAddedItem);
    setData({ ...data, options: updatedOptions });
  };

  const handleDeleteOption = (item) => {
    let newOptions = options?.filter((o) => o.id !== item.id);
    const newValidResponse = {
      ...correct_answer.valid_response,
      value: correct_answer.valid_response?.value.filter(
        (va) => va?.id !== item?.id
      ),
    };
    let newAltResponses = correct_answer.alt_responses;
    if (newAltResponses.length > 0) {
      newAltResponses = newAltResponses.map((ar) => ({
        ...ar,
        value: ar?.value.filter((v) => v?.id !== item?.id),
      }));
    }
    setData({
      ...data,
      options: newOptions,
      correct_answer: {
        valid_response: newValidResponse,
        alt_responses: newAltResponses,
      },
    });
  };

  const handleOptionChange = (e, index) => {
    const updatedItems = [...options];
    updatedItems[index] = {
      id: updatedItems[index].id,
      label: e,
      checked: false,
    };

    const updatedDataOptions = updatedItems.map((item) => ({
      id: item.id,
      label: item.label,
      checked: item.checked,
    }));

    let newAltResponses = correct_answer.alt_responses;
    if (newAltResponses.length > 0) {
      newAltResponses = newAltResponses.map((ar) => ({
        ...ar,
        value: [],
      }));
    }

    setData({
      ...data,
      options: updatedDataOptions,
      correct_answer: {
        valid_response: {
          ...correct_answer.valid_response,
          value: [],
        },
        alt_responses: newAltResponses,
      },
    });
  };

  return (
    <div>
      <Text>ANSWERS</Text>
      <div style={{ marginTop: Sizes.base, marginBottom: Sizes.base }}>
        {options?.map((item, index) => (
          <div key={item.id} className="custom_jodit--wrapper">
            <EditableOption
              index={index}
              dragItem={dragItem}
              dragOverItem={dragOverItem}
              onDragEnd={handleSort}
              OnDeleteElement={(e) => handleDeleteOption(item)}
              onFocus={(e) => {
                setCurrentOptionId(item.id);
              }}
              onBlur={(e) => {
                setCurrentOptionId("");
              }}
              isActive={item.id === currentOptionId}
              label={item?.label}
              onOptionChange={handleOptionChange}
            />
          </div>
        ))}
      </div>
      <AddButton onClick={handleAddItem} />

      <CorrectAnswerWrapper
        data={data}
        setData={setData}
        activeTab={activeTab}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      >
        <MultipleAnswersTab
          more_options={more_options}
          options={options}
          activeTab={activeTab}
          handleBoxCheck={handleBoxCheck}
        />
      </CorrectAnswerWrapper>
    </div>
  );
};

export default MultipleAnswersEdit;

export const checkResponseLimit = (
  valueArr: any[],
  maxSelection: number
): boolean => {
  if (maxSelection && maxSelection > 0) {
    if (valueArr.length == maxSelection) {
      toast.error("You exceeded the max selection number");
    }
    return valueArr.length < maxSelection;
  }
  return true;
};
