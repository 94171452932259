import { Input } from "antd";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Sizes } from "studio/theme/Sizes.theme";

type ISearchInput = {
  onChange: (value: string) => void;
  placeholder?: string;
  size?: SizeType;
  style?: any;
  value: string;
};

const SearchInput = ({
  size,
  style,
  placeholder,
  onChange,
  value,
}: ISearchInput) => {
  return (
    <Input
      value={value}
      size={size || "large"}
      placeholder={placeholder || "Search Questions"}
      onChange={(e) => onChange(e.target.value)}
      style={style}
      prefix={
        <SearchOutlined
          style={{
            opacity: 0.4,
            fontSize: Sizes.iconFontSize,
            marginRight: 14,
            color: "#CACACA",
          }}
        />
      }
    />
  );
};

export default SearchInput;
