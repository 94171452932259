import React, { useState } from "react";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";
import { setTypeQuestionOpenInEditMode } from "redux/slices/EditModeSlice";
import { QuestionContent } from "components/PreviewQuestions/PreviewWrapper/styles";
import AudioPlugin from "pages/AuthorQuestion/WrittenRecorded/AudioRecorder/AudioPlugin";
import QuestiontypeModal from "components/QuestiontypeModal";
import { FaCircle, FaPlay } from "react-icons/fa";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";
interface PreviewAudioRecorderProps {
  currentQuestion: {
    id: number;
    type: string;
    question: string;
    overwrite_warning: boolean;
    silence_stop_duration: number;
    is_math: boolean;
    options: {
      max_length: number;
    };
    more_options: {
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      ui_style: {
        download_link: boolean;
        pause: boolean;
        recording_cue: boolean;
        pause_recording: boolean;
        play: boolean;
        fontSize: string;
        play_bubble: boolean;
        progress_bar: boolean;
        record_bubble: boolean;
        seek: boolean;
        start_recording: boolean;
        stop_recording: boolean;
        timer: boolean;
        type: string;
        volume_control: boolean;
        volume_meter: boolean;
        waveform: boolean;
        responsive_layout: boolean;
      };
      validation: {
        max_score: number;
        min_score_if_attempted: number;
      };
    };
    points: string;
    settings: any;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewAudioRecorder: React.FC<PreviewAudioRecorderProps> = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "assessment",
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const maxLength = currentQuestion.options.max_length;
  const uiStyle = currentQuestion.more_options?.ui_style;

  const handleEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };
  const fontsize = currentQuestion?.more_options?.ui_style?.fontSize ?? 'medium';
  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const mappedFontSize = fontSizeMapping[fontsize] || "14px";

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const instructor_stimulus = currentQuestion?.more_options?.metadata?.instructor_stimulus ;

  return (
    <QuestionContent parentMode={parentMode === "question"}  style={{ fontSize: mappedFontSize }}>
       {instructor_stimulus && (
        <div
          className="bg-blue-100 p-4 mb-4 rounded-lg"
          style={{
            fontSize: mappedFontSize,
            lineHeight: "1.8rem",
          }}
        >
          {instructor_stimulus}
        </div>
      )}
      <FullWidthContainer>
        <LeftContent>
          <BlockTitle
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />

              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id as any}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => {
                  dispatch(setClosePreview());
                }}
                style={{
                  width: "150px",
                  height: "40px",
                  fontWeight: 600,
                  color: "#FFF",
                  borderRadius: "8px",
                  backgroundColor: "#FA9B31",
                }}
              >
                Back to edit
              </button>
            </>
          )}
        </RightContent>
      </FullWidthContainer>
      {editMode ? (
        <div className="grid justify-items-center  border-2 p-8 w-96">
          {uiStyle.type == "block" ? (
            <div>
              <h1 className="font-bold mb-5 text-center">Audio Recorder</h1>
              <h1 className="text-red-500 font-bold text-center">
                Click on record to start
              </h1>
              <div className="flex bg-slate-200 p-2 gap-2 rounded-md mt-20 ">
                <FaCircle color="red" />
                <div className="border-r-2" />
                <FaPlay color="gray" />
                <div className="border-r-2" />
                {uiStyle.timer && (
                  <div className="flex text-gray-500 gap-1">
                    <p id="progress">00:00</p>
                    <span>/</span>
                    {formatTime(maxLength)}
                  </div>
                )}
                <hr className="bg-gray-300 justify-center mt-1 text-red-400 h-1.5 w-40" />
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-6">
              <button
                type="button"
                className="flex gap-4 w-full bg-gray-300 text-black font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
                disabled
              >
                <FaCircle color="red" />
                Record
              </button>
              <button
                type="button"
                className="flex gap-4 w-full bg-gray-300 text-black font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
                disabled
              >
                <FaPlay color="black" />
                Playback
              </button>
            </div>
          )}
        </div>
      ) : (
        <AudioPlugin selectedControls={currentQuestion} />
      )}
    </QuestionContent>
  );
};

export default PreviewAudioRecorder;
