import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "studio/constants/routes.constants";
import AssessmentLib from "studio/icons/AssesmentLib";
import QuestionBank from "studio/icons/QuestionBank";
import Settings from "studio/icons/Settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
interface IconProps {
  fill?: string;
  className?: string;
}

interface Props {

  type?: string;
}

interface MenuItem {
  icon: React.FC<IconProps>;
  title: string;
  path: string;
}

const MainSideMenu = ({ type }: Props) => {
  const location = useLocation();

  const menuItems: MenuItem[] = type !== 'player' ? [
    { icon: AssessmentLib, title: "Assessment Library", path: ROUTES.assessmentLibrary },
    { icon: QuestionBank, title: "Question Bank", path: ROUTES.questionsBank },
    { icon: Settings, title: "Settings", path: ROUTES.userManagement }
  ] : [];


  return (
    <div className="bg-light-gray row-start-2 flex flex-col items-center py-6" style={{ borderRight: '1px solid #CACACA' }}>
      {menuItems.map((item, index) => {
        const isActive = location.pathname === item.path;

        return (
          <div
            key={index}
            className={`
              relative w-full flex justify-center mb-6 h-10 items-center
              ${
                isActive
                  ? "after:absolute after:right-0 after:top-0 after:h-full after:w-1 after:rounded-sm after:bg-primary"
                  : ""
              }
            `}
            title={item.title}
          >
            <Link to={item.path} className="group">
              <item.icon fill={isActive ? "#fa9b31" : "#333333"} className="group-hover:fill-primary transition-all duration-300" />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default MainSideMenu;
