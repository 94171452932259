import SingleChoice from "studio/icons/CreateAssementsIcons/singleChoice";
import MultipleChoice from "studio/icons/CreateAssementsIcons/mutlipleChoices";
import TrueFalse from "studio/icons/CreateAssementsIcons/trueOrFalse";
import BlockLayout from "studio/icons/CreateAssementsIcons/blockLayout";
import SingleChoiceTemplate from "./questionsTemplates/SingleChoice";
export const menuGroupsList = [
    {
      title: "Multiple choice",
      items: [
        {
          key: "single_choice",
          icon: <SingleChoice className="text-dark-gray w-3 h-3 hover:text-white hover:fill-white" />,
          text: "Single choice",
          templateTitle: "This is single choice example",
          template: <SingleChoiceTemplate />,
          groupKey: "mcq",
          itemKey: "mcs"
        },
        {
          key: "multiple_choice",
          icon: <MultipleChoice className="text-dark-gray w-3 h-3 hover:text-white hover:fill-white" />,
          text: "Multiple choice",
          templateTitle: "This is multiple choice example",
          template: null,
          groupKey: "mcq",
          itemKey: "mcmr"
        },
        {
          key: "true_false",
          icon: <TrueFalse className="text-dark-gray w-3 h-3 hover:text-white hover:fill-white" />,
          text: "True/False",
          templateTitle: "This is true false example",
          template: null,
          groupKey: "mcq",
          itemKey: "taf"
        },
        {
          key: "block_layout",
          icon: <BlockLayout className="text-dark-gray w-3 h-3 hover:text-white hover:fill-white" />,
          text: "Block layout",
          templateTitle: "This is block layout example",
          template: null,
          groupKey: "mcq",
          itemKey: "mcbl"
        },
      ],
    },
  ];
  export const iconLookup = menuGroupsList.reduce((acc, group) => {
    group.items.forEach(item => {
      acc[item.itemKey] = item.icon;
    });
    return acc;
  }, {} as Record<string, React.ReactNode>);