import React, { useState, useCallback, useRef, useEffect } from "react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";
import QuestiontypeModal from "components/QuestiontypeModal";
// import CustomJoditEditor from "components/CustomJoditEditor";
import { createMathliveButton } from "components/CustomJoditEditor/mathliveButton";
import axios from "axios";
import { debounce } from "utils/generalUtils";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { Jodit } from "jodit-react";
import SpecialCharacterModal from "components/CustomJoditEditor/ConfigMath/SpecialCharacters";
import CustomJoditEditor from "components/CustomJoditEditor/ConfigMath";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";
interface PreviewWrittenRecordedMathEssayRTProps {
  currentQuestion: {
    id: number;
    question: string;
    more_options: {
      character_map: [];
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      validation: {
        max_score: string;
        min_score_if_attempted: number;
      };
      ui_style: {
        fontsize: string;
        fontSize: string;
        min_height: string;
        max_height: string;
        disable_auto_link: string;
        placeholder: string;
        submit_over_limit: boolean;
        spellcheck: boolean;
        is_math?: boolean;
      };
    };
    options: {
      text_blocks: [];
      content: string;
      formatting_options: [];
      max_length: number;
      show_word_limit: string;
      show_word_count: boolean;
    };
    type: string;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewWrittenRecordedMathEssayRT: React.FC<
  PreviewWrittenRecordedMathEssayRTProps
> = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "assessment",
  editMode = false,
}) => {
  const [content, setContent] = useState<string>(
    currentQuestion?.options?.content || ""
  );
  const [isOverLimit, setIsOverLimit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxWords = currentQuestion?.options?.max_length || 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const autolink = currentQuestion?.more_options?.ui_style?.disable_auto_link;
  const minHeight = currentQuestion.more_options?.ui_style?.min_height || 200;
  const maxHeight = currentQuestion.more_options?.ui_style?.max_height || 200;
  const [wordCount, setWordCount] = useState(0);
  const editorInstanceRef = useRef<Jodit | null>(null);

  const [isCharaModalOpen, setCharaModalOpen] = useState(false);

  const handleEditorBlur = (newContent: string) => {

    let updatedContent = newContent;
    setContent(updatedContent);
  };
  const specialCharacters =currentQuestion?.more_options
  ?.character_map
  const more_options = currentQuestion?.more_options;

  const handleEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };
  const placeholder = currentQuestion.more_options?.ui_style?.placeholder || "";
  const instructorStimulus =
    currentQuestion.more_options?.metadata?.instructor_stimulus;

  /*  const config = {
       // readonly: false,
       // toolbarAdaptive: true,
       placeholder: placeholder,
       toolbar: { items: currentQuestion?.options?.formatting_options },
     };
    */

  const tokenUser = useSelector(
    (state: RootState) => state.assessments.config.tokenUser
  );
  // Access the token from the state
  const localToken = localStorage.getItem("token");
  // Determine upload URL and headers
  const defaultApiUrl = process.env.REACT_APP_API_URL || "";
  const obeAppApiUrl = process.env.REACT_APP_OBE_APP_API_URL || "";
  const uploadUrl =
    localToken || !tokenUser
      ? `${defaultApiUrl}/api/formupload`
      : `${obeAppApiUrl}/formupload`;
  const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    ...(tokenUser && !localToken
      ? { Authorization: `Bearer ${tokenUser}` }
      : { Authorization: `Bearer ${localToken}` }),
  };

  const url = window.location.href;
  const regex = /\/edit-question\/([^\/]+)$/;
  const match = url.match(regex);


  const handleFileUpload = async (file, editor) => {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      console.log(formData);

      const response = await axios.post(uploadUrl, formData, { headers });
      console.log(response);

      if (response.status) {
        editor.s.insertHTML(
          `<img src="${response.data.item.src}" alt="Uploaded Image"/>`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const customButtons = [
    {
      name: "uploadImage",
      iconURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy_ZxV2ImKF9V0GRXWBfOTuY76TfKqoDa-O4zb7_lUrINJGSLY&s", // URL for your custom icon
      exec: (editor) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.click();
        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            console.log(file);
            handleFileUpload(file, editor);
          }
        };
      },
    },
    {
      name: "math",
      iconURL: "https://img.icons8.com/ios/50/000000/sigma.png",
      exec: (editor) => {
        const button = createMathliveButton(editor);
        button.exec();
      },
    },
    {
      name: "Response",
      tooltip: "Response",
      exec: (editor) => {
        const span = document.createElement("span");
        span.style.border = "1px solid black";
        span.style.padding = "5px"; // Add padding for spacing
        span.style.userSelect = "none";
        span.style.pointerEvents = "none";
        span.style.backgroundColor = "rgb(238, 238, 238)";
        span.contentEditable = "false";
        span.innerHTML = "<b>Response</b>";
        span.id = "btnresponse";

        editor.s.insertHTML(span.outerHTML);
      },
    },
  ];
  const openSpecialCharacterModal = (editor) => {
    setEditorInstance(editor);
    setCharaModalOpen(true);
  };
  const getOrderedButtons = () => {
    const formattingOptions = currentQuestion?.options?.formatting_options ?? [];
  
    const customButtonsMap = new Map(
      customButtons.map((button) => [button.name, button])
    );
  
    const finalButtons = formattingOptions.map((buttonName) => {
      if (buttonName === 'specialCharacters') {
        return {
          name: '@',
          tooltip: 'Insert Special Character',
          exec: openSpecialCharacterModal,
        };
      }
      if (customButtonsMap.has(buttonName)) {
        return customButtonsMap.get(buttonName); 
      }
      return buttonName; 
    });
  
    return finalButtons.filter(Boolean); 
  };
  const setEditorInstance = (editorInstance: any) => {
    editorInstanceRef.current = editorInstance;
  };

  const formattingOptions: string[] =
    currentQuestion?.options?.formatting_options ?? [];

  const uniqueButtons = new Set<string>(formattingOptions);

  const updatedButtons = customButtons
    .filter(
      (button) =>
        typeof button === "string" ||
        uniqueButtons.has((button as { name: string }).name)
    )
    .filter(
      (button, index, self) =>
        typeof button === "string" ||
        self.findIndex(
          (b) =>
            typeof b !== "string" &&
            (b as { name: string }).name === (button as { name: string }).name
        ) === index
    );

  let getAllItems = updatedButtons.concat(formattingOptions as any);
  const showWordCount = currentQuestion?.options?.show_word_count;
  const namesWithObjects = new Set(
    getAllItems
      .filter((item) => typeof item === "object" && item !== null)
      .map((item) => item.name)
  );
  const isSpecialChar = currentQuestion?.more_options?.character_map?.filter(Boolean).length !== 0;


  const filteredItems = getAllItems.filter((item) => {
    if (typeof item === "object" && item !== null) {
      return true;
    }
    if (typeof item === "string" && !namesWithObjects.has(item)) {
      return true;
    }
    return false;
  });
  
  const fontSize = currentQuestion.more_options.ui_style.fontsize;
  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const mappedFontSize = fontSizeMapping[fontSize] || "14px";

  // const clipboardButtons = [];
  // if (currentQuestion.options.show_cut) clipboardButtons.push("cut");
  // if (currentQuestion.options.show_copy) clipboardButtons.push("copy");
  // if (currentQuestion.options.show_paste) clipboardButtons.push("paste");

  const joditConfig = {
    minHeight: minHeight,
    maxHeight: maxHeight,
    placeholder: placeholder,
    defaultLineHeight: "1.5",
    readonly: editMode,
    style: {
      fontSize: 14,
    },
    buttons: getOrderedButtons(), // Use the updated function
    limitWords: maxWords + 1,
    showWordsCounter: showWordCount,
    showCharsCounter: false,
  };

  useEffect(() => {
    if (editorInstanceRef.current) {
      console.log("Editor instance is ready:", editorInstanceRef.current);
  }

  if (autolink) {
    document.body.classList.add("hideJoditPopup");
  } else {
    document.body.classList.remove("hideJoditPopup");
  }
  }, [editorInstanceRef.current,autolink,maxWords,wordCount]);


  const insertCharacter = (char: string) => {
    if (!editorInstanceRef.current) {
      console.error("Editor instance is not available.");
      return;
    }
    editorInstanceRef.current.selection.insertHTML(char); // Insert the character
  };

  /* const wordCount =
       content.trim() === "<p><br></p>"
         ? 0
         : content
           .trim()
           .split(/\s+/)
           .filter((word) => word !== "").length;*/

           console.log("more_options",more_options)
  return (
    <QuestionContent parentMode={parentMode === "question"} style={more_options && more_options?.ui_style?.fontSize ? 
      {fontSize: more_options.ui_style?.fontSize}: 
      {fontSize: 'medium'}
    }> 
      <FullWidthContainer>
        <LeftContent>
        {instructorStimulus  && !editMode && (
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {instructorStimulus}
        </div>
      )}
          <BlockTitle style={more_options && more_options?.ui_style.fontSize ?
            {fontSize:more_options?.ui_style.fontSize } : {}}
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />

              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id as any}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => {
                  dispatch(setClosePreview());
                }}
                style={{
                  width: "150px",
                  height: "40px",
                  fontWeight: 600,
                  color: "#FFF",
                  borderRadius: "8px",
                  backgroundColor: "#FA9B31",
                }}
              >
                Back to edit
              </button>
            </>
          )}
        </RightContent>
      </FullWidthContainer>
      <div>
  
        <div 
        // className={isOverLimit ? "editor-over-limit" : "editor"}
        
        >
          <CustomJoditEditor
            label=""
            value={content}
            onBlur={(newContent) => handleEditorBlur(newContent)}
            allconfig={false}
            userconfig={joditConfig}
            spellCheck={currentQuestion?.more_options?.ui_style?.spellcheck}
            minHeight={currentQuestion?.more_options?.ui_style?.min_height}
            maxHeight={currentQuestion?.more_options?.ui_style?.max_height}
            
          />
          {isCharaModalOpen && (
            <SpecialCharacterModal
              specialCharacters={specialCharacters}
              onSelect={(char) => {
                console.log("Selected character:", char); // Debugging
                insertCharacter(char);
              }}
              onClose={() => setCharaModalOpen(false)}
            />
          )}

        </div>

        <div
          className="flex gap-1 mt-2"
          style={isOverLimit ? { color: "red" } : {}}
        >
          {currentQuestion?.options?.show_word_limit === "alwaysVisible" ||
          currentQuestion?.options?.show_word_limit === ""  ||
          currentQuestion?.options?.show_word_limit === null ? (
            <p>{maxWords} Word Limit</p>
          ) : null}

          {/* {currentQuestion?.options?.show_word_limit === "onLimit" &&
           <p>{maxWords} Word Limit</p>} */}
        </div>
      </div>
    </QuestionContent>
  );
};

export default PreviewWrittenRecordedMathEssayRT;
