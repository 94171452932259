import { Flex, Typography } from "antd";
import { Sizes } from "studio/theme/Sizes.theme";
import BackArrowIcon from "../ui/BackArrowIcon.component";

const { Title } = Typography;

const TitleWithBackIcon = ({ title }: { title: string }) => {
  return (
    <Flex align="center">
      <BackArrowIcon />
      <Title
        style={{
          fontWeight: "normal",
          marginBottom: 0,
          marginLeft: Sizes.padding,
        }}
        level={4}
      >
        {title}
      </Title>
    </Flex>
  );
};

export default TitleWithBackIcon;
