import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";
import { Action } from "redux";
import axios from "axios";

export const getListTags = (
    subjectId: string,
    gradeId: string
): ThunkAction<Promise<any | undefined>, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios.get<any>(
                `${process.env.REACT_APP_API_URL}/api/tag/filter?subject=${subjectId}&grade=${gradeId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        Accept: 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                // Resolve the promise with the data
                return Promise.resolve(response.data);
            }
        } catch (error) {
            console.error("Error fetching tags:", error);
        }

        // Reject the promise with an undefined value in case of error
        return Promise.reject(undefined);
    };
};

export const getTagsList = (): ThunkAction<Promise<any | undefined>, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios.get<any>(
                `${process.env.REACT_APP_API_URL}/api/tag/list?type=assessment`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        Accept: 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (error) {
            console.error("Error fetching tags:", error);
        }

        return Promise.reject(undefined);
    };
};

export const getListTagTypes = async ({ subjectId, gradeId }) => {
    let data: any = null;
    let error: any = null;
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/tagType?subject=${subjectId}&grade=${gradeId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          },
        }
      );
      data = response.data;
    } catch (err) {
      error = err.response?.data?.error || 'Unknown error';
    }
  
    return { data, error };
  };
  

/*export const getListTagTypes = (
    subjectId: string,
    gradeId: string
): ThunkAction<Promise<any | undefined>, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios.get<any>(
                `${process.env.REACT_APP_API_URL}/api/tagType/?subject=${subjectId}&grade=${gradeId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        Accept: 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                // Resolve the promise with the data
                return Promise.resolve(response.data);
            }
        } catch (error) {
            console.error("Error fetching tags:", error);
        }

        // Reject the promise with an undefined value in case of error
        return Promise.reject(undefined);
    };
};*/