import { MCQTypes } from "studio/constants/questionTypes.constants";
import PreviewMcqForStudent from "./MCQ/PreviewMcqForStudent.component";

export interface IPreviewQuestionForStudent {
  data: any;
  userResponse: any;
  setUserResponse: (newValue: any) => void;
}

const RenderQuestionForStudent = ({
  data,
  userResponse,
  setUserResponse,
}: IPreviewQuestionForStudent) => {
  const renderQuestionPreview = () => {
    const qtype = data?.type;
    const props = { data, userResponse, setUserResponse };

    if (
      [
        MCQTypes.BLOCKLAYOUT,
        MCQTypes.MULTIPLEANSWERS,
        MCQTypes.SINGLEANSWER,
        MCQTypes.TRUEFALSE,
      ].includes(qtype)
    ) {
      return <PreviewMcqForStudent {...props} />;
    }
  };

  return <div>{renderQuestionPreview()}</div>;
};

export default RenderQuestionForStudent;
