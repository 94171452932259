import React, { useState } from 'react';
import { Space, Button, Modal, Typography } from 'antd';
import Delete from "../../icons/Delete";
import Assign from "../../icons/Assign";
import Duplicate from "../../icons/Duplicate";
import './ActionButtons.styles.scss';
import BlackDelete from "../../icons/BlackDelete";
import BlackDuplicate from "../../icons/BlackDuplicate";
import {ExclamationCircleOutlined} from "@ant-design/icons";


const { Title, Paragraph } = Typography;
interface ActionButtonsProps {
    onDelete: (id: any) => void;
    onDuplicate: () => void;
    disabled: boolean;
    count?: number;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onDelete, onDuplicate, disabled, count }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);

    const handleDelete = (id: any) => {
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirm = (id: any) => {
        onDelete(id);
        setIsDeleteModalOpen(false);
    };

    const handleDuplicate = () => {
        setIsDuplicateModalOpen(true);
    };

    const handleDuplicateConfirm = () => {
        onDuplicate();
        setIsDuplicateModalOpen(false);
    };

    return (
        <div className="action-buttons">
            <Space size={8}>
                <Button style={{ marginRight: '5px' }}
                    onClick={handleDelete}
                    disabled={disabled}
                    icon={disabled ? <Delete style={{ marginRight: '10px'}}/> : <BlackDelete style={{ marginRight: '10px'}}/>}
                    type="text">Delete
                </Button>
                {/*<Button icon={<Assign />} type="text">Assign</Button>*/}
                <Button
                    onClick={handleDuplicate}
                    disabled={disabled}
                    icon={disabled ? <Duplicate style={{ marginRight: '10px'}}/> : <BlackDuplicate style={{ marginRight: '10px'}}/>}
                    type="text">Duplicate
                </Button>
            </Space>

            {/* Delete Confirmation Modal */}
            <Modal
                open={isDeleteModalOpen}
                okText="Yes"
                cancelText="No"
                centered
                className="confirmation-modal"
                footer={null}
                closable={false}
                width={window.innerWidth > 768 ? 710 : 450}
            >
                <div style={{textAlign: 'center', padding: '47px 150px'}}>
                    {/*<Title level={3} style={{marginBottom: 8}}>
                        Confirmation Delete
                    </Title>*/}

                    <Paragraph
                        style={{
                            marginBottom: 40,
                            fontSize: "19px",
                            fontWeight: 300,
                            fontFamily: "Poppins"
                        }}>
                        You are about to delete {count} item/s.<br/>
                        Are you sure ?
                    </Paragraph>

                    <div style={{display: 'flex', gap: 16, justifyContent: 'center', marginTop: '38px'}}>
                        <Button
                            style={{ width: '150px', height: '50px' }}
                            onClick={() => setIsDeleteModalOpen(false)} size="large">
                            No
                        </Button>
                        <Button
                            type="primary"
                            onClick={(id)=>{
                                onDelete(id);
                                setIsDeleteModalOpen(false)
                            }}
                            size="large"
                            style={{width: '150px', height: '50px', backgroundColor: '#faad14', borderColor: '#faad14'}}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>

            {/* Duplicate Confirmation Modal */}
            <Modal
                open={isDuplicateModalOpen}
                onOk={handleDuplicateConfirm}
                okText="Yes"
                cancelText="No"
                centered
                className="confirmation-modal"
                footer={null}
                closable={false}
                width={window.innerWidth > 768 ? 710 : 450}
            >
                <div style={{textAlign: 'center', padding: '47px 150px'}}>
                    {/*<Title level={3} style={{marginBottom: 8}}>
                        Confirmation Action Duplicate
                    </Title>*/}

                    <Paragraph style={{
                        marginBottom: 40,
                        fontSize: "19px",
                        fontWeight: 300,
                        fontFamily: "Poppins"
                    }}
                    >
                        You are about to duplicates {count} item/s.<br/>
                        Are you sure ?
                    </Paragraph>

                    <div style={{display: 'flex', gap: 16, justifyContent: 'center', marginTop: '38px'}}>
                        <Button
                            style={{ width: '150px', height: '50px' }}
                            onClick={() => setIsDuplicateModalOpen(false)} size="large">
                            No
                        </Button>
                        <Button
                            type="primary"
                            onClick={()=>{
                                onDuplicate();
                                setIsDuplicateModalOpen(false)
                            }}
                            size="large"
                            style={{width: '150px', height: '50px', backgroundColor: '#faad14', borderColor: '#faad14'}}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ActionButtons;