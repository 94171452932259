import {
  TextDecreaseOutlined,
  TextIncreaseOutlined,
} from "@mui/icons-material";
import { Card, Flex, InputNumber, Slider, Space, Typography } from "antd";
import LowercaseAscendingIcon from "studio/icons/QuestionEditIcons/lowercaseAscendingIcon";
import OneTwoThreeIcon from "studio/icons/QuestionEditIcons/onetwothreeIcon";
import UppercaseAscending from "studio/icons/QuestionEditIcons/uppercaseAscendingIcon";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";

const { Text, Title } = Typography;

const LayoutPropertiesSection = ({
  data,
  handleChangeValue,
}: {
  data: any;
  handleChangeValue: (key: string, subKey: string, value: any) => void;
}) => {
  const more_options = data?.more_options || {};
  const getFontSizeValue = () => {
    let value = 2;
    if (more_options?.layout?.fontSize) {
      const foundItem = fontSizeList.find(
        (item) => item?.value == more_options?.layout?.fontSize
      );
      if (foundItem) {
        value = foundItem.key;
      }
    }
    return value;
  };

  const getStemBorder = (value: string) => {
    let color = "#CACACA";
    let borderWidth = 1;
    if (more_options?.layout?.validation_stem_numeration) {
      if (more_options?.layout?.validation_stem_numeration === value) {
        color = Colors.primary;
        borderWidth = 2;
      }
    } else if (value === "numerical") {
      color = Colors.primary;
      borderWidth = 2;
    }
    return { color, borderWidth };
  };

  return (
    <>
      <Title
        style={{
          fontWeight: "600",
          marginBottom: Sizes.padding,
        }}
        level={5}
      >
        Layout and Selection
      </Title>
      <Space direction="vertical" className="w-full mb-4">
        <Text>Number of columns</Text>
        <InputNumber
          className="w-full"
          size="large"
          min="1"
          value={more_options?.layout?.columns || "1"}
          onChange={(val: string) => {
            handleChangeValue("layout", "columns", val);
          }}
        />
      </Space>
      <Space direction="vertical" className="w-full mb-4">
        <Text>Font size</Text>
        <Flex align="center">
          <TextDecreaseOutlined
            style={{
              color: Colors.iconBlack,
              fontWeight: "lighter",
              fontSize: 18,
              opacity: 0.7,
              marginRight: Sizes.base,
            }}
          />
          <Slider
            className="flex-1"
            dots
            min={1}
            max={5}
            tooltip={{
              formatter: (value: number) => {
                return fontSizeList.find((item) => item?.key == value).label;
              },
            }}
            onChange={(value: number) => {
              const mappedValue = fontSizeList.find(
                (item) => item?.key == value
              ).value;
              handleChangeValue("layout", "fontSize", mappedValue);
            }}
            value={getFontSizeValue()}
          />
          <TextIncreaseOutlined
            style={{
              color: Colors.iconBlack,
              fontSize: 32,
              marginLeft: Sizes.base,
            }}
          />
        </Flex>
      </Space>
      <Space direction="vertical" className="w-full mb-4">
        <Text>Stem numeration (review only)</Text>
        <Flex align="center" justify="between" gap={16}>
          {stemNumerationList.map((item) => (
            <Space key={item.value} direction="vertical">
              <Card
                style={{
                  ...cardStyle,
                  borderColor: getStemBorder(item.value).color,
                  borderWidth: getStemBorder(item.value).borderWidth,
                }}
                className="cursor-pointer hover:opacity-70"
                onClick={() => {
                  handleChangeValue(
                    "layout",
                    "validation_stem_numeration",
                    item.value
                  );
                }}
              >
                {item.icon}
              </Card>
              <Text>{item.label}</Text>
            </Space>
          ))}
        </Flex>
      </Space>
    </>
  );
};

export default LayoutPropertiesSection;

const cardStyle = {
  width: 80,
  height: 80,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const fontSizeList = [
  { label: "Small", key: 1, value: "small" },
  { label: "Normal", key: 2, value: "medium" },
  { label: "Large", key: 3, value: "large" },
  { label: "Extra Large", key: 4, value: "x-large" },
  { label: "Huge", key: 5, value: "xx-large" },
];

const stemNumerationList = [
  { label: "Numerical", value: "numerical", icon: <OneTwoThreeIcon /> },
  { label: "Uppercase", value: "uppercase", icon: <UppercaseAscending /> },
  { label: "Lowercase", value: "lowercase", icon: <LowercaseAscendingIcon /> },
];
