import React, { useState, useRef, useEffect } from "react";
import {  Jodit } from "jodit-react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";

// import ClassicEditor from "ckeditor5-build-classic-extended";
import QuestiontypeModal from "components/QuestiontypeModal";
// import CustomJoditEditor from "../../../components/CustomJoditEditor";
import SpecialCharacterModal from "components/CustomJoditEditor/ConfigMath/SpecialCharacters";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import CustomJoditEditor from "components/CustomJoditEditor/ConfigMath";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";

interface PreviewWrittenRecordedMathEssayPTProps {
  currentQuestion: {
    id: number;
    question: string;
    more_options: {
      character_map: [];
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      validation: {
        max_score: string;
        min_score_if_attempted: number;
      };
      ui_style: {
        fontsize: string;
        fontSize: string;
        min_height: string;
        max_height: string;

        disable_auto_link: string;
        placeholder: string;
        submit_over_limit: boolean;
        spellcheck: boolean;
        is_math?: boolean;
      };
    };
    options: {
      show_copy: boolean;
      show_cut: boolean;
      show_paste: boolean;
      max_length: number;
      show_word_limit: string;
      text_blocks: [];
      content: string;
      formatting_options: [];
      show_word_count: boolean;
    };

    content: string;
    formatting_options: string[];
    type: string;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewWrittenRecordedMathEssayPT: React.FC<
  PreviewWrittenRecordedMathEssayPTProps
> = ({
  currentQuestion,

  parentMode = "question",
  editMode = false,
}) => {
  const [content, setContent] = useState<string>(
    currentQuestion?.content || ""
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxWords = currentQuestion?.options?.max_length || 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const placeholder = currentQuestion?.more_options?.ui_style?.placeholder;
  const minHeight = currentQuestion?.more_options?.ui_style?.min_height;
  const maxHeight = currentQuestion?.more_options?.ui_style?.max_height;
  const instructorStimulus =
    currentQuestion.more_options?.metadata?.instructor_stimulus;

  const [isCharaModalOpen, setCharaModalOpen] = useState(false);
  const editorInstanceRefPT = useRef<Jodit | null>(null);
  const setEditorInstance = (editorInstance: any) => {
    editorInstanceRefPT.current = editorInstance;
  };
  const autolink = currentQuestion?.more_options?.ui_style?.disable_auto_link;
  const specialCharacters =currentQuestion?.more_options
        ?.character_map


    const isSpecialChar = currentQuestion?.more_options?.character_map?.filter(Boolean).length !== 0;

  const openSpecialCharacterModal = (editor) => {
    setEditorInstance(editor);
    setCharaModalOpen(true);
  };

  const fontSize = currentQuestion?.more_options?.ui_style?.fontsize;
  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const mappedFontSize = fontSizeMapping[fontSize] || "14px";
  const showWordCount = currentQuestion?.options?.show_word_count;

  
  const handleEditorBlur = (newContent: string) => {
    let updatedContent = newContent;
    setContent(updatedContent);
  };

  useEffect(() => {
    if (editorInstanceRefPT.current) {
      console.log("Editor instance is ready:", editorInstanceRefPT.current);
    }
    if (autolink) {
      document.body.classList.add("hideJoditPopup");
    } else {
      document.body.classList.remove("hideJoditPopup");
    }
  }, [autolink, editorInstanceRefPT.current]);

  const insertCharacter = (char: string) => {
    if (!editorInstanceRefPT.current) {
      console.error("Editor instance is not available.");
      return;
    }
    editorInstanceRefPT.current.selection.insertHTML(char);
  };
  const handleEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const clipboardButtons = [];
  if (currentQuestion?.options?.show_cut) clipboardButtons.push("cut");
  if (currentQuestion?.options?.show_copy) clipboardButtons.push("copy");
  if (currentQuestion?.options?.show_paste) clipboardButtons.push("paste");


  const joditConfig = {
    buttons: [
      ...clipboardButtons,
      isSpecialChar
        ? {
            name: "@",
            tooltip: "Insert Special Character",
            exec: openSpecialCharacterModal,
          }
        : {},
    ],
    minHeight: minHeight || 200,
    maxHeight: maxHeight,
    placeholder: placeholder || "",
    limitWords: maxWords + 1,
    showWordsCounter: showWordCount,
    showCharsCounter: false,
    readonly: editMode,
  };

  
  const wordCount =
    content.trim() === "<p><br></p>"
      ? 0
      : content
          .trim()
          .split(/\s+/)
          .filter((word) => word !== "").length;
          
  return (
    <QuestionContent parentMode={parentMode === "question"} style={currentQuestion?.more_options && currentQuestion?.more_options?.ui_style?.fontSize ? 
         {fontSize: currentQuestion?.more_options.ui_style?.fontSize}: 
         {fontSize: 'medium'}
       }> 
      
      <FullWidthContainer>
        <LeftContent>
        {instructorStimulus  && !editMode && (
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {instructorStimulus}
        </div>
      )}
          
          <BlockTitle  style={currentQuestion?.more_options && currentQuestion?.more_options?.ui_style.fontSize ?
            {fontSize:currentQuestion?.more_options?.ui_style.fontSize } : {}}
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />

              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id as any}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => {
                  dispatch(setClosePreview());
                }}
                style={{
                  width: "150px",
                  height: "40px",
                  fontWeight: 600,
                  color: "#FFF",
                  borderRadius: "8px",
                  backgroundColor: "#FA9B31",
                }}
              >
                Back to edit
              </button>
            </>
          )}
        </RightContent>
      </FullWidthContainer>
      <div>
        {/* <div className={isOverLimit ? "editor-over-limit" : "editor"}>
          <CKEditor
            editor={ClassicEditor}
            data={content}
            config={config}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleEditorBlur(data);
            }}
          />
        </div> */}
        <CustomJoditEditor
          label=""
          value={content}
          onBlur={(newContent) => handleEditorBlur(newContent)}
          allconfig={false}
          userconfig={joditConfig}
          spellCheck={currentQuestion?.more_options?.ui_style?.spellcheck}
          minHeight={currentQuestion?.more_options?.ui_style?.min_height}
          maxHeight={currentQuestion?.more_options?.ui_style?.max_height}
        />
        {isCharaModalOpen && (
          <SpecialCharacterModal
            specialCharacters={specialCharacters}
            onSelect={(char) => {
              console.log("Selected character:", char); // Debugging
              insertCharacter(char);
            }}
            onClose={() => setCharaModalOpen(false)}
          />
        )}
        <div
          className="flex gap-1 mt-2"
          // style={isOverLimit ? { color: "red" } : {}}
        >
          {currentQuestion?.options?.show_word_limit === "alwaysVisible" ||
          currentQuestion?.options?.show_word_limit === "" ||
          currentQuestion?.options?.show_word_limit === null ? (
            <p>{maxWords} Word Limit</p>
          ) : null}

          {/* {currentQuestion?.options?.show_word_limit === "onLimit" &&
            wordCount === maxWords && <p>{maxWords} Word Limit</p>} */}
        </div>
      </div>
    </QuestionContent>
  );
};

export default PreviewWrittenRecordedMathEssayPT;
