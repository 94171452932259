const LowercaseAscendingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="19.18"
    viewBox="0 0 32 19.18"
  >
    <path
      id="lowercase_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
      d="M215.038-602.86a5.517,5.517,0,0,1-3.922-1.4,4.6,4.6,0,0,1-1.5-3.514,4.432,4.432,0,0,1,1.73-3.643,7.014,7.014,0,0,1,4.511-1.381,10.531,10.531,0,0,1,2.2.243,9.529,9.529,0,0,1,2,.64V-613a3.63,3.63,0,0,0-1.016-2.741,3.978,3.978,0,0,0-2.877-.98,5.155,5.155,0,0,0-2.094.423,6.013,6.013,0,0,0-1.82,1.274l-1.773-1.449a7.258,7.258,0,0,1,2.544-1.827,8.245,8.245,0,0,1,3.242-.6,6.274,6.274,0,0,1,4.644,1.579,6.311,6.311,0,0,1,1.569,4.638v9.3h-2.416v-2.011h-.183a5.413,5.413,0,0,1-2.017,1.9A5.956,5.956,0,0,1,215.038-602.86Zm.36-2.108a4.432,4.432,0,0,0,3.322-1.413,4.863,4.863,0,0,0,1.359-3.508,6.773,6.773,0,0,0-1.788-.643,9.533,9.533,0,0,0-2.1-.237,4.942,4.942,0,0,0-2.935.771,2.535,2.535,0,0,0-1.072,2.187,2.643,2.643,0,0,0,.882,2.036A3.313,3.313,0,0,0,215.4-604.968Zm18.878,2.108-7.344-7.344,2.011-2.011,3.892,3.882V-622.04h2.892v13.707l3.882-3.882,2.011,2.011Z"
      transform="translate(-209.62 622.04)"
      fill="#333"
    />
  </svg>
);

export default LowercaseAscendingIcon;
