import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AssessmentState {
  questions: object[];
  pagination: object;
  assessment: { [key: string]: { [subKey: string]: any } };
  assessmentsListing: object[];
  assessmentsListingPagination: object;
  filters: any; // Define the type of your filters here
  config: object;
  userData: any;
}

const initialState: AssessmentState = {
  questions: [],
  pagination: {},
  assessment: {},
  assessmentsListing: [],
  assessmentsListingPagination: {},
  filters: { subjects: [], grades: [], statuses: [] },
  config: {
    token: null,
    tokenUser: null,
    referrer: null,
  },
  userData: null,
};

const assessmentSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<any>) {
      const { duration } = action.payload;
      state.config = {
        ...state?.config,
        duration,
      };
    },
    setToken(state, action: PayloadAction<any>) {
      state.config = {
        ...state?.config,
        token: action.payload,
      };
    },
    setTokenUser(state, action: PayloadAction<any>) {
      state.config = {
        ...state?.config,
        tokenUser: action.payload,
      };
    },
    setUserData(state, action: PayloadAction<any>) {
      state.userData = action.payload;
    },
    setReferrer(state, action: PayloadAction<any>) {
      state.config = {
        ...state?.config,
        referrer: action.payload,
      };
    },
    cleanConfig(state) {
      state.config = {};
    },
    setAssessments(state, action: PayloadAction<any>) {
      const { items, pagination } = action.payload;
      state.questions = items;
      state.pagination = pagination;
      state.filters = action.payload.filters; // Update the filters state
    },
    setOldData: (state, action) => {
      return (state = action.payload);
    },
    setAssessmentsListing(
      state,
      action: PayloadAction<{ items: any[]; filters: any; pagination: any }>
    ) {
      state.assessmentsListing = action.payload.items;
      state.filters = action.payload.filters; // Update the filters state
      state.assessmentsListingPagination = action.payload.pagination;
    },
    setKeyAssessmentValue: (state, action: PayloadAction<any>) => {
      const { key, subKey, value } = action.payload;

      // Initialize the key if it doesn't exist
      if (!state?.assessment[key]) {
        state.assessment[key] = {};
      }
      if (subKey) state.assessment[key][subKey] = value;
      else state.assessment[key] = value;
    },
    resetAssessmentData: (state) => (state = { ...state, assessment: {} }),
    setAssessmentEdit(state, action: PayloadAction<any>) {
      state.assessment = action.payload;
    },
  },
});

export const {
  setConfig,
  setToken,
  setTokenUser,
  setReferrer,
  cleanConfig,
  setAssessments,
  setAssessmentsListing,
  setOldData,
  setKeyAssessmentValue,
  resetAssessmentData,
  setAssessmentEdit,
  setUserData,
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
