import React, { useState } from 'react';
import "./ExistingAssessment.styles.scss";
import { duplicateSingleAssessmentRequest } from 'api-rest/assessments';
import AppModal from 'studio/components/general/Modal';
import AssessmentLibraryModal from 'studio/components/AssessmentLibrary/AssessmentLibraryModal.component';
import { Button, message } from 'antd';
import { ROUTES } from 'studio/constants/routes.constants';
import { useNavigate } from 'react-router-dom';

// Define the type for props
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const initialFilterData = {
  assessment_id: "",
  search: "",
  subject_id: "",
  grade_id: "",
  subject: "all",
  grade: "all",
  tags: [],
  status: "",
  dateRange: null,
};





const ExistingAssessment: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [nameValue, setNameValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [filterData, setFilterData] = React.useState(initialFilterData);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filterDataChild, setFilterDataChild] = React.useState(initialFilterData);

  const handleNameValueChange = (value: string) => {
    setNameValue(value);
  };
  const onSelectedRowsParent = (value: any) => {
    console.log('valuevalue', value);

    const data = value?.[0];

    // setNameValue(data.name);
    setFilterData({
      assessment_id: data?.id,
      search: "",
      subject_id: filterData.subject_id, // from the `id` in the `subjects` object
      grade_id: filterData.grade_id, // from the `id` in the `grades` object
      subject: filterData.subject,
      grade: filterData.grade,
      tags: filterData.tags,
      status: "",
      dateRange: null,
    })

  };
  const handleFilterChange = (field: string, value: any) => {
    if (field == 'dateRange') {
      setFilterData((prev) => ({
        ...prev,
        "start_date": value[0],
        "end_date": value[1],
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };
  const handleCreateAssessment = async () => {
    console.log('filterDatafilterData', filterData);
    if (nameValue === '') {
      message.error('Please enter assessment name.');
      return;
    }
    if (nameValue.length > 255) {
      message.error('The name must not be greater than 255 characters.');
      return;
    }
    if (filterData?.assessment_id === '') {
      message.error('Please select assessment in list.');
      return;
    }
    setLoading(true)
    const payload = {
      assessment_id: filterData?.assessment_id,
      "name": nameValue,
      "subject_id": filterData?.subject_id,
      "grade_id": filterData?.grade_id,
      "tag_id": filterData?.tags
    }
    // return;
    const { error, data } = await duplicateSingleAssessmentRequest(payload);

    if (error) {
      // message.error(error);
      return;
    }

    message.success('Assessment created with success!');
    setLoading(false)

    onClose();
    setTimeout(() => {
      navigate(ROUTES.createAssessment, { state: data?.item });
    }, 2000);
  };
  return (
    <AppModal
      title=""
      visible={isOpen}
      content={
        <div className="existing-modal-content">
          <div className="assessment-library-page">
            <AssessmentLibraryModal 
            nameValue={nameValue} 
            onChangeName={handleNameValueChange} filterDatax={filterData} 
            onFilterChangex={handleFilterChange} showBanner={true} 
            onSelectedRowsParent={onSelectedRowsParent}
            selectedRowKeys={selectedRowKeys} 
            setSelectedRowKeys={setSelectedRowKeys}
            filterDataChild={filterDataChild}
            setFilterDataChild={setFilterDataChild}
            />
          </div>
        </div>
      }
      okText="Duplicate"
      cancelText="No"
      onCancel={() => {
        setNameValue('')
        setFilterData(initialFilterData)
        setSelectedRowKeys([])
        setFilterDataChild(initialFilterData)
        onClose()
      }}
      footer={[
        <Button
          className="mt-0 px-12"
          type="primary"
          // loading={isLoading}
          size="large"
          onClick={handleCreateAssessment}
          key="save-duplicate"
        >
          Let's go!
        </Button>,
      ]}
      width={window.innerWidth}
      className="p-[15px] pb-[15px] !important"
      modalProps={{
        maskClosable: false,
        centered: true,
        // width:window.innerWidth,
      }}
    />
  );
};

export default ExistingAssessment;
