import React from 'react';

const WhiteSearch = ({ style = {}, className = '' }) => {
    return (
        <svg style={style} className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20.013" viewBox="0 0 20 20.013">
            <path id="mystery_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M117.033-836.04a7.153,7.153,0,0,0,5.255-2.175,7.185,7.185,0,0,0,2.172-5.273,7.158,7.158,0,0,0-2.172-5.255,7.158,7.158,0,0,0-5.255-2.172,7.186,7.186,0,0,0-5.273,2.172,7.154,7.154,0,0,0-2.175,5.255,7.181,7.181,0,0,0,2.175,5.273A7.181,7.181,0,0,0,117.033-836.04Zm-.006,1.5a8.672,8.672,0,0,1-3.486-.7,9.073,9.073,0,0,1-2.839-1.917A9.075,9.075,0,0,1,108.785-840a8.678,8.678,0,0,1-.7-3.489,8.618,8.618,0,0,1,.707-3.47A9.19,9.19,0,0,1,110.7-849.8a8.941,8.941,0,0,1,2.836-1.918,8.721,8.721,0,0,1,3.489-.7,8.69,8.69,0,0,1,3.471.7,9.02,9.02,0,0,1,2.846,1.917,9.033,9.033,0,0,1,1.917,2.843,8.7,8.7,0,0,1,.7,3.481,8.633,8.633,0,0,1-.565,3.124,9.36,9.36,0,0,1-1.558,2.659l4.239,4.239-1.053,1.05-4.253-4.236a9.23,9.23,0,0,1-2.645,1.545A8.612,8.612,0,0,1,117.026-834.536Z"
                  transform="translate(-108.08 852.42)" fill="#e8eaed"/>
        </svg>
    );
};

export default WhiteSearch;
