import React from "react";

interface ButtonProps {
    name: string;
    onClick: () => void;
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ name, onClick, disabled = false }) => {
    return (
        <button
            style={{
                margin: 8,
                padding: "10px 16px",
                color: "white",
                backgroundImage: disabled
                    ? 'linear-gradient(rgb(156, 156, 156), rgb(120, 120, 120))'
                    : 'linear-gradient(rgb(250, 155, 49), rgb(227, 33, 39))',
                border: "none",
                borderRadius: ".4rem",
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.7 : 1,
            }}
            onClick={(e) => {
                e.preventDefault();
                if (!disabled) {
                    onClick();
                }
            }}
            disabled={disabled}
        >
            {name}
        </button>
    );
};

export default Button;
