import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { BackgroundImage, DraggableOption } from '../../AuthorQuestion/FillBlanks/SubChildren/ImageDragDrop/styled';
import ResizableDivContainer from '../../AuthorQuestion/FillBlanks/SubChildren/ImageDragDrop/ResizableDivContainer';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import BackgroundImageContainerWithAltText from 'obsService/components/PreviewStudent/PreviewImageDragDrop/backgroundImageWithAltText.component';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

const Option = ({ option, onDragStart }) => (
  <DraggableOption onDragStart={onDragStart} draggable>
    {option}
  </DraggableOption>
);

const PreviewImageDragDrop = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;
  const duplicate_responses = currentQuestion?.settings?.duplicate_responses;
  const max_responses = Number(currentQuestion?.settings?.max_responses) || 1;
  const valid_respone_value = currentQuestion?.correct_answer && 
    currentQuestion?.correct_answer?.valid_response && 
    Array.isArray(currentQuestion?.correct_answer?.valid_response?.value) ? 
      currentQuestion?.correct_answer?.valid_response.value :
      [];

  const [divs, setDivs] = useState(
    valid_respone_value.map((div) => ({
      ...div,
      content: [],
    })) || []
  );

  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const [options, setOptions] = useState(currentQuestion?.options || []);

  useEffect(() => {
    if (showAnswer || localShowAnswer) {
      setDivs(
        valid_respone_value.map((div) => ({
          ...div,
          content: div.content || [],
        })) || []
      );
    } else {
      setDivs(
        valid_respone_value.map((div) => ({
          ...div,
          content: [],
        })) || []
      );
    }
  }, [showAnswer, localShowAnswer, currentQuestion?.correct_answer]);

  const handleDragOptionStart = (e, option) => {
    e.dataTransfer.setData('text/plain', option);
  };
  const handleDelete = (index: number) => {
    if (showAnswer) return;
    const updatedDivs = divs.map((div, i) => {
        if (i === index) {
            let newContent = [];
            return { ...div, content: newContent };
        }
        return div;
    });
    setDivs(updatedDivs);
};

  const handleDropOption = (e, index) => {
    e.preventDefault();
    const draggedData = e.dataTransfer.getData('text/plain');

    const updatedDivs = divs.map((div, i) => {
      if (i === index) {
        let newContent = [...div.content];

        if (newContent.length < max_responses) {
          newContent.push(draggedData);
        } else if (newContent.length >= max_responses) {
          const removedItem = newContent.pop(); // Remove the last item
          newContent.push(draggedData);

          // Return the removed item to the options list
          if (!duplicate_responses && removedItem) {
            // setOptions((prevOptions) => [...prevOptions, removedItem]);
          }
        }

        if (!duplicate_responses) {
           {/* @ts-ignore */}
          newContent = [...new Set(newContent)];
        }

        return { ...div, content: newContent };
      }
      return div;
    });

    setDivs(updatedDivs);

    if (!duplicate_responses) {
      // setOptions((prevOptions) => prevOptions.filter((opt) => opt !== draggedData));
    }
  };

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
    dispatch(setTypeQuestionOpenInEditMode(null));
  };

  const handleGoToEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleLayoutStyle = () => {
    let style = {};
    if (more_options && more_options?.layout?.possibility_list_position) {
      switch (more_options.layout.possibility_list_position) {
        case 'top':
          style = { display: 'flex', flexDirection: 'column-reverse' };
          break;
        case 'left':
          style = { display: 'flex', flexDirection: 'row-reverse' };
          break;
        case 'right':
          style = { display: 'flex', flexDirection: 'row' };
          break;
        default:
          style = {};
      }
    }
    return style;
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <div style={handleLayoutStyle()}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            border: '1px solid #D9D9D9',
            padding: '24px',
            marginBottom: '10px',
            backgroundColor: '#f0f0f0',
            flexGrow: 1,
          }}
        >
          <BackgroundImageContainerWithAltText

            backgroundImageUrl={currentQuestion?.image?.source}
            ariaLabel={currentQuestion?.image?.textHover}
            width={`${currentQuestion?.image?.width ? currentQuestion?.image?.width : 400}px`}
            height={`${currentQuestion?.image?.height ? currentQuestion?.image?.height : 400}px`}
            title={currentQuestion?.image?.textHover} 
            altText={currentQuestion?.image?.alt || ""}
            >
            {Array.isArray(divs) &&
              divs.map((div, index) => (
                <ResizableDivContainer
                  key={index}
                  div={div}
                  onDrag={(position) => console.log(position)}
                  onDrop={(e) => handleDropOption(e, index)}
                  onDelete={() => handleDelete(index)}

                >
                  {Array.isArray(div?.content) &&
                    div?.content.map((cont, contIndex) => (
                      <div
                        className={showAnswer || localShowAnswer || editMode ? 'border border-gray-800 border-dashed mx-1 h-fit px-1' : ''}
                        key={contIndex}
                        style={{ display: 'block', marginBottom: '8px' }} // Ensuring each answer is on a new line
                      >
                        {cont}
                      </div>
                    ))}
                </ResizableDivContainer>
              ))}
          </BackgroundImageContainerWithAltText>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection:
              more_options?.layout?.possibility_list_position === 'left' ||
              more_options?.layout?.possibility_list_position === 'right'
                ? 'column'
                : 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {Array.isArray(options) ? (
            options.map((option, index) => (
              <div key={index} style={more_options?.layout?.fontSize ? { fontSize: more_options.layout.fontSize } : {}}>
                <Option option={option} onDragStart={(e) => handleDragOptionStart(e, option)} />
              </div>
            ))
          ) : (
            <p>No options available</p>
          )}
        </div>
      </div>
    </PreviewWrapper>
  );
};

export default PreviewImageDragDrop;
