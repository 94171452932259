import { ThemeConfig } from "antd";
import { Colors } from "./colors.theme";
import { Sizes } from "./Sizes.theme";

export const theme: ThemeConfig = {
  token: {
    colorPrimary: Colors.primary,
    colorBgBase: Colors.bgWhite,
    colorBgContainer: Colors.bgWhite,
    colorTextSecondary: Colors.textBlack,
    colorText: Colors.textBlack,
    colorLink: Colors.textBlack,
    colorTextHeading: Colors.textBlack,
    fontFamily: '"Poppins", sans-serif',
    colorBgSolid: Colors.textBlack,
  },
  components: {
    Slider: {
      handleActiveColor: Colors.primary,
      handleColor: Colors.primary,
      handleSize: 14,
      handleSizeHover: 16,
      trackBg: Colors.grayBorder,
      trackHoverBg: Colors.grayBorder,
      dotActiveBorderColor: Colors.grayBorder,
    },
    Input: {
      controlHeight: Sizes.height,
      controlHeightLG: Sizes.height,
      borderRadiusLG: Sizes.inputBorderRadius,
      borderRadius: Sizes.inputBorderRadius,
      borderRadiusSM: Sizes.inputBorderRadius,
      activeBg: Colors.bgWhite,
      paddingBlock: Sizes.verticalPadding,
      paddingBlockLG: Sizes.verticalPadding,
      paddingBlockSM: Sizes.verticalPadding,
      paddingInlineLG: Sizes.padding,
      paddingInline: Sizes.padding,
      paddingInlineSM: Sizes.padding,
      colorIcon: Colors.textBlack,
      colorBorder: Colors.grayBorder,
      fontSize: Sizes.fontSize,
      fontSizeLG: Sizes.fontSize,
      inputFontSize: Sizes.fontSize,
      inputFontSizeLG: Sizes.fontSize,
    },
    InputNumber: {
      controlHeight: Sizes.height,
      controlHeightLG: Sizes.height,
      borderRadiusLG: Sizes.inputBorderRadius,
      borderRadius: Sizes.inputBorderRadius,
      borderRadiusSM: Sizes.inputBorderRadius,
      activeBg: Colors.bgWhite,
      colorIcon: Colors.textBlack,
      colorBorder: Colors.grayBorder,
      fontSize: Sizes.fontSize,
      fontSizeLG: Sizes.fontSize,
      inputFontSize: Sizes.fontSize,
      inputFontSizeLG: Sizes.fontSize,
      paddingInlineLG: Sizes.base,
      paddingInline: Sizes.base,
      paddingInlineSM: Sizes.base,
    },
    Select: {
      // controlHeight: Sizes.height,
      controlHeightLG: Sizes.height,
      borderRadiusLG: Sizes.inputBorderRadius,
      borderRadius: Sizes.inputBorderRadius,
      borderRadiusSM: Sizes.inputBorderRadius,
      controlItemBgActive: Colors.bgWhite,
      colorBgContainerDisabled: Colors.bgWhite,
      fontSize: Sizes.fontSize,
      fontSizeLG: Sizes.fontSize,
    },
    Button: {
      controlHeight: Sizes.height,
      controlHeightLG: Sizes.height,
      fontSize: Sizes.fontSize,
      fontSizeLG: Sizes.fontSize,
      borderRadiusLG: Sizes.borderRadius,
      borderRadius: Sizes.borderRadius,
      borderRadiusSM: Sizes.borderRadius,
      onlyIconSizeLG: Sizes.iconFontSize,
    },
    DatePicker: {
      controlHeight: Sizes.height,
      controlHeightLG: Sizes.height,
      borderRadiusLG: Sizes.inputBorderRadius,
      borderRadius: Sizes.inputBorderRadius,
      borderRadiusSM: Sizes.inputBorderRadius,
      colorText: Colors.textBlack,
      colorTextPlaceholder: Colors.textBlack,
      colorIcon: Colors.textBlack,
      fontSize: Sizes.fontSize,
      fontSizeLG: Sizes.fontSize,
    },
    Typography: {
      fontSize: Sizes.fontSize,
      marginLG: Sizes.headerPadding,
      marginMD: Sizes.padding,
      titleMarginBottom: Sizes.headerPadding,
      colorText: Colors.textBlack,
    },
    Table: {
      fontSize: Sizes.fontSize,
      fontSizeLG: Sizes.fontSize,
    },
    Tabs: {
      itemActiveColor: Colors.textBlack,
      itemSelectedColor: Colors.textBlack,
      inkBarColor: Colors.textBlack,
      itemHoverColor: Colors.textBlack,
      colorPrimary: Colors.textBlack,
      colorPrimaryTextHover: Colors.textBlack,
      colorPrimaryHover: Colors.grayBorder,
    },
  },
};
