import React from 'react';
import {Card, Row, Col, Typography, Button} from 'antd';
import { AssessmentStats } from "../../../types/assessment";
import './StatsCards.styles.scss';

interface StatsCardsProps {
    stats: AssessmentStats;
    openCreateAssessmentModal: any;
    currentStatus: string;
    onStatusChange: (status: string) => void;
}

const { Title } = Typography;

const StatsCards: React.FC<StatsCardsProps> = ({
    stats,
    openCreateAssessmentModal,
    currentStatus,
    onStatusChange
    }) => {
    return (
        <div className="stats-container">
            <Button className="text-row" type="primary" size="large" onClick={openCreateAssessmentModal}>
                Create new
            </Button>
            <Row gutter={16} className="cards-row">
                <Col>
                    <Card
                        className={`stats-card ${currentStatus === '' ? 'active' : ''}`}
                        onClick={() => onStatusChange('')}
                    >
                        <div className="stats-content">
                            <Title level={3} className="stats-title">Total Created</Title>
                            <Title level={5} className="stats-subtitle">{stats.totalCreated}</Title>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card
                        className={`stats-card ${currentStatus === '1' ? 'active' : ''}`}
                        onClick={() => onStatusChange('1')}
                    >
                        <div className="stats-content">
                            <Title level={3} className="stats-title">Published</Title>
                            <Title level={5} className="stats-subtitle">{stats.published}</Title>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card
                        className={`stats-card ${currentStatus === '0' ? 'active' : ''}`}
                        onClick={() => onStatusChange('0')}
                    >
                        <div className="stats-content">
                            <Title level={3} className="stats-title">Drafts</Title>
                            <Title level={5} className="stats-subtitle">{stats.drafts}</Title>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card
                        className={`stats-card ${currentStatus === '2' ? 'active' : ''}`}
                        onClick={() => onStatusChange('2')}
                    >
                        <div className="stats-content">
                            <Title level={3} className="stats-title">Scheduled</Title>
                            <Title level={5} className="stats-subtitle">{stats.scheduled}</Title>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default StatsCards;