import React from 'react';
import './Timer.styles.scss';

interface TimerProps {
    timeLeft: number;
    totalTime: number;
}

const Timer: React.FC<TimerProps> = ({ timeLeft, totalTime }) => {
    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // Calculate circle properties
    const size = 100;
    const strokeWidth = 4;
    const center = size / 2;
    const radius = center - strokeWidth;
    const circumference = 2 * Math.PI * radius;
    const progressOffset = ((totalTime - timeLeft) / totalTime) * circumference;

    return (
        <div className="timer">
            <div className="timer__content">
                <svg className="timer__svg" width={size} height={size}>
                    {/* Background circle */}
                    <circle
                        className="timer__circle-bg"
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                    />
                    {/* Progress circle */}
                    <circle
                        className="timer__circle-progress"
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={progressOffset}
                        transform={`rotate(-90 ${center} ${center})`}
                    />
                </svg>
                <span className="timer__text">{formatTime(timeLeft)}</span>
            </div>
        </div>
    );
};

export default Timer;