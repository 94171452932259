import { useEffect, useRef, useState } from 'react';
import { QuestionContent } from './styled';
import Button from '../../../components/Button';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import QuestiontypeModal from 'components/QuestiontypeModal';
import './customForMath.css'
import DOMPurify from 'dompurify';
import { getLabelByStemNumeration, getResponseFontSize } from 'utils/generalUtils';
import { MathJaxContext } from 'better-react-mathjax';
import { ChemistryTypes } from 'pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';
import { Mathfield } from 'mathlive';
import { getLayouts } from 'pages/AuthorQuestion/Chemistry/SubChildren/ChemistryFormula/mathInputForChemistry.component';

const mathJaxConfig = {
  loader: { load: ['input/tex', 'output/svg'] },
};

export const getResponseStyle = (index: number, more_options: any) => {
  const style: any = {};
  style.fontSize = getResponseFontSize(more_options);
  if(more_options && more_options?.layout?.response_min_width) {
    style.minWidth = `${more_options.layout.response_min_width}px`;
  }
  if(more_options && more_options?.response_options?.width) {
    style.width = `${more_options.response_options.width}px`;
  }
  if(more_options && more_options?.response_options?.height) {
    style.height = `${more_options.response_options.height}px`;
  }
  if(more_options && Array.isArray(more_options?.response_options_individaul)) {
    const itemExist = more_options.response_options_individaul.find((item, idx) => idx === index );
    if(itemExist) {
      if(itemExist.width) {
        style.width = `${itemExist.width}px`;
      }
      if(itemExist.height) {
        style.height = `${itemExist.height}px`;
      }
    }
  }

  return style
}


const PreviewChemistryFormula = ({
    currentQuestion, 
    showAnswer = false,
    setShowAnswers = null, 
    parentMode = 'assessment',
    editMode = false,
}) =>{

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [elements, setElements] = useState([]);
  const resElements = elements.filter(el => el.type === "Res");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const validResponse = currentQuestion?.correct_answer?.valid_response;
  const more_options = currentQuestion?.more_options;
  const mathFieldRef = useRef<Mathfield | null>(null);
  const resMinWidth = more_options && more_options?.layout?.response_min_width ? more_options && more_options?.layout?.response_min_width : 0;
  const settings = currentQuestion?.settings;
  
  useEffect(() => {
    if(Array.isArray(settings?.symbols) && settings?.symbols?.length) {
      window.mathVirtualKeyboard.layouts = getLayouts(settings.symbols, settings?.numberPad);
    }else {
      window.mathVirtualKeyboard.layouts = getLayouts(['basic', 'chemistry'], settings?.numberPad);
    }
  }, [settings])

  useEffect(() => {
    let inputString = currentQuestion.template_response;
    if (inputString) {
      const parts = inputString.split("\\colorbox{#e4e4e4}{{Response}}}");
      let T = [];
      for (let i = 0; i < parts.length; i++) {
        if (parts[i] !== undefined && parts[i] !== "") {
            T.push({ type: "txt", content: parts[i] });
            if (i != parts.length - 1)
            T.push({ type: "Res", content: "Response" });
        }
      }
      setElements(T);
    }
  }, []);

  const handleEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(setTypeQuestionOpenInEditMode({type: ChemistryTypes.CHEM_FORMULA, id: currentQuestion?.id}));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const correctTextRef = useRef<Array<HTMLSpanElement | null>>([]);

  const getTheContent = (content: string) => {
    let newContent = content;
    const regex = /<p(?![^<]*<\/p>)/g;
    newContent = newContent.replace(regex, '<span');
    return newContent
  }

  useEffect(() => {
    elements.forEach((item, i) => {
      if (correctTextRef.current[i]) {
        const content = getTheContent(item?.content);
        correctTextRef.current[i].innerHTML = item?.content == "Response" ?
          "" :
          DOMPurify.sanitize(content);
      }
    });
  }, [elements]);

  useEffect(() => {
    if (mathFieldRef.current) {
      //@ts-ignore
      mathFieldRef.current.mathModeSpace = '\\:';
      //@ts-ignore
      mathFieldRef.current.style.minWidth = "30%";
      //@ts-ignore
      mathFieldRef.current.style.maxWidth = "90%";
  }
}, []);

let resIndex = -1;

  return (
    <MathJaxContext config={mathJaxConfig}>
      <QuestionContent parentMode={parentMode === 'question'}>
      {
        more_options?.extras?.instructor_stimulus && !editMode &&
        <div 
          className="p-5 mb-4 bg-blue-100 rounded-md text-black"
          style={{fontSize: getResponseFontSize(more_options)}}
        >
          {more_options?.extras?.instructor_stimulus}
        </div>
      }
        <div className="flex flex-wrap gap-5 justify-between mb-5">
          <div className="w-full lg:w-[70%]">
            <div 
              className="bg-light-accent flex p-5 gap-3 rounded-lg"
              style={{fontSize: getResponseFontSize(more_options)}}
            >
              <p className="font-bold">Question :&nbsp;</p>
              <span
                dangerouslySetInnerHTML={{
                  __html: ` ${currentQuestion?.question}`,
                }}
              />
            </div>
          </div>

          <div className="flex gap-4">
            {editMode ? (
              <>
                <Button
                  margin
                  title={'Go to edit'}
                  onClick={() => handleEditClick()}
                />
                <Button
                  title={'Delete'}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                />

                {isModalOpen && (
                  <QuestiontypeModal
                    // @ts-ignore
                    setIsModalOpen={setIsModalOpen}
                    id={currentQuestion?.id}
                    isModalOpen={isModalOpen}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  margin
                  title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                  onClick={() => {
                    if (setShowAnswers) setShowAnswers(!showAnswer);
                  }}
                />

                <Button
                  title={'Back to edit'}
                  onClick={() => {
                    dispatch(setClosePreview());
                  }}
                />
              </>
            )}
          </div>
        </div>
      
        {
          !editMode && resElements.length === 0 && (
            <div
              className="p-4 w-full"
              style={{
                border: '1px solid #ccc',
                borderRadius: '6px',
                width: '100%',
              }}
            >
              {/* @ts-expect-error */}
              <math-field
                ref={mathFieldRef}
                style={{fontSize: getResponseFontSize(more_options)}}
              >
                {currentQuestion?.template_response}
                {/* @ts-expect-error */}
              </math-field>
            </div>
          )
        }

        {
          !editMode && resElements.length > 0 && (
              <div 
                style={{
                  border:  more_options?.scoring && more_options?.scoring?.transparent_background ? 'none' : '1px solid #D9D9D9',
                  padding: '24px',
                  marginBottom: '10px',
                  backgroundColor: more_options?.scoring && more_options?.scoring?.transparent_background ? 'transparent' : '#f0f0f0',
                  overflowX: 'scroll',
                  fontSize: getResponseFontSize(more_options),
                }}
              >
                {
                  elements.map((element, index) => {
                    if(element.type === "Res"){
                      resIndex++;
                      return(
                        <div
                          key={index}
                          style={{
                            border: "1px solid #333",
                            cursor: "move",
                            display: "inline-flex",
                            background: 'transparent',
                          }}
                          className="w-fit"
                        >
                          {/* @ts-expect-error */}
                          <math-field
                            math-mode-space="\:"
                            style={{
                              minWidth: `${resMinWidth}px`,
                              background: 'transparent',
                              ...getResponseStyle(resIndex, more_options),
                            }}
                          >
                            {/* @ts-expect-error */}
                          </math-field>
                        </div>
                      )
                    }else {
                      return(
                        // @ts-expect-error
                        <math-field
                          key={index}  
                          readonly={true}
                          style={{
                            display: "inline-flex",
                            background: 'transparent',
                          }}
                        >
                          {element.content}
                          {/* @ts-expect-error */}
                        </math-field>
                      )
                    }
                  })
                }
              </div>
          )
        }

        {
          editMode && resElements.length === 0 && (
            <div
              className="p-4 w-full"
              style={{
                border: '1px solid #ccc',
                borderRadius: '6px',
                width: '100%',
                fontSize: getResponseFontSize(more_options)
              }}
            >
              {/* @ts-expect-error */}
              <math-field
                ref={mathFieldRef}
                readonly={false}
              >
                {currentQuestion?.template_response}
                {/* @ts-expect-error */}
              </math-field>
            </div>
          )
        }

        {
          editMode && resElements.length > 0 && (
              <div 
                style={{
                  border:  more_options?.scoring && more_options?.scoring?.transparent_background ? 'none' : '1px solid #D9D9D9',
                  padding: '24px',
                  marginBottom: '10px',
                  backgroundColor: more_options?.scoring && more_options?.scoring?.transparent_background ? 'transparent' : '#f0f0f0',
                  overflowX: 'scroll',
                  fontSize: getResponseFontSize(more_options),
                }}
              >
                {
                  elements.map((element, index) => {
                    if(element.type === "Res"){
                      resIndex++;
                      return(
                        <div
                          key={index}
                          style={{
                            border: "1px solid #333",
                            cursor: "move",
                            display: "inline-flex",
                            background: 'transparent',
                          }}
                          className="w-fit"
                        >
                          {/* @ts-expect-error */}
                          <math-field
                            math-mode-space="\:"
                            readonly={true}
                            style={{
                              minWidth: `${resMinWidth}px`,
                              background: 'transparent',
                              ...getResponseStyle(resIndex, more_options),
                            }}
                          >
                            {/* @ts-expect-error */}
                          </math-field>
                        </div>
                      )
                    }else {
                      return(
                        // @ts-expect-error
                        <math-field
                          key={index}  
                          readonly={true}
                          style={{
                            display: "inline-flex",
                            background: 'transparent',
                          }}
                        >
                          {element.content}
                          {/* @ts-expect-error */}
                        </math-field>
                      )
                    }
                  })
                }
              </div>
          )
        }

        {showAnswer && (
          <ul className="w-full flex flex-col p-2">
            <p 
              className="font-semibold"
              style={{fontSize: getResponseFontSize(more_options,true)}}  
            >
              Answers: 
            </p>

            {Array.isArray(validResponse?.value) &&
              validResponse?.value.map((vr, index) => (
                <li 
                  className="mt-4" 
                  key={index}
                  style={{fontSize: getResponseFontSize(more_options,true)}}  
                >
                  <span className="px-2 bg-slate-500 text-white">
                  {getLabelByStemNumeration(more_options, index)}
                  </span>{' '}
                  {/* @ts-expect-error */}
                  <math-field readonly={true}>
                    {vr?.value || ''}
                    {/* @ts-expect-error */}
                  </math-field>
                </li>
              ))}
          </ul>
        )}
      </QuestionContent>
    </MathJaxContext>
  );
};


export default PreviewChemistryFormula;


