import React, { useEffect, useRef, useCallback } from "react";
import { FontSize } from "./types";
import { BasicGriddedInput } from "./BasicGriddedInput";
import { FloatingDecimalGriddedInput } from "./FloatingDecimalGriddedInput";
import { PlusMinusGriddedInput } from "./PlusMinusGriddedInput";
import { FractionSlashGriddedInput } from "./FractionSlashGriddedInput";

interface GriddedInputProps {
  columns: number;
  values: string[];
  fontSize: FontSize;
  onChange?: (values: string[]) => void;
  disabled?: boolean;
  options: {
    range?: { min: number; max: number };
    plusMinus?: boolean;
    fractionSlash?: boolean;
    fixedDecimal?: boolean;
    floatingDecimal?: boolean;
    decimalColumn?: number | null;
  };
}

export const GriddedInput: React.FC<GriddedInputProps> = ({
  columns,
  values,
  fontSize,
  onChange = () => {}, // Default function that does nothing
  disabled,
  options,
}) => {
  const handleChange = useCallback(
    (newValues: string[]) => {
      onChange(newValues);
    },
    [onChange]
  );
  const prevOptionsRef = useRef(options);
  useEffect(() => {
    const path = window.location.pathname;
    if (!path.startsWith("/obs-service/player")) {
      const prevOptions = prevOptionsRef.current;

      if (JSON.stringify(prevOptions) !== JSON.stringify(options)) {
        onChange(Array(columns).fill(""));
        prevOptionsRef.current = options; // Update previous options reference
      }
    } else return;
  }, [options]);
  // Handle plus/minus with fraction slash
  if (options.plusMinus && options.fractionSlash) {
    return (
      <PlusMinusGriddedInput
        columns={columns}
        values={values}
        fontSize={fontSize}
        onChange={handleChange}
        disabled={disabled}
        hasPlusMinus={options.plusMinus}
        hasFloatingDecimal={options.floatingDecimal}
        hasFixedDecimal={options.fixedDecimal}
        hasFractionSlash={true}
        decimalColumn={options.decimalColumn}
      />
    );
  }

  // Handle plus/minus without fraction slash
  if (options.plusMinus) {
    return (
      <PlusMinusGriddedInput
        columns={columns}
        values={values}
        fontSize={fontSize}
        onChange={handleChange}
        disabled={disabled}
        hasPlusMinus={options.plusMinus}
        hasFloatingDecimal={options.floatingDecimal}
        hasFixedDecimal={options.fixedDecimal}
        hasFractionSlash={false}
        decimalColumn={options.decimalColumn}
      />
    );
  }

  // Basic grid with optional fixed decimal
  return (
    <BasicGriddedInput
      columns={columns}
      values={values}
      fontSize={fontSize}
      onChange={handleChange}
      disabled={disabled}
      hasFixedDecimal={options.fixedDecimal}
      decimalColumn={options.decimalColumn}
    />
  );
};
