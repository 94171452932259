import React from 'react';

const Duplicate = ({ style = {} }) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="16" height="19.454" viewBox="0 0 16 19.454">
            <path id="file_copy_FILL0_wght300_GRAD-25_opsz20"
                  d="M141.039-864.539h-9.774a1.408,1.408,0,0,1-1.027-.433A1.4,1.4,0,0,1,129.8-866v-13.227a1.4,1.4,0,0,1,.435-1.026,1.406,1.406,0,0,1,1.029-.435h7.476l3.758,3.746V-866a1.4,1.4,0,0,1-.435,1.031A1.408,1.408,0,0,1,141.039-864.539Zm-3.173-11.53v-3.458h-6.6a.286.286,0,0,0-.206.093.286.286,0,0,0-.093.206V-866a.283.283,0,0,0,.093.208.289.289,0,0,0,.206.091h9.77a.289.289,0,0,0,.206-.091.283.283,0,0,0,.093-.208v-10.066Zm-9.9,14.831a1.409,1.409,0,0,1-1.027-.433,1.4,1.4,0,0,1-.435-1.031v-12.415h1.165V-862.7a.283.283,0,0,0,.093.208.289.289,0,0,0,.206.091H138.08v1.165Zm3-18.289v0Z"
                  transform="translate(-126.501 880.691)" fill='#000000'/>
        </svg>
    );
};

export default Duplicate;
