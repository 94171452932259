import { DownOutlined, RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import "./createAssessment.css";

const DraggableMenuItem = ({ item, onItemSelect }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.trackId,
    data: {
      ...item,
      fromMenu: true, // Mark items coming from the menu
    },
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 9999,
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      data-draggable="from-menu"
      className="fieldBlock flex flex-col items-center justify-center gap-5 bg-white border border-mid-gray rounded-lg px-2 py-4 h-full min-h-28 w-full cursor-grab text-dark-gray hover:bg-dark-gray hover:text-white transition-colors duration-200 active:cursor-grabbing"
      onDoubleClick={() => onItemSelect(item)}
    >
      <div className="hover:text-white">{item.icon}</div>
      <span className="text-sm font-normal text-center  hover:text-white">
        {item.text}
      </span>
    </div>
  );
};

const FieldsMenu = ({ title = "Dashboard", items = [], onItemSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full">
      <div
        className="flex items-center justify-between py-3 font-medium cursor-pointer"
        onClick={toggleMenu}
      >
        <span className="font-medium text-base">{title}</span>
        <span className="text-xs transition-transform duration-300">
          {isOpen ? <DownOutlined style={{ fontSize: '16px' }} /> : <RightOutlined style={{ fontSize: '16px' }}/>}
        </span>
      </div>

      {isOpen && (
        <div className="grid grid-cols-2 gap-2 py-1">
          {items.map((item) => (
            <DraggableMenuItem
              key={item.trackId}
              item={item}
              onItemSelect={onItemSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FieldsMenu;
