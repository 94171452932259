import React from 'react';

const Accessibility = ({ style = {}, className='' }) => {
    return (
        <svg style={style} className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="23.149" viewBox="0 0 20 23.149">
            <path id="accessibility_new_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M157.27-862.348a2.094,2.094,0,0,1-1.539-.632,2.094,2.094,0,0,1-.632-1.539,2.1,2.1,0,0,1,.632-1.539,2.1,2.1,0,0,1,1.539-.632,2.1,2.1,0,0,1,1.539.632,2.094,2.094,0,0,1,.632,1.539,2.094,2.094,0,0,1-.632,1.539A2.094,2.094,0,0,1,157.27-862.348Zm-3,18.807v-15.5q-1.829-.15-3.619-.429t-3.382-.68l.415-1.682a33.723,33.723,0,0,0,4.692.845,45.108,45.108,0,0,0,4.893.264,45.108,45.108,0,0,0,4.893-.264,33.723,33.723,0,0,0,4.692-.845l.415,1.682q-1.592.4-3.382.68t-3.619.429v15.5h-1.682v-7.279h-2.636v7.279Z"
                  transform="translate(-147.27 866.69)" fill="#e8eaed"/>
        </svg>
    );
};

export default Accessibility;
