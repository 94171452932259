import React from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import "./questionList.css";
const truncateText = (text: string, maxLength: number = 30) => {
  if (!text) return '';
  return text.length > maxLength 
    ? `${text.substring(0, maxLength)}...` 
    : text;
};
function stripHtmlTags(htmlString) {
  return htmlString.replace(/<[^>]*>?/gm, '');
}

const SortableItem = ({ id, question }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || "transform 200ms ease-in-out",
    position: "relative", 
  } as React.CSSProperties;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex items-center justify-start bg-white text-tiny text-dark-gray rounded-md gap-1 px-2 border border-gray-300 cursor-grab"
    >
      <div className="p-1 iconHolder">{question.icon}</div>
      <div className="w-[1px] h-[30px] bg-gray-300"></div>
      <p className="p-1 text-xs"  title={question.templateTitle}>{truncateText(stripHtmlTags(question.templateTitle))}</p>
    </div>
  );
};

const QuestionList = ({ questionList = [], setQuestionList }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setQuestionList((items) => {
        const oldIndex = items.findIndex((item) => item.trackId === active.id);
        const newIndex = items.findIndex((item) => item.trackId === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={questionList.map((q) => q.trackId)}
          strategy={verticalListSortingStrategy}
        >
          <div className="w-full flex flex-col gap-2">
            {questionList.length > 0 ? (
              questionList.map((question) => (
                <SortableItem
                  key={question.trackId}
                  id={question.trackId}
                  question={question}
                />
              ))
            ) : (
              <p className="p-1 text-lg text-dark-gray">
                No questions available
              </p>
            )}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default QuestionList;
