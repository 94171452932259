interface IconProps {
  fill?: string;
  className?: string;
}
const SingleChoice = ({ fill = "currentColor", className = "" }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
  >
    <path
      d="M14 21.0626C15.8272 21.0626 17.3778 20.4257 18.6519 19.1518C19.9257 17.8777 20.5626 16.3271 20.5626 14.4999C20.5626 12.6728 19.9257 11.1222 18.6519 9.84814C17.3778 8.57434 15.8272 7.93732 14 7.93732C12.1728 7.93732 10.6222 8.57434 9.34814 9.84814C8.07432 11.1222 7.43741 12.6728 7.43741 14.4999C7.43741 16.3271 8.07432 17.8777 9.34814 19.1518C10.6222 20.4257 12.1728 21.0626 14 21.0626ZM14.0034 28.4999C12.0673 28.4999 10.2472 28.1321 8.54298 27.3963C6.83852 26.6602 5.35591 25.6614 4.09514 24.3999C2.83462 23.1384 1.83695 21.6572 1.10218 19.9563C0.367401 18.2551 0 16.4374 0 14.5033C0 12.5673 0.367897 10.7471 1.10368 9.04291C1.83972 7.3385 2.8385 5.85583 4.10003 4.59509C5.36156 3.33459 6.84278 2.33691 8.54372 1.60211C10.2449 0.86731 12.0626 0.5 13.9966 0.5C15.9327 0.5 17.7528 0.867859 19.457 1.60364C21.1615 2.33972 22.6441 3.33844 23.9049 4.59998C25.1654 5.86151 26.1631 7.34277 26.8978 9.0437C27.6326 10.7449 28 12.5625 28 14.4965C28 16.4326 27.6321 18.2527 26.8963 19.957C26.1603 21.6614 25.1615 23.144 23.9 24.4048C22.6385 25.6653 21.1572 26.663 19.4563 27.3978C17.7551 28.1325 15.9375 28.4999 14.0034 28.4999ZM13.9996 26.3935C17.3094 26.3935 20.1192 25.2389 22.4289 22.9296C24.7387 20.6202 25.8935 17.8104 25.8935 14.5004C25.8935 11.1906 24.7389 8.3808 22.4297 6.07104C20.1202 3.76129 17.3104 2.60645 14.0004 2.60645C10.6906 2.60645 7.88084 3.76111 5.57109 6.07025C3.26135 8.37982 2.10648 11.1895 2.10648 14.4996C2.10648 17.8093 3.2611 20.6191 5.57034 22.929C7.87984 25.2386 10.6896 26.3935 13.9996 26.3935Z"
      fill={fill} className={className}
    />
  </svg>
);
export default SingleChoice;
