import { Flex, Typography, Switch, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";

const { Text } = Typography;

const FooterQuestionEdit = ({
  onSave,
  onPreview,
}: {
  onSave: () => void;
  onPreview: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="w-full h-[80px] fixed bottom-0"
      style={{
        border: `1px solid ${Colors.grayBorder}`,
        borderLeftWidth: 0,
        background: Colors.bgLightGray,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: Sizes.padding * 3,
        paddingLeft: Sizes.padding,
        flexWrap: "wrap",
      }}
    >
      <Flex gap={16} align="center" justify="centers">
        <Text>Save to the library</Text>
        <Switch onChange={(val: boolean) => {}} />
      </Flex>

      <Flex gap={16} align="center">
        <Button
          variant="solid"
          color="primary"
          onClick={onSave}
          size="large"
          style={{ width: 108 }}
        >
          Save
        </Button>
        <Button
          color="default"
          variant="solid"
          onClick={onPreview}
          size="large"
          style={{ width: 108 }}
        >
          Preview
        </Button>
        <Button
          color="default"
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
          size="large"
          style={{ width: 108, color: Colors.textBlack }}
        >
          Discard
        </Button>
      </Flex>
    </div>
  );
};

export default FooterQuestionEdit;
