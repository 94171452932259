import uuid from "react-uuid";
import {
  buttonsSettings,
  drawParabola,
  drawPoint,
  drawSine,
  generateTwoPointsShape,
  getNewCoordsValueAfterSnapping,
  getSnappingValue,
  shapesRenderer,
} from "utils/graph/shapeDrawerUtil";

const updateChildrenPositions = (shapeSelected, setCustomGraphOptions) => {
  // console.log(shapeSelected, "shapeSelected");
  setCustomGraphOptions((prevData) => {
    const filteredData = prevData.map((o) => {
      if (
        o.parentID === shapeSelected.id ||
        o.parentID === shapeSelected.parentID
      ) {
        if (o.hasParent) {
          console.log(o.drawKey.Y(), "o", o.drawKey.X());
          return {
            ...o,
            coords: { x: o.drawKey.X(), y: o.drawKey.Y() },
            options: { ...o.options },
          };
        }
        return o;
      }
      return o;
    });
    // console.log(filteredData, "filteredDataInChildren");
    return filteredData;
  });
};

export const handleLineOrShapeMove = (
  shapeSelected,
  shapeId,
  dx,
  dy,
  upPoint,
  setHistory,
  setCustomGraphOptions
) => {
  if (!shapeSelected.hasParent) {
    // Handle shapes without parents (e.g., standalone lines or shapes)
    setCustomGraphOptions((prevData) => {
      const previousState = prevData;

      const filteredData = prevData.map((o) => {
        if (o.drawKey.id === shapeId) {
          return {
            ...o,
            subElementIds: {
              ...o.subElementIds,
              startPoint: {
                ...o.subElementIds.startPoint,
                x:
                  o.type === "Circle"
                    ? o.drawKey?.midpoint?.X()
                    : o.drawKey.point1.X(),
                y:
                  o.type === "Circle"
                    ? o.drawKey?.midpoint?.Y()
                    : o.drawKey.point1.Y(),
              },
              endPoint: {
                ...o.subElementIds.endPoint,
                x: o.drawKey.point2.X(),
                y: o.drawKey.point2.Y(),
              },
            },
          };
        }
        return o;
      });

      updateHistory("move", previousState, filteredData, setHistory);
      return filteredData;
    });
    updateChildrenPositions(shapeSelected, setCustomGraphOptions);
  } else {
    // Handle shapes with parents (e.g., points belonging to a line or polygon)
    handleChildShapeMove(
      shapeSelected,
      shapeId,
      dx,
      dy,
      upPoint,
      setCustomGraphOptions,
      setHistory
    );
  }
};

export const handleChildShapeMove = (
  shapeSelected,
  shapeId,
  dx,
  dy,
  upPoint,
  setCustomGraphOptions,
  setHistory
) => {
  console.log(shapeSelected, shapeId, dx, dy, upPoint, "pointOnShapeChange");
  setCustomGraphOptions((prevData) => {
    const previousState = prevData;
    const filteredData = prevData.map((o) => {
      if (o.drawKey?.parents?.includes(shapeId)) {
        const startPointX =
          o.type === "Circle" ? o.drawKey?.midpoint?.X() : o.drawKey.point1.X();
        const startPointY =
          o.type === "Circle" ? o.drawKey?.midpoint?.Y() : o.drawKey.point1.Y();
        console.log(startPointX, startPointY);

        return {
          ...o,
          subElementIds: {
            ...o.subElementIds,
            startPoint: {
              ...o.subElementIds.startPoint,
              x: startPointX,
              y: startPointY,
            },
            endPoint: {
                  ...o.subElementIds.endPoint,
                  x: o.drawKey.point2.X(),
                  y: o.drawKey.point2.Y(),
                },
          },
        };
      } else if (o.type === "Parabola" || o.type === "Sine") {
        const [firstPoint, secondPoint] = prevData.filter(
          (i) => i.parentID === o.id
        );

        return {
          ...o,
          subElementIds: {
            ...o.subElementIds,
            startPoint: {
              ...o.subElementIds.startPoint,
              x: firstPoint.drawKey.X(),
              y: firstPoint.drawKey.Y(),
            },
            endPoint: {
              ...o.subElementIds.endPoint,
              x: secondPoint.drawKey.X(),
              y: secondPoint.drawKey.Y(),
            },
          },
        };
      }
      return o;
    });

    updateHistory("move", previousState, filteredData, setHistory);
    console.log("filteredDataIhandleChildShapeMove", filteredData);
    return filteredData;
  });
  updateChildrenPositions(shapeSelected, setCustomGraphOptions);
};

export const handlePointMove = (
  shapeSelected,
  upPoint,
  setCustomGraphOptions,
  setHistory
) => {
  setCustomGraphOptions((prevData) => {
    const previousState = prevData;
    const filteredData = prevData.map((o) => {
      if (o.drawKey.id === shapeSelected.drawKey.id) {
        return {
          ...o,
          coords: { x: upPoint.x, y: upPoint.y },
          options: { ...o.options },
        };
      }
      return o;
    });

    updateHistory("move", previousState, filteredData, setHistory);
    return filteredData;
  });
};

export const updateHistory = (action, previousState, newState, setHistory) => {
  setHistory((prevHistory) => [
    ...prevHistory,
    {
      action,
      previousState,
      newState,
    },
  ]);
};
export const handleBoardDelete = (
  board,
  object,
  graphPoints,
  setCustomGraphOptions,
  setDeleteStart,
  setDeletedElements,
  isChild
) => {
  const selectedId = isChild
    ? graphPoints.current.find((item) => item.shapeId === object.id).parentId
    : object.id;
  console.log(object, "object", selectedId);
  if (!object || !object.elType) {
    return;
  }
  object.off("up");
  object.on("up", () => {
    setCustomGraphOptions((prevData) => {
      const selectedObject = prevData.find(
        (item) => item.shapeId === selectedId
      );
      console.log(selectedObject, "selectedObject");
      if (object?.vertices?.length > 0) {
        object.vertices.forEach((vertex) => {
          board.removeObject(vertex);
        });
      }
      board.removeObject(object);
      const parent = prevData.find((item) => item.shapeId === object.id);
      console.log(parent, "parent");
      if (parent) {
        board.removeObject(parent);
      }
    });

    // setDeletedElements((prevData) => [...prevData, selectedObject.itemId]);
  });
  board.update();
  setDeleteStart(false);
};

export const handlePolygonMove = (
  shapeSelected,
  dx,
  dy,
  objectMoved,
  setCustomGraphOptions,
  setHistory
) => {
  setCustomGraphOptions((prevData) => {
    const previousState = prevData;
    const filteredData = prevData.map((o) => {
      if (o.drawKey.id === shapeSelected.drawKey.id) {
        return {
          ...o,
          subElementIds: {
            ...o.subElementIds,
            startPoint: {
              ...o.subElementIds.startPoint,
              x: o.subElementIds.startPoint.x + dx,
              y: o.subElementIds.startPoint.y + dy,
            },
            endPoint: {
              ...o.subElementIds.endPoint,
              x: o.subElementIds.endPoint.x + dx,
              y: o.subElementIds.endPoint.y + dy,
            },
            points: o.subElementIds.points.map((point, index) => ({
              ...point,
              x: objectMoved.vertices[index]?.coords.usrCoords[1] || point.x,
              y: objectMoved.vertices[index]?.coords.usrCoords[2] || point.y,
            })),
          },
        };
      }
      return o;
    });

    updateHistory("move", previousState, filteredData, setHistory);
    return filteredData;
  });
  // updateChildrenPositions(shapeSelected, setCustomGraphOptions);
};
export const handleBorderClick = (
  board,
  newPoint,
  upPoint,
  shapeId,
  dx,
  dy,
  setCustomGraphOptions,
  setHistory
) => {
  const borderClicked = board.objectsList.find((o) => o.id === shapeId);

  setCustomGraphOptions((prevData) => {
    const previousState = prevData;
    const filteredData = prevData.map((o) => {
      if (o?.drawKey?.vertices?.map((i) => i.id).includes(shapeId)) {
        return updatePolygonPoints(o, borderClicked, newPoint, upPoint, dx, dy);
      } else if (borderClicked.elType !== "point" && o?.type === "Polygon") {
        return updateLinePoints(o, borderClicked, prevData);
      } else {
        return o;
      }
    });

    updateHistory("move", previousState, filteredData, setHistory);
    return filteredData;
  });
};
const updateLinePoints = (o, borderClicked, prevData) => {
  const parent = prevData.find((i) => i.id === o.parentID);
  const src = parent ?? o;
  return {
    ...src,
    subElementIds: {
      ...src.subElementIds,
      startPoint: {
        ...src.subElementIds.startPoint,
        x: src.subElementIds.startPoint.x,
        y: src.subElementIds.startPoint.y,
      },
      endPoint: {
        ...src.subElementIds.endPoint,
        x: src.subElementIds.endPoint.x,
        y: src.subElementIds.endPoint.y,
      },
      points: src.subElementIds.points.map((point, index) => ({
        ...point,
        x: src.subElementIds.pointsShapes[index].coords.usrCoords[1],
        y: src.subElementIds.pointsShapes[index].coords.usrCoords[2],
      })),
    },
    options: { ...src.options },
  };
};

const updatePolygonPoints = (o, borderClicked, newPoint, upPoint, dx, dy) => {
  console.log('polygon points move',o, borderClicked)
  
  return {
    ...o,
    subElementIds: {
      ...o.subElementIds,
      startPoint: {
        ...o.subElementIds.startPoint,
        x: o?.drawKey?.vertices[0]?.X() || o.subElementIds.startPoint.x,
        y: o?.drawKey?.vertices[0]?.Y() || o.subElementIds.startPoint.y,
      },
      endPoint: {
        ...o.subElementIds.endPoint,
        x: o?.drawKey?.vertices.at(-1)?.X() || o.subElementIds.endPoint.x,
        y: o?.drawKey?.vertices.at(-1)?.Y() || o.subElementIds.endPoint.y,
      },
      points: o.drawKey?.vertices?.slice(0, -1).map((point) => {
        return {
          x: point.X() ,
          y: point.Y() ,
          label: point.name
        }
      }), 
    },
  };
};

export const handleBoardAddLabel = (
  board,
  elementClicked,
  object,
  graphPoints,
  event,
  setLabelState,
  isChild
) => {
  // console.log(elementClicked, "elementClicked", object);
  if (!object || !elementClicked) {
    return;
  }
  //   console.log(graphPoints.current,"clickedObjectDrag ", elementClicked);
  const selectedId = isChild
    ? graphPoints.current.find((item) => item.shapeId === elementClicked.id)
        .parentId
    : elementClicked.id;
  const selectedObject = graphPoints.current.find(
    (item) => item.shapeId === selectedId
  );

  if (!selectedObject) {
    return;
  }
  object.off("up");
  elementClicked.off("up");
  if (elementClicked.point1 && elementClicked.point2) {
    // Get the coordinates of the points
    const point1Coords = {
      x: elementClicked.point1.X(),
      y: elementClicked.point1.Y(),
    };
    const point2Coords = {
      x: elementClicked.point2.X(),
      y: elementClicked.point2.Y(),
    };

    // Convert to viewport coordinates
    const topLeft = board.getMousePosition(event);
    const viewportCoords1 = {
      x: point1Coords.x + topLeft[0],
      y: point1Coords.y + topLeft[1],
    };
    const viewportCoords2 = {
      x: point2Coords.x + topLeft[0],
      y: point2Coords.y + topLeft[1],
    };

    const state = {
      element: elementClicked,
      x: Math.round((viewportCoords1.x + viewportCoords2.x) / 2),
      y: Math.round((viewportCoords1.y + viewportCoords2.y) / 2),
      selectedObject: selectedObject,
      isChild: false,
    };
    setLabelState(state);
  } else {
    const positions = board
      .getAllUnderMouse(event)
      .find((item) => Array.isArray(item));
    const state = {
      element: elementClicked,
      x: positions[0] + board.getMousePosition(event)[0],
      y: positions[1] + board.getMousePosition(event)[1],
      selectedObject: selectedObject,
      isChild: isChild,
    };
    setLabelState(state);
  }
};
export const handlePointClick = (
  board,
  x,
  y,
  graphSettings,
  activeTabIndex,
  setCustomGraphOptions,
  selectedButton,
  setHistory
) => {
  const point = drawPoint(board, x, y, {
    snapping: graphSettings?.more_options,
  });
  console.log(point, "pointCreated");
  const snapper = getSnappingValue(graphSettings?.more_options);
  let gObj = {
    id: uuid(),
    type: selectedButton,
    hasParent: false,
    coords: {
      x: getNewCoordsValueAfterSnapping(
        x,
        snapper.snapToGrid,
        snapper.snapSizeX
      ),
      y: getNewCoordsValueAfterSnapping(
        y,
        snapper.snapToGrid,
        snapper.snapSizeY
      ),
    },
    tab: activeTabIndex,
    drawKey: point,
    options: {
      snapToGrid: snapper.snapToGrid,
      snapSizeX: snapper.snapSizeX,
      snapSizeY: snapper.snapSizeY,
    },
  };
  setCustomGraphOptions((prevData) => {
    const previousState = prevData;
    const updatedData = [...prevData, gObj];
    setHistory((prevHistory) => [
      ...prevHistory,
      {
        action: "add",
        previousState,
        newState: updatedData,
        data: "",
      },
    ]);
    return updatedData;
  });
  board.update();
};
let pointAData, pointBData;
export const handleTwoPointsShape = (
  board,
  newPoint,
  graphSettings,
  activeTabIndex,
  setCustomGraphOptions,
  pointsRef,
  elementsRef,
  selectedButton,
  questiontype,
  setHistory
) => {
  if (!pointsRef.current.shapeDrawer) {
    pointsRef.current.shapeDrawer = generateTwoPointsShape(
      board,
      "draw",
      graphSettings?.more_options,
      true
    );
    pointsRef.current.shapeDrawer.next(); // Start generator
  }

  const snapper = getSnappingValue(graphSettings?.more_options);
  console.log(snapper, "snapper");
  pointsRef.current.points.push(newPoint);
  elementsRef.current.push(newPoint);

  if (pointsRef.current.points.length === 1) {
    // First point (startCoords)
    const pointA = pointsRef.current.shapeDrawer.next(newPoint).value;
    pointAData = {
      id: uuid(),
      type: "Point",
      hasParent: true,
      coords: {
        x: getNewCoordsValueAfterSnapping(
          pointA.X(),
          snapper.snapToGrid,
          snapper.snapSizeX
        ),
        y: getNewCoordsValueAfterSnapping(
          pointA.Y(),
          snapper.snapToGrid,
          snapper.snapSizeY
        ),
      },
      tab: activeTabIndex,
      drawKey: pointA,
      options: {
        snapToGrid: snapper.snapToGrid,
        snapSizeX: snapper.snapSizeX,
        snapSizeY: snapper.snapSizeY,
      },
    };
    board.update();
  }

  if (pointsRef.current.points.length === 2) {
    // Second point (endCoords)
    pointsRef.current.shapeDrawer.next();
    const pointB = pointsRef.current.shapeDrawer.next(
      pointsRef.current.points[1]
    ).value;

    pointBData = {
      id: uuid(),
      type: "Point",
      hasParent: true,
      coords: {
        x: getNewCoordsValueAfterSnapping(
          pointB.X(),
          snapper.snapToGrid,
          snapper.snapSizeX
        ),
        y: getNewCoordsValueAfterSnapping(
          pointB.Y(),
          snapper.snapToGrid,
          snapper.snapSizeY
        ),
      },
      tab: activeTabIndex,
      drawKey: pointB,
      options: {
        snapToGrid: snapper.snapToGrid,
        snapSizeX: snapper.snapSizeX,
        snapSizeY: snapper.snapSizeY,
      },
    };
    board.update();

    // Generate the shape ID early
    const shapeId = uuid();

    // Pass the shape type and create the shape
    pointsRef.current.shapeDrawer.next();
    const shapeDetails = {
      type: buttonsSettings[selectedButton].shape,
      options: { ...buttonsSettings[selectedButton].options },
    };
    const shape = pointsRef.current.shapeDrawer.next(shapeDetails).value;

    if (!shape) {
      console.error("Shape was not created by the generator.");
      return;
    }

    board.update();

    pointAData.parentID = shapeId;
    pointBData.parentID = shapeId;

    // Extract coordinates for shape
    const { x: x1, y: y1 } = pointsRef.current.points[0];
    const { x: x2, y: y2 } = pointsRef.current.points[1];

    setCustomGraphOptions((prevData) => {
      const previousState = prevData;

      const data = [
        ...prevData,
        pointAData,
        pointBData,
        {
          id: shapeId,
          type: selectedButton,
          tab: activeTabIndex,
          drawKey: shape,
          hasParent: false,
          subElementIds: {
            startPoint: {
              x: getNewCoordsValueAfterSnapping(
                x1,
                snapper.snapToGrid,
                snapper.snapSizeX
              ),
              y: getNewCoordsValueAfterSnapping(
                y1,
                snapper.snapToGrid,
                snapper.snapSizeY
              ),
            },
            endPoint: {
              x: getNewCoordsValueAfterSnapping(
                x2,
                snapper.snapToGrid,
                snapper.snapSizeX
              ),
              y: getNewCoordsValueAfterSnapping(
                y2,
                snapper.snapToGrid,
                snapper.snapSizeY
              ),
            },
          },
          options: {
            snapToGrid: snapper.snapToGrid,
            snapSizeX: snapper.snapSizeX,
            snapSizeY: snapper.snapSizeY,
          },
        },
      ];
      setHistory((prevHistory) => [
        ...prevHistory,
        {
          action: "add",
          previousState,
          newState: data,
          data,
        },
      ]);
      return data;
    });

    // Reset for the next shape
    pointsRef.current.shapeDrawer = null;
    pointsRef.current.points = [];
    elementsRef.current = [];
    // pointBData = {};
    // pointAData = {};
  }
};

export const handlePolygonClick = (
  board,
  newPoint,
  graphSettings,
  activeTabIndex,
  setCustomGraphOptions,
  elementsRef,
  selectedButton,
  boardResetting,
  questiontype,
  polygonDone,
  setHistory
) => {
  elementsRef.current.push(newPoint);
  const snapper = getSnappingValue(graphSettings?.more_options);
  const { polygonState, polygonShape, polygonPoints, segments } =
    buttonsSettings[selectedButton].draw(board, newPoint, {
      snapToGrid: snapper.snapToGrid,
      snapSizeX: snapper.snapSizeX,
      snapSizeY: snapper.snapSizeY,
      removePoints: boardResetting,
      userDoneDrawing: questiontype,
      isPolygonDone: polygonDone,
    });
  if (polygonState) {
    let gtObj = {
      id: uuid(),
      type: selectedButton,
      tab: activeTabIndex,
      drawKey: polygonShape,
      hasParent: false,
      subElementIds: {
        startPoint: {
          x: getNewCoordsValueAfterSnapping(
            newPoint.x,
            snapper.snapToGrid,
            snapper.snapSizeX
          ),
          y: getNewCoordsValueAfterSnapping(
            newPoint.y,
            snapper.snapToGrid,
            snapper.snapSizeY
          ),
        },
        endPoint: {
          x: getNewCoordsValueAfterSnapping(
            newPoint.x,
            snapper.snapToGrid,
            snapper.snapSizeX
          ),
          y: getNewCoordsValueAfterSnapping(
            newPoint.y,
            snapper.snapToGrid,
            snapper.snapSizeY
          ),
        },
        points: polygonPoints.map((point) => {
          return {
            x: getNewCoordsValueAfterSnapping(
              point.X(),
              snapper.snapToGrid,
              snapper.snapSizeX
            ),
            y: getNewCoordsValueAfterSnapping(
              point.Y(),
              snapper.snapToGrid,
              snapper.snapSizeY
            ),
          };
        }),
        segments,
        pointsShapes: polygonPoints,
      },
      options: {
        snapToGrid: snapper.snapToGrid,
        snapSizeX: snapper.snapSizeX,
        snapSizeY: snapper.snapSizeY,
      },
    };
    setCustomGraphOptions((prevData) => {
      const previousState = prevData;

      console.log(gtObj, "gtObj");
      const updatedData = [...prevData, gtObj];
      setHistory((prevHistory) => [
        ...prevHistory,
        {
          action: "add",
          previousState,
          newState: updatedData,
          data: "",
        },
      ]);
      return updatedData;
    });
    elementsRef.current = [];
    // polygonStateRef.current = polygonState;
  }
};

export const handleCurveMove = (
  shapeSelected,
  shapeId,
  dx,
  dy,
  upPoint,
  setHistory,
  setCustomGraphOptions,
  graphShapesRef
) => {
  console.log(
    shapeSelected,
    shapeId,
    dx,
    dy,
    upPoint,
    graphShapesRef,
    "CurveMove"
  );
  const [firstChild, secondChild] = graphShapesRef.current.filter(
    (item) => item.parentID === shapeSelected.id
  );

  console.log(firstChild.drawKey.X(), firstChild.drawKey.Y(), "firstChild");
  console.log(secondChild.drawKey.X(), secondChild.drawKey.Y(), "secondChild");
  firstChild.drawKey.moveTo([
    dx + firstChild.drawKey.X(),
    dy + firstChild.drawKey.Y(),
  ]);
  secondChild.drawKey.moveTo([
    dx + secondChild.drawKey.X(),
    dy + secondChild.drawKey.Y(),
  ]);
  const pointA = firstChild.drawKey;
  const pointB = secondChild.drawKey;
  let newShape;

  if (shapeSelected.type === "Sine") {
    const board = shapeSelected.drawKey.board;

    board.removeObject(shapeSelected.drawKey);

    const updateSineParams = () => {
      const x1 = pointA.X();
      const x2 = pointB.X();
      const y1 = pointA.Y();
      const y2 = pointB.Y();

      const verticalShift = (y1 + y2) / 2;

      const dx = Math.abs(x2 - x1);

      const frequency = dx < 0.001 ? Math.PI : Math.PI / dx;

      const amplitude = Math.abs(y1 - y2) / 2 || 1;

      let phaseShift;
      if (Math.abs(x1 - x2) < 0.001) {
        phaseShift = x1;
      } else {
        const phase1 = Math.asin((y1 - verticalShift) / amplitude);

        phaseShift = x1 - phase1 / frequency;

        const getSineY = (x, params) => {
          return (
            params.amplitude *
              Math.sin(params.frequency * (x - params.phaseShift)) +
            params.verticalShift
          );
        };

        if (
          Math.abs(
            getSineY(x2, {
              amplitude,
              frequency,
              phaseShift,
              verticalShift,
            }) - y2
          ) > 0.01
        ) {
          phaseShift = x1 - (Math.PI - phase1) / frequency;
        }
      }

      return { amplitude, frequency, phaseShift, verticalShift };
    };

    newShape = board.create(
      "functiongraph",
      [
        function (x) {
          const { amplitude, frequency, phaseShift, verticalShift } =
            updateSineParams();
          return (
            amplitude * Math.sin(frequency * (x - phaseShift)) + verticalShift
          );
        },
      ],
      shapeSelected.drawKey.getAttributes()
    );
  }
  if (shapeSelected.type === "Parabola") {
    const board = shapeSelected.drawKey.board;

    board.removeObject(shapeSelected.drawKey);

    const a = () =>
      (pointB.Y() - pointA.Y()) / Math.pow(pointB.X() - pointA.X(), 2);
    newShape = board.create(
      "functiongraph",
      [(x) => a() * Math.pow(x - pointA.X(), 2) + pointA.Y()],
      shapeSelected.drawKey.getAttributes()
    );
  }

  setCustomGraphOptions((prevData) => {
    const previousState = prevData;
    const filteredData = prevData.map((o) => {
      if (o.id === shapeSelected.id) {
        return {
          ...o,
          drawKey: newShape,
          subElementIds: {
            ...o.subElementIds,
            startPoint: {
              ...o.subElementIds.startPoint,
              x: o.subElementIds.startPoint.x + dx,
              y: o.subElementIds.startPoint.y + dy,
            },
            endPoint: {
              ...o.subElementIds.endPoint,
              x: o.subElementIds.endPoint.x + dx,
              y: o.subElementIds.endPoint.y + dy,
            },
          },
        };
      }
      return o;
    });
    console.log(filteredData, "filteredData");
    updateHistory("move", previousState, filteredData, setHistory);
    return filteredData;
  });
  updateChildrenPositions(shapeSelected, setCustomGraphOptions);
};

export const getNewLabelObjectState = (
  type,
  isChild,
  obj,
  elemnt,
  label,
  prevData
) => {
  console.log(type, isChild, obj, elemnt, label, "objData");
  const newObject = {
    ...obj,
    id: obj.id,
    drawKey: obj.drawKey,
    type: obj.type,
    tab: obj.tab,
    subElementIds: {
      ...getSubElements(type, isChild, obj, elemnt, label, prevData),
    },
    coords: { ...(obj.coords || {}) },
    options: {
      name: isChild ? obj.options.name : label,
      label: {
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: "Arial",
        visible: true,
        textAlign: "center",
        textBaseline: "middle",
        opacity: 0.8,
        anchorX: "middle",
        anchorY: "middle",
        position: "top",
        offset: [20, 20],
      },
    },
  } as any;
  if (type === "Polygon") {
    newObject.options = {
      ...newObject.options,
      name: isChild ? obj.options.name : label,
    };
  }
  return newObject;
};

export const getSubElements = (
  type,
  isChild,
  obj,
  element,
  label,
  prevData
) => {
  if (type === "Polygon") {
    const result = {
      ...obj.subElementIds,
      points: isChild
        ? obj.subElementIds.points.map((point) => {
            if (point.x === element.X() && point.y === element.Y()) {
              return {
                ...point,
                label: label,
              };
            } else {
              return {
                ...point,
              };
            }
          })
        : obj.subElementIds.points,
    };
    return result;
  }
  if (type === "Point") {
    return {};
  }
  if (type === "Circle") {
    // console.log("circleEntered", isChild, obj, element, label);
    const result = isChild
      ? {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label:
              obj.drawKey.midpoint.coords.usrCoords[1] ===
                element.coords.usrCoords[1] &&
              obj.drawKey.midpoint.coords.usrCoords[2] ===
                element.coords.usrCoords[2]
                ? label
                : obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label:
              obj.drawKey.point2.coords.usrCoords[2] ===
                element.coords.usrCoords[2] &&
              obj.drawKey.point2.coords.usrCoords[1] ===
                element.coords.usrCoords[1]
                ? label
                : obj.subElementIds.endPoint.label,
          },
        }
      : {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label: obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label: obj.subElementIds.endPoint.label,
          },
        };
    return result;
  }
  if (type === "Parabola") {
    const result = isChild
      ? {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label:
              obj.subElementIds.startPoint.x ===
                Math.round(element.coords.usrCoords[1]) &&
              obj.subElementIds.startPoint.y ===
                Math.round(element.coords.usrCoords[2])
                ? label
                : obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label:
              obj.subElementIds.endPoint.x ===
                Math.round(element.coords.usrCoords[1]) &&
              obj.subElementIds.endPoint.y ===
                Math.round(element.coords.usrCoords[2])
                ? label
                : obj.subElementIds.endPoint.label,
          },
        }
      : {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label: obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label: obj.subElementIds.endPoint.label,
          },
        };
    return result;
  }
  if (type === "Sine") {
    console.log(isChild, obj, element, label, "SINE POINTS");
    let elementIsLabeled = null;
    if (isChild) {
      const [firstPoint, secondPoint] = prevData.filter(
        (point) => point.parentID === obj.id
      );
      if (
        firstPoint.drawKey.X() === element.X() &&
        firstPoint.drawKey.Y() === element.Y()
      ) {
        elementIsLabeled = firstPoint;
      }
      if (
        secondPoint.drawKey.X() === element.X() &&
        secondPoint.drawKey.Y() === element.Y()
      ) {
        elementIsLabeled = secondPoint;
      }
    }
    console.log(elementIsLabeled, "elementIsLabeled");
    const result = isChild
      ? {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label:
              elementIsLabeled.coords.x === obj.subElementIds.startPoint.x &&
              elementIsLabeled.coords.y === obj.subElementIds.startPoint.y
                ? label
                : obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label:
              elementIsLabeled.coords.x === obj.subElementIds.endPoint.x &&
              elementIsLabeled.coords.y === obj.subElementIds.endPoint.y
                ? label
                : obj.subElementIds.endPoint.label,
          },
        }
      : {
          startPoint: {
            ...obj.subElementIds.startPoint,
            label: obj.subElementIds.startPoint.label,
          },
          endPoint: {
            ...obj.subElementIds.endPoint,
            label: obj.subElementIds.endPoint.label,
          },
        };
    return result;
  }
  const result = isChild
    ? {
        startPoint: {
          ...obj.subElementIds.startPoint,
          label:
            obj.drawKey.point1.coords.usrCoords[1] ===
              element.coords.usrCoords[1] &&
            obj.drawKey.point1.coords.usrCoords[2] ===
              element.coords.usrCoords[2]
              ? label
              : obj.subElementIds.startPoint.label,
        },
        endPoint: {
          ...obj.subElementIds.endPoint,
          label:
            obj.drawKey.point2.coords.usrCoords[2] ===
              element.coords.usrCoords[2] &&
            obj.drawKey.point2.coords.usrCoords[1] ===
              element.coords.usrCoords[1]
              ? label
              : obj.subElementIds.endPoint.label,
        },
      }
    : {
        startPoint: {
          ...obj.subElementIds.startPoint,
          label: obj.subElementIds.startPoint.label,
        },
        endPoint: {
          ...obj.subElementIds.endPoint,
          label: obj.subElementIds.endPoint.label,
        },
      };
  return result;
};

export const getObjectClicked = (board, event, points) => {
  const objectsUnderMouse = board.getAllObjectsUnderMouse(event);
  // console.log(objectsUnderMouse, "objectsUnderMouse");
  const clickedParentObject = points.current
    .map((item) =>
      objectsUnderMouse.find(
        (obj) => obj.id === item.shapeId && !item.hasParent
      )
    )
    .filter(Boolean);
  // console.log(clickedParentObject, "clickedParentObject");

  const clickedChildObject = points.current
    .map((item) =>
      objectsUnderMouse.find((obj) => obj.id === item.shapeId && item.hasParent)
    )
    .filter(Boolean);
  // console.log(clickedChildObject, "clickedChildObject");
  const clickedObject =
    clickedChildObject?.length > 0
      ? clickedChildObject[0]
      : clickedParentObject[0];

  const isChild = clickedChildObject?.length > 0;

  return { clickedObject, objectsUnderMouse, isChild };
};
