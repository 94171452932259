import { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { ROLE } from "../utils/roles";
import {useSelector} from "react-redux";
import {useAppSelector} from "../hooks/useAppSelector";
import {RootState} from "../redux/store";

interface ProtectedRouteProps {
    children: JSX.Element;
    roles?: Array<ROLE>;
}

const ProtectedRoute = ({ children, roles }: ProtectedRouteProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // @ts-ignore
    const { user, token } = useAppSelector((state) => state?.authentication);
    let config = useSelector((state: RootState) => state.assessments?.config || '');
    let assessments = useSelector((state: RootState) => state.assessments);


    const arr = roles || [];

    const userHasRequiredRole = user && arr.includes(user?.roles[0]);

    const checkUserToken = () => {
        const queryParams = new URLSearchParams(location.search);
        const encryptedData = queryParams.get("token") || token;
        const type = localStorage.getItem("type");

        if ((!token && encryptedData) || token) {
            setIsLoggedIn(true);
        }else {
            setIsLoggedIn(true);
            return navigate( `/${type}-login`, { replace: true });
        }
    };

    const checkUserRole = () => {

        if (token && !userHasRequiredRole) {
            return navigate("/access-denied");
        }
    };

    useEffect(() => {
        checkUserToken();
        checkUserRole();
    }, [isLoggedIn]);

    return isLoggedIn ? children : null;
};
export default ProtectedRoute;
