import DOMPurify from "dompurify";
import React, { useEffect, useRef } from "react";

interface IQuestionPreviewLayout {
  children: React.ReactNode;
  questionHeader: string;
}

const QuestionPreviewLayout = ({
  children,
  questionHeader,
}: IQuestionPreviewLayout) => {
  const questionRef = useRef(null);

  useEffect(() => {
    questionRef.current.innerHTML = DOMPurify.sanitize(questionHeader);
    if (window.MathJax && window.MathJax.typesetPromise) {
      window.MathJax.typesetPromise([questionRef.current])
        .then(() => {})
        .catch((err: Error) => {
          console.error("MathJax typesetting failed:", err);
        });
    }
  }, [questionRef, questionHeader]);
  return (
    <div>
      <div className="question-preview" ref={questionRef} />
      {children}
    </div>
  );
};

export default QuestionPreviewLayout;
