import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Question } from "../../obsService/types";

interface SelectedQuestionsState {
  questions: Question[];
  currentlySelectedQuestions: any[];
}

const initialState: SelectedQuestionsState = {
  questions: [],
  currentlySelectedQuestions: [],
};

const selectedQuestionsSlice = createSlice({
  name: "selectedQuestions",
  initialState,
  reducers: {
    setSelectedQuestions: (state, action: PayloadAction<Question[]>) => {
      state.questions = action.payload;
    },
    cleanQuestions: (state, action: PayloadAction<Question[]>) => {
      return initialState;
    },
    removeQuestionById: (state, action: PayloadAction<string>) => {
      state.questions = state.questions.filter(
        (question) => question.id !== action.payload
      );
    },
    clearSelectedQuestions: (state) => {
      state.questions = [];
    },
    addQuestionToSelected: (state, action) => {
      const { questionsCreated } = action.payload;
      if (!Array.isArray(questionsCreated)) {
        console.error("questionsCreated is not an array", questionsCreated);
        return; // Exit the function or handle the error as needed
      }
      state.questions = [...state.questions, ...questionsCreated];
    },
    addCurrentlySelectedQuestionToSelected: (state, action) => {
      const { questionsCreated } = action.payload;
      if (!Array.isArray(questionsCreated)) {
        console.error("currently selected questionsCreated is not an array", questionsCreated);
        return; // Exit the function or handle the error as needed
      }
      state.currentlySelectedQuestions = [...state.currentlySelectedQuestions, ...questionsCreated];
    },
    setCurrentSelectedQuestions: (state, action) => {
      // action.payload;
      state.currentlySelectedQuestions = [...action.payload];
    },
    removeCurrentSelectedQuestions: (state, action) => {
      const { questionIdToBeRemoved } = action.payload;
      state.currentlySelectedQuestions =
        state.currentlySelectedQuestions.filter(
          (question) => question.id != questionIdToBeRemoved
        );
    },
    resetCurrentSelectedQuestions: (state) => {
      state.currentlySelectedQuestions = [];
    },
  },
});

export const {
  setSelectedQuestions,
  cleanQuestions,
  removeQuestionById,
  addQuestionToSelected,
  addCurrentlySelectedQuestionToSelected,
  clearSelectedQuestions,
  setCurrentSelectedQuestions,
  removeCurrentSelectedQuestions,
  resetCurrentSelectedQuestions,
} = selectedQuestionsSlice.actions;
export default selectedQuestionsSlice.reducer;
