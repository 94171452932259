import React from 'react';
import { TableCell } from "./TableCell";
import { FontSize } from "./types";
import { GriddedBase } from './GriddedBase';

interface FractionSlashGriddedProps {
    columns: number;
    range: { min: number; max: number };
    fontSize: FontSize;
    onChange: (column: number, value: string) => void;
    disabled?: boolean;
    selectedValues?: string[];
    hasFloatingDecimal?: boolean;
    hasFixedDecimal?: boolean;
    decimalColumn?: number | null;
}

export const FractionSlashGridded: React.FC<FractionSlashGriddedProps> = ({
    columns,
    range,
    fontSize,
    onChange,
    disabled,
    selectedValues = [],
    hasFloatingDecimal = false,
    hasFixedDecimal = false,
    decimalColumn = null,
    }) => {
    const length = range.max > range.min
        ? range.max - range.min + 1
        : range.min - range.max + 1;

    const numbers = Array.from(
        { length },
        (_, i) => `${Math.min(range.min, range.max) + i}`
    );

    const actualColumns = hasFixedDecimal && decimalColumn !== null ? columns + 1 : columns;


    return (
        <GriddedBase>
            {/* Fraction slash row */}
            <tr>
                {Array.from({ length: actualColumns }).map((_, colIndex) => {
                    const isDecimalPoint = hasFixedDecimal && decimalColumn === colIndex;
                    const isEdge = colIndex === 0 || colIndex === actualColumns - 1;

                    return (
                        <TableCell
                            key={colIndex}
                            value={isEdge ? "" : "/"}
                            columnIndex={colIndex}
                            rowIndex={0}
                            fontSize={fontSize}
                            disabled={isEdge}
                            onChange={(value) => onChange(colIndex, value)}
                            //isSpecial={!isEdge}
                            //isEmpty={isEdge}
                            selectedValue={selectedValues[colIndex]}
                        />
                    );
                })}
            </tr>

            {/* Floating decimal row */}
            {hasFloatingDecimal && (
                <tr>
                    {Array.from({ length: actualColumns }).map((_, colIndex) => {
                        const isDecimalPoint = hasFixedDecimal && decimalColumn === colIndex;

                        return (
                            <TableCell
                                key={colIndex}
                                value="."
                                columnIndex={colIndex}
                                rowIndex={1}
                                fontSize={fontSize}
                                //disabled={true}
                                onChange={(value) => onChange(colIndex, value)}
                                //isSpecial={true}
                                selectedValue={selectedValues[colIndex]}
                            />
                        );
                    })}
                </tr>
            )}

            {/* Number rows */}
            {numbers.map((num, rowIndex) => {
                const currentRowIndex = rowIndex + (hasFloatingDecimal ? 2 : 1);

                return (
                    <tr key={rowIndex}>
                        {Array.from({ length: actualColumns }).map((_, colIndex) => {
                            const isDecimalPoint = hasFixedDecimal && colIndex === decimalColumn - 1;

                            if (isDecimalPoint) {
                                return (
                                    <TableCell
                                        key={colIndex}
                                        value="."
                                        columnIndex={colIndex}
                                        rowIndex={currentRowIndex}
                                        fontSize={fontSize}
                                        disabled={true}
                                        onChange={() => {}}
                                        isSpecial={true}
                                        selectedValue={selectedValues[colIndex]}
                                    />
                                );
                            }

                            return (
                                <TableCell
                                    key={colIndex}
                                    value={num}
                                    columnIndex={colIndex}
                                    rowIndex={currentRowIndex}
                                    fontSize={fontSize}
                                    disabled={disabled}
                                    onChange={(value) => onChange(colIndex, value)}
                                    selectedValue={selectedValues[colIndex]}
                                />
                            );
                        })}
                    </tr>
                );
            })}
        </GriddedBase>
    );
};
