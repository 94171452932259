import React, { useRef, useCallback, useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { joditConfig } from "./JoditConfig";
import { QuestionJoditConfig } from "./QuestionJoditConfig";

interface CustomProps {
  label: string;
  value: string;
  onBlur?: (content: string, index?: number) => void;
  onChange?: (content: string, index?: number) => void;
  includeResponseButton?: boolean;
  allconfig: boolean;
  userconfig?: any;
  className?: string;
  index?: number;
  questionconfig?: boolean;
  spellCheck?: boolean;
  minHeight?: any;
  maxHeight?: any;
}

const CustomJoditEditor: React.FC<CustomProps> = ({
  label,
  value,
  onBlur,
  onChange,
  allconfig,
  userconfig, // It is custom configuration which is needed to any options
  className,
  index,
  questionconfig,
  spellCheck = false,
  minHeight = "",
  maxHeight = "",
}) => {
  const editor = useRef(null);
  const [editorContent, setEditorContent] = useState<string>(value);

  useEffect(() => {
    if (editor.current) {
      editor.current.value = editorContent;
    }
  }, [editorContent]);

  const handleContentChange = useCallback(
    (content: string) => {
      if (onChange) {
        onChange(content, index);
      }
    },
    [onChange, index]
  );

  const handleEditorBlur = useCallback(
    (content: string) => {
      if (onBlur) {
        onBlur(content, index);
      }
    },
    [onBlur, index]
  );

  const customConfig = {
    ...(allconfig && questionconfig
      ? QuestionJoditConfig
      : allconfig
      ? joditConfig
      : userconfig),
    spellcheck: spellCheck,
  };

  return (
    <>
      {label && (
        <div
          className="mt-5 mb-5 flex items-center p-5 justify-between"
          style={{
            height: "45px",
            backgroundColor: "#eee",
            borderRadius: "6px",
          }}
        >
          <h2 className="font-semibold">{label}</h2>
        </div>
      )}
      <div
        style={{
          minHeight: minHeight,
          maxHeight: maxHeight,
          overflowY: "auto",
          width: "100%",
        }}
      >
        <JoditEditor
          className={className ? className : ""}
          config={
            spellCheck
              ? customConfig
              : allconfig && questionconfig
              ? QuestionJoditConfig
              : allconfig
              ? joditConfig
              : userconfig
          }
          ref={editor}
          value={value}
          onBlur={handleEditorBlur}
          onChange={handleContentChange}
        />
      </div>
    </>
  );
};

export default CustomJoditEditor;
