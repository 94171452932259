import {
  Card,
  Flex,
  InputNumber,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import ArrowIcon from "studio/icons/ArrowIcon";

const { Text, Title } = Typography;

const ScoringSection = ({
  data,
  handleChangeValue,
}: {
  data: any;
  handleChangeValue: (key: string, subKey: string, value: any) => void;
}) => {
  const more_options = data?.more_options || { scoring: { unscored: false } };
  const scoring = more_options?.scoring;
  return (
    <>
      <Flex justify="space-between" className="mb-6" align="center">
        <Title
          className="flex-1"
          style={{
            fontWeight: "600",
            marginBottom: 0,
          }}
          level={5}
        >
          SCORING
        </Title>
        <Switch
          checked={!scoring.unscored}
          onChange={(val: boolean) => {
            handleChangeValue("scoring", "unscored", !val);
          }}
        />
      </Flex>

      {!more_options?.scoring?.unscored && (
        <>
          <Space direction="vertical" className="w-full mb-4">
            <Text>Minimum score if attempted</Text>
            <InputNumber
              className="w-full"
              size="large"
              min="0"
              value={scoring?.min_score_if_attempted || "0"}
              onChange={(val: string) => {
                handleChangeValue("scoring", "min_score_if_attempted", val);
              }}
            />
          </Space>
          <Space direction="vertical" className="w-full mb-4">
            <Text>Maximum score</Text>
            <InputNumber
              className="w-full"
              size="large"
              min="0"
              value={scoring?.max_score || "0"}
              onChange={(val: string) => {
                handleChangeValue("scoring", "max_score", val);
              }}
            />
          </Space>

          <Card className="mb-4">
            <Flex justify="space-between" align="center">
              <Text className="flex-1">Auto scoring</Text>
              <Switch
                checked={scoring.automarkable}
                onChange={(val: boolean) => {
                  handleChangeValue("scoring", "automarkable", val);
                }}
              />
            </Flex>

            {scoring?.automarkable && (
              <>
                <Space direction="vertical" className="w-full mt-6 mb-4">
                  <Text>{"Penalty point(s)"}</Text>
                  <InputNumber
                    className="w-full"
                    size="large"
                    min="0"
                    value={scoring?.penalty || "0"}
                    onChange={(val: string) => {
                      handleChangeValue("scoring", "penalty", val);
                    }}
                  />
                </Space>

                <Space direction="vertical" className="w-full mb-4">
                  <Text>Scoring Type</Text>
                  <Select
                    size="large"
                    className="w-full"
                    suffixIcon={<ArrowIcon />}
                    value={scoring?.scoring_type || "partialMatch"}
                    options={scoring_types}
                    onChange={(val: string) => {
                      handleChangeValue("scoring", "scoring_type", val);
                    }}
                    placeholder="Select"
                  />
                </Space>
              </>
            )}
          </Card>

          <Card>
            <Flex justify="space-between" align="center">
              <Text className="flex-1">Check answer</Text>
              <Switch
                checked={scoring.instant_feedback}
                onChange={(val: boolean) => {
                  handleChangeValue("scoring", "instant_feedback", val);
                }}
              />
            </Flex>

            {scoring?.instant_feedback && (
              <Space direction="vertical" className="w-full mt-6 mb-4">
                <Text>Check answer attempts</Text>
                <InputNumber
                  className="w-full"
                  size="large"
                  min="0"
                  value={scoring?.feedback_attempts || "0"}
                  onChange={(val: string) => {
                    handleChangeValue("scoring", "feedback_attempts", val);
                  }}
                />
              </Space>
            )}
          </Card>
        </>
      )}
    </>
  );
};

export default ScoringSection;

const scoring_types = [
  { id: "exactMatch", value: "exactMatch", label: "Exact match" },
  {
    id: "partialMatch",
    value: "partialMatch",
    label: "Partial match per response",
  },
  { id: "partialMatchV2", value: "partialMatchV2", label: "Partial match" },
];
