interface IconProps {
  fill?: string;
  className?: string;
}
const TrueOrFalse = ({ fill = "currentColor", className = "" }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="25"
    viewBox="0 0 32 25"
    fill="none"
  >
    <path
      d="M19.4412 24.5L17.8538 22.9126L22.0825 18.684L17.8538 14.4553L19.4412 12.8761L23.6698 17.1047L27.8985 12.8761L29.4777 14.4553L25.2491 18.684L29.4777 22.9126L27.8985 24.5L23.6698 20.2712L19.4412 24.5ZM22.6688 10.6194L17.5847 5.54309L19.172 3.95605L22.6281 7.41223L29.5403 0.5L31.12 2.12769L22.6688 10.6194ZM0.879883 19.957V17.6816H14.3099V19.957H0.879883ZM0.879883 7.57153V5.29626H14.3099V7.57153H0.879883Z"
      fill={fill}
      className={className}
    />
  </svg>
);
export default TrueOrFalse;
