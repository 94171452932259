import React from 'react';

const Search = ({ style = {} }) => {
    return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="16" height="16.019" viewBox="0 0 16 16.019">
        <path
            id="search_check_2_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
            d="M114.978-840.712a4.99,4.99,0,0,0,3.671-1.514,5.019,5.019,0,0,0,1.51-3.682,5.02,5.02,0,0,0-1.51-3.683,4.986,4.986,0,0,0-3.668-1.515,5.015,5.015,0,0,0-3.678,1.514,5.005,5.005,0,0,0-1.517,3.682,5.015,5.015,0,0,0,1.514,3.683A5.008,5.008,0,0,0,114.978-840.712Zm0,1.2a6.174,6.174,0,0,1-4.538-1.864,6.175,6.175,0,0,1-1.863-4.535,6.184,6.184,0,0,1,1.862-4.537,6.164,6.164,0,0,1,4.535-1.866,6.149,6.149,0,0,1,4.529,1.865,6.188,6.188,0,0,1,1.857,4.536,6.3,6.3,0,0,1-.384,2.2,6.359,6.359,0,0,1-1.074,1.869l4.675,4.688-.836.857-4.69-4.694a6.357,6.357,0,0,1-1.867,1.088A6.216,6.216,0,0,1,114.981-839.508Z"
            transform="translate(-108.58 852.31)"
            fill="#cacaca"
        />
    </svg>

);
};

export default Search;
