import axios from 'axios';
import { toast } from 'react-toastify';
import { message } from "antd";

const headers = {
    Accept: 'application/json'
};

export const getPreviewQuestionsRequest = async (assessmentId: string) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .get(`${process.env.REACT_APP_API_URL}/assessment/${assessmentId}`)
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => (error = err.response.data.error));

  return { data, error, status };
};

export const getAssessmentsRequest = async (
  page: number,
  perPage: number,
  filter: any
) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/assessment/datatable`, {
      params: {
        page,
        per_page: perPage,
        ...filter, // Include additional filter parameters here
      },
    })
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => (error = err.response.data.error));

  return { data, error, status };
};

export const addAssessmentRequest = async (assessmentData: any) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/assessment`, assessmentData,{headers})
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => {
      if (err.response) {
        status = err.response.status;
        error = err.response.data;
      } else if (err.request) {
        error = err.request;
        message.error(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err);
        error = err.message;
      }
      message.error(err.response.data.message);
    });

  return { data, error, status };
};
export const editAssessment = async (assessmentId: any, assessmentData: any) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .put(`${process.env.REACT_APP_API_URL}/api/assessment-order/${assessmentId}`, assessmentData,{headers})
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => {
      if (err.response) {
        status = err.response.status;
        error = err.response.data;
      } else if (err.request) {
        error = err.request;
        message.error(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err);
        error = err.message;
      }
      message.error(err.response.data.message);
    });

  return { data, error, status };
};
export const duplicateSingleAssessmentRequest = async (assessmentData: any) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/assessment/single-duplicate`, assessmentData,{headers})
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => {
      if (err.response) {
        status = err.response.status;
        error = err.response.data;
      } else if (err.request) {
        error = err.request;
        message.error(err.request);

      } else {
        // Something happened in setting up the request that triggered an Error
        error = err.message;
      }
      message.error(err.response.data.message);

    });

  return { data, error, status };
};

export const editAssessmentRequest = async (
  assessmentId: any,
  assessmentsData: any
) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/assessment/${assessmentId}`,
      assessmentsData,{headers}
    )
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => {
      if (err.response) {
        status = err.response.status;
        error = err.response.data;
      } else if (err.request) {
        error = err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
        error = err.message;
      }

      console.log('err.config', err.config);
    });

  return { data, error, status };
};

export const getAssessmentByIdRequest = async (assessmentId: any) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/assessment/${assessmentId}`)
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => {
      if (err.response) {
        status = err.response.status;
        error = err.response.data;
      } else if (err.request) {
        error = err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
        error = err.message;
      }

      console.log('err.config', err.config);
    });

  return { data, error, status };
};
