import { useEffect, useRef} from 'react';
import { Mathfield } from 'mathlive';
import { ResponseButton } from 'pages/AuthorQuestion/FillBlanks/styled';
import { symbolConfigLayouts } from "components/CustomJoditEditor/SymbolsConfig";

interface VirtualKeyboardLayout {
  label: string;
  tooltip: string;
  rows: string[][];
}

export const getLayouts = (symbols: string[], numPads: string[]): VirtualKeyboardLayout[] => {
  const chunkSize = 4;
  const groupedArray = [];
  if(Array.isArray(numPads)){
    for (let i = 0; i < numPads?.length; i += chunkSize) {
      groupedArray?.push(numPads?.slice(i, i + chunkSize));
    }
  }
  const numPadLayout = {
    label: "Numbers",
    tooltip: "Numbers and basic operators",
    rows: groupedArray,
  }

  const modifiedSymbols = symbols
    .filter((symbol) => symbolConfigLayouts[symbol.toLowerCase()])
    .map((symbol) => symbolConfigLayouts[symbol.toLowerCase()]);
  if(groupedArray.length) {
    modifiedSymbols.unshift(numPadLayout)
  }
  
  return modifiedSymbols;
};

const MathInputForChemistry = ({ setData, data }) => {
  const value = data?.template_response || "";
  const mathFieldRef = useRef<Mathfield | null>(null);
  const settings = data?.settings;

  useEffect(() => {
    if(Array.isArray(settings?.symbols) && settings?.symbols?.length) {
      window.mathVirtualKeyboard.layouts = getLayouts(settings.symbols, settings?.numberPad);
    }else {
      window.mathVirtualKeyboard.layouts = getLayouts(['basic', 'chemistry'], settings?.numberPad);
    }
  }, [settings])

  useEffect(() => {
    if (mathFieldRef.current) {
        //@ts-ignore
        //@ts-ignore
        mathFieldRef.current.mathModeSpace = '\\:';
        //@ts-ignore
        mathFieldRef.current.style.minWidth = "30%";
        //@ts-ignore
        mathFieldRef.current.style.maxWidth = "90%";
    }
  }, []);

  const handleChangeTemplateResponse = (newValue: string) => {
    setData('template_response', newValue)
  }

  const handleAddResponse = () => {
      if (mathFieldRef.current && mathFieldRef.current.insert) {
          const latexCommand = '{\\colorbox{#e4e4e4}{{Response}}}';
          mathFieldRef.current.insert(latexCommand);
      }
  };

  return (
    <div>
      <ResponseButton type="button" onClick={handleAddResponse}>
        Response
      </ResponseButton>
      <div
        className="p-4 w-full"
        style={{
          border: '1px solid #ccc',
          borderRadius: '6px',
          width: '100%',
        }}
      >
        {/* @ts-expect-error */}
        <math-field
          ref={mathFieldRef}
          onInput={(evt) => {
            handleChangeTemplateResponse(evt.target.value)
          }}
        >
          {value}
          {/* @ts-expect-error */}
        </math-field>
        
      </div>
    </div>
  );
};

export default MathInputForChemistry;
