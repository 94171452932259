import { MCQTypes } from "studio/constants/questionTypes.constants";
import { Spin } from "antd";
import { useState } from "react";
import SingleAnswerTab from "./singleAnswerTab.component";
import MultipleAnswersTab from "./multipleAnswerTab.component";
import { checkResponseLimit } from "../MultipleAnswerEdit.component";
import { Sizes } from "studio/theme/Sizes.theme";

const QuestionPreviewForMCQ = ({ data }: { data: any }) => {
  const [userResponse, setUserResponse] = useState({ value: [], score: 0 });
  const { more_options, options } = data;

  const handleRadioCheck = (itemId: string) => {
    const selectedItem = options.find((op) => op.id == itemId);
    if (selectedItem)
      setUserResponse({
        value: [{ ...selectedItem, checked: true }],
        score: 0,
      });
  };

  const handleBoxCheck = (itemId: string) => {
    const selectedItem = options.find((op) => op.id === itemId);
    let max_selection = 0;
    if (
      !isNaN(Number(more_options?.layout?.max_selection)) &&
      Number(more_options?.layout?.max_selection) > 0
    ) {
      max_selection = Number(more_options?.layout?.max_selection);
    }
    if (selectedItem) {
      const newUserResposne = {
        value: userResponse.value.find((v) => v?.id === itemId)
          ? userResponse.value.filter((it) => it.id !== itemId)
          : checkResponseLimit(userResponse.value, max_selection)
          ? userResponse.value.concat({
              ...selectedItem,
              checked: true,
            })
          : userResponse.value,
        score: 0,
      };
      setUserResponse(newUserResposne);
    }
  };

  const RenderQuestion = () => {
    const qtype = data?.type;
    const props = {
      more_options,
      options,
      activeTab: userResponse,
      layoutType: data?.type === MCQTypes.BLOCKLAYOUT ? "block" : "radio",
    };

    switch (qtype) {
      case MCQTypes.SINGLEANSWER:
        return (
          <SingleAnswerTab {...props} handleRadioCheck={handleRadioCheck} />
        );
      case MCQTypes.TRUEFALSE:
        return (
          <SingleAnswerTab handleRadioCheck={handleRadioCheck} {...props} />
        );
      case MCQTypes.BLOCKLAYOUT:
        return (
          <SingleAnswerTab handleRadioCheck={handleRadioCheck} {...props} />
        );
      case MCQTypes.MULTIPLEANSWERS:
        return (
          <MultipleAnswersTab {...props} handleBoxCheck={handleBoxCheck} />
        );

      default:
        return (
          <div className="flex flex-col h-full items-center justify-center">
            <Spin size="large"></Spin>
          </div>
        );
    }
  };

  return (
    <div style={{ paddingTop: Sizes.padding, width: 400 }}>
      {RenderQuestion()}
    </div>
  );
};

export default QuestionPreviewForMCQ;
