import React, { useEffect, useState } from 'react';
import {Form, Col, Space, Select, DatePicker, Button, Input, Tooltip, Typography} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { getListDropSubjects } from 'services/subjects';
import './AssessmentFiltersExisting.styles.scss';
import Search from "../../icons/Search";
import './styles.scss';
import ArrowIcon from "../../icons/ArrowIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import { formatObjects } from 'utils';
import {SeparatorIcon} from "../../icons/SeparatorIcon";
import {getTagsRequest} from "../../../api-rest/tags";
import {Dayjs} from "dayjs";
import {FilterAltOffOutlined, FilterAltOutlined} from "@mui/icons-material";

const { RangePicker } = DatePicker;

interface FilterData {
    search: string;
    subject_id: string;
    grade_id: string;
    subject: string;
    grade: string;
    tags: any;
    status: string;
    dateRange: any;
}

interface AssessmentFiltersProps {
    filterData: FilterData;
    onFilterChange: (field: string, value: any) => void;
    onDelete?: (id: any) => void;
    onReset?: () => void;
    onDuplicate?: () => void;
    selectedRows?: any[];
    showOtherAssessmentFilter?:boolean;
    showlabel?:boolean;
    count?: number;
}
type DateRange = [Dayjs | null, Dayjs | null];

const { Text } = Typography;

const AssessmentFilters: React.FC<AssessmentFiltersProps> = ({
                                                                 filterData,
                                                                 onFilterChange,
                                                                 onDelete,
                                                                 onReset,
                                                                 onDuplicate,
                                                                 selectedRows,
                                                                 showOtherAssessmentFilter = true,
                                                                 showlabel = true,
                                                                 count
                                                             }) => {
    const dispatch = useDispatch();
    const filters = useSelector((state: RootState) => state.assessments.filters);
    const ddsubjects = useSelector((state: RootState) => state.subjects.dropSubjects);
    const [formattedSubjects, setFormattedSubjects] = useState([]);
    const [gradeOptions, setGradeOptions] = useState<any[]>([]);
    const [userSelectedSubject, setUserSelectedSubject] = useState(null);
    const [tags, setTags] = useState([]);
    const [selectedDates, setSelectedDates] = useState<DateRange | null>(null);

    useEffect(() => {
       // @ts-ignore
       dispatch(getListDropSubjects());
       getTagsRequest({ type: 'assessment' }) 
           .then((res) => {
               let tagsList = res?.data.items;
               tagsList = tagsList.map((item) => ({
                   ...item,
                   value: item.id,
                   label: item?.name,
               }));
               setTags(tagsList);
           })
           .catch((err) => console.log(err));
   }, [dispatch]);
    useEffect(() => {
        setFormattedSubjects(formatObjects(ddsubjects));
    }, [ddsubjects]);

    // Handle subject change to update grade options
    const handleSubjectChange = (selectedValue: string) => {
        const subjectId = selectedValue !== 'all' ? parseInt(selectedValue) : '';

        const selectedSubject = filters.subjects.find(
            (subject: any) => subject.id === subjectId
        );
        setUserSelectedSubject(selectedSubject);

        if (selectedSubject && selectedSubject.grades) {
            const grades = selectedSubject.grades.map((grade: any) => ({
                id: grade.id,
                value: grade.id,
                label: grade.name,
            }));

            grades.sort((g1, g2) => {
                const nameA = g1.name || '';
                const nameB = g2.name || '';
                return nameA.localeCompare(nameB);
            });

            setGradeOptions(grades);
            onFilterChange('subject_id', subjectId !== '' ? selectedSubject.id : '');
            onFilterChange('grade_id', ''); // Reset grade when subject changes
        } else {
            onFilterChange('subject_id', '');
            setGradeOptions([]);
        }
    };

    const handleReset = () => {
        onFilterChange('', ''); // This will reset all filters based on your existing onFilterChange logic
    };

    const handleTagChange = (values) => {
        const tagIds = values.filter((value) => value !== "all");
        onFilterChange("tags", tagIds);
    };


    const hasData = Object.values(filterData).some(value => {
        return value !== "" && value !== "all" && value !== null &&
            !(Array.isArray(value) && value.length === 0);
    });

    return (
        <div className="assessment-filters">
            {showOtherAssessmentFilter && (
                <Input
                    prefix={<span style={{ marginRight: '10px'}}><Search /></span>}
                    placeholder="Search assessments"
                    style={{
                        marginBottom: '16px',
                        maxWidth: '600px',
                        height: '46px',
                        background: 'white',
                        border: '1px solid #CACACA',
                        paddingTop: '8px',
                        paddingBottom: '8px'
                    }}
                    value={filterData.search}
                    onChange={(e) => onFilterChange('search', e.target.value)}
                />
            )}

          
            <Form layout="horizontal">
                <div className="form-items-container">
                        <>
                            <Form.Item label="" style={{ marginBottom: '0' }}>
                                <div className="form-item-container">
                                    {showlabel && <div>Subject</div>}
                                    <Select
                                        virtual={false}
                                        suffixIcon={<ArrowIcon />}
                                        value={filterData.subject_id || 'all'}
                                        onChange={handleSubjectChange}
                                    >
                                        <Select.Option value="all">{showlabel ? 'All' : 'Subject'}</Select.Option>
                                        {formattedSubjects?.map((subject: any) => (
                                            <Select.Option key={subject.id} value={subject.id}>
                                                {subject.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </Form.Item>

                            <Form.Item label="" style={{ marginBottom: '0' }}>
                                <div className="form-item-container">
                                    {showlabel && <div>Grade</div>}
                                    <Select
                                        virtual={false}
                                        suffixIcon={<ArrowIcon />}
                                        value={filterData.grade_id || 'all'}
                                        onChange={(value) => onFilterChange('grade_id', value === 'all' ? '' : value)}
                                    >
                                        <Select.Option value="all">{showlabel ? 'All' : 'Grade'}</Select.Option>
                                        {gradeOptions?.map((grade: any) => (
                                            <Select.Option key={grade.id} value={grade.id}>
                                                {grade.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </Form.Item>

                            <Col xs={12} md={3}>
                            <Space className="w-full" direction="vertical" >
                            <Text>Tag</Text>
                                    <Select
                                    style={{marginTop:'8px'}}
                                        placeholder="Tag"
                                        className="w-full"
                                        size="large"
                                        suffixIcon={<ArrowIcon />}
                                        value={filterData.tags || ""}
                                        onChange={handleTagChange}
                                        mode="multiple"
                                        virtual={false}
                                        allowClear
                                        // disabled={!filterData.subject_id || !filterData?.grade_id}
                                        maxTagCount="responsive"
                                        maxTagPlaceholder={(omittedValues) => (
                                            <Tooltip
                                                styles={{ root: { pointerEvents: "none" } }}
                                                title={omittedValues.map(({ label }) => label).join(", ")}
                                            >
                                                <span>Hover Me</span>
                                            </Tooltip>
                                        )}
                                        showSearch // Enable search filtering
                                        filterOption={(input, option) =>
                                          String(option?.displayName).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {tags?.map((tag: any) => (
                                            <Select.Option key={tag.id} value={tag.id} displayName={tag.name}>
                                                {tag.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    </Space>
                           </Col>

                            <Form.Item label="" style={{ marginBottom: '0'}}>
                                <div className="form-item-container">
                                    <div>Creation date</div>
                                    <RangePicker
                                        format="YYYY-MM-DD"
                                        className='overflow-hidden'
                                        placeholder={['From', 'To']}
                                        value={selectedDates}
                                        onChange={(dates, dateStrings) => {
                                            setSelectedDates(dates);
                                            onFilterChange('dateRange', dateStrings);
                                        }}
                                        style={{
                                            width: '100%',
                                            background: 'white',
                                            border: '1px solid #CACACA'
                                        }}
                                        suffixIcon={<CalendarIcon  style={{ marginTop: '7px !important' }}/>}
                                        separator={<SeparatorIcon />}
                                    />
                                    {/*<CustomRangePicker
                                        value={selectedDates}
                                        onChange={(dates, dateStrings) => {
                                            setSelectedDates(dates);
                                            onFilterChange('dateRange', dateStrings);
                                        }}
                                    />*/}
                                </div>
                            </Form.Item>
                        </>
                   
                    <Form.Item style={{ marginTop: 37 }}>
                        <Button
                            style={{ width: 40, height: 46, backgroundColor: '#333333', }}
                            //icon={<Filter />}
                            icon={
                                hasData ? (
                                    <FilterAltOffOutlined
                                        style={{ color: "white" }}
                                        onClick={() => {
                                            onReset();
                                            setSelectedDates(null);
                                        }}
                                    />
                                ) : (
                                    <FilterAltOutlined
                                        style={{ color: "white" }}
                                    />
                                )
                            }
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default AssessmentFilters;