import axios from 'axios';
const headers = {
  Accept: 'application/json'
};
type TagData = {
  name: any;
  description: any;
  used_in: any;
  status: any;
  tags: any[];
  access: number;
};

export const getTagsRequest = async (params = {}) => {
  let data = null;
  let error = null;

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tag/filter`, {
      headers,
      params, 
    });
    data = response.data;
  } catch (err) {
    error = err.response?.data?.error || 'An error occurred';
  }

  return { data, error };
};
export const AddTagsNameRequest = async (tagData: TagData) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;
  console.log('status',status);

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/tagType`, tagData,{headers})
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => (error = err.response.data));

    
  return { data, error, status };
};
export const getTagByIdRequest = async (tagId: any) => {
  let data: any = null;
  let error: any = null;

  // Ensure tagId is a valid string or number
  const resolvedTagId = typeof tagId === 'object' ? tagId.id : tagId;

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tag/${resolvedTagId}`, { headers });
    if (response.data.status) {
      data = response.data.item; // Extract the `item` property from the response
    } else {
      error = response.data.message || 'Failed to fetch tag';
    }
  } catch (err) {
    error = err.response?.data?.message || err.message || 'Something went wrong';
  }

  return { data, error };
};

export const getTagtypeByIdRequest = async (tagTypeId: string) => {
  let data: any = null;
  let error: any = null;

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tagType/${tagTypeId}`, { headers });
    if (response.data.status) {
      data = response.data.item; 
    } else {
      error = response.data.message || 'Failed to fetch tag type';
    }
  } catch (err) {
    error = err.response?.data?.message || err.message || 'Something went wrong';
  }

  return { data, error };
};

