import { Spin, Typography } from "antd";
import { editQuestionRequest } from "api-rest/questions";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MultipleAnswersEdit from "studio/components/QuestionEdit/MCQ/MultipleAnswerEdit.component";
import SingleAnswerEdit from "studio/components/QuestionEdit/MCQ/SingleAnswerEdit.component";
import TrueFalseEdit from "studio/components/QuestionEdit/MCQ/TrueFalseEdit.component";
import {
  DefaultQuestionsData,
  initial_more_options,
} from "studio/constants/defaultQuestionData.constants";
import { MCQTypes } from "studio/constants/questionTypes.constants";
import MainLayout from "studio/layouts/main/Main.layout";
import QuestionEditLayout from "studio/layouts/question/QuestionEdit.layout";

const QuestionEditPage = () => {
  const [originalQuestion, setOriginalQuestion] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();

  const { id } = useParams();

  console.log("origin question =", originalQuestion);

  const getQuestionData = async (id: string) => {
    try {
      const resp = await axios(
        `${process.env.REACT_APP_API_URL}/api/question/${id}`
      );
      const data = resp?.data?.item;
      if (data) {
        setOriginalQuestion(data);
        if (data?.sub_questions && data?.sub_questions?.length) {
          const subQuestion = data?.sub_questions[0];
          if (!subQuestion?.more_options) {
            subQuestion.more_options = initial_more_options;
          }
          setQuestionData(subQuestion);
        }
        setDataLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      // setDataLoading(false)
    }
  };

  useEffect(() => {
    if (id && !questionData) getQuestionData(id);
  }, [id, questionData]);

  const displayQuestion = () => {
    const qtype = questionData?.type;
    const props = { data: questionData, setData: setQuestionData };
    switch (qtype) {
      case MCQTypes.SINGLEANSWER:
        return <SingleAnswerEdit {...props} />;
      case MCQTypes.TRUEFALSE:
        return <TrueFalseEdit {...props} />;
      case MCQTypes.BLOCKLAYOUT:
        return <SingleAnswerEdit {...props} />;
      case MCQTypes.MULTIPLEANSWERS:
        return <MultipleAnswersEdit {...props} />;

      default:
        return (
          <div className="flex flex-col h-full items-center justify-center">
            <Spin size="large"></Spin>
          </div>
        );
    }
  };

  const handleSaveChanges = async () => {
    setDataLoading(true);
    const updatedQuestion = { ...originalQuestion };
    const newSubQuestions = originalQuestion?.sub_questions.map(
      (sub, index) => {
        if (index == 0) {
          return { ...questionData };
        } else return sub;
      }
    );
    updatedQuestion.sub_questions = newSubQuestions;
    if (updatedQuestion?.subject) {
      updatedQuestion.subject_id = updatedQuestion?.subject?.id;
    }
    const { error } = await editQuestionRequest(id, updatedQuestion);
    if (error) {
      toast.error("Failed To Update Question!");
      setDataLoading(false);
      return;
    }
    setDataLoading(false);
    toast.success("Question Updated Successfully!", {
      position: "top-right",
      autoClose: 3000,
    });
    navigate(-1);
  };

  if (dataLoading) {
    return (
      <MainLayout title="">
        <div className="flex flex-col h-full items-center justify-center">
          <Spin fullscreen size="large"></Spin>
        </div>
      </MainLayout>
    );
  }

  if (!dataLoading && !questionData) {
    return (
      <MainLayout title="">
        <div className="flex flex-col h-full items-center justify-center">
          <Spin size="large"></Spin>
        </div>
      </MainLayout>
    );
  }

  return (
    <QuestionEditLayout
      label="Q.1"
      data={questionData}
      setData={setQuestionData}
      questionName={originalQuestion?.name || ""}
      handleSaveChanges={handleSaveChanges}
    >
      <div>{displayQuestion()}</div>
    </QuestionEditLayout>
  );
};

export default QuestionEditPage;
