import axios from "axios";
import { createMathliveButton } from "./mathliveButton";

export const readonlyMathConfig = {
  readonly: true,
  height: 200,
  toolbar: false,
  buttons: [],
};
export const QuestionJoditConfig = {
  readonly: false,
  toolbar: true,
  addNewLine: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "|",
    "superscript",
    "subscript",
    "|",
    "ul",
    "ol",
    "indent",
    "outdent",
    "|",
    "font",
    "fontsize",
    "paragraph",
    "|",
    "table",
    "|",
    "align",
    "undo",
    "redo",
    "|",
    "hr",
    "|",
    {
      name: "uploadImage",
      iconURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy_ZxV2ImKF9V0GRXWBfOTuY76TfKqoDa-O4zb7_lUrINJGSLY&s", // URL for your custom icon
      exec: (editor) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.click();
        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            console.log(file);
            handleFileUpload(file, editor, "image");
          }
        };
      },
    },
    {
      name: "uploadAudio",
      iconURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB19euuLHwG_8JZDad5i0txPbNZfqkHdfBJLb3F9SiEfQWXgCa&s", // URL for your custom icon
      exec: (editor) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "audio/*";
        input.click();
        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            console.log(file);
            handleFileUpload(file, editor, "audio");
          }
        };
      },
    },
    "|",
    {
      name: "math",
      iconURL: "https://img.icons8.com/ios/50/000000/sigma.png",
      exec: (editor: any) => {
        const button = createMathliveButton(editor);
        button.exec();
      },
    },
    "|",
    "source",
  ],
  /* events:{
        afterInit: (instance) => {
           // instance.events.on('click',handleSpanClick(instance));
           addEventListeners(instance);
            // This can be left empty because we'll handle setting the instance in the main component
          }
    }*/
  // Add other configuration options here
};

/*export const joditConfig = (includeResponseButton = false) => {

    const buttons = [
        'bold', 'italic', 'underline', '|',
        createMathliveButton(), // Include the Mathlive button
        'ul', 'ol', '|', 'left', 'center', 'right', 'justify', '|', 'link', '|', 'source'
      ];
    
      if (includeResponseButton) {
        buttons.push(responseOption()); // Conditionally add the optional button
      }

    return {
        readonly: false,
        height: 400,
        toolbar: true,
        buttons,
      };
}*/

const handleFileUpload = async (file, editor, type) => {
  // Parse localStorage safely
  const localToken = localStorage.getItem("token");
  const rootStorage = localStorage.getItem("persist:root");
  let tokenUser: string | undefined;

  if (rootStorage) {
    try {
      const parsedRootStorage = JSON.parse(rootStorage);
      const assessmentStorage = JSON.parse(
        parsedRootStorage?.assessments || "{}"
      );
      tokenUser = assessmentStorage?.config?.tokenUser;
    } catch (error) {
      console.error("Error parsing localStorage data:", error);
    }
  }

  // Determine upload URL and headers
  const defaultApiUrl = process.env.REACT_APP_API_URL || "";
  const obeAppApiUrl = process.env.REACT_APP_OBE_APP_API_URL || "";
  const uploadUrl =
    localToken || !tokenUser
      ? `${defaultApiUrl}/api/formupload`
      : `${obeAppApiUrl}/formupload`;
  const uploadAudioUrl =
    localToken || !tokenUser
      ? `${defaultApiUrl}/api/fileupload`
      : `${obeAppApiUrl}/fileupload`;

  const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    ...(tokenUser && !localToken
      ? { Authorization: `Bearer ${tokenUser}` }
      : { Authorization: `Bearer ${localToken}` }),
  };

  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    console.log(formData);

    let uploadURL;
    if (type === "image") {
      uploadURL = uploadUrl;
    } else if (type === "audio") {
      uploadURL = uploadAudioUrl;
    }

    const response = await axios.post(uploadURL, formData, { headers });
    console.log(response);

    if (response.status) {
      if (type === "image") {
        const image = editor.selection.j.createInside.element("img");
        image.setAttribute("src", response.data.item.src);
        editor.s.insertNode(image);

        //editor.s.insertHTML(`<img src="${response.data.item.src}" alt="Uploaded Image"/>`);
      } else if (type === "audio") {
        const audioElement = `<audio controls><source src="${response.data.item.src}" type="${file.type}"></audio>`;
        editor.selection.insertHTML(audioElement);
      }
    }
  } catch (err) {
    console.log(err);
  }
};

/* Currently not using this code */
/*
const addEventListeners = (editorInstance) => {
const handleSpanClick = (event) => {
    const spanNode = event.target;
    if (spanNode.nodeName === 'SPAN') {
      selectSpan(spanNode,editorInstance);
    }
  };

  if (editorInstance) {
    console.log('Editor instance:', editorInstance);
    editorInstance.events.on('click', handleSpanClick);

    // Clean up event listener when the editor is destroyed
    editorInstance.events.on('beforeDestruct', () => {
      editorInstance.events.off('click', handleSpanClick);
    });
  }
};
*/
/* const selectSpan = (spanNode,editorInstance) => {
    clearSelection(editorInstance);
    spanNode.style.border = '1px dashed blue'; // Highlight the span
    spanNode.setAttribute('data-selected', 'true');
    setNonEditableSelection(spanNode,editorInstance);
  };*/
/*
  const setNonEditableSelection = (spanNode,editorInstance) => {
    if (editorInstance) {
      const range = editorInstance.s.createRange();
      range.selectNode(spanNode);
      editorInstance.s.removeAllRanges();
      editorInstance.s.addRange(range);

      // Prevent keyboard cursor
      editorInstance.container.focus();
      editorInstance.setEditorValue(editorInstance.getEditorValue());
    }
  };

  const clearSelection = (editorInstance) => {
    if (editorInstance) {
      const selectedSpans = editorInstance.editor.querySelectorAll('span[data-selected="true"]');
      selectedSpans.forEach(span => {
        span.style.border = '';
        span.removeAttribute('data-selected');
      });
    }
  };*/
/* Currently not using this code */
