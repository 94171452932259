import { useEffect, useState } from "react";
import ButtonGroup from "./ButtonGroup.component";
import FilterQuestions from "./FilterQuestions.component";
import QuestionsTable from "./QuestionsTable.component";
import SearchInput from "../ui/SearchInput.component";
import { Sizes } from "studio/theme/Sizes.theme";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { RootState } from "redux/store";
import { getQuestionsListing } from "services/assessment";
import "../AssessmentLibrary/styles.scss";
import { useSelector } from "react-redux";
import { message } from "antd";
import axios from "axios";
import { duplicateQuestions } from "services/question";

// start_date type date
// end_date type date
// tags array of tag id
// tag change it to multi select

const initialFilterData = {
  name: "",
  subject_id: "",
  grade_id: "",
  tags: [],
  start_date: "",
  end_date: "",
  // status: 2,
};

const initialPaginationData = {
  page: 1,
  perPage: 10,
  sort_by: "",
  order: "",
};

const QuestionBankBody = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("");
  const handleSort = (field: string, order: 'ascend' | 'descend' | null) => {
    console.log('fieldfield',field,order);
    
    // Keep the current page when sorting
    setSortBy(field);
    setOrder(order === 'ascend' ? 'asc' : order === 'descend' ? 'desc' : '');
  };

  const success = (content: string) => {
    message.destroy();
    message.success({
      content,
      duration: 5,
    });
  };

  const onError = (content: string) => {
    message.error({
      content,
      duration: 3,
    });
  };
  const handleDelete = async () => {
    const ids =selectedRows.map((row) => row.id);
    let api = "api/question";
    let multiple = ids.length > 1;
    const typeDeleted = "questions";
    let res;
    try {
      if (multiple) {
        res = await axios.post(
            `${process.env.REACT_APP_API_URL}/${api}/multidelete`,
            { [typeDeleted]: ids }
        );
      } else {
        res = await axios.delete(
            `${process.env.REACT_APP_API_URL}/${api}/${ids}`
        );
      }
      if (res?.status == 200) {
        setClearSelection(true);
        success(`Delete Assessment successfully!`);
      } else {
        setClearSelection(true);
        onError(`Delete Assessment failed!`);
      }
    } catch (err) {
      console.error(err);
      onError(`Delete Assessment failed!`);
    }
    fetchData(filterData, paginationData);
    setSelectedRows([]);
  };
  const handleBtnClick =  async (action: string) => {
    console.log("action =", action);
    if(action==='delete'){
      handleDelete()
    }else if(action==='duplicate'){
      const ids = selectedRows.map((row) => row.id);
    await dispatch(duplicateQuestions(ids) as any);
    setClearSelection(true);
    fetchData(filterData, paginationData);
    setSelectedRows([]);
    }
  };

  const { questions, pagination } = useSelector(
    (state: RootState) => state.assessments
  );

  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [clearSelection, setClearSelection] = useState(false);
  const [filterData, setFilterData] = useState(initialFilterData);
  const [paginationData, setPaginationData] = useState(initialPaginationData);

  const fetchData = async (filterObj, paginationObj) => {
    setLoading(true);
    const { page, perPage, ...other } = paginationObj;
    await dispatch(
      getQuestionsListing(page, perPage, { ...filterObj, ...other,
        sort_by: sortBy,
        order: order,
        search:filterObj?.name }) as any
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchData(filterData, paginationData);
  }, [paginationData,filterData,sortBy, order,]);

  const onPaginationChange = (field: string, value: string | number) => {
    setPaginationData((previous) => ({
      ...previous,
      [field]: value,
    }));
  };

  const onFilterChange = async (field: string, value: string) => {
    if (field === "") {
      setFilterData(initialFilterData);
    } else {
      if (field == "subject_id") {
        setFilterData((prevState) => ({
          ...prevState,
          subject_id: value,
          grade_id: "",
        }));
      } else {
        setFilterData((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      }
    }
  };

  const handleRowSelected = (keys: React.Key[], rows: any) => {
    setSelectedRows(rows);
    setClearSelection(false);
  };

  return (
    <div className="assessment-library-page">
      <div
        className="w-1/3"
        style={{
          marginBottom: Sizes.base,
          maxWidth: Sizes.maxScreenWidth / 3,
        }}
      >
        <SearchInput
          value={filterData.name}
          onChange={(val: string) => {
            onFilterChange("name", val);
          }}
          style={{ minWidth: "240px" }}
        />
      </div>
      <div
        className="w-full"
        style={{ marginBottom: Sizes.base, maxWidth: Sizes.maxScreenWidth }}
      >
        <ButtonGroup onClick={handleBtnClick} disabled={selectedRows?.length === 0}
        />
      </div>

      <div className="w-full" style={{ marginBottom: Sizes.base }}>
        <FilterQuestions
          handleChange={onFilterChange}
          filterData={filterData}
          getTableData={(newFilterData) => {
            fetchData(newFilterData, paginationData);
          }}
          onReset={() => {
            setFilterData(initialFilterData);
            setSortBy("");
            setOrder("");
            fetchData(initialFilterData, paginationData);

          }}
        />
      </div>

      <div className="">
        <QuestionsTable
          data={questions}
          loading={loading}
          onSort={handleSort}

          onPageChange={(page: number) => {
            onPaginationChange("page", page);
          }}
          onPerPageChange={(perPage: number) => {
            onPaginationChange("perPage", perPage);
          }}
          pagination={pagination}
          selectedRows={selectedRows}
          onRowSelected={handleRowSelected}
          clearSelection={clearSelection}
        />
      </div>
    </div>
  );
};

export default QuestionBankBody;
