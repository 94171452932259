import React, { useState } from "react";
import FieldsMenu from "./FieldsMenu";
import { Input } from "antd";
import Search from "../../icons/Search";

const FieldsMenuWrapper = ({ menuData = [], onItemSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
 

  const filteredMenuData = menuData
    .map((menuGroup) => {
      const filteredItems = menuGroup.items.filter((item) =>
        item.text.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return {
        ...menuGroup,
        items: filteredItems,
      };
    })
    .filter((menuGroup) => menuGroup.items.length > 0);

  return (
    <div className="fields-menu-wrapper">
      <Input
        prefix={<Search style={{ marginRight: "10px" }} />}
        placeholder="Search questions"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="small"
        className="py-2 px-3 mb-4"
      />

        {filteredMenuData.length > 0 ? (
          filteredMenuData.map((menuGroup, index) => (
            <div key={`menu-group-${index}`} className="mb-2">
              <FieldsMenu
                key={menuGroup.title}
                title={menuGroup.title}
                items={menuGroup.items}
                onItemSelect={onItemSelect}
              />
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 py-4">
            No items found matching "{searchTerm}"
          </div>
        )}
    </div>
  );
};

export default FieldsMenuWrapper;