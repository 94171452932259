import React, { useState } from 'react';
import { QuestionContent } from './styled';

import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import QuestiontypeModal from 'components/QuestiontypeModal';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

export default function PreviewEasyImageMath({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const editorStyle = {
    height: '150px', // Set the desired height here
  };
  const [editorValue, setEditorValue] = useState('');
  const [toolbar, setTolbar] = useState(
    currentQuestion.correct_answer.formatting_options
  );
  const maxWords = currentQuestion.correct_answer.max_length; // Set your desired word limit here

  const [localShowAnswer, setLocalShowAnswer] = useState(false);

  const handleEditorChange = (value) => {
    if (value && value.length > maxWords) {
      // Limit the number of words
      const words = value.split(/\s+/).slice(0, maxWords);
      setEditorValue(words.join(' '));
    } else {
      setEditorValue(value);
    }
  };

  const handleEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <QuestionContent parentMode={parentMode === 'question'}  style={currentQuestion.more_options && currentQuestion.more_options?.layout.fontSize ? 
      {fontSize: currentQuestion.more_options.layout.fontSize}: 
      {fontSize: 'medium'}
    }>
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
          <div className="bg-light-accent flex p-5 gap-3 rounded-lg">
            <p className="font-bold text-sm">Question :&nbsp;</p>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${currentQuestion?.question}`,
              }}
            />
          </div>
        </div>

        <div className="flex gap-4">
          {editMode ? (
            <>
              <Button
                margin
                title={'Go to edit'}
                onClick={() => handleEditClick()}
              />

              <Button
                margin
                title={'Delete'}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (setShowAnswers) {
                    setShowAnswers(!showAnswer);
                  } else {
                    setLocalShowAnswer(!localShowAnswer);
                  }
                }}
              >
                {showAnswer || localShowAnswer ? 'Hide Answer' : 'Show Answer'}
              </Button>
            </>
          )}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #D9D9D9',
          padding: '24px',
          marginBottom: '10px',
          backgroundColor: '#f0f0f0',
        }}
      >
        <div className="w-full">
          <ReactQuill
            style={editorStyle} // Apply the custom style here
            // readOnly={true}
            value={editorValue}
            onChange={handleEditorChange}
            modules={{
              toolbar: toolbar,
            }}
          />
          <p>Words used: {editorValue.trim().split(/\s+/).length}</p>
          <p>
            Words remaining: {maxWords - editorValue.trim().split(/\s+/).length}
          </p>
        </div>
      </div>

      {isModalOpen && (
        <QuestiontypeModal
          setIsModalOpen={setIsModalOpen}
          id={currentQuestion?.id}
          isModalOpen={isModalOpen}
        />
      )}
    </QuestionContent>
  );
}
