import React from 'react';
import { TableCell } from './TableCell';
import { FontSize } from './types';
import { GriddedBase } from './GriddedBase';

interface PlusMinusGriddedProps {
    columns: number;
    range: { min: number; max: number };
    fontSize: FontSize;
    onChange: (column: number, value: string) => void;
    disabled?: boolean;
    selectedValues?: string[];
    hasPlusMinus?: boolean;
    hasFixedDecimal?: boolean;
    hasFractionSlash?: boolean;
    hasFloatingDecimal?: boolean;
    decimalColumn?: number | string | null;
}

export const PlusMinusGridded: React.FC<PlusMinusGriddedProps> = ({
                                                                      columns,
                                                                      range,
                                                                      fontSize,
                                                                      onChange,
                                                                      disabled,
                                                                      selectedValues = [],
                                                                      hasPlusMinus,
                                                                      hasFixedDecimal = false,
                                                                      hasFractionSlash = false,
                                                                      hasFloatingDecimal = false,
                                                                      decimalColumn = null,
                                                                  }) => {
    const length = range.max > range.min
        ? range.max - range.min + 1
        : range.min - range.max + 1;

    const numbers = Array.from(
        { length },
        (_, i) => `${Math.min(range.min, range.max) + i}`
    );

    const parsedDecimalColumn = decimalColumn !== null ? parseInt(decimalColumn.toString()) : null;
    const actualColumns = hasFixedDecimal || !( hasPlusMinus && hasFixedDecimal ) ? columns : columns - 1;

    const handleCellChange = (colIndex: number, value: string) => {
        const newValues = [...selectedValues];
        const updates: Array<[number, string]> = [];

        if (value === '.' || value === '/') {
            const symbolType = value;

            newValues.forEach((val, i) => {
                if (val === symbolType) {
                    updates.push([i, '']); // Track cleared cells
                    newValues[i] = '';
                }
            });

            const isValid =
                (symbolType === '.' && !(hasFixedDecimal && parsedDecimalColumn === colIndex)) ||
                (symbolType === '/' && hasFractionSlash);

            if (isValid) {
                if (newValues[colIndex] === symbolType) {
                    updates.push([colIndex, '']);
                    newValues[colIndex] = '';
                } else {
                    updates.push([colIndex, symbolType]);
                    newValues[colIndex] = symbolType;
                }
            }
        } else {
            updates.push([colIndex, value]);
            newValues[colIndex] = value;
        }

        updates.forEach(([index, val]) => onChange(index, val));

        console.log(updates)
    };

    const renderCell = (value: string, colIndex: number, rowIndex: number, isSpecial: boolean = false) => {
        // Handle fixed decimal column
        if (hasFixedDecimal && parsedDecimalColumn === colIndex) {
            return (
                <td
                    key={`decimal-${colIndex}`}
                    className="w-[60px] text-center bg-gray-50 border border-gray-200 font-bold"
                >
                    .
                </td>
            );
        }

        return (
            <TableCell
                key={`cell-${colIndex}`}
                value={value}
                columnIndex={colIndex}
                rowIndex={rowIndex}
                fontSize={fontSize}
                disabled={disabled || (isSpecial && value === '')}
                onChange={(value) => handleCellChange(colIndex, value)}
                selectedValue={selectedValues[colIndex]}
                isSpecial={isSpecial}
            />
        );
    };

    const getPlusRowValue = (colIndex: number): string => {
        if (hasFractionSlash) {
            if (colIndex === 0 || colIndex === actualColumns - 1) return '';
            return '/';
        }
        if (hasFloatingDecimal) {
            return '.';
        }
        return numbers[0];
    };

    const getMinusRowValue = (colIndex: number): string => {
        if (hasFractionSlash) {
            if (hasFloatingDecimal) {
                return '.';
            }
            return numbers[0];
        }
        if (hasFloatingDecimal) {
            return numbers[0];
        }
        return numbers[1];
    };

    const getFilteredNumbers = () => {
        if (hasFractionSlash && hasPlusMinus && hasFloatingDecimal) {
            return numbers;
        }
        if (hasFractionSlash && hasPlusMinus) {
            return numbers.slice(1);
        }
        if (hasPlusMinus) {
            return numbers.slice(2);
        }
        return numbers;
    };

    const renderRow = (cells: JSX.Element[]) => (
        <tr>
            {cells}
            {hasFixedDecimal && parsedDecimalColumn !== null && parsedDecimalColumn < actualColumns && (
                <td
                    key="decimal-point"
                    className="w-[60px] text-center bg-gray-50 border border-gray-200 font-bold"
                >
                    .
                </td>
            )}
        </tr>
    );

    console.log('frommmmmmmmmm plus minus table')

    return (
        <GriddedBase>
            {/* Plus row */}
            <tr>
                {renderCell('+', 0, 0, true)}
                {Array.from({ length: actualColumns }).map((_, colIndex) => (
                    <React.Fragment key={colIndex}>
                        {renderCell(getPlusRowValue(colIndex), colIndex + 1, 0, hasFractionSlash)}
                    </React.Fragment>
                ))}
            </tr>

            {/* Minus row */}
            <tr>
                {renderCell('-', 0, 1, true)}
                {Array.from({ length: actualColumns }).map((_, colIndex) => (
                    <React.Fragment key={colIndex}>
                        {renderCell(getMinusRowValue(colIndex), colIndex + 1, 1)}
                    </React.Fragment>
                ))}
            </tr>

            {/* Floating decimal row (only for non-fraction slash case) */}
            {hasFloatingDecimal && !hasFractionSlash && (
                <tr>
                    {renderCell('', 0, 2, true)}
                    {Array.from({ length: actualColumns }).map((_, colIndex) => (
                        <React.Fragment key={colIndex}>
                            {renderCell(numbers[1], colIndex + 1, 2, false)}
                        </React.Fragment>
                    ))}
                </tr>
            )}

            {/* Number rows */}
            {getFilteredNumbers().map((num, rowIndex) => {
                const currentRowIndex = rowIndex + 2 + (hasFloatingDecimal && !hasFractionSlash ? 1 : 0);
                return (
                    <tr key={rowIndex}>
                        {renderCell('', 0, currentRowIndex, true)}
                        {Array.from({ length: actualColumns }).map((_, colIndex) => (
                            <React.Fragment key={colIndex}>
                                {renderCell(num, colIndex + 1, currentRowIndex)}
                            </React.Fragment>
                        ))}
                    </tr>
                );
            })}
        </GriddedBase>
    );
};
