import React from 'react';
interface FromExisting {
    style?: React.CSSProperties; // Optional style prop
}
const FromExisting = ({ style = {}}) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="publish_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24" d="M203.456-771.92v19.408l-5.649-5.649-2.267,2.291,9.539,9.539,9.551-9.539-2.267-2.291-5.649,5.649V-771.92ZM188.08-748.088v6.2a3.831,3.831,0,0,0,1.159,2.812,3.831,3.831,0,0,0,2.813,1.159h26.056a3.832,3.832,0,0,0,2.813-1.159,3.831,3.831,0,0,0,1.159-2.812v-6.2h-3.259v6.193a.686.686,0,0,1-.224.493.686.686,0,0,1-.493.224H192.056a.686.686,0,0,1-.493-.224.686.686,0,0,1-.224-.493v-6.193Z" transform="translate(-188.08 771.92)" fill="#333"/>
      </svg>
    );
};

export default FromExisting;
