interface IconProps {
    fill?: string;
    className?: string;
  }
const Settings = ({ fill = "currentColor", className = "" }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.10066 20.0001V13.7814H10.7265V16.0735H20V17.6993H10.7265V20.0001H9.10066ZM0 17.6993V16.0735H6.31241V17.6993H0ZM4.68658 13.1066V10.8204H0V9.19452H4.68658V6.89349H6.31241V13.1066H4.68658ZM9.10066 10.8204V9.19452H20V10.8204H9.10066ZM13.6876 6.21857V6.10352e-05H15.3134V2.30109H20V3.92688H15.3134V6.21857H13.6876ZM0 3.92688V2.30109H10.8996V3.92688H0Z"
      fill={fill} className={className}
    />
  </svg>
);
export default Settings;
