import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import CanvasPreview from "../../../components/ComposeOtherQuestion/ImageAnnotation/CanvasPreview";
import { getLabelByStemNumeration, getResponseFontSize } from 'utils/generalUtils';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

interface PreviewImageAnnotationProps {
    currentQuestion: {
        id: string;
        type: string;
        // Add other question properties as needed
        more_options:any;
        correct_answer:any;

    } | null;
    showAnswer?: boolean;
    setShowAnswers?: (show: boolean) => void;
    parentMode?: 'assessment' | 'edit';
    editMode?: boolean;
}

const PreviewImageAnnotation: React.FC<PreviewImageAnnotationProps> = ({
                                                                           currentQuestion,
                                                                           showAnswer,
                                                                           setShowAnswers,
                                                                           parentMode = 'assessment',
                                                                           editMode = false,
                                                                       }) => {
    const [localShowAnswer, setLocalShowAnswer] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Memoize handlers to prevent unnecessary re-renders
    const handleBackToEditClick = useMemo(() => {
        return () => {
            dispatch(setClosePreview());
        };
    }, [dispatch]);

    const handleGoToEditClick = useMemo(() => {
        return (e: React.MouseEvent) => {
            e.preventDefault();
            if (currentQuestion) {
              dispatch(EditSubQues(currentQuestion));
                dispatch(
                    setTypeQuestionOpenInEditMode({
                        type: currentQuestion.type,
                        id: currentQuestion.id,
                    })
                );
                navigate(`/edit-subquestion/${currentQuestion.id}`);
            }
        };
    }, [dispatch, navigate, currentQuestion]);

    // Use controlled show answer state
    const effectiveShowAnswer = showAnswer ?? localShowAnswer;
    const effectiveSetShowAnswer = setShowAnswers ?? setLocalShowAnswer;

    // Only render if we have a valid question
    if (!currentQuestion) {
        return null;
    }

    return (
        <PreviewWrapper
            currentQuestion={currentQuestion}
            showAnswer={effectiveShowAnswer}
            setShowAnswers={effectiveSetShowAnswer}
            parentMode={parentMode}
            editMode={editMode}
            handleGoToEdit={handleGoToEditClick}
            handleBackToEdit={handleBackToEditClick}
        >
            <CanvasPreview
                key={`${currentQuestion.id}-${effectiveShowAnswer}`}
                showAnswer={effectiveShowAnswer}
                currentQuestion={currentQuestion}
                editMode={editMode}
                parentMode={parentMode}
            />
            {(showAnswer) && (
        <ul className="w-full flex flex-col p-2">
          <p 
            className="font-semibold"
            style={{fontSize: getResponseFontSize(currentQuestion.more_options)}}  
          >
            Answers: 
          </p>

          {Array.isArray(currentQuestion?.correct_answer?.valid_response?.value) &&
            currentQuestion?.correct_answer?.valid_response?.value.map((vr, index) => (
              <li 
                className="mt-4" 
                key={index}
                style={{fontSize: getResponseFontSize(currentQuestion?.more_options)}}  
              >
                <span className="px-2 bg-slate-500 text-white">
                  {getLabelByStemNumeration(currentQuestion?.more_options, index)}
                </span>{' '}
                {/* @ts-expect-error */}
                <math-field readonly={true}>
                  {vr?.label || ''}
                {/* @ts-expect-error */}
                </math-field>
              </li>
            ))}
        </ul>
      )}
        </PreviewWrapper>
    );
};

// Prevent unnecessary re-renders
export default React.memo(PreviewImageAnnotation);
