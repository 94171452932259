import PageTitle from "studio/components/general/PageTitle.component";
import QuestionBankBody from "studio/components/QuestionBank/QuestionBankBody.component";
import MainLayout from "studio/layouts/main/Main.layout";
import { Sizes } from "studio/theme/Sizes.theme";

const QuestionsBankPage = () => {
  return (
    <MainLayout title="Questions Bank">
      <div className="flex" style={{ padding: Sizes.padding }}>
        <div
          style={{
            maxWidth: Sizes.maxScreenWidth,
            display: "block",
            width: "fit-content",
            flex: 1,
           margin: "10px 25px -10px 25px"
          }}
        >
          <PageTitle title="Questions Bank" />
          <QuestionBankBody />
        </div>
      </div>
    </MainLayout>
  );
};

export default QuestionsBankPage;
