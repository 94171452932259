import React, { useState, useEffect } from 'react';
import { Group, Rect, Text, Transformer, Circle, Image as KonvaImage } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';

interface ResponseBoxProps {
    response: {
        id: string;
        position: { x: number; y: number };
        width: number;
        height: number;
        answer: string;
    };
    index: number;
    isSelected: boolean;
    isCorrect: boolean | null;
    MoreOptions: any | null;
    onDragMove: (index: number, e: any) => void;
    onClick: (index: number, e: any) => void;
    onUpdateResponse: (index: number, answer: string) => void;
    onDelete: (index: number) => void;
    onDeselect: () => void;
    disabled?: boolean;
}

const ResponseBox: React.FC<ResponseBoxProps> = ({
                                                     response,
                                                     index,
                                                     isSelected,
                                                     isCorrect,
                                                     MoreOptions,
                                                     onDragMove,
                                                     onClick,
                                                     onUpdateResponse,
                                                     onDelete,
                                                     onDeselect,
                                                     disabled = false,
                                                 }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(response.answer);
    const [icon, setIcon] = useState<HTMLImageElement | null>(null);

    const groupRef = React.useRef<Konva.Group>(null);
    const textRef = React.useRef<Konva.Text>(null);
    const transformerRef = React.useRef<Konva.Transformer>(null);
    const chevronDoubleUpSvgString = `
        <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
        style="vertical-align: middle; height: 1em; width: 1em;color: rgb(255, 255, 255)"
        >
        <path d="M16.244 16.244L12 12l-4.244 4.244-1.414-1.414L12 9.172l5.658 5.658-1.414 1.414zM16.244 9.828L12 5.586l-4.244 4.242-1.414-1.414L12 2.757l5.658 5.657-1.414 1.414z" />
        </svg>
    `;
    const [svgImage] = useImage('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(chevronDoubleUpSvgString));

    useEffect(() => {
        if (isSelected && transformerRef.current && groupRef.current) {
            transformerRef.current.nodes([groupRef.current]);
            transformerRef.current.getLayer()?.batchDraw();
        }
    }, [isSelected]);

    useEffect(() => {
        setText(response.answer);
    }, [response.answer]);

    useEffect(() => {
        const img = new window.Image();
        img.src = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcrH8Clpvjqh0PD5xPpq9DYYAXWzSCbiJSAQ&s';
        img.onload = () => {
            setIcon(img);
        };
    }, []);

    const handleTextDblClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (disabled) return;

        const textNode = textRef.current;
        const group = groupRef.current;
        if (!textNode || !group) return;

        setIsEditing(true);
        textNode.hide();

        const stage = group.getStage();
        if (!stage) return;

        const stageBox = stage.container().getBoundingClientRect();
        const groupPos = group.getAbsolutePosition();

        const areaPosition = {
            x: stageBox.left + groupPos.x,
            y: stageBox.top + groupPos.y,
        };

        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);

        textarea.value = text || '';
        textarea.style.position = 'absolute';
        textarea.style.top = `${areaPosition.y}px`;
        textarea.style.left = `${areaPosition.x}px`;
        textarea.style.width = `${response.width}px`;
        textarea.style.height = `${response.height}px`;
        textarea.style.fontSize = '16px';
        textarea.style.border = '2px solid #4299e1';
        textarea.style.padding = '8px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'white';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = '1.2';
        textarea.style.fontFamily = 'Arial';
        textarea.style.zIndex = '1000';
        textarea.style.borderRadius = '4px';
        textarea.style.boxSizing = 'border-box';

        textarea.focus();

        const handleOutsideClick = (e: MouseEvent) => {
            if (e.target !== textarea) {
                removeTextarea();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        textarea.addEventListener('keydown', function (e) {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                finishEditing();
            }
            if (e.key === 'Escape') {
                removeTextarea();
            }
        });

        textarea.addEventListener('blur', finishEditing);

        function finishEditing() {
            const newText = textarea.value;
            setText(newText);
            onUpdateResponse(index, newText);
            removeTextarea();
            onDeselect();
        }

        function removeTextarea() {
            document.removeEventListener('mousedown', handleOutsideClick);

            if (textarea.parentNode) {
                textarea.remove();
            }

            setIsEditing(false);
            textNode.show();
            textRef.current?.getLayer()?.batchDraw();
        }
    };

    const getBorderColor = () => {
        if (isSelected) return "#4299e1";
        if (isCorrect === null) return "#ddd";
        return isCorrect ? "#68D391" : "#FC8181";
    };

    return (
        <>
            <Group
                ref={groupRef}
                x={response.position.x}
                y={response.position.y}
                draggable={!disabled}
                onDragEnd={(e) => {
                    if (!disabled) {
                        onDragMove(index, e);
                        onDeselect();
                    }
                }}
                onClick={(e) => {
                    if (!disabled) {
                        onClick(index, e);
                    }
                }}
            >
                <Circle
                    x={-15}
                    y={response.height / 2}
                    radius={12}
                    fill={disabled ? "#a0aec0" : "#4299e1"}
                    stroke={disabled ? "#718096" : "#2b6cb0"}
                    strokeWidth={1}
                />
                {svgImage && (
                    <KonvaImage
                        image={svgImage}
                        x={-23}
                        y={response.height / 2 - 7}
                        width={16}
                        height={16}
                        opacity={disabled ? 0.6 : 1}
                    />
                )}

                <Rect
                    width={response.width}
                    height={response.height}
                    fill="white"
                    stroke={getBorderColor()}
                    strokeWidth={2}
                    cornerRadius={4}
                    shadowColor="rgba(0,0,0,0.2)"
                    shadowBlur={4}
                    shadowOffset={{ x: 0, y: 2 }}
                    shadowOpacity={0.5}
                    opacity={disabled ? 0.7 : 1}
                />
                <Text
                    ref={textRef}
                    text={text || 'Double-click to edit'}
                    width={response.width}
                    height={response.height}
                    align="center"
                    verticalAlign="middle"
                    fontSize={16}
                    fill={text ? (disabled ? "#666" : "#000") : "#999"}
                    padding={8}
                    onDblClick={handleTextDblClick}
                />

                {isSelected && !disabled && (
                    <Group x={response.width + 5} y={0}>
                        <Circle
                            radius={12}
                            fill="#f56565"
                            onClick={() => onDelete(index)}
                        />
                        <Text
                            text="×"
                            fontSize={20}
                            fill="white"
                            x={-6}
                            y={-10}
                            onClick={() => onDelete(index)}
                        />
                    </Group>
                )}
            </Group>
            {isSelected && !disabled && <Transformer ref={transformerRef} />}
        </>
    );
};

export default ResponseBox;
