import EditableOption from "./components/editableOption.component";
import { useRef, useState } from "react";
import { Typography } from "antd";
import { Sizes } from "studio/theme/Sizes.theme";
import CorrectAnswerWrapper from "./components/correctAnswerSection.component";
import SingleAnswerTab from "./components/singleAnswerTab.component";

const { Text } = Typography;
export interface IQuestionEditProps {
  data: any;
  setData: (newData: any) => void;
}

const TrueFalseEdit = ({ data, setData }: IQuestionEditProps) => {
  const { options, correct_answer, more_options } = data;
  const [currentOptionId, setCurrentOptionId] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { valid_response, alt_responses } = correct_answer;
  const altResponses = alt_responses || [];

  const handleRadioCheck = (itemId: string) => {
    const selectedItem = options.find((op) => op.id == itemId);
    if (selectedItem) {
      setData({
        ...data,
        correct_answer: {
          valid_response:
            activeTabIndex == 0
              ? {
                  value: [{ ...selectedItem, checked: true }],
                  score: valid_response.score,
                }
              : valid_response,
          alt_responses:
            activeTabIndex > 0
              ? alt_responses.map((altRes, index) => {
                  return index == activeTabIndex - 1
                    ? { ...altRes, value: [{ ...selectedItem, checked: true }] }
                    : altRes;
                })
              : alt_responses,
        },
      });
    }
  };

  const activeTab =
    activeTabIndex == 0 || altResponses.length == 0
      ? valid_response
      : altResponses[activeTabIndex - 1];

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = () => {
    let newOptions = [...options];
    const draggedItemContent = newOptions.splice(dragItem.current, 1)[0];
    newOptions.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setData({ ...data, options: newOptions });
  };

  const handleOptionChange = (e, index) => {
    const updatedItems = [...options];
    updatedItems[index] = {
      id: updatedItems[index].id,
      label: e,
      checked: false,
    };

    const updatedDataOptions = updatedItems.map((item) => ({
      id: item.id,
      label: item.label,
      checked: item.checked,
    }));

    let newAltResponses = correct_answer.alt_responses;
    if (newAltResponses.length > 0) {
      newAltResponses = newAltResponses.map((ar) => ({
        ...ar,
        value: [],
      }));
    }

    setData({
      ...data,
      options: updatedDataOptions,
      correct_answer: {
        valid_response: {
          ...correct_answer.valid_response,
          value: [],
        },
        alt_responses: newAltResponses,
      },
    });
  };

  return (
    <div>
      <Text>ANSWERS</Text>
      <div style={{ marginTop: Sizes.base, marginBottom: Sizes.base }}>
        {options?.map((item, index) => (
          <div key={item.id} className="custom_jodit--wrapper">
            <EditableOption
              index={index}
              dragItem={dragItem}
              dragOverItem={dragOverItem}
              onDragEnd={handleSort}
              onFocus={(e) => {
                setCurrentOptionId(item.id);
              }}
              onBlur={(e) => {
                setCurrentOptionId("");
              }}
              isActive={item.id === currentOptionId}
              label={item?.label}
              onOptionChange={handleOptionChange}
            />
          </div>
        ))}
      </div>

      <CorrectAnswerWrapper
        data={data}
        setData={setData}
        activeTab={activeTab}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      >
        <SingleAnswerTab
          more_options={more_options}
          options={options}
          handleRadioCheck={handleRadioCheck}
          activeTab={activeTab}
        />
      </CorrectAnswerWrapper>
    </div>
  );
};

export default TrueFalseEdit;
