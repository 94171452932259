import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import ResizableDivContainer from '../../AuthorQuestion/FillBlanks/SubChildren/ImageWithDropDown/ResizableDivContainer';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import BackgroundImageContainerWithAltText from 'obsService/components/PreviewStudent/PreviewImageDragDrop/backgroundImageWithAltText.component';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';
const PreviewImageDropDown = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const [localAnswers, setLocalAnswers] = useState([]);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleResProperty = (moreOptions, index, propName, defaultValue = "") => {
    let val = defaultValue;
  
    if (moreOptions?.response_options?.[propName]) {
      val = moreOptions.response_options[propName];
    }
    else if (moreOptions?.layout?.[propName]) {
      val = moreOptions.layout[propName];
    }
  
    const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
    if (resOptionsIndividaul.length > 0 && index > -1) {
      const currentResOptions = resOptionsIndividaul[index];
      if (currentResOptions?.[propName]) {
        val = currentResOptions[propName]; 
      }
    }
  
    return val;
  };

  const getOptionId = (id: string) => {
    let result = ""
    if(showAnswer || editMode) {
        if(id && currentQuestion?.correct_answer && Array.isArray(currentQuestion?.correct_answer?.valid_response?.value)) {
            const foundSolution = currentQuestion?.correct_answer?.valid_response?.value.find(item => item?.id == id);
            if(foundSolution) result = foundSolution?.selectedOptionId;
        }
    }else {
        const foundSolution = localAnswers.find(item => item?.id == id);
        if(foundSolution) result = foundSolution?.selectedOptionId;
    }
    return result;
  }

  const onChange = (e, id) => {
    let newLocalAnswers = [];
    if(localAnswers.some(item => item?.id == id)){
        newLocalAnswers = localAnswers.map(item => {
            return item?.id == id ? {...item, selectedOptionId: e.target.value} : item
        });
    }else {
        newLocalAnswers = [...localAnswers, {id, selectedOptionId: e.target.value}]
    }
    setLocalAnswers(newLocalAnswers);
  }

  
  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <>
      <BackgroundImageContainerWithAltText

backgroundImageUrl={currentQuestion?.image?.source}
ariaLabel={currentQuestion?.image?.textHover}
width={`${currentQuestion?.image?.width ? currentQuestion?.image?.width : 400}px`}
height={`${currentQuestion?.image?.height ? currentQuestion?.image?.height : 400}px`}
title={currentQuestion?.image?.textHover} 
altText={currentQuestion?.image?.alt || ""}
>
          { 
            Array.isArray(currentQuestion?.options) &&
            currentQuestion?.options.map((div, index) => (
              <ResizableDivContainer
                key={div.id}
                div={div}
                index={index}
                selectedOptionId={getOptionId(div?.id)}
                onChange={e => onChange(e, div?.id)}
                fontSize={handleResProperty(more_options, index, 'fontSize', 'normal')}
                placeholder = {handleResProperty(more_options, index, 'placeholder', '')}
                width={handleResProperty(more_options, index, 'width', div.width || 100)} 
                height={handleResProperty(more_options, index, 'height', div.height || 40)} 
              />
              
                // <div
                //    style = {more_options && more_options?.layout.fontSize ? 
                //     {fontSize:more_options?.layout.fontSize,} : 
                //     {}
                //   }
                // >
                //   {/* { showAnswer || editMode ? div.content :  */}
                //   { showAnswer ? div.content : 
                //     handleResProperty(more_options, index, 'placeholder', '')
                //   }
                //   {/* {showAnswer || localShowAnswer || editMode ? div.content : 
                //   (more_options && more_options?.response_options?.placeholder ? more_options?.response_options?.placeholder : '')
                //   } */}
                // </div>
            //   </ResizableDivContainer>
            ))}
        </BackgroundImageContainerWithAltText>
      </>
    </PreviewWrapper>
  );
};

export default PreviewImageDropDown;
