import React from 'react';
import { Button } from 'antd';
import { Flag } from 'lucide-react';
import './QuestionsStartPlayer-styles.scss';
import FlagIn from "../../icons/FlagIn";
import FlagOut from "../../icons/FlagOut";

interface QuestionHeaderProps {
    questionStatuses: any;
    currentQuestionIndex: number;
    toggleFlag: () => void;
}

const QuestionHeader: React.FC<QuestionHeaderProps> = ({
    questionStatuses,
    currentQuestionIndex,
    toggleFlag
    }) => {
    const isFlagged = questionStatuses[currentQuestionIndex]?.flagged;

    return (
        <Button
            type="text"
            onClick={toggleFlag}
            className={`question-header__flag-button ${isFlagged ? 'flagged' : ''}`}
        >
            { isFlagged ? <FlagIn />: <FlagOut /> }
            <span>Bookmark flag</span>
        </Button>
    );
};

export default QuestionHeader;