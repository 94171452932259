import React from "react";
import { Avatar, Button, Typography, Divider, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import "./UserProfileForm.styles.scss";
import { Images } from "studio/constants/images.constants";

const { Text } = Typography;

interface UserProfileFormProps {
  userData?: {
    firstName: string;
    lastName: string;
    email: string;
    organization: string;
    roles?: string[];
  };
  onResetPassword: () => void;
  onClose: () => void;
}

const UserProfileForm = ({
  userData, 
  onResetPassword,
  onClose,
}: UserProfileFormProps) => {
  const navigate = useNavigate();

  const {
    user: { first_name, last_name, email, school_name, roles },
  } = useSelector((state: RootState) => state.authentication);

  const UserData = userData || {
    firstName: first_name || "",
    lastName: last_name || "",
    email: email || "",
    organization: school_name || "",
    roles: roles || [],
  };

  const handleSignOut = () => {
    console.log("Sign out clicked");
    navigate("/login");
    onClose();
  };

  return (
    <div className="user-profile-form">
      <div className="avatar-section">
        <Avatar
          size={{
            xs: 36,
            sm: 40,
            md: 43,
            lg: 43,
            xl: 48,
          }}
          src={Images.Avatar} 
          className="ant-avatar"
        >
          {UserData.firstName.charAt(0).toUpperCase()}
        </Avatar>
        <div>
          <Row align="middle" gutter={8}>
            <Col>
              <Text strong className="user-name strong">
                {`${UserData.firstName} ${UserData.lastName}`}
              </Text>
            </Col>
            <Col>
              <Text type="secondary" className="user-role secondary">
                {UserData.roles.length > 0 ? UserData.roles.join(", ") : "No roles assigned"}
              </Text>
            </Col>
          </Row>
        </div>
      </div>

      {/* Vertical Divider */}
      <Divider type="vertical" className="vertical-divider" />

      {/* Email Section */}
      <div className="info-section">
        <div>
          <Row align="middle" gutter={8}>
            <Col>
              <Text type="secondary" className="user-title secondary">
                Email
              </Text>
            </Col>
            <Col>
              <Text className="user-email strong">{UserData.email}</Text>
            </Col>
          </Row>
        </div>
      </div>

      {/* Organization Section */}
      <div className="info-section">
        <div>
          <Row align="middle" gutter={8}>
            <Col>
              <Text type="secondary" className="user-title secondary">
                Organization
              </Text>
            </Col>
            <Col>
              <Text className="user-name strong">{`${UserData.organization}`}</Text>
            </Col>
          </Row>
        </div>
      </div>

      <Divider className="ant-divider-horizontal" />

      {/* Buttons */}
      <div className="button-container">
        <Button className="reset-password-btn" onClick={onResetPassword}>
          Reset Password
        </Button>
        <Button type="primary" className="sign-out-btn" onClick={handleSignOut}>
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default UserProfileForm;