const ArrowIcon = ({ style = {} }) => (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="12" height="7.055" viewBox="0 0 12 7.055">
        <path
            id="chevron_right_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
            d="M4.923,6L0,1.077,1.055,0l6,6-6,6L0,10.923Z"
            transform="translate(12) rotate(90)"
            fill="#333"
        />
    </svg>
);

export default ArrowIcon;