import { Tabs, TabsProps } from "antd";
import { Colors } from "studio/theme/colors.theme";
import "./questionEdit.styles.scss";
import AdvancedTab from "./AdvancedTab.component";
import { Sizes } from "studio/theme/Sizes.theme";
import PropertiesTab from "./PropertiesTab.component";

const QuestionRight = ({
  data,
  setData,
}: {
  data: any;
  setData: (newData: any) => void;
}) => {
  const moreOptions = data?.more_options || {};

  const handleChangeValue = (key, subKey, value) => {
    setData({
      ...data,
      more_options: {
        ...moreOptions,
        [`${key}`]: { ...moreOptions[`${key}`], [`${subKey}`]: value },
      },
    });
  };

  const props = { data, handleChangeValue };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Properties",
      children: <PropertiesTab {...props} />,
    },
    {
      key: "2",
      label: "Advanced",
      children: <AdvancedTab />,
    },
  ];

  return (
    <Tabs
      tabBarStyle={{ width: "100%", marginBottom: Sizes.padding }}
      color={Colors.textBlack}
      className="question_right_tabs"
      defaultActiveKey="1"
      items={items}
      centered
    />
  );
};

export default QuestionRight;
