import DOMPurify from "dompurify";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {produce} from "immer";

const ChildContent = styled.div`
  padding: 20px;
  margin: 25px 0;
  background: #fff;
`;
const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 25px 0;
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  
  /* Ensure the table takes full width */
  table {
    min-width: max-content;
  }
`;
const InlineMatrix = ({
    valid_response_value,
    showAnswer,
    data,
  }) => {
    const textRef = useRef<Array<HTMLDivElement | null>>([]);
    const textOptionRef = useRef([]);
    const more_options = data?.more_options;
    const layout = more_options ? more_options?.layout : null;
    let stemTitle = layout && layout?.column_title ? layout?.column_title : '';
    let optTitle = layout && layout?.row_title ? layout?.row_title : '';
    if(stemTitle && !optTitle) optTitle = ' ';
    if(optTitle && !stemTitle) stemTitle = ' ';
    const hasDividers = layout && layout?.divider ? layout?.divider : false;
    const fontSize = layout && layout?.fontSize ? layout?.fontSize : 'normal';
    const stemWidth = layout && layout?.stem_width ? layout?.stem_width + 'px' : '';
    const optionWidth = layout && layout?.option_width ? layout?.option_width + 'px' : '';
    const cols = data?.correct_answer?.valid_response?.value[0]?.options || [];
    const [optionsTemp, setOptionsTemp] = useState(() =>
      valid_response_value.map((row) => ({
        ...row,
        options: row.options.map((option) => ({
          ...option,
          isChecked: false,
        })),
      }))
  );
    useEffect(() => {
      valid_response_value?.map((item, i) => {
        if (textRef.current[i]) {
          textRef.current[i].innerHTML = DOMPurify.sanitize(item?.stem);
          //window.MathJax.typesetPromise([textRef.current[i]]); 
          if (window.MathJax && window.MathJax.typesetPromise) {
            window.MathJax.typesetPromise([textRef.current[i]])
              .then(() => {
                console.log('MathJax typesetting completed');
              })
              .catch((err: Error) => {
                console.error('MathJax typesetting failed:', err);
              });
          } 
        }
        return textRef.current[i].innerHTML;
      });
    }, [valid_response_value]);

    useEffect(() => {

      const initializeRefs = () => {
        if (!valid_response_value) return;
        valid_response_value.forEach((row, rowIndex) => {
          if (!textOptionRef.current[rowIndex]) {
            textOptionRef.current[rowIndex] = [];
          }
          row?.options.forEach((_, colIndex) => {
            if (!textOptionRef.current[rowIndex][colIndex]) {
            //  console.log('Initialization', rowIndex, colIndex);
              textOptionRef.current[rowIndex][colIndex] = null;
            }
          });
        });
      };
  
      initializeRefs();


  
      valid_response_value?.forEach((row, rowIndex) => {
        row?.options.forEach((item, colIndex) => {
          if (!textOptionRef.current[rowIndex]) {
            textOptionRef.current[rowIndex] = [];
          }
         // console.log(textOptionRef.current[rowIndex], 'Entering');
          if (textOptionRef.current[rowIndex][colIndex]) {
          //  console.log('Entering2');
            textOptionRef.current[rowIndex][colIndex].innerHTML = DOMPurify.sanitize(item?.label);
           // window.MathJax.typesetPromise([textOptionRef.current[rowIndex][colIndex]]); 
           if (window.MathJax && window.MathJax.typesetPromise) {
            window.MathJax.typesetPromise([textOptionRef.current[rowIndex][colIndex]])
              .then(() => {
                console.log('MathJax typesetting completed');
              })
              .catch((err: Error) => {
                console.error('MathJax typesetting failed:', err);
              });
          } 
           
          }
        });
      });
    }, [valid_response_value]);

    const handleTestItemClicked = (rowId, colId, isRadio) => {
      setOptionsTemp((prevData) =>
        produce(prevData, (draft) => {
          const question = draft.find((q) => q.id === rowId);
          if (!question) return;
    
          if (isRadio) {
            question.options.forEach((option) => {
              if (option.id === colId) {
                option.isChecked = true;
              } else {
                option.isChecked = false;
              }
            });
          } else {
            const option = question.options.find((o) => o.id === colId);
            if (option) {
              option.isChecked = !option.isChecked;
            }
          }
        })
      );
    };
    
    
    const renderOptionRadio = (rowId:string, colId:string): string | undefined => {
      const row = optionsTemp.find((item) => item.id === rowId);
      if (!row) return undefined;
  
      const option = row.options.find((opt) => opt.id === colId);
      if (!option) return undefined;
  
      return option.isChecked ? option.label : '';
    }
    const renderOptionCheckBox = (rowId: string, colId: string): boolean => {
      const row = optionsTemp.find((item) => item.id === rowId);
      if (!row) return false;
    
      const option = row.options.find((opt) => opt.id === colId);
      if (!option) return false;
    
      return option.isChecked;
    };
    console.log('valid_response_valuevalid_response_value',valid_response_value);
    
    return (
      <ChildContent>
              <ScrollContainer>

        {data?.settings?.multiple_response ? (
          <table 
            className="w-fit text-center text-gray-500"
            style={{fontSize: fontSize}}
          >
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr
              className={hasDividers ? "border border-b-gray-200 " : ""}
              >
                {
                  stemTitle && 
                  <th 
                    className="bg-gray-200 px-6 py-3" 
                    colSpan={1}
                    style={stemWidth ? {width: stemWidth} : {}}
                  >
                    {stemTitle}
                  </th>
                }

                {
                  optTitle &&
                  <th 
                    className="bg-gray-200 px-6 py-3 text-center" 
                    colSpan={cols?.length ? cols?.length : 1}
                  >
                    {optTitle}
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              {Array.isArray(valid_response_value) &&
                valid_response_value.map((row, index) => (
                  <tr 
                    key={row?.id}
                    className={hasDividers ? "border border-b-gray-200 " : ""} 
                  >
                    <td 
                        className="text-left px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        style={stemWidth ? {width: stemWidth} : {}}
                    >
                      
                      {/* {row?.stem} */}
                      <p><div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textRef.current[index] = el)}
  dangerouslySetInnerHTML={{ __html:row?.stem }}
>
</div></p>
                      {/* <div id="cms" style={{whiteSpace:"pre-wrap"}}  ref={(el) => (textRef.current[index] = el)} /> */}
                    </td>
                    {Array.isArray(row?.options) &&
                      row?.options.map((option,j) => (
                        <td
                          className="text-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          key={option?.id}
                          style={optionWidth ? {width: optionWidth} : {}}
                        >
                          
                          <label>
                            <div className="flex justify-center items-center gap-2">
                           {/* Checkbox Input */}
<input
  type="checkbox"
  checked={showAnswer ? option?.isChecked : renderOptionCheckBox(row?.id, option?.id) || false}
  onChange={() => {
    handleTestItemClicked(row?.id, option?.id, false);  // Pass `false` for checkbox
  }}
/>
<p>

<div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textOptionRef.current[j] = el)}
  dangerouslySetInnerHTML={{ __html:option?.label }}
>
</div></p>
                                     {/* <div
              key={`p${j}`}
              ref={(el) => {
                if (!textOptionRef.current[index]) {
                  textOptionRef.current[index] = [];
                }
                textOptionRef.current[index][j] = el;
              }}
            >
            </div> */}
                               
                            </div>
                          </label>
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <table 
            className="w-fit text-center text-gray-500"
            style={{fontSize: fontSize}}
            >
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr
              className={hasDividers ? "border border-b-gray-200 " : ""}
              >
                {
                  stemTitle && 
                  <th 
                    className="bg-gray-200 px-6 py-3" 
                    colSpan={1}
                    style={stemWidth ? {width: stemWidth} : {}}
                  >
                    {stemTitle}
                  </th>
                }

                {
                  optTitle &&
                  <th 
                    className="bg-gray-200 px-6 py-3 text-center" 
                    colSpan={cols?.length ? cols?.length : 1}
                  >
                    {optTitle}
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              {Array.isArray(valid_response_value) &&
                valid_response_value.map((row, index) => (
                  <tr 
                    key={row?.id}
                    className={hasDividers ? "border border-b-gray-200 " : ""} 
                  >
                    <td 
                        className="text-left px-6 py-4 text-gray-900 whitespace-nowrap"
                        style={stemWidth ? {width: stemWidth} : {}}
                    >
                      
                      {/* {row?.stem} */}
                      <p><div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textRef.current[index] = el)}
  dangerouslySetInnerHTML={{ __html:row?.stem }}
>
</div></p>
                      {/* <div id="cms" style={{whiteSpace:"pre-wrap"}}  ref={(el) => (textRef.current[index] = el)} /> */}
                    </td>
                    {Array.isArray(row?.options) &&
                      row?.options.map((option,j) => (
                        <td
                          className="text-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          key={option?.id}
                          style={optionWidth ? {width: optionWidth} : {}}
                        >
                          <label>
                            <div className="flex items-center justify-center gap-2">
                          <input
                            type="radio"
                            name={row?.id} 
                            checked={showAnswer ? option?.isChecked : renderOptionRadio(row?.id, option?.id) === option.label || false}
                            onChange={() => {
                              handleTestItemClicked(row?.id, option?.id, true);  
                            }}
                          />


                               {/* <div
              key={`p${j}`}
              ref={(el) => {
                if (!textOptionRef.current[index]) {
                  textOptionRef.current[index] = [];
                }
                textOptionRef.current[index][j] = el;
              }}
            >
            </div> */}
              <p> <div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textOptionRef.current[j] = el)}
  dangerouslySetInnerHTML={{ __html:option?.label }}
>
</div></p>
                             {/*  <p>{option.label}</p> */}
                            </div>
                          </label>
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
            </ScrollContainer>

      </ChildContent>
    );
  };

  export default InlineMatrix;