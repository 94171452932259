interface IconProps {
  fill?: string;
  className?: string;
}
const MultipleChoice = ({
  fill = "currentColor",
  className = "",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M10.0096 17.9542L19.5045 8.45923L18.0716 7.02637L10.0096 15.0884L5.95618 11.0354L4.5233 12.4683L10.0096 17.9542ZM2.45892 24.5C1.77728 24.5 1.19711 24.2606 0.718414 23.7816C0.239471 23.3029 0 22.7227 0 22.0411V2.95892C0 2.27728 0.239471 1.69714 0.718414 1.21844C1.19711 0.739502 1.7773 0.5 2.45892 0.5H21.5411C22.2227 0.5 22.8029 0.739502 23.2816 1.21844C23.7605 1.69714 24 2.27728 24 2.95892V22.0411C24 22.7227 23.7605 23.3029 23.2816 23.7816C22.8029 24.2606 22.2227 24.5 21.5411 24.5H2.45892ZM2.46217 22.4818H21.5378C21.6489 22.4818 21.7506 22.4355 21.8429 22.343C21.9355 22.2506 21.9818 22.1489 21.9818 22.0378V2.96216C21.9818 2.85107 21.9355 2.74939 21.8429 2.65704C21.7506 2.56451 21.6489 2.51825 21.5378 2.51825H2.46217C2.35109 2.51825 2.24939 2.56451 2.15706 2.65704C2.06448 2.74939 2.0182 2.85107 2.0182 2.96216V22.0378C2.0182 22.1489 2.06448 22.2506 2.15706 22.343C2.24939 22.4355 2.35109 22.4818 2.46217 22.4818Z"
      fill={fill} className={className}
    />
  </svg>
);
export default MultipleChoice;
