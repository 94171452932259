import React, { useEffect, useState } from "react";
import { Table, Button, Dropdown } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Assessment } from "../../../types/assessment";
import { Colors } from "studio/theme/colors.theme";
import "../AssessmentLibrary/styles.scss";
import { Sizes } from "studio/theme/Sizes.theme";
import More from "../../icons/More";
import Play from "../../icons/Play";
import BlackDelete from "../../icons/BlackDelete";
import BlackEdit from "../../icons/BlackEdit";
import BlackDuplicate from "../../icons/BlackDuplicate";
import CustomPagination from "../general/CustomPagination";
import Ascend from "studio/icons/Ascend";
import { SorterResult } from "antd/es/table/interface";

const CellWrapper = ({ label }: { label: string }) => {
  return (
    <div className="table-cell-content table-cell-content_new">
      <span className=" overflow-ellipsis ml-1">{label}</span>
    </div>
  );
};

const dropDownItemStyle = {
  borderBottom: "1px solid #CACACA",
  WebkitBackgroundClip: "padding-box",
  backgroundClip: "padding-box",
  height: Sizes.headerPadding,
  padding: 0,
  margin: 0,
  paddingBottom: Sizes.base,
  display: "flex",
  alignItems: "center",
};

interface IQuestionsTable {
  data: Assessment[]; // Ensure this matches your type definition
  loading: boolean;
  selectedRows?: Assessment[]; // Ensure this matches
  onRowSelected?: (keys: React.Key[], rows: any[]) => void;
  onSort?: (field: string, order: 'ascend' | 'descend' | null) => void;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  onRowClick?: (row: Assessment) => void; // Ensure this matches
  pagination?: any;
  clearSelection?: any;
  currentSortField?: string;
  currentSortOrder?: 'ascend' | 'descend' | null;
}

// nothing

const QuestionsTable: React.FC<IQuestionsTable> = ({
  data,
  loading,
  selectedRows,
  onRowSelected,
  onSort,
  onPageChange,
  onPerPageChange,
  onRowClick,
  pagination,
  clearSelection,
  currentSortField,
  currentSortOrder
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  console.log('currentSortField', currentSortField, currentSortOrder);

  useEffect(() => {
    if (clearSelection) {
      setSelectedRowKeys([]);
      onRowSelected?.([], []);
    }
  }, [clearSelection, data]);

  const renderDropdown = (record, onRowClick) => {
    const menuItems = [
      {
        key: "1",
        label: (
          <div
            style={dropDownItemStyle}
            onClick={(e) => {
              console.log("Deleting row data:", record);
              e.stopPropagation();
              onRowClick?.(record);
            }}
          >
            <Play style={{ marginRight: Sizes.base }} /> Preview
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div
            style={dropDownItemStyle}
            onClick={(e) => {
              e.stopPropagation();
              // onDelete(record.id);
              setSelectedRowKeys([]);
            }}
          >
            <BlackDelete style={{ marginRight: Sizes.base }} /> Delete
          </div>
        ),
      },
      {
        key: "3",
        label: (
          <div style={dropDownItemStyle} onClick={(e) => e.stopPropagation()}>
            <BlackEdit style={{ marginRight: Sizes.base }} /> Edit
          </div>
        ),
      },
      {
        key: "4",
        label: (
          <div
            style={{
              ...dropDownItemStyle,
              borderBottomWidth: 0,
              paddingBottom: Sizes.base / 2,
            }}
            onClick={(e) => {
              e.stopPropagation();
              // onDuplicate(record.id);
              setSelectedRowKeys([]);
            }}
          >
            <BlackDuplicate style={{ marginRight: Sizes.base }} /> Duplicate
          </div>
        ),
      },
    ];
    //nothing

    return (
      <Dropdown
        menu={{
          items: menuItems,
        }}
        trigger={["click"]}
        overlayStyle={{
          // minWidth: "150px",
          boxShadow: "0 2px 6px rgba(0,0,0,0.15)",
          borderRadius: Sizes.borderRadius,
          border: "1px solid #CCCCCC",
        }}
      >
        <Button type="text" icon={<More />} />
      </Dropdown>
    );
  };

  const columns: ColumnsType<Assessment> = [
    {
      // title: "Question name",
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Question name
          <Ascend
            style={{
              marginLeft: 20,
              transform: currentSortField === 'name' && currentSortOrder === 'descend'
                ? 'rotate(180deg)'
                : 'none',
              opacity: currentSortField === 'name' ? 1 : 0.4
            }}
          />
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: "35%",
      sorter: true,
      sortOrder: currentSortField === 'name' ? currentSortOrder : null,
      render: (text) => <CellWrapper label={text} />,
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Grade
          <Ascend
            style={{
              marginLeft: 20,
              transform: currentSortField === 'grade' && currentSortOrder === 'descend'
                ? 'rotate(180deg)'
                : 'none',
              opacity: currentSortField === 'grade' ? 1 : 0.4
            }}
          />
        </div>
      ),
      dataIndex: "grades",
      key: "grades",
      width: "10%",
      sorter: true,
      sortOrder: currentSortField === 'grade' ? currentSortOrder : null,
      render: (grades) => (
        <div className="table-cell-content">{grades?.name || "N/A"}</div>
      ),
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Created date
          <Ascend
            style={{
              marginLeft: 20,
              transform: currentSortField === 'created_at' && currentSortOrder === 'descend'
                ? 'rotate(180deg)'
                : 'none',
              opacity: currentSortField === 'created_at' ? 1 : 0.4
            }}
          />
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
      sorter: true,
      sortOrder: currentSortField === 'created_at' ? currentSortOrder : null,
      render: (text) => <div className="table-cell-content">{text}</div>,
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Subject
          <Ascend
            style={{
              marginLeft: 20,
              transform: currentSortField === 'subject' && currentSortOrder === 'descend'
                ? 'rotate(180deg)'
                : 'none',
              opacity: currentSortField === 'subject' ? 1 : 0.4
            }}
          />
        </div>
      ),
      dataIndex: "subjects",
      key: "subjects",
      width: "10%",
      sorter: true,
      sortOrder: currentSortField === 'subject' ? currentSortOrder : null,
      render: (subjects) => (
        <div className="table-cell-content">
          {subjects ? subjects.name : "N/A"}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Tag
          <Ascend
            style={{
              marginLeft: 20,
              transform: currentSortField === 'tag' && currentSortOrder === 'descend'
                ? 'rotate(180deg)'
                : 'none',
              opacity: currentSortField === 'tag' ? 1 : 0.4
            }}
          />
        </div>
      ),
      dataIndex: "tags",
      key: "tags",
      width: "10%",
      sorter: true,
      sortOrder: currentSortField === 'tag' ? currentSortOrder : null,
      render: (tags) => (
        <div className="table-cell-content">
          {tags.length > 0 ? tags.map((tag) => tag.name).join(", ") : "N/A"}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          DOK
          <Ascend
            style={{
              marginLeft: 20,
              transform: currentSortField === 'dok' && currentSortOrder === 'descend'
                ? 'rotate(180deg)'
                : 'none',
              opacity: currentSortField === 'dok' ? 1 : 0.4
            }}
          />
        </div>
      ),
      dataIndex: "dok", // Adjust as necessary; currently not in provided data
      key: "dok",
      width: "10%",
      sorter: true,
      sortOrder: currentSortField === 'dok' ? currentSortOrder : null,
      render: (dok) => <span className="underline ml-1">{dok || "N/A"} </span>,
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Status
          <Ascend
            style={{
              marginLeft: 20,
              transform: currentSortField === 'status' && currentSortOrder === 'descend'
                ? 'rotate(180deg)'
                : 'none',
              opacity: currentSortField === 'status' ? 1 : 0.4
            }}
          />
        </div>
      ),
      dataIndex: "status",
      key: "status",
      width: "10%",
      sorter: true,
      sortOrder: currentSortField === 'status' ? currentSortOrder : null,
      render: (status: boolean) => (
        <div className="table-cell-content">
          {status ? "Published" : "Unpublished"}
        </div>
      ),
    },
    {
      title: "",
      key: "actions",
      width: "100px",
      render: (_, record) => renderDropdown(record, onRowClick),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      onRowSelected?.(newSelectedRowKeys, selectedRows);
    },
  };

  const rowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<Assessment>
  ) => {
    // Handle sorting
    if (sorter && 'field' in sorter) {
      console.log('sorter',sorter)
      if (sorter.field == 'subjects') {
        onSort?.('subject', sorter.order || null);
      }
      else if (sorter.field == 'grades') {
        onSort?.('grade', sorter.order || null);
      }
      else if (sorter.field == 'tags') {
        onSort?.('tag', sorter.order || null);
      }
      else {
        onSort?.(sorter.field as string, sorter.order || null);

      }
    }
  };

  // console.log("selected rows =", selectedRows);
  // console.log("selected rows keys =", selectedRowKeys);

  return (
    <div className="assessment-table assessment-library-page custom_table_new">
      <div
        style={{
          border: "1px solid " + Colors.bgLightGray,
          maxWidth: Sizes.maxScreenWidth,
        }}
      >
        <Table
          className="custom-header-no-border"
          style={{ maxWidth: Sizes.maxScreenWidth }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={false}
          scroll={{ x: true }}
          rowClassName={rowClassName}
          onRow={(record) => ({
            onClick: () => onRowClick?.(record),
          })}
          rowKey="id"
        />

        {pagination && (
          <CustomPagination
            current={pagination.current_page}
            pageSize={pagination.per_page}
            total={pagination.total}
            onChange={onPageChange}
            onPageSizeChange={onPerPageChange}
            totalSelectedRows={selectedRowKeys?.length}
          />
        )}
      </div>
    </div>
  );
};

export default QuestionsTable;
