import React from 'react';

const FlagFilled = ({ style = {}, className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="20.605" viewBox="0 0 16 20.605">
            <path id="bookmark_flag_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M231.566-799.968h1.377v-3.786h5.419l-1.7-2.263,1.7-2.269h-6.8Zm-5.106,7.844v-18.452a2.075,2.075,0,0,1,.629-1.525,2.073,2.073,0,0,1,1.523-.629h11.7a2.073,2.073,0,0,1,1.523.629,2.075,2.075,0,0,1,.629,1.525v18.452l-8-3.417Zm1.766-2.7,6.234-2.69,6.234,2.69v-15.752a.371.371,0,0,0-.121-.267.371.371,0,0,0-.267-.121H228.614a.371.371,0,0,0-.267.121.371.371,0,0,0-.121.267Zm0-16.141h0Z"
                  transform="translate(-226.46 812.73)" fill="#702fba"/>
        </svg>

    );
};

export default FlagFilled;
