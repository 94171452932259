import React from 'react';

export const TrophyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path id="emoji_events_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
              d="M155.28-780.73v-2.691h6.649v-6.708a9.407,9.407,0,0,1-4.287-2.034,8.378,8.378,0,0,1-2.63-3.9,8.959,8.959,0,0,1-5.491-2.709,7.662,7.662,0,0,1-2.251-5.538v-1.923a2.588,2.588,0,0,1,.8-1.9,2.589,2.589,0,0,1,1.9-.8h4.553v-3.808h17.511v3.808h4.553a2.588,2.588,0,0,1,1.9.8,2.588,2.588,0,0,1,.8,1.9v1.923a7.662,7.662,0,0,1-2.251,5.538,8.953,8.953,0,0,1-5.484,2.709,8.374,8.374,0,0,1-2.634,3.9,9.423,9.423,0,0,1-4.281,2.034v6.708h6.649v2.691Zm-.766-18.236v-7.265h-4.553v1.923a5.154,5.154,0,0,0,1.293,3.491A5.52,5.52,0,0,0,154.514-798.966Zm8.753,6.264a5.82,5.82,0,0,0,4.274-1.755,5.811,5.811,0,0,0,1.757-4.273v-11.31H157.242v11.31A5.816,5.816,0,0,0,159-794.457,5.811,5.811,0,0,0,163.267-792.7Zm8.759-6.264a5.52,5.52,0,0,0,3.26-1.85,5.153,5.153,0,0,0,1.293-3.491v-1.923h-4.553ZM163.27-801.37Z"
              transform="translate(-147.27 812.73)" fill="#333"/>
    </svg>


);