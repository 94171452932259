import MainSideMenu from "studio/components/general/MainSideMenu.component";
import MainHeader from "../../components/general/MainHeader";
import type {IHeaderType} from "../../components/general/MainHeader";

const MainLayout = ({
  children,
  title,
  headerType,
  handleSave,
  handlePublish
}: {
  children: React.ReactNode;
  title: string;
  headerType?: IHeaderType;
  handleSave?: () => void;
  handlePublish?: () => void;
}) => {
  return (
    <div className="bg-white grid grid-cols-[48px_1fr] grid-rows-[48px_1fr] h-screen overflow-hidden">
      <MainHeader title={title} type={headerType} handleSave={handleSave} handlePublish={handlePublish} />
      <MainSideMenu type={headerType}/>
      <main className="overflow-auto row-start-2">{children}</main>
    </div>
  );
};

export default MainLayout;
