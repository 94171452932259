interface IconProps {
  fill?: string;
  className?: string;
}
const AssessmentStudioAdd = ({
  fill = "currentColor",
  className = "",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M10.9704 14.7668L14.775 10.9569L11.7872 7.96387L9.75515 9.99585L8.05116 8.29144L10.0697 6.2594L7.24218 3.41016L3.43231 7.22046L10.9704 14.7668ZM24.763 28.5377L28.5729 24.7278L25.7806 21.949L23.7486 23.9675L22.0224 22.2414L24.041 20.2094L21.0648 17.2467L17.2684 21.0431L24.763 28.5377ZM8.05246 30.2621H1.73788V23.9693L9.239 16.4677L0 7.22046L7.27737 0L16.5199 9.26074L23.5765 2.15546C23.8262 1.90607 24.0999 1.72107 24.3976 1.60059C24.6957 1.48041 25.0091 1.42029 25.3378 1.42029C25.6608 1.42029 25.9735 1.48041 26.2759 1.60059C26.5786 1.72107 26.8573 1.90607 27.1122 2.15546L29.8446 4.94299C30.094 5.19788 30.2762 5.47437 30.3912 5.7724C30.5059 6.07043 30.5632 6.38092 30.5632 6.70386C30.5632 7.03265 30.5059 7.3446 30.3912 7.63977C30.2762 7.93488 30.094 8.20728 29.8446 8.45697L22.7992 15.5236L32 24.7227L24.7795 32L15.5453 22.7827L8.05246 30.2621ZM4.16917 27.8308H7.01321L23.5348 11.3145L20.6855 8.46521L4.16917 25.0085V27.8308ZM22.128 9.87726L20.6855 8.46521L23.5348 11.3145L22.128 9.87726Z"
      fill={fill} className={className}
    />
  </svg>
);
export default AssessmentStudioAdd;
