import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import Button from "components/Button";
import DroppableArea from "../partials/DroppableArea/DroppableArea";
import { TOptionItem } from "types";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import uuid from 'react-uuid';


type ClassificationBlocPrevProps = {
  columnTitles: TOptionItem[];
  rowsTitles: TOptionItem[];
  possibRes: TOptionItem[];
  correctAnswers?: string[][];
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  showAnswer?: boolean;
  score?: number;
  handleDataRest?: any;
  currentQuestion?: any;
};

export const ClassificationBlocPrev: React.FC<ClassificationBlocPrevProps> = ({
  columnTitles,
  rowsTitles,
  possibRes,
  correctAnswers,
  handlePointsChange,
  editMode,
  showAnswer,
  score,
  handleDataRest,
  currentQuestion,
}) => {
  const { t } = useTranslation();
  const colRef = useRef<Array<HTMLSpanElement | null>>([]);
  const rowRef = useRef<Array<HTMLSpanElement | null>>([]);
  const subQuestion = useSelector(
    (state: RootState) =>
      state.question.subQuestions);
    const [droppedItems, setDroppedItems] = useState<{ [key: number]: { label: string; id: string; parentId: string }[] }>({});

    const [availableItems, setAvailableItems] = useState<{ label: string; id: string }[]>(
      possibRes?.map((item) => ({ label: item.label, id: uuid() }))
    );
        const [hasAnswered, setHasAnswered] = useState<boolean>(false);
    const [showCheckAnswer, setShowCheckAnswer] = useState<boolean>(false); // State to track show/hide answer classification
  
  const row_min_height = currentQuestion?.more_options?.ui_style
  ?.row_min_height;
  
  const row_titles_width =currentQuestion?.more_options?.ui_style
  ?.row_titles_width;  
  const isDuplicateResponse = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options
        ?.duplicate_responses
  );
  const correctAns = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.correct_answer
        ?.possible_responses
  );
  
  const max_response_per_cell = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options?.ui_style
        ?.max_response_per_cell
  );

  // Track if at least one item has been answered
useEffect(() => {
  // Check if any droppable area has at least one dropped item
  const anyItemsDropped = Object.values(droppedItems).some((items) => items.length > 0);
  setHasAnswered(anyItemsDropped);
}, [droppedItems]);


useEffect(() => {
  colRef.current = colRef.current.slice(0, columnTitles?.length);
}, [columnTitles]);

useEffect(() => {
  rowRef.current = rowRef.current.slice(0, rowsTitles?.length);
}, [rowsTitles]);

const more_options = useSelector(
  (state: RootState) =>
    (state.question.subQuestions?.["class"] as any)?.more_options
);

const getLabel = (index: number) => {
  let label = "";
  if (more_options?.ui_style?.validation_stem_numeration) {
    switch (more_options?.ui_style?.validation_stem_numeration) {
      case "numerical":
        label = (index + 1).toString();
        break;
      case "lower-alpha":
        label = String.fromCharCode(97 + index);
        break;
      case "upper-alpha":
        label = String.fromCharCode(65 + index);
        break;
      default:
        break;
    }
  }
  return label;
};

useEffect(() => {
  columnTitles?.forEach((item, i) => {
    if (colRef.current[i]) {
      colRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
    }
  });
}, [columnTitles]);

useEffect(() => {
  rowsTitles?.forEach((item, i) => {
    if (rowRef.current[i]) {
      rowRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
    }
  });
}, [rowsTitles]);

function removeDuplicatesByLabelAndParentId(data) {
  // Iterate over the object values (arrays)
  for (const key in data) {
    const uniqueItems = [];
    const seenLabelsAndParentIds = new Set();

    // Filter out duplicate labels for the same parentId
    data[key] = data[key].filter(item => {
      const uniqueIdentifier = item.label + item.parentId;
      
      if (!seenLabelsAndParentIds.has(uniqueIdentifier)) {
        seenLabelsAndParentIds.add(uniqueIdentifier);
        uniqueItems.push(item);
        return true;
      }
      return false;
    });
  }
  return data;
}


const handleDrop = (targetId: number, label: string, sourceId?: number,itemId?: string) => {
  setDroppedItems((prev) => {
    const updated = { ...prev };

    if (targetId === -1) {
      // Remove the item by ID instead of label
      Object.keys(updated).forEach((key) => {
        updated[Number(key)] = updated[Number(key)].filter((item) => item.id !== label);
      });
    } else {
      // Ensure currentItem has parentId
      let currentItem = droppedItems[sourceId]?.find((item) => item.id === itemId);

      if (!currentItem) {
        const currentAvailable = availableItems.find((item) => item.id === itemId);
        if (currentAvailable) {
          currentItem = { ...currentAvailable, parentId: '' }; // Ensure parentId exists
        }
      }
      if (!updated[targetId]) {
        updated[targetId] = [];
      }

      // Check if the target column has reached the max_response_per_cell limit
      if ((updated[targetId].length >= max_response_per_cell) && max_response_per_cell > 0) {
        // If the limit is reached, do not add the item
        return updated;
      }

      // Remove the item from the source column (if it exists)
      if (sourceId !== undefined && sourceId !== -1) {
        updated[sourceId] = updated[sourceId].filter((item) => item.id !== itemId);
      }

      // If isDuplicateResponse is false, remove the item from all other columns
      if (!isDuplicateResponse) {
        
        console.log('current item is = ', currentItem);
        Object.keys(updated).forEach((key) => {
          if (Number(key) !== targetId) {
            updated[Number(key)] = updated[Number(key)].filter((item) => item.parentId !== itemId);
          }
        });
      }

      // Create a new item with a unique ID
      const newItem = { label, id: uuid(),parentId: (currentItem.parentId != '') ? currentItem.parentId : itemId };

      // Add new item if not a duplicate (unless `isDuplicateResponse` is true)
      if (isDuplicateResponse || !updated[targetId].some((item) => item.parentId === itemId)) {
        updated[targetId].push(newItem);
      }
    }
     return removeDuplicatesByLabelAndParentId(updated);
  });
};








const renderDroppableArea = (id: number) => (
  <DroppableArea
    id={id}
    onDrop={(targetId, label, sourceId, itemId) => {
      handleDrop(targetId, label, sourceId, itemId);
    }}
    droppedItems={droppedItems[id] || []}
    height={row_min_height}
    input={true}
  />
);










  const handleCheckAnswerButton = (e) => {
    e.preventDefault();
    setShowCheckAnswer(!showCheckAnswer);
  };
  const rowHeaderContainerRef = useRef<HTMLDivElement>(null);

  const draggableArea = () => {
    return (
      <div
        className="overflow-x-scroll pb-4"
      // style={row_min_height ? { minHeight: `${row_min_height}px` } : {}}
      >      
      <table>
          <tbody>
          <div className="row-header-display flex items-center">
      </div>
            <tr>
              <th className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px] leading-normal "  style={{
                    width: row_titles_width ? `${row_titles_width}px` : '48px',
                    minHeight: row_min_height ? `${row_min_height}px` : '48px',
                  }}>&nbsp;
                     {currentQuestion?.more_options?.ui_style?.row_header && <p>{currentQuestion?.more_options?.ui_style?.row_header}</p>}

                  </th>
              {columnTitles?.map((item, key) => (
                <th key={key} className="p-2 border-solid border border-grey-500 my-4 rounded-md min-w-[20vw] min-h-[48px] leading-normal"
                style={{
                  width: row_titles_width ? `${row_titles_width}px` : '48px',
                  minHeight: row_min_height ? `${row_min_height}px` : '48px',
                }}
                >
                  <span ref={(el) => (colRef.current[key] = el)} />
                </th>
              ))}
            </tr>

            {rowsTitles?.map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px] leading-normal"
                style={{
                  width: row_titles_width ? `${row_titles_width}px` : '48px',
                  minHeight: row_min_height ? `${row_min_height}px` : '48px',
                }}
                >
                  <span ref={(el) => (rowRef.current[rowIndex] = el)} />
                </td>
                {columnTitles?.map((_, colIndex) => (
                  <th key={colIndex} className="p-2 border-solid border border-grey-500 my-4 rounded-md leading-normal "
                  style={{
                    width: row_titles_width ? `${row_titles_width}px` : '48px',
                    minHeight: row_min_height ? `${row_min_height}px` : '48px',
                  }}
                  >
                                       {renderDroppableArea(rowIndex * columnTitles.length + colIndex)}

                    {(showAnswer || showCheckAnswer) && 
                      correctAnswers &&
                      correctAnswers[rowIndex * columnTitles.length + colIndex]?.map((answer, key) => {
                        const isCorrect =
                          droppedItems[rowIndex * columnTitles.length + colIndex]?.[key].label === answer;
                        return (
                          <div
                            key={key}
                            // className={`border-2 p-1 my-1 ${
                            //   droppedItems[
                            //     rowIndex * columnTitles.length + colIndex
                            //   ]?.includes(answer)
                            //     ? "border-green-500"
                            //     : "border-red-500"
                            // }`}
                            className={`border-2 p-1 my-1  ${isCorrect ? "border-green-500" : "border-red-500"
                              }`}
                          >
                             <span>
                              {getLabel(
                                rowIndex * columnTitles.length + colIndex
                              )}
                              .{" "}
                            </span>
                            <span
                              dangerouslySetInnerHTML={{ __html: answer }}
                            />
                          </div>
                        );
                      })}
                  </th>
                ))}
              </tr>
            ))}
           
          </tbody>
        </table>
      </div>
    );
  };
  const instant_feedback = more_options?.ui_style?.instant_feedback;

  const possibility_list_position = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options?.ui_style
        ?.possibility_list_position
  );

  const getDroppableArea = (input=null) => (
    <DroppableArea
    id={-1}
    onDrop={handleDrop}
    droppedItems={availableItems} // Initialize items as objects
    className="flex items-center gap-1 flex-wrap bg-red-500"
    height={row_min_height}
    input={input}
  />
  
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {handleDataRest && (
        <Button variant="contained" title={t("reset")} onClick={handleDataRest} />
      )}

      <div className="mt-4">
        {possibility_list_position === "top" && (
          <div className="mb-4">{getDroppableArea()}</div>
        )}
        {possibility_list_position !== "left" &&
          possibility_list_position !== "right" &&
          draggableArea()}
        {possibility_list_position === "bottom" && (
          <div className="mt-4">{getDroppableArea()}</div>
        )}

        {possibility_list_position === "left" && (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "1rem" }}>{getDroppableArea()}</div>
            {draggableArea()}
          </div>
        )}

        {possibility_list_position === "right" && (
          <div style={{ display: "flex" }}>
            {draggableArea()}
            <div style={{ marginLeft: "1rem" }}>{getDroppableArea()}</div>
          </div>
        )}
{editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: "1px solid #eee",
              borderRadius: "6px",
              width: "300px",
            }}
          >
            <span>{t("points")}</span>
            {score ? (
              <input
                className="outline-none"
                type="number"
                value={score}
                readOnly
              />
            ) : (
              <input
                className="outline-none"
                placeholder="100"
                type="number"
                onChange={handlePointsChange}
              />
            )}
          </div>
        )}
        {/* Check Answer Button */}
        {instant_feedback && (

        <div className="mt-6 flex justify-end">
          <button
            disabled={!hasAnswered}
            onClick={handleCheckAnswerButton}
            className={`text-right font-semibold py-2 px-4 border rounded transition duration-300 ${
              !hasAnswered
                ? "bg-[#aaaaaa] text-[#717171] cursor-not-allowed"
                : "bg-transparent hover:bg-accent text-gray-700 hover:text-white border hover:border-transparent"
            }`}
          >
            Check Answer
          </button>
        </div>
        )}

      </div>
    </DndProvider>
  );
};

ClassificationBlocPrev.defaultProps = {
  editMode: false,
};