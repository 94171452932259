import React from "react";
import { Modal, Form, Input, Button, Row, Col, message } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Messages } from "studio/constants/messages.constant";

interface ResetPasswordModalProps {
  visible: boolean;
  onClose: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordModalProps> = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { token } = useSelector((state: RootState) => state.authentication);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]*$/;

  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error(Messages.NewPasswordRequired));
    }
    
    if (value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters long and contain uppercase, lowercase, number, and special character")
      );
    }
    
    if (!passwordRegex.test(value)) {
      return Promise.reject(
        new Error("Password must contain uppercase, lowercase, number, and special character")
      );
    }
    
    return Promise.resolve();
  };

  const handleSubmit = async (values: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/profile/change-password`,
        {
          current_password: values.oldPassword,
          new_password: values.newPassword,
          new_password_confirmation: values.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status) {
        message.success(response.data.message || Messages.PasswordResetSuccess);
        form.resetFields();
        onClose();
      } else {
        message.error(response.data.message || Messages.PasswordResetFailure);
      }
    } catch (error: any) {
      message.error(
        error.response?.data?.message ||
          error.response?.data?.error ||
          Messages.PasswordResetError
      );
    }
  };

  return (
    <Modal
      className="reset-password-form"
      title={
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            fontSize: "20px",
            marginBottom: -8,
            padding: 0,
            marginLeft: 23,
            marginTop: "2%",
          }}
        >
          Reset password
        </h3>
      }
      centered
      open={visible}
      onCancel={onClose}
      closable={false}
      footer={null}
      width={window.innerWidth > 768 ? 710 : 386}
      bodyStyle={{
        padding: "24px",
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Old password"
              name="oldPassword"
              rules={[{ required: true,message: Messages.OldPasswordRequired }]}
              required={false} 
              style={{ marginBottom: 24 }}
            >
              <Input.Password visibilityToggle={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="New password"
              name="newPassword"
              rules={[
                { required: true, validator: validatePassword }
              ]}
              required={false} 

              style={{ marginBottom: 24 }}
            >
              <Input.Password visibilityToggle={false} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Confirm new password"
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                {  required: true,message: Messages.ConfirmPasswordRequired },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(Messages.PasswordsDoNotMatch));
                  },
                }),
              ]}

              
              required={false} 
              style={{ marginBottom: 24 }}
            >
              <Input.Password visibilityToggle={false} />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
          <Button color="default" variant="outlined"
            onClick={onClose}
            style={{
              width: 130,
              height: 48,
              borderRadius: 6,
              border: "1px solid",
              fontFamily: "Poppins, medium",
              fontSize: "14px",
              fontWeight: 500,
              // backgroundColor: "white"

            }}
          >
            Discard
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: 130,
              height: 48,
              borderRadius: 6,
              background: "#FA9B31",
              borderColor: "#FA9B31",
              fontFamily: "Poppins, medium",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ResetPasswordForm;