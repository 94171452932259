import { MutableRefObject } from "react";

export type TOptionItem = {
  id: string;
  label: string;
  order?: number;
};

export type TSortDraggedElement = {
  data: TOptionItem[];
  dragItem: MutableRefObject<any>;
  dragOverItem: MutableRefObject<any>;
};

export type SubQuestionData = {
  type: string;
  question: string;
  correct_answer: {
    stimulus_list: string[];
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      id?: string;
      score: number;
      value: string[];
    }[];
  };
};

export type MoreOptionClassify = {
  metadata: {
    distractor_rationale: string;
    rubric_reference: string;
    sample_answer: string;
    acknowledgements: string;
    stimulus_review: string;
    instructor_stimulus: string;
    distractor_rationale_response_level: string[];
  };
  ui_style: {
    fontsize: string;
    placeholder: string;
    spellcheck: boolean;
    is_math: boolean;
    input_type: string;
    unscored:boolean
    possibility_list_position:string
    validation_stem_numeration:string
    row_header:string
    max_response_per_cell:number
    row_titles_width: number;
    row_min_height: number;
    instant_feedback: boolean;
  };
  validation: {
    scoring_type: string;
    penalty: number;
    feedback_attempts:number;
    max_score: string;
    min_score_if_attempted: number;
  };
  feedback_attempts: number;
  max_length: number;
  case_sensitive: boolean;
  spellcheck: boolean;
  rounding:string;
  aria_label: string;
  ignore_leading_and_trailing_spaces: true;
  enable_fullwidth_scoring: boolean;
  character_map: any;
  automarkable: true;
  duplicate_responses:boolean
};
export type MoreOptionmatchlist = {
  metadata: {
    distractor_rationale: string;
    rubric_reference: string;
    sample_answer: string;
    acknowledgements: string;
    stimulus_review: string;
    instructor_stimulus: string;
    distractor_rationale_response_level: string[];
  };
  ui_style: {
    fontsize: string;
    is_math: boolean;
    unscored:boolean
    possibility_list_position:string
    validation_stem_numeration:string
    instant_feedback: boolean;
  };
  validation: {
    scoring_type: string;
    penalty: number;
    feedback_attempts:number;
    max_score: string;
    min_score_if_attempted: number;
  };
  duplicate_responses:boolean;
  rounding:string;
  automarkable: true;
};
export type MoreOptionOrderlist = {
  metadata: {
    distractor_rationale: string;
    rubric_reference: string;
    sample_answer: string;
    acknowledgements: string;
    stimulus_review: string;
    instructor_stimulus: string;
  };
  ui_style: {
    fontsize: string;
    is_math: boolean;
    unscored:boolean
    list_style:string
    validation_stem_numeration:string
    instant_feedback: boolean;
  };
  validation: {
    scoring_type: string;
    penalty: number;
    feedback_attempts:number;
    max_score: string;
    min_score_if_attempted: number;
  };

  // case_sensitive: boolean;
  // spellcheck: boolean;
  rounding:string;
  // aria_label: string;
  // ignore_leading_and_trailing_spaces: true;
  // enable_fullwidth_scoring: boolean;
  // character_map: any;
  automarkable: true;
};
export type MoreOptionSortlist = {
  metadata: {
    distractor_rationale: string;
    rubric_reference: string;
    sample_answer: string;
    acknowledgements: string;
    stimulus_review: string;
    instructor_stimulus: string;
  };
  ui_style: {
    fontsize: string;
    is_math: boolean;
    unscored:boolean
    show_drag_handle:boolean
    validation_stem_numeration:string
    instant_feedback: boolean;
  };
  validation: {
    scoring_type: string;
    penalty: number;
    feedback_attempts:number;
    max_score: string;
    min_score_if_attempted: number;
  };

  // case_sensitive: boolean;
  // spellcheck: boolean;
  rounding:string;
  // aria_label: string;
  // ignore_leading_and_trailing_spaces: true;
  // enable_fullwidth_scoring: boolean;
  // character_map: any;
  automarkable: true;
};
export type MatchListSubQuestionData = {
  type: string;
  question: string;
  more_options: MoreOptionmatchlist;
  options: {
    possible_responses: {
      value: string[];
    };
  };
  correct_answer: {
    stimulus_list: string[];
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      id?: string;
      score: number;
      value: string[];
    }[];
    group_possible_responses: boolean;
    possible_response_groups: {
      title: string;
      responses: string[];
    }[];
  };
};

export type ClassificationSubQuestionData = {
  type: string;
  question: string;
  more_options: MoreOptionClassify;
  correct_answer: {
    ui_style: {
      column_titles: string[];
      row_titles: string[];
    };
    possible_responses: string[];
    valid_response: {
      score: string;
      value: string[];
    };
    alt_response?: {
      score: string;
      value: string[];
    }[];
  };
};
export type SortListSubQuestionData = {
  type: string;
  question: string;
  more_options: MoreOptionSortlist;
  correct_answer: {
    stimulus_list: string[];
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      score: number;
      value: string[];
    }[];
  };
};
export type OrderListSubQuestionData = {
  type: string;
  question: string;
  more_options: MoreOptionOrderlist;
  correct_answer: {
    stimulus_list: string[];
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      score: number;
      value: string[];
    }[];
  };
};

export const ItemTypes = {
  MATCH: "machli",
  CLASS: "class",
  Order: "ordlis",
  Sort: "sortlis",


};

export type GroupMatchList = {
  id: string;
  title: string;
  responses: string[];
};

export type AnswerTab = {
  id: number;
  content: string;
};

export type shadingSubQuestionData = {
  type: string;
  question: string;
  more_options: MoreOptionShading;
  options: {
    possible_responses: {
      value: string[];
    };
  };
  correct_answer: {
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      id?: string;
      score: number;
      value: string[];
    }[];
  };
};

export type MoreOptionShading = {
  metadata: {
    distractor_rationale: string;
    rubric_reference: string;
    sample_answer: string;
    acknowledgements: string;
    stimulus_review: string;
    instructor_stimulus: string;
    distractor_rationale_response_level: string[];
  };
  ui_style: {
    fontsize: string;
    is_math: boolean;
    unscored:boolean
    possibility_list_position:string
    validation_stem_numeration:string
    instant_feedback: boolean;
  };
  validation: {
    scoring_type: string;
    penalty: number;
    feedback_attempts:number;
    max_score: string;
    min_score_if_attempted: number;
  };
};

export type ImageAnnotationData = {
  type: string;
  question: string;
  image?: {
    source?: string;
    width?: number;
    height?: number;
    alt?: string;
    textHover: string;
  };
  more_options: MoreOptionmatchlist;
  correct_answer: {
    valid_response: {
      score: number;
      value: string[];
    };
  };
};

export interface GridAnswer {
  content: string;
  score: number;
}

export interface GridAlternativeAnswer extends GridAnswer {
  id: string;
}

export interface GridAnswers {
  correct: GridAnswer;
  alternatives: GridAlternativeAnswer[];
}
export interface GridQuestion {
  question?: string;
  type: string;
  answers: {
    valid_response: { value: string; score: number };
    alt_responses: Array<{ id: string; value: string; score: number }>;
  };
}
export interface QuestionState {
  subQuestions: {
    grid?: GridQuestion;
    [key: string]: any;
  };
}

export interface Question {
  id: string;              // Unique identifier for the question
  text: string;            // The content of the question
  type: string;            // Type of question (e.g., "multiple-choice", "true/false", etc.)
  difficulty: string;      // Difficulty level of the question (e.g., "easy", "medium", "hard")
  created_by: string;      // Who created the question
  created_at: string;      // Timestamp when the question was created
  updated_at: string;      // Timestamp when the question was last updated
  status: boolean;         // Whether the question is active or not
  options: {
      id: number;          // Option ID
      text: string;        // Text for the option
      is_correct: boolean; // Whether this option is correct
  }[];                     // Array of options if it's a multiple-choice question
  correct_answer: string | null; // For non-multiple choice questions, the correct answer
  tags: string[];          // Tags related to the question (e.g., "math", "algebra")
}

