import { Action, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from '../reducers/rootReducer';
import { composeWithDevTools } from '@redux-devtools/extension';
import { ThunkAction } from '@reduxjs/toolkit';

// Function to determine the current path
const getCurrentPath = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname;
  }
  return '/'; // Default to root if window is not defined
};

// Function to create a dynamic persist config based on the current path
const createPersistConfig = (path: string) => {
  if (path.startsWith('/obs-service/')) {
    return {
      key: 'service',
      storage,
      blacklist: ['selectedQuestions'],
    };
  } else {
    return {
      key: 'root',
      storage,
      blacklist: ['selectedQuestions'],
    };
  }
};

// Function to create the Redux store
export function makeStore() {
  const currentPath = getCurrentPath();  // Get the path once before store creation
  const persistConfig = createPersistConfig(currentPath);  // Configure persistConfig based on path
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middleware = [thunk];
  const storeEnhancers = composeWithDevTools(applyMiddleware(...middleware));

  const store = createStore(persistedReducer, storeEnhancers);
  const persistor = persistStore(store);  // Ensure persistor is created once

  return { store, persistor };
}

// Initialize store and persistor
const { store, persistor } = makeStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default  store;
