import React from 'react';

interface Cross {
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<SVGSVGElement>; // Optional onClick prop
}
const Cross = ({ style = {}}) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg"  width="16" height="16.05" viewBox="0 0 16 16.05">
        <path id="close_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24" d="M220.7-725.26l-1.2-1.22,6.78-6.8-6.78-6.8,1.2-1.22,6.8,6.8,6.8-6.8,1.2,1.22-6.78,6.8,6.78,6.8-1.2,1.22-6.8-6.8Z" transform="translate(-219.5 741.31)" fill="#333333"/>
      </svg>
    );
};

export default Cross;
