const UppercaseAscending = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="19.654"
      viewBox="0 0 32 19.654"
    >
      <path
        id="uppercase_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
        d="M175.133-647.456v-12.533l-3.556,3.556-1.838-1.838,6.71-6.711,6.71,6.711-1.838,1.838-3.547-3.556v12.533Zm-23.973,0,7.279-19.654h2.7l7.292,19.654H165.8l-1.881-5.41h-8.273l-1.907,5.41Zm5.3-7.6h6.655l-3.264-9.268h-.138Z"
        transform="translate(-151.16 667.11)"
        fill="#333"
      />
    </svg>
  );
};

export default UppercaseAscending;
