import React from 'react';

interface HorizontalDeviderProps {
  propStyle?: React.CSSProperties; // Make propStyle optional
}

const HorizontalDevider: React.FC<HorizontalDeviderProps> = ({ propStyle = {} }) => {
  const borderStyle: React.CSSProperties = {
    borderTop: "1.5px solid #E1E1E1", // Border style
    margin: "20px 0px", // Margin for spacing
  };

  return (
    <div style={{ ...borderStyle, ...propStyle }}></div>
  );
};

export default HorizontalDevider;
