import React from 'react';

interface BlackDelete {
    style?: React.CSSProperties; // Optional style prop
}

const BlackDelete = ({ style = {} }) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="16" height="18.728" viewBox="0 0 16 18.728">
            <path id="delete_FILL0_wght300_GRAD0_opsz20"
                  d="M215.353-772.655a1.849,1.849,0,0,1-1.357-.562,1.849,1.849,0,0,1-.562-1.357v-13.961h-.657a.754.754,0,0,1-.553-.221.742.742,0,0,1-.223-.549.763.763,0,0,1,.223-.555.744.744,0,0,1,.553-.227h4.358v-.358a.9.9,0,0,1,.275-.662.9.9,0,0,1,.662-.275h3.858a.9.9,0,0,1,.662.275.9.9,0,0,1,.275.662v.358h4.358a.755.755,0,0,1,.553.221.742.742,0,0,1,.223.549.763.763,0,0,1-.223.555.744.744,0,0,1-.553.227h-.657v13.948a1.86,1.86,0,0,1-.562,1.375,1.857,1.857,0,0,1-1.357.558Zm9.662-15.881h-10.03v13.961a.358.358,0,0,0,.1.264.358.358,0,0,0,.264.1h9.3a.358.358,0,0,0,.264-.1.358.358,0,0,0,.1-.264Zm-7.4,12.179h1.552v-10.03h-1.552Zm3.215,0h1.552v-10.03h-1.552Zm-5.846-12.179v0Z"
                  transform="translate(-212.001 791.383)" fill="#000000"/>
        </svg>
    );
};

export default BlackDelete;
