const Ascend = ({ style = {} }) => (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
        <path id="arrow_forward_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24_1_"
              data-name="arrow_forward_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24 (1)"
              d="M196.236-766.442H188.08v-.959h8.156l-3.839-3.839.683-.681,5,5-5,5-.683-.68Z"
              transform="translate(-761.92 -188.08) rotate(90)" fill="#333"/>
    </svg>

);

export default Ascend;