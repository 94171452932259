import {ImageAnnotationData} from '../types';

export const handleImageUploadQuestion = (
    state: any,
    key: string,
    subKey: string,
    value: any
): void => {
    // Always initialize the base structure for the key if it doesn't exist

    state.subQuestions[key].type = key;

    if (!state.subQuestions[key]) {
        state.subQuestions[key] = {
            type: key,
            question: "",
            image: {},
            correct_answer: {
                valid_response: {
                    score: 0,
                    value: [],
                },
            },
            more_options: {},
        } as ImageAnnotationData;
    }

    // Handle specific subKey updates
    if (['textHover', 'width', 'alt', 'source', 'height'].includes(subKey)) {
        // Update image properties
        state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
        };
        return;
    }

    if (['points', 'correct_answer'].includes(subKey)) {
        // Ensure correct_answer structure exists
        if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {
                valid_response: { score: 0, value: [] }
            };
        }

        if (!state.subQuestions[key].correct_answer.valid_response) {
            state.subQuestions[key].correct_answer.valid_response = {
                score: 0,
                value: []
            };
        }

        // Update scoring or correct answer values
        if (subKey === 'points') {
            state.subQuestions[key].correct_answer.valid_response.score = value;
        } else {
            state.subQuestions[key].correct_answer.valid_response.value = value;
        }
        return;
    }

    if (subKey === 'more_options') {
        // Update more_options
        state.subQuestions[key].more_options = {
            ...state.subQuestions[key].more_options,
            ...value,
        };
        return;
    }

    if (subKey === '') {
        // Directly update the entire key's properties
        state.subQuestions[key] = {
            ...state.subQuestions[key],
            ...value,
        };
        return;
    }
    else {
        state.subQuestions[key][subKey] = value;
    }
    // General case for other subKeys
    //state.subQuestions[key][subKey] = value;
};

export const handleGridQuestion = (state: any, key: string, subKey: string, value: any): void => {
    // Initialize grid question if it doesn't exist

    state.subQuestions[key].type = key;

    if (!state.subQuestions[key]) {
        state.subQuestions[key] = {
            type: 'grid',
            correct_answer: {
                valid_response: { value: [], score: 0 },
                alt_responses: []
            }
        };
    }

    else {
        state.subQuestions[key][subKey] = value;
    }
};

export const generateWords = (content: string) => { 
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const elements = Array.from(doc.body.children);
    let currentId = 0;

    const responses = elements.flatMap((element, key) => {
    const result = [];
    let position = 0;

    if (element.tagName === 'P') {
        const childNodes = Array.from(element.childNodes);

        childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            const wordsArray = node.textContent.trim().split(/\s+|&nbsp;/);
            wordsArray.forEach((word) => {
            const cleanedWord =
                word === '<p><br></p>'
                ? '*'
                : word === '<p>&nbsp;</p>'
                ? '*'
                : word;
            result.push({
                type: 'txt',
                content: cleanedWord,
                id: currentId,
                position: position++,
            });

            if (cleanedWord === 'Response') {
                result.push({
                type: 'Response',
                content: cleanedWord,
                id: currentId,
                position: position++,
                });
            }
            });
        } else if (
            node.nodeType === Node.ELEMENT_NODE &&
            (node as Element).tagName === 'IMG'
        ) {
            const imgElement = node as HTMLImageElement;
            result.push({
            type: 'img',
            content: imgElement.src,
            width: imgElement.width,
            height: imgElement.height,
            id: currentId,
            position: position++,
            });
        } else if (
            ( node.nodeType === Node.ELEMENT_NODE &&
            (node as Element).tagName === 'STRONG'
            ) || 
            // @ts-ignore
            node?.childNodes[0]?.tagName === 'STRONG'
        ) {
            const wordsArray = node.textContent.trim().split(/\s+|&nbsp;/);
            wordsArray.forEach((word) => {
            const cleanedWord =
                word === '<p><br></p>'
                ? '*'
                : word === '<p>&nbsp;</p>'
                ? '*'
                : word;
            if (cleanedWord === 'Response') {
                result.push({
                type: 'Response',
                content: cleanedWord,
                id: currentId,
                position: position++,
                });
            } else {
                result.push({
                type: 'txt',
                content: cleanedWord,
                id: currentId,
                position: position++,
                });
            }
            });
        }
        });

        currentId++;
    } else if (element.tagName === 'FIGURE') {
        Array.from(element.childNodes).forEach((node, index) => {
        if (
            node.nodeType === Node.ELEMENT_NODE &&
            (node as Element).tagName === 'TABLE'
        ) {
            result.push({
            type: 'table',
            content: (node as Element).outerHTML, // You can use more detailed parsing if needed
            id: currentId,
            position: position++,
            });
        // } else if (
        //     node.nodeType === Node.ELEMENT_NODE &&
        //     (node as Element).tagName === 'IMG'
        // ) {
        //     const imgElement = node as HTMLImageElement;
        //     result.push({
        //     type: 'img',
        //     content: imgElement.src,
        //     width: imgElement.width,
        //     height: imgElement.height,
        //     id: currentId,
        //     position: position++,
        //     });
        // }
        // });

        } else if (
            node.nodeType === Node.ELEMENT_NODE &&
            (node as Element).tagName === 'IMG'
          ) {
            const imgElement = node as HTMLImageElement;

            let imgalignment = '';

            if (element.className.includes('image-style-side')) {
              imgalignment = 'right';
            } else if (element.className.includes('image')) {
              imgalignment = 'center';
            }

            result.push({
              type: 'img',
              content: imgElement.src,
              width: imgElement.width,
              height: imgElement.height,
              id: currentId,
              position: position++,
              alttext: imgElement.alt,
              imgalignment: imgalignment,
            });
          }
        });

        currentId++;
    }
    return result;
    });

    const modifiedFilledWords = responses?.reduce((result, res) => {
    if (res && res?.content === '') {
        result.push({
        type: 'txt',
        content: '\n',
        id: res.id,
        position: res.position,
        });
    } else {
        result.push(res);
    }
    return result;
    }, []);

    return modifiedFilledWords;
}

export const getNewMatrix = (oldMatrix, allOptions, testItems) => {
  let newMatrix = [...oldMatrix];
  newMatrix = newMatrix.map((item) => {
    let stem = item?.stem ? item.stem : '';
    allOptions.forEach((opt) => {
      if (item.id == opt.id) {
        stem = opt.label;
      }
    });
    let itemTestItems = item?.options.map((itOpt) => {
      let label = itOpt?.label;
      testItems.forEach((ti) => {
        if (ti?.id == itOpt?.id) {
          label = ti?.label;
        }
      });
      return { ...itOpt, label };
    });

    testItems.forEach((ti) => {
      if (!itemTestItems.some((it) => it?.id == ti?.id)) {
        itemTestItems.push({
          id: ti?.id,
          isChecked: false,
          label: ti?.label || '',
        });
      }
    });

    return {
      ...item,
      stem,
      options: itemTestItems.filter(
        (iti) => testItems.findIndex((ti) => iti?.id == ti?.id) > -1
      ),
    };
  });

  allOptions.forEach((opt) => {
    if (!newMatrix.some((item) => item.id == opt.id)) {
      newMatrix = [
        ...newMatrix,
        {
          id: opt?.id,
          stem: opt.label,
          options: testItems.map((option) => ({
            id: option?.id,
            label: option.label,
            isChecked: false,
          })),
        },
      ];
    }
  });
  newMatrix = newMatrix.filter(
    (item) => allOptions.findIndex((opt) => opt?.id == item?.id) > -1
  );

  return newMatrix;
};

export const getMatrixChildType = (questionType: string, moreOptions: any) => {
  let matrixType = 'inline';
  if(moreOptions && moreOptions?.layout?.type) {
  matrixType = moreOptions?.layout?.type;
  }else if(['cms', 'cml', 'cmsnl'].includes(questionType)) {
  matrixType = 'table';
  }
  return matrixType;
}

export const stripHtmlTagsAndTrackNewlines = (html: string) => {
  let positions = [];
  let strippedText = "";
  let index = 0;

  // Temporary element to parse HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  function traverse(node) {
      if (node.nodeType === Node.TEXT_NODE) {
          strippedText += node.nodeValue;
          index += node.nodeValue.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (["P", "DIV", "LI", "TR", "BR", "H1", "H2", "H3", "H4", "H5", "H6"].includes(node.tagName)) {
              if (strippedText.length > 0) {
                  strippedText += "\n";  // Add newline for block elements
                  positions.push(index); // Store position where newline is added
                  index += 1;
              }
          }
          node.childNodes.forEach(traverse);
      }
  }

  traverse(tempDiv);

  return { strippedText: strippedText.trim(), positions };
}

// const parseTemplateResponse = () => {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(
    //         currentQuestion?.template_response || '',
    //         'text/html'
    //     );

    //     const elements = Array.from(doc.body.children);
    //     let currentId = 0;

    //     const responses = elements.flatMap((element, key) => {
    //         if (element.tagName === 'P') {
    //             const parentContent = element.textContent?.trim() || '';
    //             const wordsArray = parentContent.split(/\s+|&nbsp;/);

    //             const result = wordsArray.map((word, index) => {
    //                 const cleanedWord = word === '<p><br></p>' ? '*' : word;

    //                 if (cleanedWord === 'Response') {
    //                     return { type: 'Response', content: cleanedWord, id: currentId, position: index };
    //                 } else {
    //                     return { type: 'txt', content: cleanedWord, id: currentId, position: index };
    //                 }
    //             });

    //             currentId++; // Increment id for the next <p> element
    //             return result;
    //         } else if (element.tagName === 'DIV' && element.classList.contains('rectangle')) {
    //             const content = element?.textContent?.trim() || '';
    //             return [{ type: 'Response', content: content, id: key, position: 0 }];
    //         }
    //         return [];
    //     });

    //     const modifiedFilledWords = responses.reduce((result, res) => {
    //         if (res.content === '') {
    //             result.push({ type: 'txt', content: '\n', id: res.id, position: res.position });
    //         } else {
    //             result.push(res);
    //         }
    //         return result;
    //     }, [] as { type: string; content: string; id: number; position: number }[]);

    //     // setFilledWords(modifiedFilledWords);
    //     // setResponses(modifiedFilledWords);
    // };
