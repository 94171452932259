import DOMPurify from "dompurify";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {produce} from "immer";
import RadioInput from "../../../components/ComposeQuestion/components/radioInput";
import { getLabelByStemNumeration } from "utils/generalUtils";

const ChildContent = styled.div`
  padding: 20px;
  margin: 25px 0;
  background: #fff;
`;
const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 25px 0;
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  
  /* Ensure the table takes full width */
  table {
    min-width: max-content;
  }
`;
const TableForMatrix = ({
    testItems,// cols,
    data,
    showAnswer,
    valid_response_value // activeTab
  }) => {
    const textRef = useRef<Array<HTMLDivElement | null>>([]);
    const textOptionRef = useRef<Array<HTMLDivElement | null>>([]);
    const more_options = data?.more_options;
    const type = data?.type;
    const layout = more_options ? more_options?.layout : null;
console.log('datadatadatadatadatadata',data);

    let stemTitle = layout && layout?.column_title ? layout?.column_title : '';
    let optTitle = layout && layout?.row_title ? layout?.row_title : '';
    if(stemTitle && !optTitle) optTitle = ' ';
    if(optTitle && !stemTitle) stemTitle = ' ';
    const hasDividers = layout && layout?.divider ? layout?.divider : false;
    const fontSize = layout && layout?.fontSize ? layout?.fontSize : 'normal';
    const stemWidth = layout && layout?.stem_width ? layout?.stem_width + 'px' : '';
    const optionWidth = layout && layout?.option_width ? layout?.option_width + 'px' : '';
    const [sanitizedItems, setSanitizedItems] = useState(testItems);
    const [optionsTemp, setOptionsTemp] = useState(() =>
        valid_response_value.map((row) => ({
          ...row,
          options: row.options.map((option) => ({
            ...option,
            isChecked: false,
          })),
        }))
    );

    useEffect(() => {
        valid_response_value?.map((item, i) => {
          if (textRef && textRef?.current && textRef.current[i]) {
            textRef.current[i].innerHTML = DOMPurify.sanitize(item?.stem);
            if (window.MathJax && window.MathJax.typesetPromise) {
              window.MathJax.typesetPromise([textRef.current[i]])
                .then(() => {
                  console.log('MathJax typesetting completed');
                })
                .catch((err: Error) => {
                  console.error('MathJax typesetting failed:', err);
                });
            } 

           // window.MathJax.typesetPromise([textRef.current[i]]);
            return textRef.current[i].innerHTML;
          }
        });
      }, [valid_response_value]);

      useEffect(() => {
        if (testItems) {
          testItems?.map((item, i) => {
            if (textOptionRef.current[i]) {
              const sanitizedContent = DOMPurify.sanitize(item?.label);
              // Set the content as text (no HTML tags)
              textOptionRef.current[i].textContent = sanitizedContent.replace(/<[^>]*>/g, '');
              if (window.MathJax && window.MathJax.typesetPromise) {
                window.MathJax.typesetPromise([textOptionRef.current[i]])
                  .then(() => {
                    console.log('MathJax typesetting completed');
                  })
                  .catch((err: Error) => {
                    console.error('MathJax typesetting failed:', err);
                  });
              }

             // window.MathJax.typesetPromise([textOptionRef.current[i]]);
            }

            return textOptionRef.current[i]?.textContent;
          });
        }
      }, [testItems]);
  useEffect(() => {

    // Map over the testItems and sanitize them
    const updatedTestItems = testItems.map((item) => {
      const sanitizedLabel = DOMPurify.sanitize(item?.label).replace(/<[^>]*>/g, ''); // Remove HTML tags

      // Return a new object with the sanitized label
      return {
        ...item, // retain other properties of the item
        label: sanitizedLabel, // updated label with sanitized content
      };
    });

    // Now, update the state with the new sanitized testItems
    setSanitizedItems(updatedTestItems);
  }, [testItems]);
  const handleTestItemClicked = (rowId, colId) => {
    setOptionsTemp((prevData) =>
        produce(prevData, (draft) => {
          const question = draft.find((q) => q.id === rowId);
          if (!question) return;
          const option = question.options.find((o) => o.id === colId);
          if (option) option.isChecked = !option.isChecked;
        })
    );

  }
  const renderOptionCheckBox = (rowId:string, colId: string): boolean => {
    const row = optionsTemp.find((item) => item.id === rowId);
    if (!row) return false;

    const option = row.options.find((opt) => opt.id === colId);
    if (!option) return false;

    return option.isChecked;
  }

  const renderOptionRadio = (rowId:string, colId:string): string | undefined => {
    const row = optionsTemp.find((item) => item.id === rowId);
    if (!row) return undefined;

    const option = row.options.find((opt) => opt.id === colId);
    if (!option) return undefined;

    return option.isChecked ? option.label : '';
  }
  const handleTestItemClickedRadio = (rowId, colId) => {
    setOptionsTemp((prevData) =>
        produce(prevData, (draft) => {
          const question = draft.find((q) => q.id === rowId);
          if (!question) return;

          // Update all options in the row: uncheck them
          question.options.forEach((option) => {
            option.isChecked = option.id === colId; // Only the clicked option gets checked
          });
        })
    );
  };
    return (
      <ChildContent>
              <ScrollContainer>

        {data?.settings?.multiple_response ? (
          <table 
            className="w-fit text-center text-gray-500 mb-4"
            style={{fontSize: fontSize}}
          >
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr
              className={hasDividers ? "border border-b-gray-200 " : ""}
              >
                {
                  stemTitle && 
                  <th 
                    className="bg-gray-200 px-6 py-3" 
                    colSpan={1}
                    style={stemWidth ? {width: stemWidth} : {}}
                  >
                    {stemTitle}
                  </th>
                }

                {
                  optTitle &&
                  <th 
                    className="bg-gray-200 px-6 py-3 text-center" 
                    colSpan={Array.isArray(testItems) ? testItems?.length : 2}
                  >
                    {optTitle}
                  </th>
                }
              </tr>
              <tr
                className={hasDividers ? "border border-b-gray-200 " : ""}
              >
                <th className="px-6 py-3"></th>
                {Array.isArray(sanitizedItems) &&
                    sanitizedItems.map((option, optionIndex) => (
                    <th 
                      className="px-6 py-3" 
                      key={optionIndex}
                      style={optionWidth ? {width: optionWidth} : {}}
                    >
                      {option.label}
                    </th>
                  ))}
              </tr>
            </thead>
  
            <tbody>
              {Array.isArray(valid_response_value) &&
                valid_response_value.map((row, index) => (
                  <tr
                    className={hasDividers ? "border border-b-gray-200 " : ""} 
                    key={row?.id}
                  >
                   {/* {['cms', 'cmi'].includes(type) && (
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-left">
                         {row?.stem} 
                        <div id="cms" style={{whiteSpace:"pre-wrap"}}  ref={(el) => (textRef.current[index] = el)} />
                      </td>
                    )}  */}
  
                  { ['cml', 'cmsnl','cms', 'cmi'].includes(type) && (

                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-left">
                        <div className="flex items-center gap-2">
                          <p
                            style={{
                              padding: '5px',
                              backgroundColor: '#ddd',
                              width: '30px',
                              height: '30px',
                              textAlign: 'center',
                            }}
                          >
                            {/* {String.fromCharCode(index + 65)} */}
                            {getLabelByStemNumeration(more_options, index)}

                          </p>
                          <p>
                            {/* {row?.stem} */}
                            <div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textRef.current[index] = el)}
  dangerouslySetInnerHTML={{ __html:row?.stem }}
>
</div>
                            {/* <div id="cms" style={{whiteSpace:"pre-wrap"}}  ref={(el) => (textRef.current[index] = el)} /> */}
                          </p>
                        </div>
                      </td>
                    )}
  
                    {Array.isArray(row?.options) &&
                      row?.options.map((option) => (
                        <td
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          key={option?.id}
                        >
                          <label>
                            <input
                              type="checkbox"
                              className={showAnswer ? 'accent-green-100' : 'accent-inherit'}
                              checked={showAnswer ? option?.isChecked : renderOptionCheckBox(row?.id, option?.id) || false}
                              onChange={() => {
                                 handleTestItemClicked(row?.id, option?.id);
                              }}
                            />
                          </label>
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <table 
            className="w-fit text-center text-gray-500 mb-4"
            style={{fontSize: fontSize}}
          >
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr
              className={hasDividers ? "border border-b-gray-200 " : ""}
              >
              {
                stemTitle && 
                <th 
                  className="bg-gray-200 px-6 py-3" 
                  colSpan={1}
                  style={stemWidth ? {width: stemWidth} : {}}
                >
                  {stemTitle}
                </th>
              }

              {
                optTitle &&
                <th 
                  className="bg-gray-200 px-6 py-3 text-center" 
                  colSpan={Array.isArray(testItems) ? testItems?.length : 2}
                >
                  {optTitle}
                </th>
              }
              </tr>

              <tr
                className={hasDividers ? "border border-b-gray-200 " : ""}
              >
                <th className="px-6 py-3"></th>
                {Array.isArray(testItems) &&
                  testItems.map((option, optionIndex) => (
                    <th 
                      className="px-6 py-3" 
                      key={optionIndex}
                      style={optionWidth ? {width: optionWidth} : {}}
                    >
                      <p>  <div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textOptionRef.current[optionIndex] = el)}
  dangerouslySetInnerHTML={{ __html:option?.label }}
>
</div></p>
                        {/* <div id="cms" style={{whiteSpace:"pre-wrap"}}  ref={(el) => (textOptionRef.current[optionIndex] = el)} /> */}
                    </th>
                  ))}
              </tr>
            </thead>
  
            <tbody>
              {Array.isArray(valid_response_value) &&
                valid_response_value.map((row, index) => (
                  <tr key={row?.id} className={hasDividers ? "border border-b-gray-200 " : ""} >
                      {['cml', 'cmsnl','cms', 'cmi'].includes(type) && (

<td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-left">
                        <div className="flex items-center gap-2">
                                              <p
                            style={{
                              padding: '5px',
                              backgroundColor: '#ddd',
                              width: '30px',
                              height: '30px',
                              textAlign: 'center',
                            }}
                          >
                                                    {getLabelByStemNumeration(more_options, index)}
                                                    </p>
                        {/* {row?.stem} */}
                        <p> 
                           <div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textRef.current[index] = el)}
  dangerouslySetInnerHTML={{ __html:row?.stem }}
>
</div>
</p>
                        {/* <div id="cms" style={{whiteSpace:"pre-wrap"}}  ref={(el) => (textRef.current[index] = el)} /> */}
                    </div>  </td>
                     
                    )}
  
                    {/* {type === 'cml' && ( */}
                                          {/* {['cms', 'cml'].includes(type) && (

                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-left">
                        <div className="flex items-center gap-2">
                          <p
                            style={{
                              padding: '5px',
                              backgroundColor: '#ddd',
                              width: '30px',
                              height: '30px',
                              textAlign: 'center',
                            }}
                          >
                            {getLabelByStemNumeration(more_options, index)}

                          </p>
                          <p>
                            <div
  style={{ whiteSpace: "pre-wrap" }}
  ref={(el) => (textRef.current[index] = el)}
  dangerouslySetInnerHTML={{ __html:row?.stem }}
>
</div>
                          </p>
                        </div>
                      </td>
                    )} */}
  
                    {Array.isArray(row?.options) &&
                      row?.options.map((option) => (
                        <td
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          key={option?.id}
                        >
                          <label>
                            <input
                              type="radio"
                              name={row?.id}
                              value={option.label}
                              className={showAnswer ? 'accent-green-100' : 'accent-inherit'}
                              checked={showAnswer ? option?.isChecked : renderOptionRadio(row?.id, option?.id) === option.label || false}
                              onChange={() => {
                                handleTestItemClickedRadio(row?.id, option?.id);
                              }}
                            />

                          </label>
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
          </ScrollContainer>

      </ChildContent>
    );
};

export default TableForMatrix;