import { Radio } from "antd";

  const SingleChoiceTemplate = () => (
    <Radio.Group
        className="flex flex-col gap-3 px-4"
        value={""}
        options={[
          { value: 1, label: "SingleOption 1" },
          { value: 2, label: "SingleOption 2" },
          { value: 3, label: "SingleOption 3" },
          { value: 4, label: "SingleOption 4" },
        ]}
      />
  );
  export default SingleChoiceTemplate;
  