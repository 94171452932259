import { Button, Modal } from 'antd';
import React from 'react';
import './QuestionsStartPlayer-styles.scss';

interface ConfirmationSectionProps {
    isVisible: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    summary: {
        total: number;
        answered: number;
        unanswered: number;
    };
}

const ConfirmationSection = ({
    isVisible,
    onCancel,
    onSubmit,
    summary
    }: ConfirmationSectionProps): JSX.Element => {
    return (
        <Modal
            centered
            closable={false}
            title="Submit assessment"
            open={isVisible}
            onCancel={onCancel}
            footer={[
                <Button
                    key="back"
                    onClick={onCancel}
                    className="confirmation-modal__back-btn"
                >
                    Go Back
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={onSubmit}
                    className="confirmation-modal__submit-btn"
                >
                    Submit Anyway
                </Button>
            ]}
            className="confirmation-modal"
            width={740} // Match CSS width
        >
            <div className="confirmation-content">
                <p>You have [{summary.unanswered}] unanswered questions in this exam.</p>
                <p>Submitting now will finalize your answers, and you won't be able to make changes.</p>
            </div>
        </Modal>
    );
};

export default ConfirmationSection;