import { GriddedConfig } from '../GriddedResponse';

export const defaultGriddedConfig: GriddedConfig = {
    columns: 6,
    range: { min: 0, max: 9 },
    options: {
        plusMinus: false,
        fractionSlash: false,
        fixedDecimal: false,
        floatingDecimal: false,
        decimalColumn: null
    },
    fontSize: 'normal',
    scoring: {
        maxScore: 100,
        partialCredit: false
    }
};

export interface ScoringConfig {
    maxScore?: number;
    partialCredit?: boolean;
    enableAutoScoring?: boolean;
}

