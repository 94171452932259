import { DragIndicatorOutlined } from "@mui/icons-material";
import CustomJoditEditor from "components/CustomJoditEditor";
import { DragEventHandler, FocusEventHandler, MouseEventHandler } from "react";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";
import { BsTrash } from "react-icons/bs";

type IEditableOptionProps = {
  index: number;
  dragItem: any;
  dragOverItem: any;
  onDragEnd: DragEventHandler<HTMLDivElement>;
  OnDeleteElement?: MouseEventHandler<SVGElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  isActive?: boolean;
  label: any;
  onOptionChange: (e, index) => void;
};

const EditableOption = ({
  index,
  dragItem,
  dragOverItem,
  onDragEnd,
  OnDeleteElement,
  onClick,
  onFocus,
  onBlur,
  isActive,
  label,
  onOptionChange,
}: IEditableOptionProps) => {
  const containerStyle = {
    minHeight: 48,
    border: `1px solid ${Colors.inputBorder}`,
    borderRadius: Sizes.borderRadius,
  };

  return (
    <div
      key={index}
      className="flex items-center mb-2"
      draggable
      onDragStart={(e) => {
        dragItem.current = index;
      }}
      onDragEnter={(e) => {
        dragOverItem.current = index;
      }}
      onDragEnd={onDragEnd}
      onDragOver={(e) => e.preventDefault()}
      style={containerStyle}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {isActive ? (
        <CustomJoditEditor
          className="custom_jodit flex-1"
          label=""
          value={label}
          allconfig={true}
          onChange={onOptionChange}
          index={index}
          questionconfig={true}
        />
      ) : (
        <>
          <div
            className="w-[48px] h-[48px] flex justify-center items-center"
            style={{
              borderRight: `1px solid ${Colors.inputBorder}`,
            }}
          >
            <DragIndicatorOutlined
              style={{
                width: "0.84em",
                height: "1.8em",
                color: Colors.iconBlack,
                cursor: "move",
              }}
            />
          </div>

          <div
            className="flex-1 h-12 flex items-center px-4"
            // @ts-ignore
            onClick={onFocus}
            dangerouslySetInnerHTML={{ __html: label }}
          />

          {OnDeleteElement && (
            <div
              className="w-[48px] h-[48px] flex justify-center items-center"
              style={{
                borderLeft: `1px solid ${Colors.inputBorder}`,
              }}
            >
              <BsTrash
                className={`text-[#F80000] cursor-pointer self-center w-4 h-4`}
                onClick={OnDeleteElement}
                size={22}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditableOption;
