import AuthLayout from "../../layouts/auth/Auth.layout";
import ForgotPasswordForm from "../../components/auth/ForgotPasswordForm.component";

const ForgotPasswordFormPage = () => {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

export default ForgotPasswordFormPage;
