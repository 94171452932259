import React from 'react';

const More = ({ style = {} }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3.126" height="16" viewBox="0 0 3.126 16">
            <path id="more_vert_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M423.183-762.84a1.507,1.507,0,0,1-1.106-.457,1.507,1.507,0,0,1-.457-1.106,1.507,1.507,0,0,1,.457-1.106,1.507,1.507,0,0,1,1.106-.457,1.507,1.507,0,0,1,1.106.457,1.507,1.507,0,0,1,.457,1.106,1.507,1.507,0,0,1-.457,1.106A1.507,1.507,0,0,1,423.183-762.84Zm0-6.437a1.507,1.507,0,0,1-1.106-.457,1.507,1.507,0,0,1-.457-1.106,1.507,1.507,0,0,1,.457-1.106,1.507,1.507,0,0,1,1.106-.457,1.507,1.507,0,0,1,1.106.457,1.507,1.507,0,0,1,.457,1.106,1.507,1.507,0,0,1-.457,1.106A1.507,1.507,0,0,1,423.183-769.277Zm0-6.437a1.507,1.507,0,0,1-1.106-.457,1.507,1.507,0,0,1-.457-1.106,1.507,1.507,0,0,1,.457-1.106,1.507,1.507,0,0,1,1.106-.457,1.507,1.507,0,0,1,1.106.457,1.507,1.507,0,0,1,.457,1.106,1.507,1.507,0,0,1-.457,1.106A1.507,1.507,0,0,1,423.183-775.714Z"
                  transform="translate(-421.62 778.84)" fill="#333"/>
        </svg>
    );
};

export default More;
