import React from 'react';
interface UseTemplates {
    style?: React.CSSProperties; // Optional style prop
}
const UseTemplates = ({ style = {}}) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="34" height="33.962" viewBox="0 0 34 33.962">
        <path id="widgets_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24" d="M186.544-826.428l-9.456-9.456,9.456-9.456L196-835.884ZM162-829.593v-13.394h13.394v13.394Zm18.215,18.215v-13.434h13.434v13.434Zm-18.215,0v-13.394h13.394v13.394Zm2.781-21h7.832v-7.832h-7.832Zm21.813,2.076,5.536-5.536-5.536-5.536-5.536,5.536ZM183-814.159h7.872v-7.872H183Zm-18.215,0h7.832v-7.832h-7.832ZM172.613-832.374ZM181.058-835.834ZM172.613-821.991ZM183-822.031Z" transform="translate(-162 845.34)" fill="#333"/>
      </svg>
    );
};

export default UseTemplates;
