import React from "react";

export const SeparatorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="19"
    viewBox="0 0 6 10"
    // style={{ marginTop: 12 }}
  >
    <text
      id="_-"
      data-name="-"
      transform="translate(0 15)"
      fill="#333"
      fontSize="14"
      fontFamily="SegoeUI, Segoe UI"
    >
      <tspan x="0" y="0">
        -
      </tspan>
    </text>
  </svg>
);
