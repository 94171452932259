import React, { useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { GriddedInput } from "./GriddedInput";
import { GriddedTable } from "./GriddedTable";
import type { GriddedProps } from "./types";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { useGridConfig } from "./useGridConfig";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GriddedResponse: React.FC<GriddedProps> = ({
  currentQuestion,
  isEditMode,
  value,
  onChange,
  disabled,
}) => {
  const url = window.location.href;
  const questionType = "grid";

  //const { currentQuestion } = useSelector((state: RootState) => state.showQuestion);
  const gridSubQuestions =
    currentQuestion?.sub_questions?.find(
      (subQuestion) => subQuestion.type === questionType
    ) || currentQuestion;
  const question = useSelector((state: RootState) =>
    isEditMode ? gridSubQuestions : state?.question?.subQuestions?.[questionType]
  );
  const sbQuestion = useSelector((state: RootState) =>
    isEditMode
      ? state?.editSubQuestion?.subQuestions?.[questionType]
      : state?.question?.subQuestions?.[questionType]
  );

  //const config = useGridConfig(sbQuestion);
  const config = useGridConfig(
    url.includes("/edit-subquestion/")
      ? sbQuestion
      : question || currentQuestion
  );
  const prevRangeRef = useRef(config.range);
  useEffect(() => {
    const path = window.location.pathname;
    if (!path.startsWith("/obs-service/player")) {
      const prevRange = prevRangeRef.current;
      if (JSON.stringify(prevRange) !== JSON.stringify(config.range)) {
        handleInputChange(Array(config.columns).fill(""));
        prevRangeRef.current = config.range; // Update previous options reference
      }
    } else return;
  }, [config.range]);

  const handleInputChange = (newValues: string[]) => {
    const validatedValues = newValues.map((val) => {
      if (val === "") return "";
      const min = config.range.min;
      const max = config.range.max;
      const digit = val.match(
        new RegExp(`^(${min}|[${min + 1}-${max - 1}]|${max})$`)
      );
      return digit ? digit[0] : "";
    });

    onChange({
      ...value,
      values: validatedValues,
    });
  };

  const handleTableChange = (column: number, inputValue: string) => {
    const currentValues = [...(value?.values || [])];
    const newValues = [...currentValues];
    const symbolTypes = [".", "/"];

    if (symbolTypes.includes(inputValue)) {
      const symbol = inputValue;
      const hadSymbolPreviously = currentValues[column] === symbol;

      newValues.forEach((val, i) => {
        if (val === symbol) {
          newValues[i] = "";
        }
      });

      if (!hadSymbolPreviously) {
        newValues[column] = symbol;
      }
    } else {
      newValues[column] = inputValue;
    }

    onChange({
      ...value,
      values: newValues,
    });
  };

  return (
    <Container>
      <GriddedInput
        columns={config.columns}
        values={value?.values || []}
        fontSize={config.fontSize}
        options={config.options}
        onChange={handleInputChange}
        disabled={disabled}
      />
      <GriddedTable
        columns={config.columns}
        range={config.range}
        options={config.options}
        fontSize={config.fontSize}
        onChange={handleTableChange}
        disabled={disabled}
        selectedValues={value?.values || []}
      />
    </Container>
  );
};

export default GriddedResponse;
