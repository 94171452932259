import { TSortDraggedElement } from 'types';
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Format objects to work with react-select component
 * @param obj
 * @returns formatted obj { id, value, label }
 */
const formatObjects = (obj: any) => {
  if (obj && Object.keys(obj)?.length !== 0) {
    let arr = obj?.map((item: any) => {
      return { id: item.id, value: item.id, label: item.label };
    });

    return arr;
  }
};

/**
 * Format objects to work with Match list component
 * @param obj
 * @returns formatted obj { id, label }
 */
const formatDataWithLabel = (obj: any) => {
  if (obj && Object.keys(obj)?.length !== 0) {
    return obj.map((item: any, key: number) => {
      const stringID = `item-${key + 1}-${btoa(item)}`; 

      return { id: stringID, label: item };
    });
  }
  return [];
};


const setEmptyArrResponses = (arr: number) => {
  let responses = [];

  for (let index = 0; index < arr; index++) {
    responses.push([]);
  }
  return responses;
};

const setEmptyResponses = (arr: any[]) => {
  let responses: any[] = [];
  arr?.map(() => responses.push(''));
  return responses;
};

const sortDraggedElement = ({
                              data,
                              dragItem,
                              dragOverItem,
                            }: TSortDraggedElement) => {
  //duplicate items
  let _optionItems = [...data];

  //remove and save the dragged item content
  const draggedItemContent = _optionItems.splice(dragItem.current, 1)[0];

  //switch the position
  _optionItems.splice(dragOverItem.current, 0, draggedItemContent);

  //reset the position ref
  dragItem.current = null;
  dragOverItem.current = null;

  return _optionItems;
};

// Convert seconds to HH:MM:SS format
const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const pad = (num: number) => (num < 10 ? `0${num}` : num);

  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

const editorAudioControls = (editorcontent:any,playerString:string, isTimerActive:boolean)=>{
  const parser = new DOMParser();
  const doc = parser.parseFromString(editorcontent, 'text/html');
  const audioElements = doc.querySelectorAll('audio');
  // console.log(playerString, " Player Data");

  if (playerString === 'player') {
    // Enable or disable controls based on the condition
    audioElements.forEach(audio => {
      audio.controls = true;
      if(!isTimerActive)
      {
        audio.classList.add('custom-audio-controls');
      }
    });
  }
  return doc.body.innerHTML;
}

function getParsedJwt<T extends object = { [k: string]: string | number }>(
    token: string
): T | undefined {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch {
    return undefined;
  }
}
const formatDataWithLabelForDnD = (obj: any) => {

  if (obj && Object.keys(obj)?.length !== 0) {

    let arr = obj?.map((item: any, key: number) => {

      let arr2 = item.map((el: any) => {

        const stringID = `item-${key + 1}-${new Date().getTime()}`;

        return {id: stringID, label: el};

      });

      return arr2;

    });

    return arr;

  }

};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}


export {
  formatTime,
  formatDataWithLabelForDnD,
  getParsedJwt,
  formatObjects,
  formatDataWithLabel,
  setEmptyArrResponses,
  setEmptyResponses,
  sortDraggedElement,
  editorAudioControls
};
