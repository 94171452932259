import React, {useMemo, useState} from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import ShadingGrid from './ShadingGrid';
import {setClosePreview} from "../../../../redux/slices/PreviewSlice";
import {setTypeQuestionOpenInEditMode} from "../../../../redux/slices/EditModeSlice";
import { ShadingPreviewProps, ShadingOptions, CorrectAnswer } from './types';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';
const PreviewShading: React.FC<ShadingPreviewProps> = ({
                                                           currentQuestion,
                                                           showAnswer = undefined,
                                                           setShowAnswers = undefined,
                                                           parentMode = 'assessment',
                                                           editMode = false,
                                                       }) => {
    const [localShowAnswer, setLocalShowAnswer] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleBackToEditClick = () => {
        dispatch(setClosePreview());
    };

    const handleGoToEditClick = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(EditSubQues(currentQuestion));
        dispatch(
            setTypeQuestionOpenInEditMode({
                type: currentQuestion?.type,
                id: currentQuestion?.id,
            })
        );
        navigate(`/edit-subquestion/${currentQuestion.id}`);
    };

    // Extract and transform data with proper typing
    const options: ShadingOptions = useMemo(() => ({
        row_count: currentQuestion?.options?.row_count ?? 1,
        column_count: currentQuestion?.options?.column_count ?? 1,
        cell_width: currentQuestion?.options?.cell_width ?? 50,
        cell_height: currentQuestion?.options?.cell_height ?? 50,
        shaded: currentQuestion?.options?.shaded ?? [],
        lock_shaded_cell: currentQuestion?.options?.lock_shaded_cell ?? false,
        possible_responses: {
            value: currentQuestion?.options?.possible_responses?.value ?? []
        },
        image: currentQuestion?.options?.image ?? { source: '' },
        canvas_container_style: currentQuestion?.options?.canvas_container_style
    }), [currentQuestion]);

    const correctAnswer = useMemo(() => ({
        score: currentQuestion?.correct_answer?.valid_response?.score ?? "0",
        value: currentQuestion?.correct_answer?.valid_response?.value?.value ?? "0",
        method: currentQuestion?.correct_answer?.valid_response?.value?.method ?? "byCount"
    }), [currentQuestion]);

    return (
        <PreviewWrapper
            currentQuestion={currentQuestion}
            showAnswer={showAnswer ?? localShowAnswer}
            setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
            parentMode={parentMode}
            editMode={editMode}
            handleGoToEdit={handleGoToEditClick}
            handleBackToEdit={handleBackToEditClick}
        >
            <ShadingGrid
                rowCount={options.row_count}
                columnCount={options.column_count}
                cellWidth={options.cell_width}
                cellHeight={options.cell_height}
                shadedCells={options.possible_responses.value}
                hideCells={currentQuestion?.more_options?.layout?.hideCells}
                lockShadedCells={options.lock_shaded_cell}
                possibleResponses={options.possible_responses.value}
                correctAnswer={correctAnswer}
                showAnswer={showAnswer ?? localShowAnswer}
                image={options.image?.source}
                title={currentQuestion?.options?.title}
                alt={currentQuestion?.options?.alt}
                maxSelection={parseInt(currentQuestion?.more_options?.layout?.max_selection)}
                borderType={currentQuestion?.more_options?.layout?.border_type || 'Full'}
                canvasStyle={options.canvas_container_style}
                isHoverState={currentQuestion?.more_options?.layout?.hover_state || false}
                backgroundImage={currentQuestion?.more_options?.image?.source}
                backgroundImageAlt={currentQuestion?.more_options?.image?.alt}
                backgroundImageTitle={currentQuestion?.more_options?.image?.textHover}
                opacity={currentQuestion?.more_options?.image?.opacity}
                width={currentQuestion?.more_options?.image?.width}
                height={currentQuestion?.more_options?.image?.height}
            />
        </PreviewWrapper>
    );
};

export default PreviewShading;
