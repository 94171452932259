import styled from 'styled-components';
import { CellProps } from './types';

export const ShadingCell = styled.div<CellProps>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => {
    if (props.isHidden) return '#ffffff';
    if (props.isShaded && props.isLocked) return '#cccccc';
    if ((props.isSelected)) return '#007bff';
    if (props.backgroundImage) return 'transparent';
    return '#e6f2fc';
}};
  display: flex;
  justify-content: center;
  visibility: ${props => (props.backgroundImage && props.isHidden ? 'hidden' : 'visible')};  justify-content: center;
  align-items: center;
  cursor: ${props => (props.isLocked ? 'not-allowed' : 'pointer')};
  
  ${props => {
    if (props.isHidden && props.borderType !== 'Outer') {
      return 'border: none;';
    }

    switch (props.borderType) {
      case 'Outer':
        return `
                border-top: ${props.$isFirstRow ? '2px solid black' : 'none'};
                border-left: ${props.$isFirstCol ? '2px solid black' : 'none'};
                border-bottom: ${props.$isLastRow ? '2px solid black' : 'none'};
                border-right: ${props.$isLastCol ? '2px solid black' : 'none'};
                box-sizing: border-box;
            `;
      case 'Full':
        return 'border: 2px solid black;';
      case 'None':
        return 'border: none;';
      default:
        return 'border: none;';
    }
  }}
  
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;

  &:hover {
      ${props => props.isHoverState && !props.isHidden && `
      background-color: #cfd9e1;
        opacity: 0.2;
    `}
  }

  ${props => props.showAnswer && `
    ${props.isCorrect && `
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 255, 0, 0.2);
      }
    `}
    ${props.isIncorrect && `
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 0, 0, 0.2);
      }
    `}
  `}
`;

