import React, { useEffect, useState, useRef, useMemo } from "react";
import { Form, Input, Select, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { getListDropSubjects } from "services/subjects";
import ArrowIcon from "../../icons/ArrowIcon";
import { formatObjects } from "utils";
import { getTagsList } from "services/tags";

interface SubjectGradeTagSelectorProps {
  assessment?: any;
  onValuesChange?: (values: {
    newName: string;
    subjectId: number | null;
    gradeId: string | null;
    tagIds: any[];
  }) => void;
}

// Helper function to compare arrays
const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  
  // Sort both arrays for consistent comparison
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();
  
  for (let i = 0; i < sortedA.length; i++) {
    if (sortedA[i] !== sortedB[i]) return false;
  }
  return true;
};

const SubjectGradeTagSelector: React.FC<SubjectGradeTagSelectorProps> = ({
  assessment,
  onValuesChange,
}) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state.assessments.filters);
  const ddsubjects = useSelector(
    (state: RootState) => state.subjects.dropSubjects
  );
  const [formattedSubjects, setFormattedSubjects] = useState([]);
  const [gradeOptions, setGradeOptions] = useState<any[]>([]);
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null );
  const [selectedTag, setSelectedTag] = useState<any[]>([]);
  const [assessmentNewName, setAssessmentNewName] = useState(
    `${assessment.name || ""} - copy`
  );
    // Only fetch subjects once on component mount
    useEffect(() => {
        // @ts-ignore
        dispatch(getListDropSubjects());
        // Fetch tags immediately on component mount
        // @ts-ignore
        dispatch(getTagsList())
          .then((data) => {
            if (data) {
              let customItems = data.data.map((s) => {
                return { label: s.name, value: s.id };
              });
              setTagsList(customItems);
            } else {
              console.log("Error fetching data.");
            }
          })
          .catch((error) => {
            console.error("Error during API request:", error);
          });
      }, [dispatch]);
    useEffect(() => {
      setFormattedSubjects(formatObjects(ddsubjects));
    }, [ddsubjects]);
  useEffect(() => {
    // @ts-ignore
    setAssessmentNewName(`${assessment.name || ""} - copy`);
    if (assessment?.subjects) {
      setSelectedSubject(assessment?.subjects?.id);
      handleSubjectChange(assessment?.subjects?.id);
    }
    if (assessment?.grades) {
      setSelectedGrade(assessment?.grades?.id);
      handleGradeChange(assessment?.grades?.id);
    }

  //     // Fetch tags if both subject and grade are set
  // if (assessment?.subjects?.id && assessment?.grades?.id) {
  //   // handleSelectionComplete(assessment.subjects.id, assessment.grades.id);
  // }
  
    if (assessment?.tags) {
      handleTagChange(assessment?.tags?.map((tag: { id: number }) => tag.id));
    }

  }, [assessment]);
  // Use a ref to track previous values for comparison
  const prevValues = useRef({
    newName: assessmentNewName,
    subjectId: selectedSubject,
    gradeId: selectedGrade,
    tagIds: selectedTag,
  });





  // Memoize values to prevent unnecessary object creation
  
  const currentValues = useMemo(() => ({
    newName: assessmentNewName,
    subjectId: selectedSubject,
    gradeId: selectedGrade,
    tagIds: selectedTag,
  }), [assessmentNewName,selectedSubject,selectedGrade,selectedTag]);

  // Only call onValuesChange when values actually change
  useEffect(() => {
    const prev = prevValues.current;
    
    // Check if any value has actually changed
    if (onValuesChange && 
        (prev.newName !== currentValues.newName || 
         prev.subjectId !== currentValues.subjectId || 
         prev.gradeId !== currentValues.gradeId || 
         !arraysEqual(prev.tagIds, currentValues.tagIds))) {
      
      // Call the callback with the new values
      onValuesChange(currentValues);
      
      // Update previous values reference
      prevValues.current = {
        newName: currentValues.newName,
        subjectId: currentValues.subjectId,
        gradeId: currentValues.gradeId,
        tagIds: [...currentValues.tagIds],
      };
    }
  }, [currentValues, onValuesChange]);

  const handleSubjectChange = (selectedValue: string) => {
    const subjectId = selectedValue !== "all" ? parseInt(selectedValue) : null;
    setSelectedSubject(subjectId);
    setSelectedGrade(null);
    // setSelectedTag([]);

    const selectedSubject = filters.subjects.find(
      (subject: any) => subject.id === subjectId
    );

    if (selectedSubject && selectedSubject.grades) {
      const grades = selectedSubject.grades.map((grade: any) => ({
        id: grade.id,
        value: grade.id,
        label: grade.name,
      }));

      grades.sort((g1, g2) => {
        const nameA = g1.name || "";
        const nameB = g2.name || "";
        return nameA.localeCompare(nameB);
      });
      setGradeOptions(grades);
    } else {
      setGradeOptions([]);
    }
  };

  const handleGradeChange = (selectedValue: string) => {
    const gradeId = selectedValue !== "all" ? selectedValue : null;
    setSelectedGrade(gradeId);
    // setSelectedTag([]);

    // if (selectedSubject && gradeId) {
    //   handleSelectionComplete(selectedSubject, gradeId);
    // }
  };

  // const handleSelectionComplete = (subjectId: number, gradeId: string) => {
  //   // @ts-ignore
  //   dispatch(getTagsList(subjectId, gradeId))
  //     .then((data) => {
  //       if (data) {
  //         let customItems = data.data.map((s) => {
  //           return { label: s.name, value: s.id };
  //         });
  //         setTagsList(customItems);
  //       } else {
  //         console.log("Error fetching data.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error during API request:", error);
  //     });
  // };

  const handleNameChange = (e) => {
    setAssessmentNewName(e.target.value);
  };

  const handleTagChange = (values) => {
    const tagIds = values.filter((value) => value !== "all");
    setSelectedTag(tagIds);
  };

  return (
    <Form layout="horizontal" className="pl-4 pr-0">
      <p className="text-xl font-semibold">Duplicate</p>
      <div className="form-items-container mt-4">
        <Form.Item label="" className="basis-full mb-2">
          <div className="form-item-container">
            <Input
              placeholder="Assessment name"
              value={assessmentNewName}
              onChange={handleNameChange}
              className="w-full h-12 bg-white border border-gray-300 mb-0 px-2"
            />
          </div>
        </Form.Item>
        <Form.Item label="" className="flex-1 mb-1">
          <div className="form-item-container">
            <Select
              placeholder="Subject"
              virtual={false}
              suffixIcon={<ArrowIcon />}
              value={selectedSubject || "all"}
              onChange={handleSubjectChange}
            >
              <Select.Option value="all">Subject</Select.Option>
              {formattedSubjects?.map((subject: any) => (
                <Select.Option key={subject.id} value={subject.id}>
                  {subject.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Form.Item>
        <Form.Item label="" className="flex-1 mb-1">
          <div className="form-item-container">
            <Select
              placeholder="Grade"
              virtual={false}
              suffixIcon={<ArrowIcon />}
              value={selectedGrade || "all"}
              onChange={handleGradeChange}
              disabled={!selectedSubject}
            >
              <Select.Option value="all">Grade</Select.Option>
              {gradeOptions.map((grade: any) => (
                <Select.Option key={grade.id} value={grade.id}>
                  {grade.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Form.Item>
        <Form.Item label="" className="flex-1 mb-1">
          <div className="form-item-container">
            <Select
              placeholder="Tag"
              suffixIcon={<ArrowIcon />}
              value={selectedTag}
              onChange={handleTagChange}
              mode="multiple"
              virtual={false}

              allowClear
              // disabled={!selectedSubject || !selectedGrade}
              maxTagCount="responsive"
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip
                  styles={{ root: { pointerEvents: 'none' } }}
                  title={omittedValues.map(({ label }) => label).join(', ')}
                >
                  <span>Hover Me</span>
                </Tooltip>
              )}
              showSearch // Enable search filtering
              filterOption={(input, option) =>
                String(option?.displayName).toLowerCase().includes(input.toLowerCase())
              }
            >
              {tagsList?.map((tag: any) => (
                <Select.Option key={tag.value} value={tag.value} displayName={tag.label}>
                  {tag.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default SubjectGradeTagSelector;