import React from 'react';

export const RepeatIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.558" height="32" viewBox="0 0 28.558 32">
        <path id="repeat_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
              d="M154.194-819.92l-6.114-6.114,6.114-6.114,1.709,1.728-3.178,3.178h18.33v-6.792h2.407v9.2H152.724l3.178,3.187Zm-2.937-17.886v-9.208H172l-3.187-3.179,1.709-1.727,6.114,6.114-6.114,6.114-1.709-1.728L172-844.607H153.664v6.8Z"
              transform="translate(-148.08 851.92)" fill="#333"/>
    </svg>


);