import React from 'react';
interface Blank {
    style?: React.CSSProperties; // Optional style prop
}
const Blank = ({ style = {}}) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
  <path id="note_stack_add_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24" d="M116.45-821.265v-19.179a2.969,2.969,0,0,1,.921-2.2,3.045,3.045,0,0,1,2.213-.9h19.151a3.006,3.006,0,0,1,2.206.914,3.005,3.005,0,0,1,.914,2.205v13.724l-8.552,8.56H119.57a3,3,0,0,1-2.205-.914A3.006,3.006,0,0,1,116.45-821.265Zm-8.539-23.87a2.94,2.94,0,0,1,.509-2.326,3.116,3.116,0,0,1,2.032-1.284l18.877-3.344a2.939,2.939,0,0,1,2.325.51,3.116,3.116,0,0,1,1.285,2.032l.385,2.176h-2.589l-.342-1.819a.453.453,0,0,0-.211-.307.527.527,0,0,0-.387-.079l-18.9,3.339a.525.525,0,0,0-.369.238.608.608,0,0,0-.088.43l2.188,12.355v8.993a3.036,3.036,0,0,1-.891-.861,3.04,3.04,0,0,1-.482-1.174Zm11.1,4.7v19.165a.547.547,0,0,0,.159.4.547.547,0,0,0,.4.158h12.366l7.362-7.371V-840.43a.547.547,0,0,0-.158-.4.547.547,0,0,0-.4-.159H119.57a.546.546,0,0,0-.4.159A.546.546,0,0,0,119.007-840.43ZM129.157-830.852Zm-1.283,6.766h2.557v-5.483h5.483v-2.557h-5.483v-5.483h-2.557v5.483H122.39v2.557h5.483Z" transform="translate(-107.854 852.146)" fill="#333"/>
</svg>
    );
};

export default Blank;
