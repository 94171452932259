import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../redux/store";
import {Action} from "redux";
import axios from "axios";
import {toast} from "react-toastify";
import {resetAssessmentData, setAssessments, setAssessmentsListing} from "../redux/slices/assessmentSlice";
import {usersListingRequest} from "../redux/slices/UserManagementSlice";
import {message } from "antd";

const success = (content: string) => {
    const startTime = Date.now();
    message.destroy();
    message.success({
      content,
      duration: 5, 
      onClose: () => {
        const endTime = Date.now();
        const durationInSeconds = (endTime - startTime) / 1000;
      },
    });

    setTimeout(() => {
      const currentTime = Date.now();
      const elapsed = (currentTime - startTime) / 1000;
    }, 5000); 
  };
  const onError = (content: string) => {
    message.error({
      content,
      duration: 3,
    });
  };
export const addAssessment = (
    assessment
): ThunkAction<void, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/assessment`,
                assessment,
                {
                    headers: {
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                toast.success('Assessment added successfully');
                dispatch(resetAssessmentData());
            }
        } catch (error) {
            console.log('Error:', error);
            const errorResponse = error.response?.data; // Get the error response data

            // Check if the error response is present
            if (errorResponse && errorResponse.errors) {
                Object.keys(errorResponse.errors).forEach((key) => {
                    const errorMessages = errorResponse.errors[key].join('\n');
                    toast.error(errorMessages);
                });
            } else {
                toast.error('Create assessment failed!');
            }
        }
    };
};


export const getQuestionsListing = (
    page,
    perPage,
    filter
): ThunkAction<void, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/question/datatable`,
                {
                    params: {
                        page,
                        per_page: perPage,
                        ...filter, // Include additional filter parameters here
                    },
                    headers: {
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                dispatch(
                    setAssessments({
                        items: response?.data?.items,
                        filters: response?.data?.filters,
                        pagination: response?.data.pagination
                    })
                );
            }
        } catch (error) {
            //toast.error('get grades failed!');
        }
    };
};


export const getAssessmentListing = (
    page,
    perPage,
    filter
): ThunkAction<void, RootState, null, Action<string>> => {
    return async (dispatch, getState) => {
        try {
            // Access the current Redux state
            
            // Now, you can access the filters directly
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/assessment/datatable`,
                {
                    params: {
                        page,
                        per_page: perPage,
                        ...filter, // Include additional filter parameters here
                    },
                    headers: {
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                dispatch(
                    setAssessmentsListing({
                        items: response?.data?.items,
                        filters: response?.data?.filters,
                        pagination: response?.data.pagination
                    })
                );
            }
        } catch (error) {
            //toast.error('get grades failed!');
        }
    };
};

export const duplicateAssessments = (
    assessment: any
): ThunkAction<void, RootState, null, Action<string>> => {
    return async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/assessment/duplicate`,
                { assessement: assessment  },
                {
                    headers: {
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                success(`Duplicate Assessment done successfully`);
            }
        } catch (error) {
            console.log('Error:', error);
            const errorResponse = error.response?.data; // Get the error response data

            // Check if the error response is present
            if (errorResponse && errorResponse.errors) {
                Object.keys(errorResponse.errors).forEach((key) => {
                    const errorMessages = errorResponse.errors[key].join('\n');
                    onError(errorMessages);
                });
            } else {
                toast.error('Duplicate assessment failed!');
            }
        }
    };
};

export const getAssessmentById = (id): ThunkAction<Promise<any>, RootState, null, Action<string>> => {
    return async (dispatch) => {
        dispatch(usersListingRequest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/assessment/${id}`,
                {
                    headers: {
                        'Accept': 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                return response?.data?.item;
                //dispatch(setQuestionValues(response?.data?.item))
            }
        } catch (error) {
            toast.error('get assessment failed!');
        }
    };
};


export const editAssessment = (
    assessment, id
): ThunkAction<void, RootState, null, Action<string>> => {
    return async (dispatch) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/assessment/${id}`,
                assessment,
                {
                    headers: {
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                toast.success('Assessment updated successfully');
                dispatch(resetAssessmentData());
            }
        } catch (error) {
            console.log('Error:', error);
            const errorResponse = error.response?.data; // Get the error response data

            // Check if the error response is present
            if (errorResponse && errorResponse.errors) {
                Object.keys(errorResponse.errors).forEach((key) => {
                    const errorMessages = errorResponse.errors[key].join('\n');
                    toast.error(errorMessages);
                });
            } else {
                toast.error('update assessment failed!');
            }
        }
    };
};
