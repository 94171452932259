import AssessmentLibrary from "../../components/AssessmentLibrary/AssessmentLibrary.component";
import MainLayout from "../../layouts/main/Main.layout";
import Banner from "../../components/general/Banner";

const Assessment = () => {
  const title = "Assessments Library";
  return (
    <MainLayout title={title}>
      <div className="assessment-library-page">
        <AssessmentLibrary showBanner={true} />
      </div>
    </MainLayout>
  );
};

export default Assessment;
