import React, { useState } from 'react';
import { addAssessmentRequest } from 'api-rest/assessments';
import { Button, message } from 'antd';
import AppModal from 'studio/components/general/Modal';
import SubjectGradeTagSelectorModal from 'studio/components/AssessmentLibrary/SubjectTagGradeSelectorModal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'studio/constants/routes.constants';
import { toast } from 'react-toastify';

// Define the type for props
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}



const BlankAssessment: React.FC<ModalProps> = ({ isOpen, onClose }) => {
 
  return (
    <SubjectGradeTagSelectorModal  isOpen={isOpen}
    onClose={onClose}/>

  );
};

export default BlankAssessment;
