import { GraphData, GraphOptions } from "./graphing.types";

export const defaultOptionItems: GraphOptions = {
  canvas: { x_max: 10.4, x_min: -10.4, y_max: 10.4, y_min: -10.4 },
  toolbar: {
    default_tool: "Point",
    tools: ["Point", "Move"],
    controls: ["Undo", "Redo", "Reset"],
  },
};

export const initialData: GraphData = {
  type: "graph",
  question: "",
  options: defaultOptionItems,
  correct_answer: {
    valid_response: { points: [], score: 0, tools: [] },
    alt_responses: [], // [{value: [], score}, {value: [], score}]
  },
  more_options: {
    scoring: {},
    layout: {
      width: "800",
      height: "600",
      margin: "0",
      snapto: "Grid",
      validation_stem_numeration: "numerical",
      show_on_hover: false
    },
    metadata: {},
    controls: ["Undo", "Redo", "Reset"],
    grid: {
      x_distance: 1,
      y_distance: 1,
    },
    axis_x: {
      hide_tricks: true,
      draw_labels: true,
      min_arrow: true,
      max_arrow: true,
      comma_label: false,
      show_axis_label: false,
      ticks_distance: "1",
    },
    axis_y: {
      hide_tricks: true,
      draw_labels: true,
      min_arrow: true,
      max_arrow: true,
      comma_label: false,
      show_axis_label: false,
      ticks_distance: "1",
    },
    background_image: {
      src: "",
      width: "100",
      height: "100",
      x: "0",
      y: "0",
      display_points: true,
      opacity: "100",
    },
    background_shapes: [],
    annotation: {},
  },
};

export const defaultTools = [
  "Move",
  "Label",
  "Point",
  "Line",
  "Ray",
  "Segment",
  "Vector",
  "Circle",
  "Parabola",
  "Sine",
  "Polygon",
];