interface SpecialCharacterModalProps {
  specialCharacters: string[];
  onSelect: (char: string) => void;
  onClose: () => void;
}

const SpecialCharacterModal: React.FC<SpecialCharacterModalProps> = ({
  specialCharacters,
  onSelect,
  onClose,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-lg font-bold">Select a Special Character</h2>
        <div className="grid grid-cols-5 gap-4 mt-4">
          {specialCharacters?.map((char) => (
            <button
              key={char}
              className="p-2 text-xl border rounded hover:bg-gray-200"
              onClick={() => {
                onSelect(char);
                onClose();
              }}
            >
              {char}
            </button>
          ))}
        </div>
        <button
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SpecialCharacterModal;
