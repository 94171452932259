import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Layout } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./AssessmentPlayer-styles.scss";
import { TimerIcon } from "../../icons/TimerIcon";
import { ListOrderedIcon } from "../../icons/ListOrderedIcon";
import { TrophyIcon } from "../../icons/TrophyIcon";
import { RepeatIcon } from "../../icons/RepeatIcon";
import { AlertTriangleIcon } from "../../icons/AlertTriangleIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { cleanAllAnswers } from "../../../redux/slices/PreviewSlice";
import { getAssessmentById } from "../../../obsService/actions";
import { fetchUserData } from "../../../obsService/utils/api";
import {
  setReferrer,
  setToken,
  setTokenUser,
  setUserData,
} from "../../../redux/slices/assessmentSlice";

const { Content } = Layout;


const AssessmentStartPlayer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const { token } = useSelector(
    (state: RootState) => state.assessments?.config || {}
  );

  const { isLoading, assessment, time, isTimerActive, allAnswers } =
    useSelector((state: RootState) => state.preview || {});
  const {
    name = "",
    created_at = null,
    grades = [],
    subjects = [],
    tags = [],
    questions = [],
  } = assessment?.defaultAssessment?.data || {};

  const { config } = useSelector((state: RootState) => state.assessments || {});

  const [userResults, setUserResults] = useState<any>(null);
  const [previewSubQuestions, setPreviewSubQuestions] = useState([])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get("token") || token;

    if (encryptedData) {
      dispatch(setToken(encryptedData));
      dispatch(setReferrer(document.referrer));
      initializeUserData(encryptedData);
    }
  }, []);

  const initializeUserData = async (encryptedData: string) => {
    try {
      const result = await fetchUserData(encryptedData);
      setUserResults(result);
      dispatch(setUserData(result));
      dispatch(setTokenUser(result?.access_token));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          const errorMessage =
            error.response.data?.message || "An error occurred.";
        } else {
          console.error(
            `Unhandled error: ${error.response.status}`,
            error.response.data
          );
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };

  useEffect(() => {
    dispatch(cleanAllAnswers());
    if (userResults) {
      dispatch(
        // @ts-ignore
        getAssessmentById(
          null,
          userResults.data.obeasses_id,
          userResults.access_token,
          "redirect"
        )
      );
    }
  }, [userResults]);

  useEffect(() => {
    if (assessment?.subQuestions?.length > 0) {
      setPreviewSubQuestions(assessment?.subQuestions);
    }
  }, [assessment, assessment?.subQuestions?.length]);

  const examInfo = {
    //title: "Mathematics Exam Q2 2024",
    //date: "Monday, February 24",
    //subject: "Mathematics",
    //grade: "6",
    description:
      "This exam assesses your understanding of key mathematical concepts for the second quarter of 2024. It covers algebra, geometry, and problem-solving skills. Ensure you read each question carefully and manage your time effectively. Good luck!",
  };

  const examStats = [
    {
      icon: <TimerIcon />,
      value: `${
        userResults?.data?.duration && userResults?.data?.duration
      } min`,
    },
    {
      icon: <ListOrderedIcon />,
      value: `${previewSubQuestions?.length} Questions`,
    },
    {
      icon: <TrophyIcon />,
      value: "80%",
    },
    {
      icon: <RepeatIcon />,
      value: "3 attempts",
    },
  ];

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    // @ts-ignore
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="assessment-player">
      <Content className="assessment-player__content">
        <div className="assessment-player__header">
          <p className="assessment-player__date">{formatDate(created_at)}</p>
          <h2 className="assessment-player__title">{name}</h2>
        </div>

        <div className="assessment-player__details">
          <p>
            <strong>Subject:</strong> {subjects?.name}
          </p>
          <p>
            <strong>Grade:</strong> {grades?.name}
          </p>
        </div>

        <Divider className="assessment-player__divider" />

        <p className="assessment-player__description">{examInfo.description}</p>

        <Divider className="assessment-player__divider" />

        <div className="assessment-player__stats">
          {examStats.map((stat, index) => (
            <Card key={index} className="assessment-player__stat-card">
              <div className="assessment-player__stat-content">
                {stat.icon}
                <p className="assessment-player__stat-value">{stat.value}</p>
              </div>
            </Card>
          ))}
        </div>

        <Divider className="assessment-player__divider" />

        <div className="assessment-player__warning">
          <AlertTriangleIcon />
          <p className="assessment-player__warning-msg">
            This assessment will be auto-submitted when time expires.
          </p>
        </div>

        <Button
          type="primary"
          className="assessment-player__start-button"
          onClick={() => navigate("/assessment-questions-player")}
        >
          Start
        </Button>
      </Content>
    </div>
  );
};

export default AssessmentStartPlayer;
