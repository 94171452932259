import AuthLayout from "../../layouts/auth/Auth.layout";
import LoginForm from "../../components/auth/LoginForm.component";
import "./auth.styles.scss";

const LoginPage = () => {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  );
};

export default LoginPage;
