import React from "react";
import { Modal, ModalProps } from "antd";
import Cross from "studio/icons/Cross";

interface AppModalProps {
  children: React.ReactNode;
  width?: number;
  className?: string;
  modalProps?: Partial<ModalProps>;
  open: boolean;
  onClose: () => void;
}

const PreviewQuestionModal: React.FC<AppModalProps> = ({
  children,
  width = 520,
  className,
  open,
  onClose,
  modalProps = {},
}) => {
  return (
    <Modal
      width={width}
      className={className}
      closeIcon={<Cross />}
      open={open}
      footer={null}
      onCancel={onClose}
      {...modalProps}
    >
      {children}
    </Modal>
  );
};

export default PreviewQuestionModal;
