import { MCQTypes } from "studio/constants/questionTypes.constants";
import { Spin } from "antd";
import { Sizes } from "studio/theme/Sizes.theme";
import { checkResponseLimit } from "studio/components/QuestionEdit/MCQ/MultipleAnswerEdit.component";
import SingleAnswerTab from "studio/components/QuestionEdit/MCQ/components/singleAnswerTab.component";
import MultipleAnswersTab from "studio/components/QuestionEdit/MCQ/components/multipleAnswerTab.component";
import { IPreviewQuestionForStudent } from "../RenderQuestionForStudent.component";

const PreviewMcqForStudent = ({
  data,
  userResponse,
  setUserResponse,
}: IPreviewQuestionForStudent) => {
  const { more_options, options } = data;

  const handleRadioCheck = (itemId: string) => {
    const selectedItem = options.find((op) => op.id == itemId);
    if (selectedItem)
      setUserResponse({
        value: [{ ...selectedItem, checked: true }],
        score: 0,
      });
  };

  const handleBoxCheck = (itemId: string) => {
    const selectedItem = options.find((op) => op.id === itemId);
    let max_selection = 0;
    const layoutMaxSelection = Number(more_options?.layout?.max_selection);
    if (!isNaN(layoutMaxSelection) && layoutMaxSelection > 0) {
      max_selection = layoutMaxSelection;
    }
    if (selectedItem) {
      if (userResponse?.value) {
        const newUserResponse = {
          value: userResponse.value.find((v) => v?.id === itemId)
            ? userResponse.value.filter((it) => it.id !== itemId)
            : checkResponseLimit(userResponse.value, max_selection)
            ? userResponse.value.concat({
                ...selectedItem,
                checked: true,
              })
            : userResponse.value,
          score: 0,
        };
        setUserResponse(newUserResponse);
      } else {
        const newUserResponse = {
          value: [{ ...selectedItem, checked: true }],
          score: 0,
        };
        setUserResponse(newUserResponse);
      }
    }
  };

  const RenderQuestion = () => {
    const qtype = data?.type;
    const props = {
      more_options,
      options,
      activeTab: userResponse,
      layoutType: data?.type === MCQTypes.BLOCKLAYOUT ? "block" : "radio",
    };

    switch (qtype) {
      case MCQTypes.SINGLEANSWER:
        return (
          <SingleAnswerTab {...props} handleRadioCheck={handleRadioCheck} />
        );
      case MCQTypes.TRUEFALSE:
        return (
          <SingleAnswerTab handleRadioCheck={handleRadioCheck} {...props} />
        );
      case MCQTypes.BLOCKLAYOUT:
        return (
          <SingleAnswerTab handleRadioCheck={handleRadioCheck} {...props} />
        );
      case MCQTypes.MULTIPLEANSWERS:
        return (
          <MultipleAnswersTab {...props} handleBoxCheck={handleBoxCheck} />
        );

      default:
        return (
          <div className="flex flex-col h-full items-center justify-center">
            <Spin size="large"></Spin>
          </div>
        );
    }
  };

  return (
    <div style={{ paddingTop: Sizes.padding, minWidth: 400, width: "100%" }}>
      {RenderQuestion()}
    </div>
  );
};

export default PreviewMcqForStudent;
