import React, {useEffect} from 'react';
import styled from 'styled-components';
import { FontSize } from './types';

const InputWrapper = styled.div`
  display: flex;
  gap: 2px;
  margin-bottom: 8px;
`;

const Input = styled.input<{ $fontSize: FontSize }>`
  width: 60px;
  height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: ${props => props.$fontSize === 'small' ? '14px' : props.$fontSize === 'large' ? '20px' : '16px'};

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
`;

const SpecialCharInput = styled(Input)`
  background-color: #f8f9fa;
  border-color: #eee;
  font-weight: bold;
  color: #666;
`;

interface BasicGriddedInputProps {
    columns: number;
    values: string[];
    fontSize: FontSize;
    onChange: (values: string[]) => void;
    disabled?: boolean;
    hasFixedDecimal?: boolean;
    decimalColumn?: number | string | null;
}

export const BasicGriddedInput: React.FC<BasicGriddedInputProps> = ({
    columns,
    values,
    fontSize,
    onChange,
    disabled,
    hasFixedDecimal = false,
    decimalColumn = null,
    }) => {
    useEffect(() => {
        if (values.length !== columns) {
            const newValues = Array(columns).fill('').map((_, i) => values[i] || '');
            if (JSON.stringify(newValues) !== JSON.stringify(values)) {
                onChange(newValues);
            }
        }
    }, [columns, values, onChange]);

    const parsedDecimalColumn = decimalColumn !== null ? parseInt(decimalColumn.toString()) : null;

    const handleInputChange = (index: number, value: string) => {
        const newValues = Array(columns).fill('').map((_, i) => values[i] || '');

        if (value === '.' && !(hasFixedDecimal && parsedDecimalColumn === index)) {
            const decimalIndex = newValues.indexOf('.');
            if (decimalIndex !== -1 && decimalIndex !== parsedDecimalColumn) {
                newValues[decimalIndex] = '';
            }
        }

        newValues[index] = value;
        onChange(newValues);
    };


    return (
        <InputWrapper>
            {Array(columns).fill(null).map((_, i) => {
                if (hasFixedDecimal && parsedDecimalColumn === i + 1) {
                    return (
                        <SpecialCharInput
                            key={`decimal-${i}`}
                            type="text"
                            value="."
                            readOnly
                            disabled
                            $fontSize={fontSize}
                            aria-label="Decimal point"
                        />
                    );
                }

                return (
                    <Input
                        key={`input-${i}`}
                        type="text"
                        maxLength={1}
                        value={values[i] || ''}
                        onChange={(e) => handleInputChange(i, e.target.value)}
                        disabled={disabled}
                        $fontSize={fontSize}
                        aria-label={`Column ${i + 1} input`}
                        readOnly={true}
                    />
                );
            })}
        </InputWrapper>
    );
};
