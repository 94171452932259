import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import styled from 'styled-components';
import VerticalInput from "components/ComposeQuestion/components/verticalRadioInput";
import TabInput from "components/ComposeQuestion/components/tabInput";
import RadioInput from "components/ComposeQuestion/components/radioInput";
import { toast } from "react-toastify";


const ChildContent = styled.div`
  padding: 20px;
  margin: 25px 0;
  background: #fff;
`;

const OptionsForMcq = ({
    data,
    showAnswer,
    multiResponse,
  }) => {
    const textRef = useRef<Array<HTMLDivElement | null>>([]);
    const more_options = data?.more_options ? data?.more_options : {layout:{}, scoring:{}, extras: {}};
    const questionType = data?.type || "";
    const [inputType, setInputType] = useState(questionType == 'mcbl' ? 'block' : 'standard');
    const items = data?.options || [];
    const correctAnswerTab = data?.correct_answer?.['valid_response'];
    const [localSelectedItems, setLocalSelectedItems] = useState<string[]>([])

    useEffect(() => {
      items?.map((item, i) => {
        // parse html string
        if (textRef.current[i]) {
          textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
         // window.MathJax.typesetPromise([textRef.current[i]]);
         if (window.MathJax && window.MathJax.typesetPromise) {
          window.MathJax.typesetPromise([textRef.current[i]])
            .then(() => {
              console.log('MathJax typesetting completed');
            })
            .catch((err: Error) => {
              console.error('MathJax typesetting failed:', err);
            });
        }
        }
        return textRef.current[i].innerHTML;
      });
    }, [items, inputType]);

    useEffect(() => {
      if(more_options && more_options?.layout && more_options?.layout?.type) {
        if(more_options.layout.type != inputType)setInputType(more_options.layout.type)
      }
    }, [more_options])

    const RenderChild = (index: number) =>{
     return (
      <h2
        className="answer-placeholder"
        data-text="question answer here..."
      >
          {/* {label} */}
          <div ref={(el) => (textRef.current[index] = el)} />
      </h2>
      );
    }

    const colNumber = more_options?.layout?.columns ? more_options?.layout?.columns : '1';
    //const orientation = more_options?.layout?.orientation ? more_options?.layout?.orientation == 'horizontal' ? 'row' : 'col' : 'row';
    const gridTemplateColumns = `repeat(${colNumber}, auto)`;

    const getLabel = (index: number) => {
      let label = "";
      const isMCBL = data?.type === 'mcbl';
    
      const layoutType = isMCBL ? (more_options?.layout?.type || 'block') : (more_options?.layout?.type || 'standard' );
    
      const validationStemNumeration = (layoutType === 'standard' || layoutType === 'horizontal-input-button') 
      ? more_options?.layout?.validation_stem_numeration || 'numerical'  
      : more_options?.layout?.validation_stem_numeration || 'numerical'; 
    
      const choiceLabel = more_options?.layout?.choice_label || 'numerical';
    
      const getAlphaLabel = (i: number, isUpperCase: boolean) => {
        const baseCharCode = isUpperCase ? 65 : 97; 
        const alphabetLength = 26;
    
        if (i < alphabetLength) {
          return String.fromCharCode(baseCharCode + i);
        } else {
          let adjustedIndex = i - alphabetLength;
          let letter = String.fromCharCode(baseCharCode + (adjustedIndex % alphabetLength));
          return letter + letter;  
        }
      };
    console.log("layoutType",layoutType,validationStemNumeration)
      if (layoutType === 'block') {
        switch (choiceLabel) {
          case 'numerical':
            label = (index + 1).toString();
            break;
          case 'lower-alpha':
            label = getAlphaLabel(index, false);  
            break;
          case 'upper-alpha':
            label = getAlphaLabel(index, true);  
            break;
          default:
            break;
        }
      } else if (layoutType === 'standard' || layoutType === 'horizontal-input-button') {
        if (validationStemNumeration) {
          switch (validationStemNumeration) {
            case 'numerical':
              label = (index + 1).toString();
              break;
            case 'lowercase':
              label = getAlphaLabel(index, false); 
              break;
            case 'uppercase':
              label = getAlphaLabel(index, true); 
              break;
            default:
              break;
          }
        }
      }
    
      return label;
    };
    


    const isActive = (item: any) => {
      if(showAnswer) {
        return correctAnswerTab && correctAnswerTab?.value.findIndex((v) => v?.id === item.id) > -1;
      }else {
        return localSelectedItems.includes(item?.id)
      }
    }
    const checkMaxResponseLimit = (valueArr: any[], maxSelection:number) : boolean => {
      if(maxSelection && maxSelection > 0) {
        if(valueArr.length == maxSelection ) {
          toast.error("You exceeded the max selection number")
        }
        return valueArr.length < maxSelection
      }
      return true;
    }
    const checkResponseLimit = (valueArr: any[], minSelection:number) : boolean => {
      if(minSelection && minSelection > 0) {
        return valueArr.length < minSelection
      }
      return false;
    }    
    return (
      <ChildContent
        style={{
          fontSize: more_options?.layout.fontSize ? more_options?.layout.fontSize : '14px',
          gridTemplateColumns,
          overflowX: 'auto', // Scroll appears only when content overflows
          maxWidth: '100%',  // Ensures container doesn’t expand beyond available width
          whiteSpace: 'nowrap', // Prevent wrapping for overflowable content
           
        }}
        className={`grid gap-2`}
      >
      {
        !!data.settings.multiple_response && 
        checkResponseLimit(localSelectedItems, more_options?.layout?.min_selection) && 
        <div className="text-black w-fit h-8 p-2 text-center border border-red-600">
          The minimum number of selections should be {more_options?.layout?.min_selection}
        </div>
      }
        {Array.isArray(items) &&
          items?.map((item, index) => {
              if(inputType == "horizontal-input-button"){
                return (
                  <VerticalInput
                    key={index}
                    label={getLabel(index)}
                    isActive={isActive(item)}
                    isCheckBox={multiResponse}
                    handleItemClicked={() => {
                      if (multiResponse) {
                        if (localSelectedItems.includes(item?.id)) {
                          setLocalSelectedItems(localSelectedItems.filter(id => id !== item?.id));
                        } else {
                          if (more_options?.layout?.max_selection && localSelectedItems.length >= more_options.layout.max_selection) {
                            toast.error("You exceeded the max selection number"); 
                          } else {
                            setLocalSelectedItems([...localSelectedItems, item?.id]);
                          }
                        }
                      } else {
                        setLocalSelectedItems([item?.id]);
                      }
                    }}
                    item={item}
                    otherStyle={isActive(item) && showAnswer ? {backgroundColor: '#7ac491'} : {}}
                  >
                    {RenderChild(index)}
                  </VerticalInput>
              );
            }
            else if( inputType === "block"){
              return (
                <TabInput
                  isActive={isActive(item)}
                  handleItemClicked={() => {
                   

                    if(multiResponse) {
                      if(localSelectedItems.includes(item?.id)) {
                        setLocalSelectedItems(localSelectedItems.filter(id => id != item?.id))
                      }else{
                        if(!!data.settings.multiple_response){
                          const value= checkMaxResponseLimit(localSelectedItems,more_options?.layout?.max_selection)
                          if(value===false){
                           return;
                          } 
                         }
                        setLocalSelectedItems([...localSelectedItems, item?.id])
                      }
                    }else {
                      setLocalSelectedItems([item?.id])
                    }
                  }}
                  item={item}
                  label={getLabel(index)}
                  key={index}
                  otherStyle={isActive(item) && showAnswer ? {backgroundColor: '#7ac491'} :
                    isActive(item) ? {backgroundColor: '#de202a'} :
                  {}}
                >
                  {RenderChild(index)}
                </TabInput>
              )
            }else {
              return (
                <RadioInput
                  isActive={isActive(item)}
                  isCheckBox={!!data.settings.multiple_response}
                  handleItemClicked={() => {
                   
                    if(multiResponse) {
                      if(localSelectedItems.includes(item?.id)) {
                        setLocalSelectedItems(localSelectedItems.filter(id => id != item?.id))
                      }else{
                        if(!!data.settings.multiple_response){
                          const value= checkMaxResponseLimit(localSelectedItems,more_options?.layout?.max_selection)
                          console.log('valuevalue',value);
                          if(value===false){
                           return;
                          } 
                         }
                        setLocalSelectedItems([...localSelectedItems, item?.id])
                      }
                    }else {
                      setLocalSelectedItems([item?.id])
                    }
                  }}
                  item={item}
                  key={index}
                  label={getLabel(index)}
                  otherStyle={isActive(item) && showAnswer ? {backgroundColor: '#7ac491'} : {}}
                >
                  {RenderChild(index)}
                </RadioInput>
            );
            }
          })}
      </ChildContent>
    );
}

export default OptionsForMcq;