import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { TbPoint } from "react-icons/tb";
import { IoMdMove } from "react-icons/io";
import { FaRegCircle } from "react-icons/fa";
import Controls from "./components/Controls";
import { setKeyValue } from "redux/slices/QuestionSlice";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";
import { IoIosUndo, IoIosRedo, IoIosClose } from "react-icons/io";
import { RxReset } from "react-icons/rx";
import {
  getNewLabelObjectState,
  handleBorderClick,
  handleCurveMove,
  handleLineOrShapeMove,
  handlePointClick,
  handlePointMove,
  handlePolygonClick,
  handlePolygonMove,
  handleTwoPointsShape,
} from "./utils/boardUtils";
import {
  buttonsSettings,
  getNewCoordsValueAfterSnapping,
  getSnappingValue,
  shapesRenderer,
} from "utils/graph/shapeDrawerUtil";
import {
  HistoryItem,
  INewGraphProps,
} from "./SubChildren/GraphingUnit/graphing.types";
import { initialData } from "./SubChildren/GraphingUnit/graphingDefaultValues";

export default function GraphingComponent({
  currentQuestion,
  optionItems,
  questiontype,
  data = initialData,
  showAnswer,
  studentAnswers = [],
  onCustomGraphOptionsChange,
  isLocked,
  activeTabIndex = 0,
}: INewGraphProps) {
  const dispatch = useDispatch();
  // console.log('currentQuestioncurrentQuestioncurrentQuestion',currentQuestion);

  const [graphSettings, setGraphSettings] = useState(currentQuestion || data);
  const [selectedButton, setSelectedButton] = useState("Point");
  const [customGraphOptions, setCustomGraphOptions] = useState<any[]>(() => {
    if (questiontype === "edit") {
      if (
        currentQuestion?.correct_answer?.valid_response?.shapes?.length > 0 ||
        currentQuestion?.correct_answer?.alt_responses?.length > 0
      ) {
        const validShapes =
          currentQuestion?.correct_answer?.valid_response?.shapes;
        const altShapes =
          currentQuestion?.correct_answer?.alt_responses?.flatMap(
            (response) => response.shapes
          );
        return [...validShapes, ...altShapes];
      }
    } else {
      return [];
    }
    return [];
  });
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const isLockedRef = useRef(isLocked);
  const [redoHistory, setRedoHistory] = useState<HistoryItem[]>([]);
  const [customGraphOptionsIndex, setCustomGraphOptionsIndex] = useState(null);
  const [labelValue, setLabelValue] = useState("");
  const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
  const [activeShape, setActiveShape] = useState(false);
  const isBoardInitialized = useRef(false);
  const [isBoardInitializedDone, setIsBoardInitializedDone] = useState(false);
  const [labelState, setLabelState] = useState({
    element: null,
    x: 0,
    y: 0,
    selectedObject: null,
    isChild: false,
  });
  const graphRef = useRef<(HTMLDivElement | null)[]>([]);
  const board = useRef<JXG.Board | null>(null);
  const graphPoints = useRef<any[]>([]);
  const [deleteStart, setDeleteStart] = useState(false);
  const deleteStartRef = useRef(false);
  const [boardResetting, setBoardResetting] = useState(false);
  const pointsRef = useRef({ points: [], shapeDrawer: null });
  const elementsRef = useRef<any[]>([]);
  const selectedButtonRef = useRef(selectedButton);
  const previousSettings = useRef(graphSettings);
  const tabsMap = useRef<Map<number, JXG.Board>>(new Map());
  const graphShapesRef = useRef<any[]>([]);
  useEffect(() => {
    isLockedRef.current = isLocked;
  }, [isLocked]);

  useEffect(() => {
    if (board.current) {
      isBoardInitialized.current = true;
      setIsBoardInitializedDone(true);
    }
  }, [board.current]);
  useEffect(() => {
    if (board.current) {
      updateBoardProperties();
    } else {
      initializeBoard(activeTabIndex);
    }
  }, [
    graphSettings,
    questiontype,
    board.current,
    graphRef.current[activeTabIndex],
    previousSettings.current,
  ]);
  const initializeBoard = (tabIndex: number) => {
    if (graphRef.current[activeTabIndex]) {
      board.current = JXG.JSXGraph.initBoard(graphRef.current[activeTabIndex], {
        boundingbox: [
          previousSettings.current?.options?.canvas?.x_min,
          previousSettings.current?.options?.canvas?.y_max,
          previousSettings.current?.options?.canvas?.x_max,
          previousSettings.current?.options?.canvas?.y_min,
        ],
        axis: false,
        keepaspectratio: false,
        showNavigation: false,
        showCopyright: false,
        pan: { enabled: true },
        // logging: {enabled: true},
        showInfobox:
          previousSettings.current?.more_options?.layout?.show_on_hover ||
          false,
      });

      // Add grid and axes
      board.current.create("grid", [], {
        majorStep: [
          Number(previousSettings.current?.more_options?.grid?.x_distance) > 0
            ? Number(previousSettings.current?.more_options?.grid?.x_distance)
            : 1,
          Number(previousSettings.current?.more_options?.grid?.y_distance) > 0
            ? Number(previousSettings.current?.more_options?.grid?.y_distance)
            : 1,
        ],
      });

      // Add X and Y axes
      board.current.create(
        "axis",
        [
          [0, 0],
          [1, 0],
        ],
        {
          name: previousSettings.current?.more_options?.axis_x?.axis_label,
          withLabel:
            previousSettings.current?.more_options?.axis_x?.show_axis_label,
          label: {
            offset: [
              -(
                previousSettings.current?.more_options?.axis_x?.axis_label
                  ?.length * 5
              ) || -10,
              20,
            ],
            distance: 0,
            position: ".95fr right",
          },
          ticks: {
            visible:
              !previousSettings.current?.more_options?.axis_x?.hide_tricks,
            majorHeight: 10,
            minorHeight: 5,
            minorTicks: 0,
            drawLabels:
              previousSettings.current?.more_options?.axis_x?.draw_labels,
            strokeColor: "black",
            insertTicks: false,
            label: {
              visible: true,
              offset: [0, -10],
            },
            ticksDistance:
              previousSettings.current?.more_options?.axis_x?.ticks_distance ||
              1,
          },
          lastArrow: previousSettings.current?.more_options?.axis_x?.max_arrow,
          firstArrow: previousSettings.current?.more_options?.axis_x?.min_arrow,
        }
      );

      board.current.create(
        "axis",
        [
          [0, 0],
          [0, 1],
        ],
        {
          withLabel:
            previousSettings.current?.more_options?.axis_y?.show_axis_label,
          name: previousSettings.current?.more_options?.axis_y?.axis_label,
          label: {
            position: "rt",
            offset: [10, 0],
          },
          ticks: {
            visible:
              !previousSettings.current?.more_options?.axis_y?.hide_tricks,
            majorHeight: 10,
            minorHeight: 5,
            minorTicks: 0,
            drawLabels:
              previousSettings.current?.more_options?.axis_y?.draw_labels,
            insertTicks: false,
            label: {
              visible: true,
              offset: [-25, 0],
            },
            ticksDistance:
              previousSettings.current?.more_options?.axis_y?.ticks_distance ||
              1,
            strokeColor: "black",
          },
          lastArrow: previousSettings.current?.more_options?.axis_y?.max_arrow,
          firstArrow: previousSettings.current?.more_options?.axis_y?.min_arrow,
        }
      );

      board.current.on("down", handleBoardClick);
      tabsMap.current.set(tabIndex, board.current);
      isBoardInitialized.current = true;
      setIsBoardInitializedDone(true);
      if (customGraphOptions?.length) {
        const filteredOptions = customGraphOptions
          .filter((option) => option.tab === activeTabIndex)
          .filter((option) => !option.hasParent);
        const options = { visibilityOnBoard: true };
        const activeBoard = tabsMap.current.get(activeTabIndex);
        if (activeBoard) {
          shapesRenderer(
            activeBoard,
            filteredOptions,
            true,
            graphPoints,
            "draw",
            previousSettings.current?.more_options,
            options
          );
          activeBoard.update();
        }
      }
    }
  };
  const updateBoardProperties = () => {
    if (!board.current) return;

    // Remove existing grid and axes
    board.current.objectsList
      .filter((obj: any) => obj.elType === "axis" || obj.elType === "grid")
      .forEach((obj: any) => board.current.removeObject(obj));

    // Recreate the grid with updated spacing
    board.current.create("grid", [], {
      majorStep: [
        Number(previousSettings.current?.more_options?.grid?.x_distance) || 1,
        Number(previousSettings.current?.more_options?.grid?.y_distance) || 1,
      ],
    });

    // Recreate X-Axis
    board.current.create(
      "axis",
      [
        [0, 0],
        [1, 0],
      ],
      {
        name: previousSettings.current?.more_options?.axis_x?.axis_label,
        withLabel:
          previousSettings.current?.more_options?.axis_x?.show_axis_label,
        label: {
          offset: [
            -(
              previousSettings.current?.more_options?.axis_x?.axis_label
                ?.length * 5
            ) || -10,
            20,
          ],
          distance: 0,
          position: ".95fr right",
        },
        ticks: {
          visible: !previousSettings.current?.more_options?.axis_x?.hide_tricks,
          majorHeight: 10,
          minorHeight: 5,
          minorTicks: 0,
          drawLabels:
            previousSettings.current?.more_options?.axis_x?.draw_labels,
          strokeColor: "black",
          insertTicks: false,
          label: { visible: true, offset: [0, -10] },
          ticksDistance:
            previousSettings.current?.more_options?.axis_x?.ticks_distance || 1,
        },
        lastArrow: previousSettings.current?.more_options?.axis_x?.max_arrow,
        firstArrow: previousSettings.current?.more_options?.axis_x?.min_arrow,
      }
    );

    // Recreate Y-Axis
    board.current.create(
      "axis",
      [
        [0, 0],
        [0, 1],
      ],
      {
        name: previousSettings.current?.more_options?.axis_y?.axis_label,
        withLabel:
          previousSettings.current?.more_options?.axis_y?.show_axis_label,
        label: { position: "rt", offset: [10, 0] },
        ticks: {
          visible: !previousSettings.current?.more_options?.axis_y?.hide_tricks,
          majorHeight: 10,
          minorHeight: 5,
          minorTicks: 0,
          drawLabels:
            previousSettings.current?.more_options?.axis_y?.draw_labels,
          insertTicks: false,
          label: { visible: true, offset: [-25, 0] },
          ticksDistance:
            previousSettings.current?.more_options?.axis_y?.ticks_distance || 1,
          strokeColor: "black",
        },
        lastArrow: previousSettings.current?.more_options?.axis_y?.max_arrow,
        firstArrow: previousSettings.current?.more_options?.axis_y?.min_arrow,
      }
    );

    board.current.update();
  };

  useEffect(() => {
    if (!board.current) return;
    if (questiontype !== "shaded") {
      for (let i = board.current.objectsList.length - 1; i >= 0; i--) {
        const item = board.current.objectsList[i] as any;
        // console.log(item.visProp, "item");
        if (item.visProp && item.visProp.strokecolor === "gray") {
          // console.log(item, "itemFoundColor");
          board.current.removeObject(item);
          if (item.poin1) board.current.removeObject(item.poin1);
          if (item.poin2) board.current.removeObject(item.poin2);
        }
        if (
          item.visProp &&
          item.visProp.borders &&
          item.visProp.borders.strokecolor === "gray"
        ) {
          // console.log(item, "itemFound");
          board.current.removeObject(item);
          if (item.poin1) board.current.removeObject(item.poin1);
          if (item.poin2) board.current.removeObject(item.poin2);
        }
        if (item.visProp && item.visProp.color === "gray") {
          // console.log(item, "itemFoundColor");
          board.current.removeObject(item);
        }
      }
      board.current.update();
      // if (backGroundShapesRef.current.length > 0)
      const drawnShapes =
        previousSettings.current?.more_options?.background_shapes?.filter(
          (item) => !item?.hasParent
        );
      // console.log(drawnShapes, "drawnShapes");
      if (drawnShapes?.length > 0) {
        shapesRenderer(
          board.current,
          drawnShapes,
          false,
          graphPoints,
          "shaded",
          previousSettings.current?.more_options,
          {
            strokeColor: "gray",
            borders: {
              strokeColor: "gray",
            },
            draggable: false,
            fixed: true,
            visibilityOnBoard:
              previousSettings.current?.more_options?.background_image
                ?.display_points,
          }
        );
  
        board.current.update();
      }
     
    }
  }, [
    previousSettings.current?.more_options?.background_shapes,
    questiontype,
    board.current,
  ]);

  useEffect(() => {
    if (!board.current) return;
    for (let i = board.current.objectsList.length - 1; i >= 0; i--) {
      const obj = board.current.objectsList[i] as any;
      if (obj.elType === "image") {
        board.current.removeObject(obj);
      }
    }
    if (
      typeof previousSettings.current?.more_options?.background_image?.src ===
        "string" &&
      previousSettings.current?.more_options?.background_image?.src.length > 0
    ) {
      console.log(
        previousSettings.current?.more_options?.background_image?.src,
        "imageSrc"
      );
      if (questiontype !== "shaded") {
        const src =
          previousSettings.current?.more_options?.background_image?.src;
        const width = Number(
          previousSettings.current?.more_options?.background_image?.width || 100
        );
        const height = Number(
          previousSettings.current?.more_options?.background_image?.height ||
            100
        );
        const xMax = previousSettings.current?.options?.canvas?.x_max;
        const yMax = previousSettings.current?.options?.canvas?.y_max;
        const imgWidth = ((xMax + xMax) * width) / 100;
        const imgHeight = ((yMax + yMax) * height) / 100;
        const startxPos =
          imgWidth / 2 -
            Number(
              previousSettings.current?.more_options?.background_image?.x
            ) || 0;
        const startyPos =
          imgHeight / 2 -
            Number(
              previousSettings.current?.more_options?.background_image?.y
            ) || 0;

        board.current.create(
          "image",
          [src, [-startxPos, -startyPos], [imgWidth, imgHeight]],
          {
            opacity:
              Number(
                previousSettings.current?.more_options?.background_image
                  ?.opacity
              ) / 100 || 1,
            fixed: true,
            draggable: false,
          }
        );
      }
    }
  }, [
    previousSettings.current?.more_options?.background_image?.opacity,
    previousSettings.current?.more_options?.background_image?.y,
    previousSettings.current?.more_options?.background_image?.x,
    previousSettings.current?.options?.canvas?.y_max,
    previousSettings.current?.options?.canvas?.x_max,
    previousSettings.current?.more_options?.background_image?.src,
    questiontype,
    previousSettings.current?.more_options?.background_image?.width,
    previousSettings.current?.more_options?.background_image?.height,
    board.current,
  ]);
  useEffect(() => {
    return () => {
      if (board.current) {
        board.current.off("down", handleBoardClick);
      }
    };
  }, []);

  useEffect(() => {
    graphShapesRef.current = customGraphOptions;
    onCustomGraphOptionsChange(customGraphOptions);
  }, [customGraphOptions, onCustomGraphOptionsChange]);

  useEffect(() => {
    // Check if a board instance exists for the active tab
    console.log(activeTabIndex, " Active Tab Index");
    if (!tabsMap.current.has(activeTabIndex)) {
      // Initialize a new board for the active tab
      initializeBoard(activeTabIndex);
    }

    // Render shapes for the active tab
    if (customGraphOptions?.length > 0) {
      const filteredOptions = customGraphOptions
        .filter((option) => option.tab === activeTabIndex)
        .filter((option) => !option.hasParent);
      const options = { visibilityOnBoard: true };
      const activeBoard = tabsMap.current.get(activeTabIndex);

      if (activeBoard) {
        shapesRenderer(
          activeBoard,
          filteredOptions,
          true,
          graphPoints,
          "draw",
          previousSettings.current?.more_options,
          options
        );
        activeBoard.update();
      }
    }
  }, [activeTabIndex]);
  useEffect(() => {
    return () => {
      tabsMap.current.forEach((boardInstance) => {
        boardInstance.removeChild(boardInstance);
      });
      tabsMap.current.clear();
    };
  }, []);

  useEffect(() => {
    selectedButtonRef.current = selectedButton;
  }, [selectedButton]);

  useEffect(() => {
    deleteStartRef.current = deleteStart;
  }, [deleteStart]);

  useEffect(() => {
    if (currentQuestion) {
      setGraphSettings(currentQuestion);
      previousSettings.current = currentQuestion;
    }
    return () => {
      setGraphSettings(data);
    };
  }, [currentQuestion]);
  useEffect(() => {
    previousSettings.current = graphSettings;
  }, [graphSettings]);

  useEffect(() => {
    if (labelState.element) {
      const x = labelState?.x;
      const y = labelState?.y;
      setLabelPosition({ x, y });
    }
  }, [labelState.element]);

  useEffect(() => {
    if (questiontype === "preview") return;

    const storeData = {
      ...previousSettings.current,
      question: previousSettings.current?.question,
      correct_answer: {
        valid_response: {
          ...currentQuestion?.correct_answer?.valid_response,
          tools: optionItems?.toolbar?.tools,
          shapes: customGraphOptions?.filter((o) => o?.tab === 0) || [],
        },
        alt_responses: currentQuestion?.correct_answer?.alt_responses
          ?.map((res, i) => ({
            ...res,
            tools: optionItems?.toolbar?.tools,
            shapes: customGraphOptions?.filter((o) => o.tab === i + 1) || [],
          }))
          .filter((o) => o.shapes.length > 0),
      },
      options: {
        canvas: { ...previousSettings.current?.options?.canvas },
        toolbar: {
          ...previousSettings.current?.options?.toolbar,
          tools: optionItems?.toolbar?.tools,
          controls: previousSettings.current?.options?.toolbar?.controls,
        },
      },
      more_options: {
        ...previousSettings.current?.more_options,
        background_shapes:
          previousSettings.current?.more_options?.background_shapes,
      },
    };
    // @ts-ignore
    if (questiontype === "add") {
      dispatch(setKeyValue({ key: "graph", subKey: "", value: storeData }));
    } else if (questiontype === "edit") {
      dispatch(EditSubQues(storeData));
    } else if (questiontype === "shaded") {
      const objectData = {
        ...currentQuestion,
        more_options: {
          ...data?.more_options,
          background_shapes: customGraphOptions,
        },
        options: {
          canvas: optionItems?.canvas,
          toolbar: {
            ...optionItems?.toolbar,
            tools: data?.options?.toolbar?.tools,
            controls: data?.options?.toolbar?.controls,
          },
        },
      };
      dispatch(setKeyValue({ key: "graph", subKey: "", value: objectData }));
    }
  }, [customGraphOptions, questiontype]);

  useEffect(() => {
    if (questiontype === "preview" && board.current) {
      const src = currentQuestion;
      if (
        !src?.correct_answer?.valid_response?.shapes ||
        !src?.correct_answer?.alt_responses
      )
        return;
      if (!showAnswer) {
        for (let i = board.current?.objectsList.length - 1; i >= 0; i--) {
          const item = board.current?.objectsList[i] as any;
          if (item?.visProp && item.visProp.strokecolor === "green") {
            board.current?.removeObject(item);
            if (item.poin1) board.current?.removeObject(item.poin1);
            if (item.poin2) board.current?.removeObject(item.poin2);
          }
          if (
            item?.visProp &&
            item?.visProp?.borders &&
            item?.visProp?.borders?.strokecolor === "green"
          ) {
            board.current?.removeObject(item);
            if (item.poin1) board.current?.removeObject(item.poin1);
            if (item.poin2) board.current?.removeObject(item.poin2);
          }
          if (item?.visProp && item.visProp.color === "green") {
            board.current?.removeObject(item);
          }
        }
        board.current?.update();
        return;
      }
      if (
        src?.correct_answer?.valid_response.shapes.length > 0 ||
        src?.correct_answer?.alt_responses.length > 0
      ) {
        const validShapes = src?.correct_answer?.valid_response.shapes;
        const altShapes = src?.correct_answer?.alt_responses.flatMap(
          (response) => response.shapes
        );
        const allShapes = [...validShapes, ...altShapes].filter(
          (shape) => !shape.hasParent
        );
        const answers = allShapes.map((shape) => ({
          ...shape,
          options: { ...shape.options, strokeColor: "green" },
        }));
        const options = { visibilityOnBoard: showAnswer };
        shapesRenderer(
          board.current,
          answers,
          true,
          graphPoints,
          "preveiw",
          previousSettings.current?.more_options,
          options
        );
        // const correctAnswers = correctAnswers
      }
    }
  }, [showAnswer, questiontype]);

  useEffect(() => {
    if (!isBoardInitializedDone && questiontype !== "preview") return;
    if (
      previousSettings.current?.options?.toolbar?.tools.includes(selectedButton)
    ) {
      const selecetdIdx =
        previousSettings.current?.options?.toolbar?.tools.indexOf(
          selectedButton
        );
      setSelectedButton(
        previousSettings.current?.options?.toolbar?.tools[selecetdIdx]
      );
    } else {
      setSelectedButton(null);
      setCustomGraphOptionsIndex(null);
    }
    if (!studentAnswers.length) return;
    if (studentAnswers.length) {
      for (let i = board.current.objectsList.length - 1; i >= 0; i--) {
        const item = board.current.objectsList[i] as any;
        if (item?.visProp && item?.visProp?.strokecolor === "blue") {
          board.current.removeObject(item);
          if (item?.poin1) board.current.removeObject(item.poin1);
          if (item?.poin2) board.current.removeObject(item.poin2);
        }
        if (
          item?.visProp &&
          item?.visProp?.borders &&
          item?.visProp?.borders?.strokecolor === "blue"
        ) {
          board.current.removeObject(item);
          if (item?.poin1) board.current.removeObject(item.poin1);
          if (item?.poin2) board.current.removeObject(item.poin2);
        }
        if (item?.visProp && item?.visProp?.color === "blue") {
          board.current.removeObject(item);
        }
        if (item?.visProp && item?.visProp?.color === "orange") {
          board.current.removeObject(item);
        }
      }

      const answers = studentAnswers
        .map((shape) => ({
          ...shape,
          options: { ...shape.options, strokeColor: "blue" },
        }))
        .filter((item) => !item.hasParent);
      console.log(studentAnswers, "answersDrawn");
      const options = { visibilityOnBoard: true };
      shapesRenderer(
        board.current,
        answers,
        true,
        graphPoints,
        "draw",
        previousSettings.current?.more_options,
        options
      );
      console.log(graphPoints.current, "graphPoints");
      const newElements = graphPoints.current.map((i: any) => i.shapeId);
      const lastElementState = board?.current?.objectsList.filter((item: any) =>
        newElements.includes(item.id)
      ) as any[];
      const getNewItemData = (shape, item) => {
        if (item.type === "Point") {
          return {
            coords: {
              x: shape?.X(),
              y: shape?.Y(),
            },
          };
        }
        if (item.type === "Circle") {
          return {
            subElementIds: {
              ...item?.subElementIds,
              startPoint: {
                ...item?.subElementIds?.startPoint,
                x: shape?.midpoint?.X(),
                y: shape?.midpoint?.Y(),
              },
              endPoint: {
                ...item?.subElementIds?.endPoint,
                x: shape?.point2?.X(),
                y: shape?.point2?.Y(),
              },
            },
          };
        }
        if (item.type === "Parabola" || item.type === "Sine") {
          const children = graphPoints.current.filter(
            (item) => item.parentId === shape.id
          );
          const [firstPoint, secondPoint] = lastElementState.filter((item) =>
            children.find((child) => child.shapeId === item.id)
          );
          return {
            subElementIds: {
              ...item?.subElementIds,
              startPoint: {
                ...item?.subElementIds?.startPoint,
                x: firstPoint?.X(),
                y: firstPoint?.Y(),
              },
              endPoint: {
                ...item?.subElementIds?.endPoint,
                x: secondPoint?.X(),
                y: secondPoint?.Y(),
              },
            },
          };
        }
        if (item.type === "Polygon") {
          console.log(item, "item", shape);
          
          return {
            drawKey: shape,
            subElementIds: {
              ...item?.subElementIds,
              points: shape?.vertices?.slice(0, -1).map((el) => {
                return {
                  x: el.X(),
                  y: el.Y(),
                };
              }),
              pointsShapes: shape?.vertices?.slice(0, -1),
              startPoint: {
                ...item?.subElementIds?.startPoint,
                x: shape?.vertices[0]?.X(),
                y: shape?.vertices[0]?.Y(),
              },
              endPoint: {
                ...item?.subElementIds?.endPoint,
                x: shape?.vertices[0]?.X(),
                y: shape?.vertices[0]?.Y(),
              },
              segments: shape?.borders?.slice(0, -1),
            },
          };
        }
        return {
          subElementIds: {
            ...item?.subElementIds,
            startPoint: {
              ...item?.subElementIds?.startPoint,
              x: shape?.point1?.X(),
              y: shape?.point1?.Y(),
            },
            endPoint: {
              ...item?.subElementIds?.endPoint,
              x: shape?.point2?.X(),
              y: shape?.point2?.Y(),
            },
          },
        };
      };
      console.log(lastElementState, "lastElementState");
      const children = lastElementState.filter((el) => el.elType === "point");
      const snapper = getSnappingValue(graphSettings?.more_options);
      const getDrawKey = (item, lastElementState, points) => {
        if (!item.hasParent) {
          const newItem = points.find((el) => el.itemId === item.id);
          return lastElementState.find((el) => el.id === newItem.shapeId);
        } else {
          const parentShape = points.find((el) => el.itemId === item.parentID);
          const parentItem = lastElementState.find(
            (el) => el.id === parentShape.shapeId
          );

          const parentSubElements = studentAnswers.find(
            (el) => el.id === parentShape.itemId
          );
          console.log(parentSubElements, "parentSubElements");
          const res = children.find((el, index) => {
            const parents = Object.keys(el.childElements);
            if (parents.includes(parentItem.id)) {
              console.log(el.X() , item.coords.x , el.Y() , item.coords.y)
             if(el.X() === item.coords.x && el.Y() === item.coords.y){
               children.splice(index, 1);
               return el;
             }
            }
            if(parentItem.hasPoint(el.coords.scrCoords[1], el.coords.scrCoords[2])) {
              console.log(el,"found");
              children.splice(index, 1);
              return el;
            }
          });
          console.log(res, "res");
          return res;
        }
      };
      const newState = studentAnswers?.map((item: any, index) => {
        const drawKeyData = getDrawKey(item, lastElementState, graphPoints.current);
        return {
          ...item,
          drawKey: drawKeyData,
          ...getNewItemData(drawKeyData, item),
        };
      });
      console.log(newState, "newStateInChild");

      setCustomGraphOptions(newState || []);
    }

    // console.log(customGraphOptions, "Updating answers customGraphOptions");
    return () => {
      setSelectedButton(null);
    };
  }, [studentAnswers, isBoardInitializedDone, questiontype]);

  const handleUndo = () => {
    if (!history.length) return;

    // Get the last action from the history
    const lastAction = history[history.length - 1];
    const {
      previousState,
      newState,
      data: changedElement,
      action,
    } = lastAction;

    if (action !== "delete") {
      const deletedIds = newState.map((item: any) => item.drawKey.id);
      const polygonsIds = newState
        .filter((item: any) => item.type === "Polygon")
        .map((item: any) => {
          return {
            segments: item.subElementIds.segments.map(
              (segment: any) => segment.id
            ),
            points: item.subElementIds.pointsShapes.map(
              (point: any) => point.id
            ),
          };
        })
        .flatMap((item) => [...item.segments, ...item.points]);
      const drawnPoints = graphPoints.current.map((item) => item.shapeId);
      deletedIds.push(...drawnPoints);
      if (polygonsIds.length > 0) {
        deletedIds.push(...polygonsIds);
      }
      // Remove the elements from the board
      for (let i = board.current?.objectsList.length - 1; i >= 0; i--) {
        const item = board.current?.objectsList[i] as any;
        if (item?.id && deletedIds.includes(item?.id)) {
          board.current?.removeObject(item);
        }
      }
    }
    // Render the previous state
    shapesRenderer(
      board.current,
      previousState,
      true,
      graphPoints,
      "draw",
      previousSettings.current?.more_options,
      {
        strokeColor: "blue",
        borders: {
          strokeColor: "blue",
          strokeWidth: 2,
        },
        draggable: true,
        fixed: false,
        visibilityOnBoard:
          previousSettings.current?.more_options?.background_image
            ?.display_points,
      }
    );

    // Update the board
    board.current?.update();
    // Update the customGraphOptions state
    const newElements = graphPoints.current
      .filter((i) => !i.parentId)
      .map((i: any) => i.shapeId);
    const lastElementState = board?.current?.objectsList.filter((item: any) =>
      newElements.includes(item.id)
    );

    const drawnState = previousState.map((shape, index) => ({
      ...shape,
      drawKey: lastElementState[index],
    }));
    setCustomGraphOptions(drawnState);

    // Remove the last action from the history
    setRedoHistory((prevRedoHistory) => [
      ...prevRedoHistory,
      {
        action: lastAction.action,
        previousState: previousState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),
        newState: newState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),
        data: changedElement,
      },
    ]);
    setHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, -1).map((item: any) => ({
        ...item,
        previousState: item.previousState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),
        newState: item.newState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),
      }));
      return newHistory;
    });
  };

  const handleRedo = () => {
    if (!redoHistory.length) return;

    // Get the last action from the redo history
    const lastRedoAction = redoHistory[redoHistory.length - 1];
    const {
      previousState,
      newState,
      data: changedElement,
      action,
    } = lastRedoAction;

    // Remove the elements from the board that were added in the last redo action
    const deletedIds = previousState.map((item: any) => item.drawKey.id);
    const polygonsIds = previousState
      .filter((item: any) => item.type === "Polygon")
      .map((item: any) => {
        return {
          segments: item.subElementIds.segments.map(
            (segment: any) => segment.id
          ),
          points: item.subElementIds.pointsShapes.map((point: any) => point.id),
        };
      })
      .flatMap((item) => [...item.segments, ...item.points]);
    const drawnPoints = graphPoints.current.map((item) => item.itemId);
    deletedIds.push(...drawnPoints);
    if (polygonsIds.length > 0) {
      deletedIds.push(...polygonsIds);
    }

    for (let i = board.current?.objectsList.length - 1; i >= 0; i--) {
      const item = board.current?.objectsList[i] as any;
      if (item?.id && deletedIds.includes(item.id)) {
        board.current?.removeObject(item);
      }
    }
    // Render the new state (the state after the redo action)
    shapesRenderer(
      board.current,
      newState,
      true,
      graphPoints,
      "draw",
      previousSettings.current?.more_options,
      {
        strokeColor: "blue",
        borders: {
          strokeColor: "blue",
          strokeWidth: 2,
        },
        draggable: true,
        fixed: false,
        visibilityOnBoard:
          previousSettings.current?.more_options?.background_image
            ?.display_points,
      }
    );

    // Update the board
    board.current?.update();

    // Update the customGraphOptions state
    const newElements = graphPoints.current
      .filter((i) => !i.parentId)
      .map((i: any) => i.shapeId);
    const lastElementState = board?.current?.objectsList.filter((item: any) =>
      newElements.includes(item.id)
    );

    const drawnState = newState.map((shape, index) => ({
      ...shape,
      drawKey: lastElementState[index],
    }));

    setCustomGraphOptions(drawnState);

    // Remove the last action from the redo history
    setHistory((prevHistory) => [
      ...prevHistory,
      {
        action: lastRedoAction.action,
        previousState: lastRedoAction.previousState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),
        newState: lastRedoAction.newState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),

        data: changedElement,
      },
    ]);
    setRedoHistory((prevRedoHistory) => {
      const newHistory = prevRedoHistory.slice(0, -1).map((item: any) => ({
        ...item,
        previousState: item.previousState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),
        newState: item.newState.map((shape, index) => ({
          ...shape,
          drawKey: lastElementState[index],
        })),
        data: item.data,
      }));
      return newHistory;
    });
  };
  const handleReset = () => {
    const graphPointsForReset = graphPoints.current.map((i: any) => i.shapeId);
    setBoardResetting(true);
    setCustomGraphOptions((prevState) => {
      const filteredData = prevState.filter(
        (option) => option.tab !== activeTabIndex
      );
      const shapes = prevState
        .filter((option) => option.tab === activeTabIndex)
        .map((option) => {
          if (option.subElementIds?.segments) {
            return [
              ...option.subElementIds.segments.map((s) => s.id),
              ...option.subElementIds.pointsShapes.map((s) => s.id),
              option.drawKey.id,
            ];
          }
          return option.drawKey.id;
        });
      shapes.push(...graphPointsForReset);
      const objectsListCopy = [...board.current.objectsList];
      objectsListCopy.forEach((shape: any) => {
        if (shapes.flat().includes(shape.id)) {
          board.current.removeObject(shape);
        }
      });
      return filteredData;
    });
    graphPoints.current = [];
    setActiveShape(false);
    board.current.update();
    setHistory([]);
    setRedoHistory([]);
    graphShapesRef.current = graphShapesRef.current.filter(
      (shape) => shape.tab !== activeTabIndex
    );
  };
  const handleDelete = () => {
    setDeleteStart(true);
  };

  const handleSettingLabel = (e: any) => {
    if (e.key === "Enter" || e.type === "blur") {
      if (labelState?.element) {
        labelState.element.setAttribute({ name: labelValue, withLabel: true });
      }
      console.log(labelState, " Label State");
      console.log(graphShapesRef.current, 'items')
      setCustomGraphOptions((prevData) => {
        const previousState = prevData;
        const objInBoard = graphShapesRef.current.find(
          (element) => element.id === labelState.selectedObject.id
        );
        const newObject = getNewLabelObjectState(
          objInBoard.type,
          labelState.isChild,
          objInBoard,
          labelState.element,
          labelValue,
          prevData
        );
        const filteredData = graphShapesRef.current.filter(
          (element) => element.id !== labelState.selectedObject.id
        );
        const newState = [...filteredData, newObject];
        setHistory((prevHistory) => [
          ...prevHistory,
          {
            action: "label",
            previousState,
            newState,
            data: labelState.selectedObject.drawKey.id,
          },
        ]);
        return [...filteredData, newObject];
      });

      setLabelValue("");
      setActiveShape(false);
    }
  };

  const buttons = [
    {
      label: "Undo",
      icon: <IoIosUndo className="mr-1" />,
      action: handleUndo,
      isDisabled: history.length === 0,
    },
    {
      label: "Redo",
      icon: <IoIosRedo className="mr-1" />,
      action: handleRedo,
      isDisabled: !redoHistory?.length,
    },
    {
      label: "Reset",
      icon: <RxReset className="mr-1" />,
      action: handleReset,
      isDisabled: graphShapesRef?.current?.length === 0,
    },
    {
      label: "Delete",
      icon: <IoIosClose className="mr-1" />,
      action: handleDelete,
      isDisabled: graphShapesRef?.current?.length === 0,
    },
  ];
  const handleBoardClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (isLockedRef.current) return;
    const coords = board.current.getUsrCoordsOfMouse(event);
    //TODO: fix this filtering condition
    const clickedObject = board.current
      .getAllObjectsUnderMouse(event)
      .filter((item: any) => item.elType !== "image" && item.elType !== "axis" && item.elType !== "text") as any;
    console.log(clickedObject, "clickedObject")
    const x = Math.round(coords[0]);
    const y = Math.round(coords[1]);
    const newPoint = { x, y };
    if (clickedObject.filter((item) => item.elType !== "image").length > 0) {
      if (!clickedObject.length) return;
      if (clickedObject) {
        const shapeId = clickedObject[0].id;
        clickedObject[0].off("up");
        clickedObject[0].on("up", (e) => {
          const coords = board.current.getUsrCoordsOfMouse(e);
          const newX = Math.round(coords[0]);
          const newY = Math.round(coords[1]);
          const upPoint = { x: newX, y: newY };
          const isOnPolygon = Object.values(clickedObject[0].descendants).find(
            (i: any) => i.elType === "polygon"
          );
          const isPolygonLine = clickedObject.filter(
            (item) => item.elType === "polygon"
          );
          if (
            graphShapesRef.current.map((i) => i?.drawKey?.id).includes(shapeId) ||
            isOnPolygon ||
            isPolygonLine.length > 0
          ) {
            // console.log("mainCheck");
            if (
              selectedButtonRef.current === "Label" &&
              !deleteStartRef.current
            ) {
              if (!clickedObject.length) return;
              const shapeId = clickedObject[0].id;
              // console.log(
              //   shapeId,
              //   clickedObject.length,
              //   "firstCondition Entered"
              // );
              handleLabelClick(
                board.current,
                newPoint,
                shapeId,
                event,
                clickedObject[0]
              );
              return;
            } else if (deleteStartRef.current) {
              if (!clickedObject.length) return;
              handleDeleteElement(board.current, clickedObject);
              setDeleteStart(false);
              return;
            } else {
              handleMoveShape(
                board.current,
                newPoint,
                upPoint,
                shapeId,
                clickedObject[0]
              );
            }
            return;
          }
        });
      }
      return;
    }
    if (selectedButtonRef.current === "Point") {
      handlePointClick(
        board.current,
        x,
        y,
        previousSettings.current,
        activeTabIndex,
        setCustomGraphOptions,
        selectedButtonRef.current,
        setHistory
      );
      return;
    }

    if (buttonsSettings[selectedButtonRef.current]?.max === 2) {
      handleTwoPointsShape(
        board.current,
        newPoint,
        previousSettings.current,
        activeTabIndex,
        setCustomGraphOptions,
        pointsRef,
        elementsRef,
        selectedButtonRef.current,
        questiontype,
        setHistory
      );
      return;
    }

    if (selectedButtonRef.current === "Polygon") {
      handlePolygonClick(
        board.current,
        newPoint,
        previousSettings.current,
        activeTabIndex,
        setCustomGraphOptions,
        elementsRef,
        selectedButtonRef.current,
        boardResetting,
        questiontype,
        false,
        setHistory
      );
      return;
    }
  };
  // Helper to update point coordinates
  const handleMoveShape = (board, newPoint, upPoint, shapeId, objectMoved) => {
    const dx = upPoint.x - newPoint.x;
    const dy = upPoint.y - newPoint.y;
    const shapeSelected = graphShapesRef.current.find(
      (s) => s.drawKey.id === shapeId
    );
    if (!shapeSelected && shapeId) {
      handleBorderClick(
        board,
        newPoint,
        upPoint,
        shapeId,
        dx,
        dy,
        setCustomGraphOptions,
        setHistory
      );
      return;
    }

    if (shapeSelected.drawKey.elType === "point" && !shapeSelected.hasParent) {
      handlePointMove(
        shapeSelected,
        upPoint,
        setCustomGraphOptions,
        setHistory
      );
      return;
    }

    if (
      shapeSelected.drawKey.elType !== "curve" &&
      shapeSelected.drawKey.elType !== "polygon"
    ) {
      handleLineOrShapeMove(
        shapeSelected,
        shapeId,
        dx,
        dy,
        upPoint,
        setHistory,
        setCustomGraphOptions
      );
      return;
    }

    if (shapeSelected.drawKey.elType === "curve") {
      handleCurveMove(
        shapeSelected,
        shapeId,
        dx,
        dy,
        upPoint,
        setHistory,
        setCustomGraphOptions,
        graphShapesRef
      );
      return;
    }

    if (shapeSelected.drawKey.elType === "polygon") {
      handlePolygonMove(
        shapeSelected,
        dx,
        dy,
        objectMoved,
        setCustomGraphOptions,
        setHistory
      );
    }
  };

  const handleLabelClick = (board, newPoint, shapeId, event, element) => {
    setActiveShape(false);
    const elementCLicked = graphShapesRef.current.find(
      (item) => item.drawKey.id === shapeId
    );
    const parent = graphShapesRef.current.find((item) =>
      item.drawKey.parents.includes(shapeId)
    );

    if (!elementCLicked) {
      const parentPolygon = graphShapesRef.current.find((item) =>
        item.drawKey?.vertices?.map((i) => i.id).includes(shapeId)
      );
      if (!parentPolygon) return;
      const positions = board
        .getAllUnderMouse(event)
        .find((item) => Array.isArray(item));
      const state = {
        element: element,
        x: positions[0] + board.getMousePosition(event)[0],
        y: positions[1] + board.getMousePosition(event)[1],
        selectedObject: parentPolygon,
        isChild: true,
      };
      setLabelState(state);
      setActiveShape(true);
      return;
    }
    if (!elementCLicked.hasParent) {
      const positions = board
        .getAllUnderMouse(event)
        .find((item) => Array.isArray(item));
      const state = {
        element: element,
        x: positions[0] + board.getMousePosition(event)[0],
        y: positions[1] + board.getMousePosition(event)[1],
        selectedObject: elementCLicked,
        isChild: false,
      };
      setLabelState(state);
    } else {
      if (parent) {
        const point1Coords = {
          x:
            parent.drawKey?.point1?.X() || parent?.subElementIds?.startPoint?.x,
          y:
            parent.drawKey?.point1?.Y() || parent?.subElementIds?.startPoint?.y,
        };
        const point2Coords = {
          x: parent.drawKey?.point2?.X() || parent?.subElementIds?.endPoint?.x,
          y: parent.drawKey?.point2?.Y() || parent?.subElementIds?.endPoint?.y,
        };

        // Convert to viewport coordinates
        const topLeft = board.getMousePosition(event);
        const viewportCoords1 = {
          x: point1Coords.x + topLeft[0],
          y: point1Coords.y + topLeft[1],
        };
        const viewportCoords2 = {
          x: point2Coords.x + topLeft[0],
          y: point2Coords.y + topLeft[1],
        };

        const state = {
          element: element,
          x: Math.round((viewportCoords1.x + viewportCoords2.x) / 2),
          y: Math.round((viewportCoords1.y + viewportCoords2.y) / 2),
          selectedObject: parent,
          isChild: true,
        };
        setLabelState(state);
      } else {
        const parentCurve = graphShapesRef.current
          .find(item => item.id === elementCLicked.parentID)
        // Convert to viewport coordinates
        const topLeft = board.getMousePosition(event);
        const viewportCoords1 = {
          x: elementCLicked.coords.x + topLeft[0],
          y: elementCLicked.coords.y + topLeft[1],
        };
        const state = {
          element: element,
          x: Math.round(viewportCoords1.x),
          y: Math.round(viewportCoords1.y),
          selectedObject: parentCurve,
          isChild: true,
        };
        setLabelState(state);
      }
    }
    setActiveShape(true);
  };

  const handleDeleteElement = (board, clickedObject) => {
    if (!clickedObject || clickedObject.length === 0) return;

    const elementId = clickedObject[0].id;
    let elementToDelete = graphShapesRef.current.find(
      (item) => item.drawKey.id === elementId
    );

    const findElementsToDelete = (mainElement) => {
      let idsToDelete = [mainElement.drawKey.id];

      if (mainElement.type === "Polygon") {
        const children = [
          ...(mainElement.subElementIds.segments || []),
          ...(mainElement.subElementIds.pointsShapes || []),
        ];
        idsToDelete.push(...children.map((s) => s.id));
      } else if (!mainElement.hasParent) {
        const children = graphShapesRef.current.filter(
          (item) => item.parentID === mainElement.id
        );
        idsToDelete.push(...children.map((s) => s.drawKey.id));
      } else {
        const parent = graphShapesRef.current.find(
          (item) => item.id === mainElement.parentID
        );
        if (parent) {
          const siblings = graphShapesRef.current.filter(
            (item) => item.parentID === parent.id
          );
          idsToDelete.push(
            parent.drawKey.id,
            ...siblings.map((s) => s.drawKey.id)
          );
        }
      }

      return idsToDelete;
    };

    if (!elementToDelete) {
      const parent = graphShapesRef.current.find(
        (item) => clickedObject[0].descendants?.[item.drawKey.id]
      );

      if (parent) {
        elementToDelete = parent;
      } else {
        const parentPolygon = clickedObject.find(
          (item) => item.elType === "polygon"
        );
        if (parentPolygon) {
          elementToDelete = graphShapesRef.current.find(
            (item) => item.drawKey.id === parentPolygon.id
          );
        }
      }
    }

    if (!elementToDelete) {
      return;
    }

    const idsToDelete = findElementsToDelete(elementToDelete);
    for (let i = board.objectsList.length - 1; i >= 0; i--) {
      let deletionsCount = idsToDelete.length;
      if (deletionsCount > 0) {
        const item = board.objectsList[i];
        if (idsToDelete.includes(item?.id)) {
          board.removeObject(item);
          deletionsCount--;
        }
      }
      if (deletionsCount === 0) break;
    }

    setCustomGraphOptions((prevData) => {
      const previousState = prevData;
      const newState = prevData.filter(
        (o) => !idsToDelete.includes(o.drawKey.id)
      );
      setHistory((prevHistory) => [
        ...prevHistory,
        {
          action: "delete",
          previousState,
          newState,
        },
      ]);
      return newState;
    });

    board.update();
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-start justify-between mb-5 rounded-md gap-y-1 w-[800px]">
        {graphSettings?.more_options?.annotation?.title && (
          <div className="text-center text-lg font-medium mt-2 mb-1">
            {graphSettings?.more_options?.annotation?.title}
          </div>
        )}
        <div className="mt-5 flex items-center p-2 justify-between bg-[#eee] rounded-md flex-wrap min-w-full gap-2">
          <div className="flex space-x-2">
            {optionItems?.toolbar?.tools?.map((tool, index) => (
              <button
                key={`${tool}-${index}`}
                type="button"
                disabled={isLocked}
                className={`mr-2 flex p-[5px] ${
                  customGraphOptionsIndex === index
                    ? "rounded-sm border-[#fa9b31]"
                    : ""
                } border-2`}
                onClick={() => {
                  setSelectedButton(tool);
                  setCustomGraphOptionsIndex(index);
                }}
              >
                {tool === "Point" && <TbPoint className="mr-1" />}
                {tool === "Move" && <IoMdMove className="mr-1" />}
                {tool === "Circle" && <FaRegCircle className="mr-1" />}
                {tool}
              </button>
            ))}
          </div>
          <Controls
            buttons={buttons.filter((btn) =>
              graphSettings?.options?.toolbar?.controls?.includes(btn.label)
            )}
          />
        </div>
        <div className="flex items-center justify-between mb-5 rounded-md relative">
          <div
            style={{
              margin: `${graphSettings?.more_options?.layout?.margin || 0}px`,
            }}
          >
            {graphSettings?.more_options?.annotation?.label_top && (
              <div className="text-center font-2 mt-2 mb-1">
                {graphSettings?.more_options?.annotation?.label_top}
              </div>
            )}
            <div className="basis-full">
              {graphSettings?.more_options?.annotation?.label_left && (
                <div className="text-center font-2 mt-2 mb-1 rotate-90 origin-top-left pr-40">
                  {graphSettings?.more_options?.annotation?.label_left}
                </div>
              )}
              <div
                ref={(el) => {
                  graphRef.current[activeTabIndex] = el; // Correctly assign the current div
                }}
                className="jxgbox border"
                style={{
                  height: `${
                    graphSettings?.more_options?.layout?.height || 800
                  }px`,
                  width: `${
                    graphSettings?.more_options?.layout?.width || 800
                  }px`,
                }}
              ></div>
              {graphSettings?.more_options?.annotation?.label_right && (
                <div className="text-center font-2 mt-4 mb-4 rotate-90 origin-top-right pl-40 translate-x-4">
                  {graphSettings?.more_options?.annotation?.label_right}
                </div>
              )}
            </div>
            {graphSettings?.more_options?.annotation?.label_bottom && (
              <div className="text-center font-2 mt-2 mb-1">
                {graphSettings?.more_options?.annotation?.label_bottom}
              </div>
            )}
          </div>
          {activeShape && (
            <input
              type="text"
              value={labelValue}
              onChange={(e) => setLabelValue(e.target.value)}
              style={{
                position: "absolute",
                top: `${labelPosition.y}px`,
                left: `${labelPosition.x}px`,
                display: labelState.element ? "block" : "none",
              }}
              onKeyDown={(e) => {
                handleSettingLabel(e);
              }}
              onBlur={(e) => handleSettingLabel(e)}
              placeholder="Type then press enter"
              className="border border-gray-300 rounded-md p-2"
            />
          )}
        </div>
      </div>
    </div>
  );
}
