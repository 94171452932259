import React from 'react';
interface IconProps {
    fill?: string;
    className?: string;
    style?: {}
  }
const Play = ({ fill = "#333", className = "", style ={} }: IconProps) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="play_circle_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M114.2-840.55l5.254-3.37-5.254-3.37Zm1.886,4.63a7.788,7.788,0,0,1-3.12-.631,8.085,8.085,0,0,1-2.542-1.712,8.1,8.1,0,0,1-1.71-2.539,7.773,7.773,0,0,1-.63-3.116,7.787,7.787,0,0,1,.631-3.12,8.086,8.086,0,0,1,1.712-2.542,8.1,8.1,0,0,1,2.539-1.71,7.774,7.774,0,0,1,3.116-.63,7.788,7.788,0,0,1,3.12.631,8.085,8.085,0,0,1,2.542,1.712,8.1,8.1,0,0,1,1.71,2.539,7.773,7.773,0,0,1,.63,3.116,7.787,7.787,0,0,1-.631,3.12,8.086,8.086,0,0,1-1.712,2.542,8.1,8.1,0,0,1-2.539,1.71A7.774,7.774,0,0,1,116.082-835.92Zm0-1.2A6.557,6.557,0,0,0,120.9-839.1a6.556,6.556,0,0,0,1.98-4.817,6.557,6.557,0,0,0-1.979-4.817,6.556,6.556,0,0,0-4.817-1.98,6.557,6.557,0,0,0-4.817,1.979,6.556,6.556,0,0,0-1.98,4.817,6.557,6.557,0,0,0,1.979,4.817A6.556,6.556,0,0,0,116.08-837.124ZM116.08-843.92Z"
                  transform="translate(-108.08 851.92)" fill={fill} className={className}/>
        </svg>

    );
};

export default Play;
