import React from 'react';
import styled from 'styled-components';
import Blank from 'studio/icons/Blank';
import FromExisting from 'studio/icons/FromExisting';
import UseTemplates from 'studio/icons/UseTemplates';
import "./CreateAssesment.styles.scss";
import AppModal from 'studio/components/general/Modal';

// Define the type for props
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  openModal: (arg: string) => void;
}



// Container for the boxes inside the modal
const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

// Individual box style
const Box = styled.div`
  border: 1px solid #ddd; /* Light gray border for each box */
  border-radius: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 32%;
  height:210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff; /* White background for the box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for the box */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  }
`;

// Box icon style
const BoxIcon = styled.div<{ onClick?: () => void }>`
  font-size: 32px; /* Larger icon size */
  margin-bottom: 10px;
  color: #007bff; /* Icon color */
`;



const CreateAssessment: React.FC<ModalProps> = ({ isOpen, onClose, openModal }) => {

  const ContentData=()=>{
    return(
      <div className="create-modal-content">

      <div className="modal-header">
        Create New Assessment
      </div>

      <BoxContainer>
        <Box onClick={() => openModal('blank')}>
          <BoxIcon >
            <Blank />
          </BoxIcon>
          <div className="modal-content">
            BLANK
          </div>
        </Box>
        <Box onClick={() => openModal('existing')}>
          <BoxIcon>
            <FromExisting />
          </BoxIcon>
          <div className="modal-content">
            FROM EXISTING
          </div>
        </Box>
        <Box>
          <BoxIcon>
            <UseTemplates />
          </BoxIcon>
          <div className="modal-content">
            USE TEMPLATE
          </div>
        </Box>
      </BoxContainer>
    </div>
    )
  }
  return (
    <AppModal
    title=""
    visible={isOpen}
    content={<ContentData/>}
    okText="Duplicate"
    cancelText="No"
    onCancel={() => onClose()}
    footer={[
    ]}
    width={750}
    className="p-0"
    modalProps={{
      maskClosable: false,
      centered: true,
    }}
  />
   

  );
};

export default CreateAssessment;
