import uuid from "react-uuid";
import { MCQTypes } from "./questionTypes.constants";

export const initial_more_options = {
  scoring: { unscored: false },
  layout: {},
  extras: {},
};

const mcqGeneral = {
  // name: "Multiple choice - signle answer",
  question: "",
  correct_answer: {
    valid_response: { value: [], score: 0 },
    alt_responses: [], // [{value: [], score}, {value: [], score}]
  },
  attachment_type: "",
  attachment: "",
  settings: {
    multiple_response: false,
  },
  options: [
    { id: uuid(), label: "", checked: false },
    { id: uuid(), label: "", checked: false },
    { id: uuid(), label: "", checked: false },
    { id: uuid(), label: "", checked: false },
  ],
  more_options: initial_more_options,
};

export const DefaultQuestionsData = {
  mcq: {
    mcs: {
      type: MCQTypes.SINGLEANSWER,
      ...mcqGeneral,
    },
    taf: {
      type: MCQTypes.TRUEFALSE,
      ...mcqGeneral,
      options: [
        { id: uuid(), label: "True", checked: false },
        { id: uuid(), label: "False", checked: false },
      ],
    },
    mcbl: {
      type: MCQTypes.BLOCKLAYOUT,
      ...mcqGeneral,
    },
    mcmr: {
      type: MCQTypes.MULTIPLEANSWERS,
      ...mcqGeneral,
    },
  },
};
