import { Input } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

type TextInput = {
  onChange: (value: string) => void;
  placeholder?: string;
  size?: SizeType;
  style?: any;
  value: string;
};

const TextInput = ({
  size,
  style,
  placeholder,
  onChange,
  value,
}: TextInput) => {
  return (
    <Input
      value={value}
      size={size || "large"}
      placeholder={placeholder || "Give it a name!"}
      onChange={(e) => onChange(e.target.value)}
      style={style}
      // prefix={
      //   <SearchOutlined
      //     style={{
      //       opacity: 0.4,
      //       fontSize: Sizes.iconFontSize,
      //       marginRight: 14,
      //       color: "#CACACA",
      //     }}
      //   />
      // }
    />
  );
};

export default TextInput;
