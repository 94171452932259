import React, { useState, useEffect } from "react";
import { Table, Button, Dropdown } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import { Assessment } from "../../../types/assessment";
import "./styles.scss";

import CustomPagination from "../general/CustomPagination";

import type { TableRowSelection } from 'antd/es/table/interface';
import Ascend from "../../icons/Ascend";
import CustomDropdown from "./CustomDropdown";

interface PaginationData {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
}

interface AssessmentTableProps {
    data: Assessment[];
    loading: boolean;
    selectedRows?: any[];
    onRowSelected?: (keys: React.Key[], rows: any[]) => void;
    onSort?: (field: string, order: 'ascend' | 'descend' | null) => void;
    page: number;
    perPage: number;
    totalRows: number;
    onPageChange: (page: number) => void;
    onPerPageChange: (perPage: number) => void;
    onRowClick?: (row: any) => void;
    assessmentsListingPagination: PaginationData;
    onDuplicate?: (row: Assessment) => void;
    onDelete: (id: any) => void;
    onEdit: (id: any) => void;
    clearSelection?: boolean;
    showBanner?: boolean;
    selectedRowKeys?: React.Key[];
    currentSortField?: string;
    currentSortOrder?: 'ascend' | 'descend' | null;
    onSelectedRowKeysChange?: (keys: React.Key[], rows: Assessment[]) => void;
    setSelectedRowKeys?: any;
}

const AssessmentTable: React.FC<AssessmentTableProps> = ({
                                                             data,
                                                             loading,
                                                             selectedRows,
                                                             onRowSelected,
                                                             onSort,
                                                             onPageChange,
                                                             onPerPageChange,
                                                             onRowClick,
                                                             assessmentsListingPagination,
                                                             clearSelection,
                                                             onDelete,
                                                             onEdit,
                                                             onDuplicate,
                                                             showBanner,
                                                             selectedRowKeys,
                                                             setSelectedRowKeys,
                                                             currentSortField,
                                                             currentSortOrder
                                                         }) => {
    const [currentPage, setCurrentPage] = useState(
        assessmentsListingPagination.current_page
    );
    const [pageSize, setPageSize] = useState(
        assessmentsListingPagination.per_page
    );

    useEffect(() => {
        if (clearSelection) {
            onRowSelected?.([], []);
        }
    }, [clearSelection, data]);
console.log('currentSortField',currentSortField,
    currentSortOrder);

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: SorterResult<Assessment>
    ) => {
        // Handle sorting
        if (sorter && 'field' in sorter) {
            console.log(sorter)
            if (sorter.field == 'subjects'){
                onSort?.('subject', sorter.order || null);
            }
            else if (sorter.field == 'grades'){
                onSort?.('grade', sorter.order || null);
            }
            else if (sorter.field == 'tags'){
                onSort?.('tag', sorter.order || null);
            }
            else {
                onSort?.(sorter.field as string, sorter.order || null);

            }
        }
    };

    const handleRowClick = (record: any) => {
        console.log('Preview:', record);
        onRowClick?.(record);
    };

    const handleDelete = (id: string) => {
        console.log('Delete:', id);
        onDelete(id);
        setSelectedRowKeys([]);
    };

    const handleDuplicate = (record: any) => {
        console.log('Duplicate:', record);
        onDuplicate?.(record);
        setSelectedRowKeys([]);
    };

    const handleAssign = (record: any) => {
        console.log('Assign:', record);
    };

    const handleEdit = (record: any) => {
        console.log('Edit:', record);
        onEdit(record);
    };

    const columns: ColumnsType<Assessment> = [
        {
            title: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    Assessment name
                    <Ascend
                        style={{
                            marginLeft: 20,
                            transform: currentSortField === 'name' && currentSortOrder === 'descend'
                                ? 'rotate(180deg)'
                                : 'none',
                            opacity: currentSortField === 'name' ? 1 : 0.4
                        }}
                    />
                </div>
            ),
            dataIndex: "name",
            key: "name",
            width: "35%",
            sorter: true,
            sortOrder: currentSortField === 'name' ? currentSortOrder : null,
            render: (text) => (
                <div className="table-cell-content">
                    <span style={{ color: "#262626", fontWeight: 500 }}>{text}</span>
                </div>
            ),
        },
        {
            title: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    Grade
                    <Ascend
                        style={{
                            marginLeft: 20,
                            transform: currentSortField === 'grade' && currentSortOrder === 'descend'
                                ? 'rotate(180deg)'
                                : 'none',
                            opacity: currentSortField === 'grade' ? 1 : 0.4
                        }}
                    />
                </div>
            ),
            dataIndex: "grades",
            key: "grades",
            width: "10%",
            sorter: true,
            sortOrder: currentSortField === 'grade' ? currentSortOrder : null,
            render: (grades) => (
                <div className="table-cell-content">{grades?.name || "N/A"}</div>
            ),
        },
        {
            title: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    Created date
                    <Ascend
                        style={{
                            marginLeft: 20,
                            transform: currentSortField === 'created_at' && currentSortOrder === 'descend'
                                ? 'rotate(180deg)'
                                : 'none',
                            opacity: currentSortField === 'created_at' ? 1 : 0.4
                        }}
                    />
                </div>
            ),
            dataIndex: "created_at",
            key: "created_at",
            width: "15%",
            sorter: true,
            sortOrder: currentSortField === 'created_at' ? currentSortOrder : null,
            render: (text) => <div className="table-cell-content">{text}</div>,
        },
        {
            title: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    Subject
                    <Ascend
                        style={{
                            marginLeft: 20,
                            transform: currentSortField === 'subject' && currentSortOrder === 'descend'
                                ? 'rotate(180deg)'
                                : 'none',
                            opacity: currentSortField === 'subject' ? 1 : 0.4
                        }}
                    />
                </div>
            ),
            dataIndex: "subjects",
            key: "subjects",
            width: "10%",
            sorter: true,
            sortOrder: currentSortField === 'subject' ? currentSortOrder : null,
            render: (subjects) => (
                <div className="table-cell-content">
                    {subjects ? subjects.name : "N/A"}
                </div>
            ),
        },
        {
            title: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    Tag
                    <Ascend
                        style={{
                            marginLeft: 20,
                            transform: currentSortField === 'tag' && currentSortOrder === 'descend'
                                ? 'rotate(180deg)'
                                : 'none',
                            opacity: currentSortField === 'tag' ? 1 : 0.4
                        }}
                    />
                </div>
            ),
            dataIndex: "tags",
            key: "tags",
            width: "10%",
            sorter: true,
            sortOrder: currentSortField === 'tag' ? currentSortOrder : null,
            render: (tags) => (
                <div className="table-cell-content">
                    {tags.length > 0 ? tags.map((tag) => tag.name).join(", ") : "N/A"}
                </div>
            ),
        },
        {
            title: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    Status
                    <Ascend
                        style={{
                            marginLeft: 20,
                            transform: currentSortField === 'status' && currentSortOrder === 'descend'
                                ? 'rotate(180deg)'
                                : 'none',
                            opacity: currentSortField === 'status' ? 1 : 0.4
                        }}
                    />
                </div>
            ),
            dataIndex: "status",
            key: "status",
            width: "10%",
            sorter: true,
            sortOrder: currentSortField === 'status' ? currentSortOrder : null,
            render: (status: boolean) => (
                <div className="table-cell-content">
                    {status ? "Published" : "Unpublished"}
                </div>
            ),
        },

        {
            title: "",
            key: "actions",
            width: "100px",
            render: (_, record) => (
                <CustomDropdown
                    record={record}
                    onRowClick={handleRowClick}
                    onDelete={handleDelete}
                    onDuplicate={handleDuplicate}
                    onAssign={handleAssign}
                    onEdit={handleEdit}
                />
            ),
        },
    ];

    const rowSelection: TableRowSelection<Assessment> = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
            onRowSelected?.(newSelectedRowKeys, selectedRows);
        },
        preserveSelectedRowKeys: true,
        type: showBanner ? 'checkbox' : 'radio',
    };

    const rowClassName = (record: Assessment, index: number) => {
        return index % 2 === 0 ? "even-row" : "odd-row";
    };

    return (
        <div className="assessment-table">
            <div style={{ maxWidth: "100%" }}>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    onChange={handleTableChange}
                    pagination={false}
                    rowKey="id"
                    scroll={{ x: true }}
                    rowClassName={rowClassName}
                    onRow={(record) => ({
                        onClick: () => onRowClick?.(record),
                    })}
                />
                <CustomPagination
                    totalSelectedRows={selectedRows?.length}
                    current={assessmentsListingPagination.current_page}
                    pageSize={assessmentsListingPagination.per_page}
                    total={assessmentsListingPagination.total}
                    onChange={onPageChange}
                    onPageSizeChange={onPerPageChange}
                />
            </div>
        </div>
    );
};

export default AssessmentTable;