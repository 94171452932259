import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

export const GriddedBase: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Table role="grid">
            <tbody>
            {children}
            </tbody>
        </Table>
    );
};
