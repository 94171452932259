interface IconProps {
    fill?: string;
    className?: string;
  }
const QuestionBank: React.FC<IconProps> = ({ fill = "currentColor", className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M3.2714 16.9331V8.4317H4.82784V16.9331H3.2714ZM9.2329 16.9331V8.4317H10.7893V16.9331H9.2329ZM0 20.6022V19.0458H20V20.6022H0ZM15.1722 16.9331V8.4317H16.7286V16.9331H15.1722ZM0 6.31897V4.87073L9.99999 6.10352e-05L20 4.87073V6.31897H0ZM3.72059 4.76257H16.2794L9.99999 1.74811L3.72059 4.76257Z"
      fill={fill} className={className}
    />
  </svg>
);
export default QuestionBank;
