import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginRequest } from "../../../services/authentication";
import { setAuthentication } from "../../../utils/CheckAuthentication";
import { setUser } from "../../../redux/slices/AuthenticationSlice";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import type { FormProps } from "antd";
import { Button, Checkbox, Flex, Form, Input, message, Typography } from "antd";
import { Messages } from "../../constants/messages.constant";
import { Colors } from "../../theme/colors.theme";
import { Roles } from "../../constants/enums.constants";
import { Sizes } from "studio/theme/Sizes.theme";
import { ROUTES } from "studio/constants/routes.constants";

const { Title, Link } = Typography;

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const regex = /\/(.*?)\-/;
  const match = currentPath.match(regex);
  const extractedString = match ? match[1] : "admin";
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const success = (content: string) => {
    const startTime = Date.now();
    message.destroy();
    message.success({
      content,
      duration: 5,
      onClose: () => {
        const endTime = Date.now();
        const durationInSeconds = (endTime - startTime) / 1000;
      },
    });

    setTimeout(() => {
      const currentTime = Date.now();
      const elapsed = (currentTime - startTime) / 1000;
    }, 5000);
  };

  const onError = (content: string) => {
    message.error({
      content,
      duration: 3,
    });
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    loginHandler(values.username, values.password);
  };

  useEffect(() => {
    localStorage.setItem("type", extractedString);
  }, []);

  const handleNavigateAfterSuccess = (path: string) => {
    setTimeout(() => {
      navigate(path);
    }, 800);
  };

  const loginHandler = async (email: string, password: string) => {
    setLoading(true);
    let userLoginInfo = { email, password };
    const { data, error } = await loginRequest(userLoginInfo);
    if (data) {
      setLoading(false);
      setAuthentication({
        id: data.users.client_id,
        token: data.access_token,
        rememberMe: true,
      });
      const dataRole = data?.users?.roles[0];
      dispatch(setUser({ user: data?.users, token: data?.access_token }));
      success(`${dataRole} ${Messages.LoginSuccess}`);
      if (!Roles.includes(dataRole)) {
        onError(Messages.LoginInvalid);
        return;
      }
      if (dataRole === "Manager")
        handleNavigateAfterSuccess("/client-dashboard");
      else handleNavigateAfterSuccess(ROUTES.assessmentLibrary);
    } else {
      setLoading(false);
      let errorMessages = "";
      if (typeof error === "object") {
        Object.keys(error).forEach((key) => {
          errorMessages = error[key].join("\n");
        });
      } else {
        errorMessages = error;
      }
      onError(errorMessages);
    }
  };

  return (
    <Form
      name="basic"
      size="large"
      wrapperCol={{ span: 24 }}
      style={{ width: "60%", maxWidth: 434 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      {contextHolder}
      <Title style={{ fontSize: 30, fontWeight: "400" }}>Login</Title>
      <Form.Item<FieldType>
        label={null}
        name="username"
        rules={[
          { required: true, message: Messages.EnterUserName },
          { type: "email", message: Messages.EnterValidEmail },
        ]}
        style={{ marginBottom: Sizes.padding }}
      >
        <Input placeholder="Username" />
      </Form.Item>

      <Form.Item<FieldType>
        label={null}
        name="password"
        rules={[{ required: true, message: Messages.EnterPassword }]}
        style={{ marginBottom: Sizes.headerPadding }}
      >
        <Input.Password
          size="large"
          placeholder="Password"
          iconRender={(visible) =>
            visible ? (
              <EyeOutlined
                style={{ fontSize: Sizes.iconFontSize }}
                size={Sizes.iconFontSize}
                color={Colors.textBlack}
              />
            ) : (
              <EyeInvisibleOutlined
                style={{ fontSize: Sizes.iconFontSize }}
                size={Sizes.iconFontSize}
                color={Colors.textBlack}
              />
            )
          }
        />
      </Form.Item>

      <Form.Item<FieldType>
        name="remember"
        valuePropName="checked"
        label={null}
        style={{ marginBottom: Sizes.padding }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item label={null} style={{ marginBottom: Sizes.headerPadding }}>
        <Button loading={loading} block type="primary" htmlType="submit">
          SIGN IN
        </Button>
      </Form.Item>

      <Flex justify="center" align="center">
        <Link
          style={{ color: Colors.textBlack }}
          onClick={() => navigate("/reset-password")}
        >
          Forget Password?
        </Link>
      </Flex>
    </Form>
  );
};

export default LoginForm;
