import { Divider, Select, Space, Typography } from "antd";
import ArrowIcon from "studio/icons/ArrowIcon";

const { Text } = Typography;

const QuestionLeft = () => {
  return (
    <div>
      <Text>STANDARDS</Text>
      <Space direction="vertical" className="w-full mt-6">
        <Text>Standards</Text>
        <Select
          size="large"
          className="w-full"
          // value={filterData?.subject_id}
          // defaultValue="All"
          suffixIcon={<ArrowIcon />}
          // onChange={(id: number) => {
          //   const selectedSub = subjects.find((sub) => sub.id == id);
          //   if (selectedSub) {
          //     setSelectedSubject(selectedSub);
          //     handleChange("subject_id", id);
          //   } else {
          //     setSelectedSubject(null);
          //     handleChange("subject_id", "");
          //   }
          // }}
          options={[{ id: "", value: "", label: "All" }]}
          placeholder="Select"
        />
      </Space>
      <Divider />
      <Space direction="vertical" className="w-full mb-4">
        <Text>Chapter/unit</Text>
        <Select
          size="large"
          className="w-full"
          suffixIcon={<ArrowIcon />}
          options={[{ id: "", value: "", label: "All" }]}
          placeholder="Select"
        />
      </Space>
      <Space direction="vertical" className="w-full mb-4">
        <Text>Lesson</Text>
        <Select
          size="large"
          className="w-full"
          suffixIcon={<ArrowIcon />}
          options={[{ id: "", value: "", label: "All" }]}
          placeholder="Select"
        />
      </Space>
      <Space direction="vertical" className="w-full mb-4">
        <Text>Learning objectives</Text>
        <Select
          size="large"
          className="w-full"
          suffixIcon={<ArrowIcon />}
          options={[{ id: "", value: "", label: "All" }]}
          placeholder="Select"
        />
      </Space>
      <Divider />
      <Space direction="vertical" className="w-full">
        <Text>Depth of knowledge</Text>
        <Select
          size="large"
          className="w-full"
          suffixIcon={<ArrowIcon />}
          options={[{ id: "", value: "", label: "All" }]}
          placeholder="Select"
        />
      </Space>
    </div>
  );
};

export default QuestionLeft;
