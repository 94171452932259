import { Divider, Flex, InputNumber, Tabs, Typography } from "antd";
import { BsTrash } from "react-icons/bs";
import AddButton from "studio/components/general/AddButton.component";
import { MCQTypes } from "studio/constants/questionTypes.constants";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";

interface ICorrectAnswerSection {
  data: any;
  setData: (newData: any) => void;
  children: React.ReactNode;
  activeTabIndex: number;
  setActiveTabIndex: (idx: number) => void;
  activeTab: any;
}

const { Text } = Typography;

const CorrectAnswerWrapperForMcq = ({
  data,
  setData,
  children,
  activeTabIndex,
  setActiveTabIndex,
  activeTab,
}: ICorrectAnswerSection) => {
  const { correct_answer } = data;
  const validResponse = correct_answer?.valid_response || {
    value: [],
    score: 0,
  };
  const altResponses = correct_answer?.alt_responses || [];

  const handleNewAltAnswer = () => {
    setData({
      ...data,
      correct_answer: {
        valid_response: validResponse,
        alt_responses: [...altResponses, { value: [], score: 0 }],
      },
    });
  };

  const handlePointsChange = (val: string) => {
    let newValidResponse = validResponse;
    let newAltResponses = altResponses;
    if (activeTabIndex === 0) {
      newValidResponse = {
        ...newValidResponse,
        score:
          !isNaN(Number(val)) && Number(val) > 0 ? val : validResponse.score,
      };
    } else {
      newAltResponses = newAltResponses.map((altRes, index) => {
        return index === activeTabIndex - 1
          ? {
              ...altRes,
              score: !isNaN(Number(val)) && Number(val) > 0 ? val : "0",
            }
          : altRes;
      });
    }

    setData({
      ...data,
      correct_answer: {
        valid_response: newValidResponse,
        alt_responses: newAltResponses,
      },
    });
  };

  const handleRemoveTab = (index: number) => {
    const updatedArr = removeItemByIndex(altResponses, index - 1);
    setActiveTabIndex(0);
    setData({
      ...data,
      correct_answer: {
        valid_response: validResponse,
        alt_responses: updatedArr,
      },
    });
  };

  const removeItemByIndex = (arr: any[], index: number) => {
    const updatedArr = [...arr];
    updatedArr.splice(index, 1);
    return updatedArr;
  };

  const alternateArray = (n: number) => {
    let arr = [];
    for (let index = 0; index < n; index++) {
      const element = `Alternate ${index + 1}`;
      arr.push(element);
    }
    return arr;
  };

  const items = ["Answer", ...alternateArray(altResponses.length)].map(
    (label, idx) => {
      return {
        key: idx.toString(),
        label,
        children,
      };
    }
  );

  const onChange = (activeKey: string) => {
    setActiveTabIndex(Number(activeKey));
  };

  return (
    <div className="answer_tabs">
      <Divider />
      <Text>CORRECT ANSWER</Text>
      <div style={{ marginTop: Sizes.padding }}>
        <Tabs
          activeKey={activeTabIndex.toString()}
          defaultActiveKey="0"
          items={items}
          onChange={onChange}
        />
      </div>
      <Flex style={{ marginTop: Sizes.base }} vertical>
        <Text>POINTS</Text>
        <InputNumber
          size="large"
          min="0"
          value={activeTab?.score}
          defaultValue="0"
          style={{
            width: 276,
            height: 46,
            marginTop: 6,
          }}
          onChange={(val: string) => {
            handlePointsChange(val);
          }}
        />

        <Flex
          align="center"
          style={{
            marginTop: Sizes.padding,
          }}
        >
          <AddButton
            width={212}
            label="Alternative Answer"
            onClick={handleNewAltAnswer}
            disabled={data.type === MCQTypes.TRUEFALSE && altResponses.length}
          />

          {activeTabIndex > 0 && (
            <div
              className="w-[40px] h-[48px] hover:opacity-70 hover:scale-105 flex justify-center items-center cursor-pointer"
              style={{
                border: `1px solid ${Colors.inputBorder}`,
                borderRadius: Sizes.borderRadius,
                marginLeft: Sizes.base,
              }}
              onClick={() => handleRemoveTab(activeTabIndex)}
            >
              <BsTrash
                className={`text-[#333333] cursor-pointer self-center w-4 h-4`}
                size={22}
              />
            </div>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default CorrectAnswerWrapperForMcq;
