interface IconProps {
  fill?: string;
  className?: string;
}
const EditPen = ({ fill = "currentColor", className = "" }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M1.45885 16.0295C1.05443 16.0295 0.710236 15.8874 0.426224 15.6033C0.142075 15.3193 0 14.9751 0 14.5707V3.24951C0 2.84521 0.142075 2.50098 0.426224 2.21686C0.710236 1.93268 1.05508 1.79071 1.46077 1.79071H8.81725L7.61989 2.98804H1.46077C1.39487 2.98804 1.3345 3.0155 1.27974 3.07037C1.22484 3.12524 1.19737 3.18555 1.19737 3.25146V14.5687C1.19737 14.6346 1.22484 14.695 1.27974 14.7498C1.3345 14.8047 1.39487 14.8322 1.46077 14.8322H12.778C12.8439 14.8322 12.9043 14.8047 12.9591 14.7498C13.014 14.695 13.0414 14.6346 13.0414 14.5687V8.34479L14.2388 7.14746V14.5687C14.2388 14.9745 14.0967 15.3193 13.8126 15.6033C13.5286 15.8874 13.1844 16.0295 12.78 16.0295H1.45885ZM5.02505 11.0045V8.12921L12.8029 0.347229C12.9284 0.224304 13.0628 0.135742 13.206 0.081543C13.3492 0.0270996 13.4986 0 13.6543 0C13.8125 0 13.9632 0.0264282 14.1064 0.0793457C14.2496 0.132324 14.3826 0.218811 14.5054 0.338928L15.6386 1.4729C15.7583 1.59863 15.8484 1.73651 15.9091 1.8866C15.9697 2.03662 16 2.19043 16 2.3479C16 2.50537 15.9739 2.65527 15.9217 2.79761C15.8696 2.94 15.7807 3.07263 15.6551 3.19537L7.85931 11.0045H5.02505ZM6.22244 9.80713H7.35799L12.7337 4.42078L12.1657 3.85291L11.5708 3.27948L6.22244 8.61719V9.80713Z"
      fill={fill} className={className}
    />
  </svg>
);
export default EditPen;
