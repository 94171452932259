import React, { useEffect, useState } from "react";
import { Layout, Button, Typography, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import StatsCards from "./StatsCards";
import AssessmentFilters from "./AssessmentFilters";
import ActionButtons from "./ActionButtons";
import AssessmentTable from "./AssessmentTable";
import Banner from "../general/Banner";
import { useNavigate } from "react-router-dom";
import {
  getAssessmentListing,
  duplicateAssessments,
} from "../../../services/assessment";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import axios from "axios";
import DuplicateModal from "./duplicate.model";
import { Sizes } from "../../theme/Sizes.theme";
import { ROUTES } from "studio/constants/routes.constants";

interface Assessment {
  id: string;
  name: string;
  grade: number;
  createdDate: string;
  subject: string;
  tag: any;
  dok: string;
  status: string;
}

interface AssessmentLibrary {
  showBanner?: boolean;
  onSelectedRowsParent?: (value: any) => void;
}

const initialFilterData = {
  search: "",
  subject_id: "",
  grade_id: "",
  subject: "all",
  grade: "all",
  tags: [],
  status: "",
  dateRange: null,
};

const AssessmentLibrary: React.FC<AssessmentLibrary> = ({
                                                          showBanner = true,
                                                          onSelectedRowsParent,
                                                        }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { assessmentsListing, assessmentsListingPagination } = useAppSelector(
      (state: RootState) => state.assessments
  );

  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<Assessment[]>([]);
  const [filterData, setFilterData] = React.useState(initialFilterData);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("");
  const [clearSelection, setClearSelection] = useState(false);
  const [assessment, setAssessment] = useState("");

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortBy, order, filterData]);

  const fetchData = async () => {
    setLoading(true);
    try {
      await dispatch(
          getAssessmentListing(page, perPage, {
            ...filterData,
            sort_by: sortBy,
            order: order,
            page: page,
            per_page: perPage,
          }) as any
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleSort = (field: string, order: 'ascend' | 'descend' | null) => {
    // Keep the current page when sorting
    setSortBy(field);
    setOrder(order === 'ascend' ? 'asc' : order === 'descend' ? 'desc' : '');
  };

  const success = (content: string) => {
    message.destroy();
    message.success({
      content,
      duration: 5,
    });
  };

  const onError = (content: string) => {
    message.error({
      content,
      duration: 3,
    });
  };

  const handleFilterChange = (field: string, value: any) => {
    if (field === "dateRange") {
      setFilterData((prev) => ({
        ...prev,
        start_date: value[0],
        end_date: value[1],
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    // Reset page to 1 only when filters change
    //setPage(1);
  };

  const handleRowSelected = (keys: React.Key[], rows: Assessment[]) => {
    setSelectedRowKeys(keys);
    setSelectedRows(rows);
    onSelectedRowsParent?.(rows);
  };

  const handlePageChange = (newPage: number) => {
    setClearSelection(false);
    setPage(newPage);
  };

  const handlePerPageChange = (newPerPage: number) => {
    setPerPage(newPerPage);
    //setPage(1); // Reset to first page when changing items per page
  };

  const handleDuplicate = async (id?: number) => {
    const ids = id ? [id] : selectedRows.map((row) => row.id);
    await dispatch(duplicateAssessments(ids) as any);
    setClearSelection(true);
    fetchData();
    setSelectedRows([]);
  };

  const handleDelete = async (id: any) => {
    const ids =
        typeof id !== "object" ? [id] : selectedRows.map((row) => row.id);
    let api = "api/assessment";
    let multiple = ids.length > 1;
    const typeDeleted = "assessment";
    let res;
    try {
      if (multiple) {
        res = await axios.post(
            `${process.env.REACT_APP_API_URL}/${api}/multidelete`,
            { [typeDeleted]: ids }
        );
      } else {
        res = await axios.delete(
            `${process.env.REACT_APP_API_URL}/${api}/${ids}`
        );
      }
      if (res?.status == 200) {
        setClearSelection(true);
        success(`Delete Assessment successfully!`);
      } else {
        setClearSelection(true);
        onError(`Delete Assessment failed!`);
      }
    } catch (err) {
      console.error(err);
      onError(`Delete Assessment failed!`);
    }
    fetchData();
    setSelectedRows([]);
  };

  const handleEdit = (id: any) => {
    navigate(ROUTES.createAssessment, { state: id });
  };

  const handleRowClick = (row: Assessment) => {
    //navigate(`/assessment/${row.id}`);
  };

  const handleDuplicateRow = (assessment: any) => {
    setAssessment(assessment);
    setIsModalOpen(true);
  };

  const handleReset = () => {
    setFilterData(initialFilterData);
    setPage(1);
    setSortBy("");
    setOrder("");
  };

  const handleStatusChange = (status: string) => {
    handleFilterChange("status", status);
  };

  return (
      <Layout>
        <div>
          <DuplicateModal
              open={isModalOpen}
              assessment={assessment}
              onCancel={() => setIsModalOpen(false)}
              refreshTable={fetchData}
          />
          {showBanner && (
              <Banner
                  currentStatus={filterData.status}
                  onStatusChange={handleStatusChange}
              />
          )}
          <div
              className="flex"
              style={showBanner ? { padding: Sizes.padding } : {}}
          >
            <div
                style={{
                  maxWidth: Sizes.maxScreenWidth,
                  display: "block",
                  width: "fit-content",
                  flex: 1,
                  margin: "0 auto",
                }}
            >
              <div style={showBanner ? { margin: "70px 25px 40px 25px" } : {}}>
                {showBanner && (
                    <h1
                        style={{
                          marginBottom: "25px",
                          fontSize: "18px",
                          fontWeight: 600,
                          fontFamily: "Poppins",
                        }}
                    >
                      Total Created ({assessmentsListingPagination?.total})
                    </h1>
                )}
                <AssessmentFilters
                    count={selectedRows?.length}
                    filterData={filterData}
                    onFilterChange={handleFilterChange}
                    onReset={handleReset}
                    onDelete={(id) => handleDelete(id)}
                    onDuplicate={() => handleDuplicate()}
                    selectedRows={selectedRows}
                    showOtherAssessmentFilter={showBanner}
                />
                <AssessmentTable
                    data={assessmentsListing}
                    loading={loading}
                    onRowSelected={handleRowSelected}
                    onSort={handleSort}
                    onPageChange={handlePageChange}
                    onPerPageChange={handlePerPageChange}
                    onRowClick={handleRowClick}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    page={page}
                    perPage={perPage}
                    totalRows={assessmentsListing.length}
                    assessmentsListingPagination={assessmentsListingPagination}
                    clearSelection={clearSelection}
                    onDuplicate={handleDuplicateRow}
                    showBanner={showBanner}
                    selectedRowKeys={selectedRowKeys}
                    selectedRows={selectedRows}
                    setSelectedRowKeys={setSelectedRowKeys}
                    currentSortField={sortBy}
                    currentSortOrder={order === 'asc' ? 'ascend' : order === 'desc' ? 'descend' : null}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
  );
};

export default AssessmentLibrary;
