// AnswerTabContent.tsx
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { defaultGriddedConfig } from '../MoreOptions/config';
import { GriddedValue } from "../GriddedResponse";
import GriddedResponse from "../GriddedResponse/GriddedResponse";

const TabContentContainer = styled.div`
  border: 1px solid #dadada;
  padding: 16px;
  background: white;
  width: fit-content;
`;

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 55px;
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 300px;
  font-size: small;
  margin-top: 16px;
`;

const PointsInput = styled.input`
  outline: none;
  width: fit-content;
  padding: 10px;
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

interface AnswerTabContentProps {
    currentQuestion?: any;
    isEditMode?: boolean;
    value: string[];
    score: number;
    onValueChange: (value: string[]) => void;
    onScoreChange: (score: number) => void;
    alternativeId?: string;
    disabled?: boolean;
    isPreview?: boolean;
    hidePoints?: boolean;
}

const AnswerTabContent: React.FC<AnswerTabContentProps> = React.memo(({
    currentQuestion,
    isEditMode,
    value,
    score,
    onValueChange,
    onScoreChange,
    alternativeId,
    disabled = false,
    isPreview = false,
    hidePoints = false
    }) => {
    const griddedValue: GriddedValue = useMemo(() => ({
        values: value || Array(defaultGriddedConfig.columns).fill('')
    }), [value]);

    const handleGriddedChange = useCallback((newValue: GriddedValue) => {
        onValueChange(newValue.values);
    }, [onValueChange]);

    return (
        <>
            <TabContentContainer>
                <GriddedResponse
                    currentQuestion={currentQuestion}
                    isEditMode={isEditMode}
                    value={griddedValue}
                    onChange={handleGriddedChange}
                    disabled={disabled}
                />
            </TabContentContainer>
            {!isPreview && !hidePoints && (
                <PointsContainer>
                    <span>Points</span>
                    <PointsInput
                        type="number"
                        min="0"
                        value={score}
                        onChange={(e) => onScoreChange(Number(e.target.value))}
                        disabled={disabled}
                    />
                </PointsContainer>
            )}
        </>
    );
});

AnswerTabContent.displayName = 'AnswerTabContent';

export default AnswerTabContent;
