import { Button } from "antd";
import { AddOutlined } from "@mui/icons-material";
import { Sizes } from "studio/theme/Sizes.theme";

const AddButton = ({
  onClick,
  label,
  width,
  disabled,
}: {
  onClick: () => void;
  label?: string;
  width?: number;
  disabled?: boolean;
}) => {
  return (
    <Button
      color="default"
      variant="solid"
      onClick={onClick}
      disabled={!!disabled}
      size="large"
      style={{
        width: width || 108,
      }}
      icon={
        <AddOutlined
          style={{
            fontWeight: Sizes.iconFontWeight,
            fontSize: Sizes.iconFontSize,
          }}
        />
      }
    >
      {label || "Add"}
    </Button>
  );
};

export default AddButton;
