import React from 'react';

const Delete = ({ style = {} }) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="14.195" height="16" viewBox="0 0 14.195 16">
            <path id="calendar_month_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M148.724-842.15a1.4,1.4,0,0,1-1.029-.425,1.4,1.4,0,0,1-.425-1.03v-11.286a1.4,1.4,0,0,1,.425-1.029,1.4,1.4,0,0,1,1.031-.425h1.268v-1.8h1.227v1.8h6.327v-1.8h1.194v1.8h1.268a1.4,1.4,0,0,1,1.031.425,1.4,1.4,0,0,1,.425,1.029V-843.6a1.4,1.4,0,0,1-.425,1.03,1.4,1.4,0,0,1-1.029.425Zm0-1.194h11.283a.251.251,0,0,0,.18-.082.251.251,0,0,0,.082-.181v-7.887H148.464v7.887a.251.251,0,0,0,.082.181A.251.251,0,0,0,148.726-843.344Zm-.263-9.343h11.808v-2.2a.251.251,0,0,0-.082-.18.25.25,0,0,0-.18-.082H148.726a.25.25,0,0,0-.18.082.251.251,0,0,0-.082.18Zm0,0v0Zm5.9,4.358a.714.714,0,0,1-.523-.214.709.709,0,0,1-.215-.522.714.714,0,0,1,.214-.523.709.709,0,0,1,.522-.215.715.715,0,0,1,.523.214.708.708,0,0,1,.215.522.714.714,0,0,1-.214.523A.709.709,0,0,1,154.369-848.329Zm-3.413,0a.714.714,0,0,1-.523-.214.709.709,0,0,1-.215-.522.715.715,0,0,1,.214-.523.709.709,0,0,1,.522-.215.715.715,0,0,1,.523.214.708.708,0,0,1,.215.522.714.714,0,0,1-.214.523A.709.709,0,0,1,150.956-848.329Zm6.826,0a.714.714,0,0,1-.523-.214.709.709,0,0,1-.215-.522.714.714,0,0,1,.214-.523.709.709,0,0,1,.522-.215.715.715,0,0,1,.523.214.708.708,0,0,1,.215.522.714.714,0,0,1-.214.523A.709.709,0,0,1,157.782-848.329Zm-3.413,3.347a.715.715,0,0,1-.523-.214.708.708,0,0,1-.215-.522.714.714,0,0,1,.214-.523.708.708,0,0,1,.522-.215.715.715,0,0,1,.523.214.709.709,0,0,1,.215.522.714.714,0,0,1-.214.523A.709.709,0,0,1,154.369-844.981Zm-3.413,0a.715.715,0,0,1-.523-.214.708.708,0,0,1-.215-.522.714.714,0,0,1,.214-.523.708.708,0,0,1,.522-.215.715.715,0,0,1,.523.214.709.709,0,0,1,.215.522.714.714,0,0,1-.214.523A.709.709,0,0,1,150.956-844.981Zm6.826,0a.715.715,0,0,1-.523-.214.708.708,0,0,1-.215-.522.714.714,0,0,1,.214-.523.708.708,0,0,1,.522-.215.715.715,0,0,1,.523.214.709.709,0,0,1,.215.522.714.714,0,0,1-.214.523A.709.709,0,0,1,157.782-844.981Z"
                  transform="translate(-147.27 858.15)" fill="#333333"/>
        </svg>
    );
};

export default Delete;
