import React, { useState, useEffect } from 'react';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setClosePreview } from "../../../redux/slices/PreviewSlice";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useNavigate } from "react-router";
import styled from 'styled-components';
import AnswerTabContent from "components/ComposeOtherQuestion/Gridded/AnswerTabs/AnswerTabContent";
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

const PreviewContainer = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
`;

const CorrectAnswerBanner = styled.div`
  background-color: #e8f5e9;
  border: 1px solid #81c784;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
  color: #2e7d32;
  font-weight: 500;
`;

const PreviewGridded = ({
    currentQuestion,
    showAnswer = undefined,
    setShowAnswers = undefined,
    parentMode = 'assessment',
    editMode = false,
    }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    console.log(currentQuestion)
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')

    const url = window.location.href;
    const pathsToCheck = ['/edit-question', '/edit-subquestion/'];
    const isEditMode = pathsToCheck.some(path => url.includes(path));

    const defaultInputs = currentQuestion?.more_options?.scoring?.default_inputs;
    const hasDefaultInputs = Array.isArray(defaultInputs) && defaultInputs.length > 0;

    const [currentValues, setCurrentValues] = useState<string[]>(
        hasDefaultInputs ? defaultInputs :
            currentQuestion?.answer?.values || Array(6).fill('')
    );

    const [currentScore, setCurrentScore] = useState<number>(
        currentQuestion?.answer?.score || 0
    );

    useEffect(() => {
        if (showAnswer && currentQuestion?.correct_answer?.valid_response) {
            setCurrentValues([...currentQuestion.correct_answer.valid_response.value]);
            setCurrentScore(currentQuestion.correct_answer.valid_response.score || 0);
        } else if (hasDefaultInputs && !currentQuestion?.answer?.values) {
            // Use default inputs if they exist and no answer has been set yet
            setCurrentValues([...defaultInputs]);
            setCurrentScore(0);
        } else {
            setCurrentValues(currentQuestion?.answer?.values || Array(6).fill(''));
            setCurrentScore(currentQuestion?.answer?.score || 0);
        }
    }, [showAnswer, currentQuestion, defaultInputs, hasDefaultInputs]);

    const handleBackToEditClick = () => {
        dispatch(setClosePreview());
    };

    const handleGoToEditClick = (e) => {
        e.preventDefault();
        dispatch(EditSubQues(currentQuestion));
        dispatch(
            setTypeQuestionOpenInEditMode({
                type: currentQuestion?.type,
                id: currentQuestion?.id,
            })
        );
        navigate(`/edit-subquestion/${currentQuestion.id}`);
    };

    const handleValueChange = (newValues: string[]) => {
        if (!showAnswer) {
            setCurrentValues(newValues);
            if (currentQuestion?.answer) {
                currentQuestion.answer.values = newValues;
            }
        }
    };

    const handleScoreChange = (newScore: number) => {
        if (!showAnswer) {
            setCurrentScore(newScore);
            if (currentQuestion?.answer) {
                currentQuestion.answer.score = newScore;
            }
        }
    };

    return (
        <PreviewWrapper
            currentQuestion={currentQuestion}
            showAnswer={showAnswer}
            setShowAnswers={setShowAnswers}
            parentMode={parentMode}
            editMode={editMode}
            handleGoToEdit={(e) => handleGoToEditClick(e)}
            handleBackToEdit={handleBackToEditClick}
        >
            <PreviewContainer>
                <AnswerTabContent
                    currentQuestion={currentQuestion}
                    isEditMode={isEditMode}
                    value={currentValues}
                    score={currentScore}
                    onValueChange={handleValueChange}
                    onScoreChange={handleScoreChange}
                    alternativeId={currentQuestion?.id}
                    disabled={showAnswer}
                    isPreview={true}
                />
            </PreviewContainer>
        </PreviewWrapper>
    );
};

export default PreviewGridded;
