import React, { useEffect } from 'react';
import { EditTokenTypes } from '../../Charts/SubChildren/components/chartquestion.constants';
import './tokenHighlight.css';

const EditCorrectAnswer = ({questionData, handleData, activeTab, showAnswer, disabled}) => {  
    const token_type = questionData?.template_response?.token_type;
    const token_templates = activeTab?.value;
    const more_options = questionData?.more_options;
    const templateRef = React.useRef(null);
    // const fontSize = more_options && more_options?.layout.fontSize ? 
    //     more_options.layout.fontSize  : 
    //     '12px';
    
    const maxNumberOfResponses = more_options && !isNaN(Number(more_options?.layout?.max_selection)) ?
        Number(more_options.layout.max_selection) : -1;

    const toggleParagraphCorrect = (idx: Number) => {
        // check if exceeded max responses
        if(maxNumberOfResponses && maxNumberOfResponses > 0) {
            const selectedItem = token_templates?.paragraphs.find((_i, index) => index === idx);
            if(selectedItem && !selectedItem?.isCorrect) {
                const totalTokenCount = token_templates?.paragraphs.filter(p => p.isCorrect).length;
                if(totalTokenCount >= maxNumberOfResponses) return;
            }

        }
        const newParagraphs = token_templates?.paragraphs.map((p: any, index: number) => {
            return idx === index ? {...p, isCorrect: !p?.isCorrect} : p;
        });
        handleData({...token_templates, paragraphs: newParagraphs})
    }

    const exceededMaxResLimit = () => {
        if(token_type=== EditTokenTypes.None) {
            const currentNoneTypeTokens: number = 
            token_templates?.none ? token_templates.none.split('is-correct').length : 0;
            if(maxNumberOfResponses > 0 && currentNoneTypeTokens > 0 && (currentNoneTypeTokens  - 1) === maxNumberOfResponses) {
                return true;
            }
        }
        if(token_type=== EditTokenTypes.Sentence) {
            const currentNoneTypeTokens: number = token_templates?.sentences ? 
                token_templates.sentences.split('is-correct').length - 1: 
                0;
            if(maxNumberOfResponses > 0 && currentNoneTypeTokens > 0 && currentNoneTypeTokens === maxNumberOfResponses) {
                return true;
            }
        }
        if(token_type=== EditTokenTypes.Word) {
            const currentNoneTypeTokens: number = 
            token_templates?.words ? token_templates.words.split('is-correct').length - 1 : 0;
            if(maxNumberOfResponses > 0 && currentNoneTypeTokens > 0 && currentNoneTypeTokens === maxNumberOfResponses) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        const handleClick = (event: any) => {
            // event.preventDefault();
            if (event.target.classList.contains("highlighted-text")) {
                const correctAnswerContainer = event.target.closest(".correct-answer");
                if (correctAnswerContainer) {
                    const selectedElement = event.target;
                    if(selectedElement) {
                        if(showAnswer || disabled) return;
                        if(!selectedElement.classList.contains('is-correct')){
                            if(exceededMaxResLimit()) return;
                        }
                        selectedElement.classList.toggle("is-correct");
                        if(templateRef.current) {
                            const newData = {...token_templates}
                            if(token_type=== EditTokenTypes.None)newData.none = templateRef.current.innerHTML
                            if(token_type=== EditTokenTypes.Sentence)newData.sentences = templateRef.current.innerHTML
                            if(token_type=== EditTokenTypes.Word)newData.words = templateRef.current.innerHTML
                            handleData(newData)
                            event.stopPropagation();
                        }
                    }
                }
            }else if([EditTokenTypes.Sentence ,EditTokenTypes.Word].includes(token_type)) {
                if(showAnswer || disabled) return;
                const parentTarget = event.target.closest('.highlighted-text');
                if(parentTarget) {
                    if(!parentTarget.classList.contains('is-correct')){
                        if(exceededMaxResLimit()) return;
                    }
                    parentTarget.classList.toggle("is-correct");
                    if(templateRef.current) {
                        const newData = {...token_templates}
                        if(token_type=== EditTokenTypes.None)newData.none = templateRef.current.innerHTML
                        if(token_type=== EditTokenTypes.Sentence)newData.sentences = templateRef.current.innerHTML
                        if(token_type=== EditTokenTypes.Word)newData.words = templateRef.current.innerHTML
                        handleData(newData)
                        event.stopPropagation();
                    }
                }else {
                    if(event.target.classList.contains('rounded-md')){
                        if(!event.target.classList.contains('is-correct')){
                            if(exceededMaxResLimit()) return;
                        }
                        event.target.classList.toggle("is-correct");
                        if(templateRef.current) {
                            const newData = {...token_templates}
                            if(token_type=== EditTokenTypes.None)newData.none = templateRef.current.innerHTML
                            if(token_type=== EditTokenTypes.Sentence)newData.sentences = templateRef.current.innerHTML
                            if(token_type=== EditTokenTypes.Word)newData.words = templateRef.current.innerHTML
                            handleData(newData)
                            event.stopPropagation();
                        }
                    }else {
                        const parentSpan =  event.target.closest('.rounded-md');
                        if(parentSpan) {
                            if(!parentSpan.classList.contains('is-correct')){
                                if(exceededMaxResLimit()) return;
                            }
                            parentSpan.classList.toggle("is-correct");
                            if(templateRef.current) {
                                const newData = {...token_templates}
                                if(token_type=== EditTokenTypes.None)newData.none = templateRef.current.innerHTML
                                if(token_type=== EditTokenTypes.Sentence)newData.sentences = templateRef.current.innerHTML
                                if(token_type=== EditTokenTypes.Word)newData.words = templateRef.current.innerHTML
                                handleData(newData)
                                event.stopPropagation();
                            }
                        }
                    }
                }
            }
        };
    
        const parent = templateRef.current;
        if (parent) {
            parent.addEventListener("click", handleClick);
        }
    
        return () => {
            if (parent) {
                parent.removeEventListener("click", handleClick);
            }
        };
    }, [token_templates, token_type, more_options, disabled]);

    return (
        <div 
            className='my-4 rounded-sm bg-gray-200 p-2 overflow-x-scroll'
            // style={{
            //     fontSize, 
            //     lineHeight: ["xx-large", "x-large"].includes(fontSize) ? '2rem' : '1.5rem',
            // }}
        >
            {
                token_type === EditTokenTypes.None && token_templates?.none &&  (
                    <div className='px-2'>
                        <div 
                            // style={{
                            //     fontSize, 
                            //     lineHeight: ["xx-large", "x-large"].includes(fontSize) ? '2rem' : '1.5rem',
                            // }}
                            className='correct-answer'
                            ref={templateRef}
                            dangerouslySetInnerHTML={{__html: token_templates.none}}
                        />
                    </div>
                )
            }

            {
                token_type === EditTokenTypes.Sentence && token_templates?.sentences &&  (
                    <div className='px-2'>
                        <div 
                            // style={{
                            //     fontSize, 
                            //     lineHeight: ["xx-large", "x-large"].includes(fontSize) ? '2rem' : '1.5rem',
                            // }}
                            className='correct-answer'
                            ref={templateRef}
                            dangerouslySetInnerHTML={{__html: token_templates.sentences}}
                        />
                    </div>
                )
            }

            {
                token_type === EditTokenTypes.Word && token_templates?.words &&  (
                    <div className='px-2'>
                        <div 
                            // style={{
                            //     fontSize, 
                            //     lineHeight: ["xx-large", "x-large"].includes(fontSize) ? '2rem' : '1.5rem',
                            // }}
                            className='correct-answer'
                            ref={templateRef}
                            dangerouslySetInnerHTML={{__html: token_templates.words}}
                        />
                    </div>
                )
            }

            {
                token_type === EditTokenTypes.Paragraph &&
                Array.isArray(token_templates?.paragraphs) &&
                <div className='px-2 correct-answer'>
                    {
                        token_templates?.paragraphs.map((p: any, index: number) => {
                            return(
                                <div
                                    key={index.toString()}
                                    style={{ width: 'fit-content', padding: '2px', margin: '0 0 4px 0'}}
                                    className={ `${p?.isToken ? "highlighted-text hover:opacity-70 cursor-pointer" : " "} ${p?.isCorrect ? "is-correct" : ""}`}
                                    dangerouslySetInnerHTML={{__html: p?.value}}
                                    onClick={(e) => toggleParagraphCorrect(index)}
                                />
                            )
                        })
                    }
                </div>
            }
        </div>
    );
};

export default EditCorrectAnswer;