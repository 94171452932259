import { useLocation } from "react-router-dom";
import MainLayout from "../../layouts/main/Main.layout";
import CreateAssessmentComponent from "studio/components/CreateAssessment/CreateAssessment";
import { LocationState } from "studio/components/CreateAssessment/AssessmentStudio";
import { useState } from "react";
import { addQuestionRequest } from "api-rest/questions";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {DefaultQuestionsData} from "studio/constants/defaultQuestionData.constants";

const EditCreateedAssessment = () => {
  const navigate = useNavigate();
  const location = useLocation();
//   const state = location.state as LocationState;
//   const { subjects, grades, id } = state;
//   const title = grades?.name ? `${subjects?.name} assessment - ${grades?.name}` : `${subjects?.name}`;
  const [questions, setQuestions] = useState([]);
  const transformQuestions = (questionItems, templates) => {
    return questionItems.map(item => {
      // Find the matching template
      const template = templates[item.groupKey]?.[item.itemKey];
      
      // If template is found, merge it with the item data
      if (template) {
        return {
          ...template, // Include all template properties
          key: item.key,
          icon: item.icon,
          text: item.text,
          templateTitle: item.templateTitle,
          groupKey: item.groupKey,
          itemKey: item.itemKey,
          trackId: item.trackId
        };
      }
    });
  };
  
  const handleSave = async () => {
    // Handle save logic
    console.log(questions,'Save clicked');
    console.log(DefaultQuestionsData, 'deffs');
    const assessmentData = {
      assessment_id: "",
      sub_questions: transformQuestions(questions, DefaultQuestionsData)
    };
    const { error } = await addQuestionRequest(assessmentData);

    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Assessment created with success!', {
      position: 'top-right',
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setTimeout(() => {
      navigate('/assessment-library');
    }, 400);
  };
  return (
    <MainLayout title="test" headerType="assessment" handleSave={handleSave}>
      <div className="h-full w-full max-w-[1648px]">
        <CreateAssessmentComponent onQuestionListChange={(questionList) => setQuestions(questionList)}  />
      </div>
    </MainLayout>
  );
};

export default EditCreateedAssessment;
