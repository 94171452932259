import { useNavigate } from "react-router-dom";
import ArrowBack from "studio/icons/ArrowBack";
import { Colors } from "studio/theme/colors.theme";

const BackArrowIcon = ({ style }: { style?: any }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(-1);
      }}
      className="cursor-pointer"
    >
      <ArrowBack
        style={{
          color: Colors.iconBlack,
          ...style,
        }}
      />
    </div>
  );
};

export default BackArrowIcon;
