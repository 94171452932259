import { Button, Modal } from 'antd';
import React from 'react';
import './QuestionsStartPlayer-styles.scss';

interface SummarySectionProps {
    isVisible: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    summary: {
        answered: number;
        skipped: number;
        flagged: number;
    };
}

const SummarySection = ({
    isVisible,
    onCancel,
    onConfirm,
    summary
    }: SummarySectionProps): JSX.Element => {
    return (
        <Modal
            centered
            closable={false}
            title="Assessment summary"
            open={isVisible}
            onCancel={onCancel}
            footer={[
                <Button
                    key="cancel"
                    onClick={onCancel}
                    className="summary-modal__cancel-btn"
                >
                    Cancel
                </Button>,
                <Button
                    key="proceed"
                    type="primary"
                    onClick={onConfirm}
                    className="summary-modal__proceed-btn"
                >
                    Proceed
                </Button>
            ]}
            className="summary-modal"
            width={600}
        >
            <div className="summary-stats">
                <div className="stat-item">
                    <div className="stat-indicator answered"></div>
                    <div className="stat-label">Answered</div>
                    <div className="stat-value">{summary.answered}</div>
                </div>
                <div className="stat-item">
                    <div className="stat-indicator skipped"></div>
                    <div className="stat-label">Skipped</div>
                    <div className="stat-value">{summary.skipped}</div>
                </div>
                <div className="stat-item">
                    <div className="stat-indicator flagged"></div>
                    <div className="stat-label">Flagged</div>
                    <div className="stat-value">{summary.flagged}</div>
                </div>
            </div>
        </Modal>
    );
};

export default SummarySection;