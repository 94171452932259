import React from 'react';

export const FirstPageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.323" height="12" viewBox="0 0 12.323 12">
        <path id="last_page_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
              d="M253.957-694.075l1.057-1.036-4.852-4.852,4.852-4.852-1.057-1.036-5.887,5.887Zm-9.778.115v-12H242.69v12Z"
              transform="translate(-242.69 705.96)" fill="#333"/>
    </svg>

);

export const PrevPageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path id="arrow_forward_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24_1_"
              data-name="arrow_forward_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24 (1)"
              d="M190.293-765.347h9.787v-1.15h-9.787L194.9-771.1l-.819-.817-6,6,6,6,.819-.817Z"
              transform="translate(-188.08 771.92)" fill="#333"/>
    </svg>

);

export const NextPageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path id="arrow_forward_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24_1_"
              data-name="arrow_forward_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24 (1)"
              d="M197.867-765.347H188.08v-1.15h9.787l-4.606-4.606.819-.817,6,6-6,6-.819-.817Z"
              transform="translate(-188.08 771.92)" fill="#333"/>
    </svg>

);

export const LastPageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.323" height="12" viewBox="0 0 12.323 12">
        <path id="last_page_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
              d="M243.747-694.075l-1.057-1.036,4.852-4.852-4.852-4.852,1.057-1.036,5.887,5.887Zm9.778.115v-12h1.489v12Z"
              transform="translate(-242.69 705.96)" fill="#333"/>
    </svg>

);

export const BookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17.495" viewBox="0 0 20 17.495">
        <path id="auto_stories_FILL0_wght300_GRAD-25_opsz20"
              d="M82.021-870.465a12.444,12.444,0,0,0-2.517-1.167,8.625,8.625,0,0,0-2.758-.446,12.625,12.625,0,0,0-1.55.1,8.146,8.146,0,0,0-1.513.335,1.183,1.183,0,0,1-1.12-.134,1.129,1.129,0,0,1-.522-1v-10.9a1.22,1.22,0,0,1,.227-.718,1.358,1.358,0,0,1,.574-.474,11.164,11.164,0,0,1,1.905-.544,10.555,10.555,0,0,1,2-.19,10.067,10.067,0,0,1,2.758.379,11.976,11.976,0,0,1,2.537,1.042v12.365a13.362,13.362,0,0,1,2.569-1.047,9.727,9.727,0,0,1,2.726-.386,10.456,10.456,0,0,1,1.642.124,12.536,12.536,0,0,1,1.6.37.369.369,0,0,0,.193,0q.09-.024.09-.156V-885a1.669,1.669,0,0,0,.267.08,1.284,1.284,0,0,1,.26.084,1.344,1.344,0,0,1,.474.444,1.12,1.12,0,0,1,.174.617V-872.8a1.1,1.1,0,0,1-.543.973,1.335,1.335,0,0,1-1.176.159,12.8,12.8,0,0,0-1.5-.306,10.588,10.588,0,0,0-1.489-.106,9.18,9.18,0,0,0-2.787.427A10.482,10.482,0,0,0,82.021-870.465ZM83.8-874.43v-8.823L89.1-887.96v8.823Zm-2.94,1.9v-10.731a6.717,6.717,0,0,0-2.039-.9,8.852,8.852,0,0,0-2.177-.273,7.308,7.308,0,0,0-1.722.2,7.662,7.662,0,0,0-1.531.542.437.437,0,0,0-.127.083.166.166,0,0,0-.052.125v10.512q0,.132.09.156a.319.319,0,0,0,.193-.014,8.688,8.688,0,0,1,1.481-.322,12.847,12.847,0,0,1,1.662-.1,12.67,12.67,0,0,1,2.281.2A10.9,10.9,0,0,1,80.864-872.529Zm0,0v0Z"
              transform="translate(-72.04 887.96)"/>
    </svg>
);