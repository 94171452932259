import React, { useEffect, useState } from "react";
import AppModal from "../general/Modal";
import SubjectTagGradeSelector from "./SubjectTagGradeSelector";
import { Button, message } from "antd";
import { duplicateSingleAssessmentRequest } from "api-rest/assessments";

interface IDuplicateProps {
  open?: boolean;
  onCancel?: () => void;
  onDuplicate?: (itemId: number) => void;
  assessment?: any;
  refreshTable?: () => void;
}
const DuplicateModal: React.FC<IDuplicateProps> = ({
  open,
  onCancel,
  onDuplicate,
  assessment,
  refreshTable,
}) => {
  const [assessmentNewData, setAssessmentNewData] = useState(assessment ?? null);
  useEffect(() => {
    setAssessmentNewData(assessment);
  }, [assessment]);
  const success = (content: string) => {
      const startTime = Date.now();
      message.destroy();
      message.success({
        content,
        duration: 5, 
        onClose: () => {
          const endTime = Date.now();
          const durationInSeconds = (endTime - startTime) / 1000;
        },
      });
  
      setTimeout(() => {
        const currentTime = Date.now();
        const elapsed = (currentTime - startTime) / 1000;
      }, 5000); 
    };
    const onError = (content: string) => {
      message.error({
        content,
        duration: 3,
      });
    };
    // implement cancel event logic here
    const saveNewAssessment = async () => {
        const reqBody = {
          assessment_id: assessment?.id,
          name: assessmentNewData?.newName ?? assessment?.name+'- copy',
          subject_id: assessmentNewData?.subjectId,
          tag_id: assessmentNewData?.tagIds,
          grade_id: assessmentNewData?.gradeId
          
        }
        const { error } = await duplicateSingleAssessmentRequest(reqBody);
    
        if (error) {
          console.log('error =', error);
          onError(error.message);
          return;
        }
        success(`Assessment duplicated with success!`);
        setTimeout(() => {
          onCancel()
        }, 300);
        refreshTable();
      };
    
  
  const handleFormValuesChange = (changedValues: any) => {
    setAssessmentNewData((prev: any) => ({
      ...prev,
      ...changedValues
    }));
  };
  

  return (
    <AppModal
      title=""
      visible={open}
      content={<SubjectTagGradeSelector assessment={assessment} onValuesChange={handleFormValuesChange} />}
      okText="Duplicate"
      cancelText="No"
      onCancel={() => onCancel()}
      footer={[
        <Button
          className="mt-0 px-12"
          type="primary"
          size="large"
          onClick={saveNewAssessment}
          key="save-duplicate"
        >
          Save
        </Button>,
      ]}
      width={750}
      className="p-0"
      modalProps={{
        maskClosable: false,
        centered: true,
      }}
    />
  );
};

export default DuplicateModal;
