interface IconProps {
  fill?: string;
  className?: string;
}
const UploadIcon = ({ fill = "currentColor", className = "" }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.04483 20V8.5835L5.722 11.9064L4.38841 10.5587L9.99988 4.94727L15.6179 10.5587L14.2846 11.9064L10.9618 8.5835V20H9.04483ZM-0.00012207 5.98145V2.33624C-0.00012207 1.68835 0.227234 1.13696 0.681915 0.682068C1.13684 0.227356 1.68835 0 2.33649 0H17.6633C18.3114 0 18.8629 0.227356 19.3178 0.682068C19.7725 1.13696 19.9999 1.68835 19.9999 2.33624V5.98145H18.0829V2.33868C18.0829 2.23315 18.0391 2.13641 17.9514 2.04852C17.8634 1.96082 17.7667 1.91699 17.6612 1.91699H2.33853C2.23303 1.91699 2.13631 1.96082 2.04839 2.04852C1.96069 2.13641 1.91684 2.23315 1.91684 2.33868V5.98145H-0.00012207Z"
      fill={fill} className={className}
    />
  </svg>
);
export default UploadIcon;
