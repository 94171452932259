import { Flex, Typography, Row, Col } from "antd";
import { ReactNode } from "react";
import { Images } from "studio/constants/images.constants";
import { Messages } from "studio/constants/messages.constant";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";
import { LazyLoadImage } from "react-lazy-load-image-component";

const { Text } = Typography;

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      style={{ height: "100dvh", background: Colors.bgLightGray }}
      className="auth-page"
    >
      <Row style={{ flex: 1 }}>
        <Col
          xs={0}
          sm={12}
          md={12}
          lg={12}
          style={{ ...layoutStyle, position: "relative" }}
        >
          <Text style={copywrighttext}>{Messages.Copyright}</Text>
          <LazyLoadImage
            src={Images.loginLeft}
            height={"100%"}
            width={"100%"}
            alt="login"
            preview={false}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Col>

        <Col style={layoutStyle} xs={24} sm={12} md={12} lg={12}>
          <Flex
            style={{ width: "100%", height: "100%" }}
            justify="center"
            align="center"
          >
            {children}
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
};

export default AuthLayout;

const layoutStyle = {
  overflow: "hidden",
  //   width: "calc(50% - 8px)",
  //   maxWidth: "calc(50% - 8px)",
  height: "100%",
  background: "transparent",
};

const copywrighttext: React.CSSProperties = {
  color: "white",
  position: "absolute",
  zIndex: 1000,
  bottom: Sizes.headerPadding,
  width: "100%",
  textAlign: "center",
  transform: "scale(0.9)",
};
