import QuestionPreviewLayout from "studio/layouts/question/QuestionPreview.layout";
import QuestionPreviewForMCQ from "./MCQ/components/questionPreviewForMCQ.component";
import { MCQTypes } from "studio/constants/questionTypes.constants";

const QuestionPreviewForEdit = ({ data }: { data: any }) => {
  const renderQuestionPreview = () => {
    const qtype = data?.type;

    if (
      [
        MCQTypes.BLOCKLAYOUT,
        MCQTypes.MULTIPLEANSWERS,
        MCQTypes.SINGLEANSWER,
        MCQTypes.TRUEFALSE,
      ].includes(qtype)
    ) {
      return <QuestionPreviewForMCQ data={data} />;
    }
  };

  return (
    <QuestionPreviewLayout questionHeader={data?.question || ""}>
      {renderQuestionPreview()}
    </QuestionPreviewLayout>
  );
};

export default QuestionPreviewForEdit;
