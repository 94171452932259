import React from 'react';

interface BlackEdit {
    style?: React.CSSProperties; // Optional style prop
}

const BlackEdit = ({ style = {} }) => {
    return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="14.97" height="15" viewBox="0 0 14.97 15">
        <path id="edit_FILL0_wght300_GRAD0_opsz20"
              d="M147.571-804.72h1.16l7.992-7.992-1.16-1.16-7.992,7.992Zm12.144-8.951-3.184-3.2,1.007-1.007a1.374,1.374,0,0,1,1.005-.425,1.374,1.374,0,0,1,1.005.425l1.182,1.182a1.346,1.346,0,0,1,.395,1.014,1.4,1.4,0,0,1-.412,1.014ZM147-803.3a.811.811,0,0,1-.593-.251.811.811,0,0,1-.251-.593v-2.007a.812.812,0,0,1,.058-.3.8.8,0,0,1,.182-.269l9.154-9.154,3.184,3.184-9.154,9.154a.8.8,0,0,1-.269.182.813.813,0,0,1-.3.058Zm9.15-9.98-.587-.587,1.16,1.16Z"
              transform="translate(-146.156 818.305)" fill="#000000"/>
    </svg>
    );
};

export default BlackEdit;
