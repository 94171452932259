import React from 'react';

const Notifications = ({ style = {}, className='' }) => {
    return (
        <svg style={style} className={className}  xmlns="http://www.w3.org/2000/svg" width="20" height="20.658" viewBox="0 0 20 20.658">
            <path id="notifications_active_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
                  d="M117.23-843.558a9.6,9.6,0,0,1,1-4.344,10.1,10.1,0,0,1,2.688-3.373l.953,1.206a8.462,8.462,0,0,0-2.26,2.844,8.144,8.144,0,0,0-.834,3.667H117.23Zm18.458,0a8.165,8.165,0,0,0-.835-3.672,8.451,8.451,0,0,0-2.264-2.849l.953-1.2a10.11,10.11,0,0,1,2.691,3.373,9.585,9.585,0,0,1,1,4.344Zm-16.581,9.608v-1.542h1.881v-8.025a6.079,6.079,0,0,1,1.378-3.927,6.173,6.173,0,0,1,3.527-2.2v-.643a1.277,1.277,0,0,1,.386-.939,1.285,1.285,0,0,1,.944-.385,1.3,1.3,0,0,1,.948.385,1.272,1.272,0,0,1,.389.939v.643a6.182,6.182,0,0,1,3.531,2.2,6.074,6.074,0,0,1,1.38,3.927v8.025h1.881v1.542ZM127.227-841.857Zm0,10.9a1.879,1.879,0,0,1-1.38-.569,1.878,1.878,0,0,1-.569-1.379h3.9a1.878,1.878,0,0,1-.569,1.379A1.879,1.879,0,0,1,127.227-830.952Zm-4.7-4.54h9.4v-8.025a4.519,4.519,0,0,0-1.38-3.321,4.538,4.538,0,0,0-3.325-1.376,4.521,4.521,0,0,0-3.32,1.376,4.528,4.528,0,0,0-1.374,3.321Z"
                  transform="translate(-117.23 851.61)" fill="#e8eaed"/>
        </svg>
    );
};

export default Notifications;
