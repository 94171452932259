import React from 'react';

export const ListOrderedIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.83" height="32" viewBox="0 0 28.83 32">
        <path id="format_list_numbered_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
              d="M142.12-820.42V-822.3h4.3v-1.91H143.84v-1.877h2.579v-1.9h-4.3v-1.877h4.8a1.32,1.32,0,0,1,.97.4,1.314,1.314,0,0,1,.4.963v2.05a1.323,1.323,0,0,1-.4.972,1.322,1.322,0,0,1-.971.4,1.321,1.321,0,0,1,.971.4,1.315,1.315,0,0,1,.4.963v1.918a1.322,1.322,0,0,1-.4.971,1.32,1.32,0,0,1-.97.4Zm0-11.268v-4.291a1.331,1.331,0,0,1,.4-.972,1.31,1.31,0,0,1,.967-.4h2.935v-1.9h-4.3v-1.877h4.8a1.321,1.321,0,0,1,.97.4,1.313,1.313,0,0,1,.4.962v2.928a1.309,1.309,0,0,1-.4.967,1.329,1.329,0,0,1-.971.4H144v1.91h4.3v1.877Zm2.579-11.29v-7.565H142.12v-1.877h4.456v9.441Zm7.512,17.934v-2.406H170.95v2.406H152.211Zm0-10.179v-2.406H170.95v2.406H152.211Zm0-10.179v-2.406H170.95v2.406H152.211Z"
              transform="translate(-142.12 852.42)" fill="#333"/>
    </svg>


);