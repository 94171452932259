import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import type { FormProps } from "antd";
import { Button, Flex, Form, Input, message, Space, Typography } from "antd";
import { Messages } from "../../constants/messages.constant";
import { Colors } from "../../theme/colors.theme";
import { ArrowBackOutlined } from "@mui/icons-material";
import {  setNewPassword } from "../../../services/authentication";
import { Sizes } from "studio/theme/Sizes.theme";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const { Title, Paragraph } = Typography;
type FieldType = {
  newPassword?: string;
  confirmPassword?: string;
};


const NewPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const onFinish: FormProps<FieldType>['onFinish'] = async (values: FieldType) => {
    setLoading(true);
    const {  newPassword, confirmPassword } = values;
const payload={
  email: email,
  password: newPassword,
  password_confirmation: confirmPassword,
  token: token,
}
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/reset`,
        payload,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        message.success("Reset password successfully");
        const type = localStorage.getItem("type");
        window.location.href = `/${type}-login`;
        setLoading(false);

      }
    } catch (error) {      
      message.error("Reset password email failed!");
      setLoading(false);
    }
  };
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]*$/;
  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error(Messages.NewPasswordRequired));
    }
    
    if (value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters long and contain uppercase, lowercase, number, and special character")
      );
    }
    
    if (!passwordRegex.test(value)) {
      return Promise.reject(
        new Error("Password must contain uppercase, lowercase, number, and special character")
      );
    }
    
    return Promise.resolve();
  };
  return (
    <Form
      name="basic"
      size="large"
      form={form}
      wrapperCol={{ span: 24 }}
      style={{ width: "60%", maxWidth: 450 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Space direction="vertical">
        <ArrowBackOutlined
          fontSize="large"
          style={{
            color: Colors.iconBlack,
            fontSize: 38,
            width: 38,
            height: 38,
            fontWeight: Sizes.iconFontWeight,
            marginBottom: Sizes.headerPadding + 10,
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Title
          style={{
            fontWeight: "normal",
            marginBottom: Sizes.base,
            color: Colors.textBlack,
          }}
          level={2}
        >
          {Messages.ForgotPasswordTitle}
        </Title>
        <Paragraph style={{ lineHeight: "32px", marginBottom: 44 }}>
          {Messages.ForgotPasswordSubTitle}
        </Paragraph>
        <Form.Item
              // label="New password"
              name="newPassword"
              rules={[
                { required: true, validator: validatePassword }
              ]}
              required={false} 

              style={{ marginBottom: 24 }}
            >
              <Input.Password visibilityToggle={true} placeholder="New password" />
            </Form.Item>
            <Form.Item
              // label="Confirm new password"
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                {  required: true,message: Messages.ConfirmPasswordRequired },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(Messages.PasswordsDoNotMatch));
                  },
                }),
              ]}

              
              required={false} 
              style={{ marginBottom: 24 }}
            >
              <Input.Password visibilityToggle={false} placeholder="Confirm new password" style={{paddingLeft:15}}/>
            </Form.Item>
          
        <Form.Item label={null} style={{ marginBottom: 48 }}>
          <Button
            loading={loading}
            variant="solid"
            block
            color="default"
            htmlType="submit"
            className="hover:opacity-90"
          >
            SUBMIT
          </Button>
        </Form.Item>

        {/* <Flex justify="center" align="center">
          <Paragraph>{Messages.ForgotEmail}</Paragraph>
        </Flex> */}
      </Space>
    </Form>
  );
};

export default NewPasswordForm;
// nothing
