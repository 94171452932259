import React from 'react';

const Divider = ({ style = {} }) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="1" height="37" viewBox="0 0 1 40">
            <line id="Ligne_1" data-name="Ligne 1" y2="40" transform="translate(0.5)" fill="none" stroke="#707070"
                  stroke-width="1"/>
        </svg>
    );
};

export default Divider;
