import { useState } from 'react';

import { useNavigate } from 'react-router';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import TableForMatrix from './TableForMatrix.component';
import InlineMatrix from './InlineMatrix.component';
import { EditSubQues } from 'redux/slices/EditSubQuestionSlice';

const PreviewMatrixStandard = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(EditSubQues(currentQuestion));
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  let valid_response_value = [];
  let cols = [];
  if (
    currentQuestion &&
    currentQuestion?.correct_answer &&
    currentQuestion?.correct_answer.valid_response
  ) {
    valid_response_value = currentQuestion?.correct_answer.valid_response.value;
    if (Array.isArray(valid_response_value[0].options))
      cols = valid_response_value[0].options;
  }


  // useEffect(() => {
  //   valid_response_value?.map((item, i) => {
  //     // parse html string
  //     if (textRef.current[i]) {
  //       textRef.current[i].innerHTML = DOMPurify.sanitize(item?.stem);
  //     }
  //     return textRef.current[i].innerHTML;
  //   });
  // }, [valid_response_value]);

  const getMatrixType = (questionType: string, moreOptions: any) => {
    let matrixType = 'table';
    if(moreOptions && moreOptions?.layout?.type) {
      matrixType = moreOptions?.layout?.type;
    }else if(questionType == 'cmi') {
      matrixType = 'inline'
    }
    return matrixType;
  }

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      {
        getMatrixType(currentQuestion?.type, currentQuestion?.more_options) === 'table' &&
        <TableForMatrix 
          testItems = {cols}
          data = {currentQuestion}
          showAnswer = {showAnswer || localShowAnswer}
          valid_response_value = {valid_response_value}
        />
      }

      {
        getMatrixType(currentQuestion?.type, currentQuestion?.more_options) === 'inline' &&
        <InlineMatrix 
          data = {currentQuestion}
          showAnswer = {showAnswer || localShowAnswer}
          valid_response_value = {valid_response_value}
        />  
      }
      {/* <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3"></th>
            {cols.map((col) => (
              <th className="px-6 py-3 text-center" key={col?.id}>
                {col?.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {valid_response_value.map((row, index) => (
            <tr key={row?.id}>
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {/* {row?.stem} */}
                {/* <div ref={(el) => (textRef.current[index] = el)} />
              </td>
              {row?.options.map((option) => (
                <td
                  className="px-6 py-4 text-center font-medium text-gray-900 whitespace-nowrap"
                  key={option?.id}
                >
                  <label>
                    <input
                      type={
                        currentQuestion?.settings?.multiple_response
                          ? 'checkbox'
                          : 'radio'
                      }
                      checked={
                        showAnswer || localShowAnswer
                          ? option?.isChecked
                          : false
                      }
                    />
                  </label>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table> */}
    </PreviewWrapper>
  );
};

export default PreviewMatrixStandard;
