

const Logo = ({ style = {} }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 32 32"
      style={{ 
        display: 'block',
        ...style 
      }}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M4.347,14.107V7.283a2.9,2.9,0,0,1,2.9-2.9h2.81a2.9,2.9,0,0,1,2.9,2.9v9.729H7.251a2.9,2.9,0,0,1-2.9-2.9M4.14.429A3.788,3.788,0,0,0,.429,4.288V17.054A3.786,3.786,0,0,0,4.14,20.913h9a3.787,3.787,0,0,0,3.714-3.859V4.288A3.789,3.789,0,0,0,13.142.429Z"
            transform="translate(-0.429 -0.429)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="clip-Fav_icon">
          <rect width="32" height="32" />
        </clipPath>
      </defs>
      <g id="Fav_icon" data-name="Fav icon" clip-path="url(#clip-Fav_icon)">
        <rect width="32" height="32" fill="#fff" />
        <g
          id="Group_1620"
          data-name="Group 1620"
          transform="translate(-114 81.747)"
        >
          <rect
            id="Rectangle_16"
            data-name="Rectangle 16"
            width="50"
            height="38"
            rx="10"
            transform="translate(105 -83.747)"
            fill="#fa9b31"
          />
          <g
            id="Group_1"
            data-name="Group 1"
            transform="translate(121.786 -75.561)"
            clip-path="url(#clip-path)"
          >
            <rect
              id="Rectangle_2"
              data-name="Rectangle 2"
              width="16.427"
              height="20.484"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
  
  export default Logo;