import AuthLayout from "../../layouts/auth/Auth.layout";
import NewPasswordForm from "../../components/auth/NewPasswordForm.component";

const NewPasswordFormPage = () => {
  return (
    <AuthLayout>
      <NewPasswordForm />
    </AuthLayout>
  );
};

export default NewPasswordFormPage;
