import React from "react";

const ArrowBack = ({ style }: { style?: any }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={style}
    >
      <path
        id="arrow_back_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
        d="M191.768-760.965l7.684,7.684-1.372,1.361-10-10,10-10,1.372,1.361-7.684,7.677H208.08v1.917Z"
        transform="translate(-188.08 771.92)"
        fill="#333"
      />
    </svg>
  );
};

export default ArrowBack;
