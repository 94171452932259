import { Typography } from "antd";
import { Sizes } from "studio/theme/Sizes.theme";

const { Title } = Typography;

const PageTitle = ({ title }: { title: string }) => {
  return (
    <Title
      level={4}
      style={{
        fontSize: 18,
        marginBottom: Sizes.padding,
        fontWeight: "600",
        maxWidth: Sizes.maxScreenWidth,
      }}
    >
      {title}
    </Title>
  );
};

export default PageTitle;
