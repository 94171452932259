import React from 'react';
import { Modal, ModalProps } from 'antd';
import Cross from 'studio/icons/Cross';

interface AppModalProps {
  title?: string;
  content?: React.ReactNode;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  width?: number;
  className?: string;
  visible: boolean; // Required prop
  footer?: React.ReactNode;
  modalProps?: Partial<ModalProps>;
}

const AppModal: React.FC<AppModalProps> = ({
  title = "Modal",
  content,
  okText = "OK",
  cancelText = "Cancel",
  onOk,
  onCancel,
  width = 520,
  className,
  visible,
  footer,
  modalProps = {},
}) => {
  return (
    <Modal
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      width={width}
      className={className}
      footer={footer}
      closeIcon={<Cross/>}
      {...modalProps}
    >
      {content}
    </Modal>
  );
};

export default AppModal;