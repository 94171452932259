import React, { useEffect, useState } from "react";
import { Layout, Typography } from "antd";
import AssessmentTable from "./AssessmentTable";
import { useNavigate } from "react-router-dom";
import {
  getAssessmentListing,
  duplicateAssessments,
} from "../../../services/assessment";

import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import axios from "axios";
import { toast } from "react-toastify";
import DuplicateModal from "./duplicate.model";
import { Sizes } from "../../theme/Sizes.theme";
import AssessmentFilters from "./AssessmentFiltersExisitng";
import { ROUTES } from "studio/constants/routes.constants";
import AssessmentFilters_Modal from "./AssessmentFilters_Modal";
import HorizontalDevider from "../ui/HorizontalDevider.componen";
import TextInput from "../ui/TextInput.component";
interface Assessment {
  id: string;
  name: string;
  grade: number;
  createdDate: string;
  subject: string;
  tag: any;
  dok: string;
  status: string;
}
interface FilterData {
  search: string;
  subject_id: string;
  grade_id: string;
  subject: string;
  grade: string;
  tags: any;
  status: string;
  dateRange: any;
}

interface AssessmentLibrary {
  showBanner?: boolean;
  onSelectedRowsParent?: (value: any) => void;
  filterDatax: FilterData;
  onFilterChangex: (field: string, value: any) => void;
  onChangeName: (value: string) => void;
  nameValue: string;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (keys: React.Key[]) => void;
  filterDataChild: FilterData;
  setFilterDataChild: (value: any) => void;

}

const initialFilterData = {
  search: "",
  subject_id: "",
  grade_id: "",
  subject: "all",
  grade: "all",
  tags: [],
  status: "",
  dateRange: null,
};

const { Content } = Layout;
const { Title } = Typography;

const AssessmentLibrary: React.FC<AssessmentLibrary> = ({ showBanner = true, onSelectedRowsParent, filterDatax, onFilterChangex, nameValue, 
  onChangeName,selectedRowKeys ,setSelectedRowKeys,filterDataChild,setFilterDataChild}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { assessmentsListing, assessmentsListingPagination } = useAppSelector(
    (state: RootState) => state.assessments
  );
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Assessment[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("");
  const [clearSelection, setClearSelection] = useState(false);
  const [assessment, setAssessment] = useState("");

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortBy, order, filterDataChild]);


  const fetchData = async () => {
    setLoading(true);
    try {
      await dispatch(
          getAssessmentListing(page, perPage, {
            ...filterDataChild,
            sort_by: sortBy,
            order: order,
            page: page,
            per_page: perPage,
          }) as any
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleFilterChange = (field: string, value: any) => {
    if (field == 'dateRange') {
      setFilterDataChild((prev) => ({
        ...prev,
        "start_date": value[0],
        "end_date": value[1],
      }));
    } else {
      setFilterDataChild((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    setPage(1);
  };

  const handleRowSelected = (keys: React.Key[], rows: Assessment[]) => {
    setSelectedRowKeys(keys);
    setSelectedRows(rows);
    onSelectedRowsParent?.(rows);
  };

  const handlePageChange = (newPage: number) => {
    setClearSelection(false);
    setPage(newPage);
  };

  const handlePerPageChange = (newPerPage: number) => {
    setPerPage(newPerPage);
    //setPage(1); // Reset to first page when changing items per page
  };

 
  const handleSort = (field: string, order: 'ascend' | 'descend' | null) => {
    // Keep the current page when sorting
    setSortBy(field);
    setOrder(order === 'ascend' ? 'asc' : order === 'descend' ? 'desc' : '');
  };



  const handleDuplicate = async (id?: number) => {
    const ids = id ? [id] : selectedRows.map((row) => row.id);
    await dispatch(duplicateAssessments(ids) as any);
    setClearSelection(true);
    fetchData();
    setSelectedRows([]);
  };

  const handleDelete = async (id: any) => {
    console.log("helool here", id, selectedRows, typeof id);
    const ids =
      typeof id !== "object" ? [id] : selectedRows.map((row) => row.id);
    let api = "api/assessment";
    let multiple = ids.length > 1;
    const typeDeleted = "assessment";
    let res;
    try {
      if (multiple) {
        console.log("#1");
        res = await axios.post(
          `${process.env.REACT_APP_API_URL}/${api}/multidelete`,
          { [typeDeleted]: ids }
        );
        console.log(res);
      } else {
        console.log("#2");
        res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/${api}/${ids}`
        );
        console.log(res);
      }
      if (res?.status == 200) {
        setClearSelection(true);
        toast.success("Delete Assessment successfully!");
      } else {
        setClearSelection(true);
        toast.warning("Delete Assessment failed!");
      }
    } catch (err) {
      console.log(err);
    }
    fetchData();
    setSelectedRows([]);
  };

  const handleEdit = (id: any) => {
    console.log(id, "id");
    navigate(ROUTES.createAssessment, { state: id });
  };

  const handleRowClick = (row: Assessment) => {
    //navigate(`/assessment/${row.id}`);
  };
  const handleDuplicateRow = (assessment: any) => {
    setAssessment(assessment);
    setIsModalOpen(true);
  };

  const handleReset = () => {
    setFilterDataChild(initialFilterData);
    setPage(1);
    setSortBy("");
    setOrder("");
  };

  const handleStatusChange = (status: string) => {
    handleFilterChange('status', status);
  };

  return (

    <div>
      <DuplicateModal
        open={isModalOpen}
        assessment={assessment}
        onCancel={() => setIsModalOpen(false)}
        refreshTable={fetchData}
      />

      <div className="flex" style={{}}>
        <div
          style={{
            maxWidth: Sizes.maxScreenWidth,
            display: "block",
            width: "fit-content",
            flex: 1,
            margin: "0 auto",
          }}
        >
          <div style={showBanner ? { margin: "10px 25px -10px 25px" } : {}}>
            <div className="modal-header">
              Create new assessment (From existing)
            </div>
            <div className="modal-assessment-filter">

              <div style={{ width: '55%' }}>
                <TextInput value={nameValue} onChange={onChangeName} style={{ height: 45, marginTop: 8 }} />
              </div>
              <AssessmentFilters_Modal
                filterData={filterDatax}
                onFilterChange={onFilterChangex}
                showOtherAssessmentFilter={false}
                showlabel={false}

              />
            </div>
            <HorizontalDevider />

            <AssessmentFilters
              count={selectedRows?.length}
              filterData={filterDataChild}
              onFilterChange={handleFilterChange}
              onReset={handleReset}
              onDelete={(id) => handleDelete(id)}
              onDuplicate={() => handleDuplicate()}
              selectedRows={selectedRows}
              showOtherAssessmentFilter={showBanner}
            />
                <AssessmentTable
                    data={assessmentsListing}
                    loading={loading}
                    onRowSelected={handleRowSelected}
                    onSort={handleSort}
                    onPageChange={handlePageChange}
                    onPerPageChange={handlePerPageChange}
                    onRowClick={handleRowClick}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    page={page}
                    perPage={perPage}
                    totalRows={assessmentsListing.length}
                    assessmentsListingPagination={assessmentsListingPagination}
                    clearSelection={clearSelection}
                    onDuplicate={handleDuplicateRow}
                    // showBanner={showBanner}
                    selectedRowKeys={selectedRowKeys}
                    selectedRows={selectedRows}
                    setSelectedRowKeys={setSelectedRowKeys}
                    currentSortField={sortBy}
                    currentSortOrder={order === 'asc' ? 'ascend' : order === 'desc' ? 'descend' : null}
                />
            <HorizontalDevider />

          </div>
        </div>
      </div>

    </div>

  );
};

export default AssessmentLibrary;
