import { CopyOutlined } from "@ant-design/icons";
import { AddOutlined } from "@mui/icons-material";
import { Button, Flex } from "antd";
import Delete from "../../icons/Delete";
import { useState } from "react";
import { BsTrash } from "react-icons/bs";
import BlackDelete from "studio/icons/BlackDelete";
import Duplicate from "../../icons/Duplicate";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";
import BlackDuplicate from "../../icons/BlackDuplicate";

type IButtonGroup = {
  disabled?:boolean;
  onClick: (action: string) => void;
};

const ButtonGroup = ({ disabled=false,onClick }: IButtonGroup) => {
  console.log('disableddisabled',disabled);
  
  const [activeBtnIndex, setActiveBtnIndex] = useState(0);
  return (
    <div className="action-buttons">
 <Flex
      gap="small"
      wrap
      className="rounded-md border border-[#CACACA] p-[2px]"
      style={{ background: "#FCFCFC" }}
    >
      <Button
        style={{
          minWidth: "100px",
          color: activeBtnIndex == 0 ? Colors.textWhite : "#CACACA",
        }}
        type={ activeBtnIndex == 0 ?"primary": "text"}
        onClick={() => {
          onClick("add");
          setActiveBtnIndex(0);
        }}
        size="large"       

        icon={
          <AddOutlined
            style={{
              ...iconStyle,
              fontSize: Sizes.iconFontSize + 4,
              marginRight: Sizes.base / 2 - 4,
            }}
          />
        }
      >
        Add
      </Button>
      <Button
        style={{
          minWidth: "100px",
          color: !disabled ? Colors.textBlack : "#CACACA",
        }}
        type={"text"}
        disabled={disabled}
        onClick={() => {
          onClick("delete");
          setActiveBtnIndex(1);
        }}
        size="large"

        icon={disabled ? <Delete style={{ marginRight: '10px'}}/> : <BlackDelete style={{ marginRight: '10px'}}/>}
        >
        Delete
      </Button>
      <Button
        style={{
          minWidth: "100px",
          color: !disabled ? Colors.textBlack : "#CACACA",
        }}
        type={"text"}
        onClick={() => {
          onClick("duplicate");
          setActiveBtnIndex(2);
        }}
        disabled={disabled}
        size="large"
        icon={disabled ? <Duplicate style={{ marginRight: '10px'}}/> : <BlackDuplicate style={{ marginRight: '10px'}}/>}
      >
        Duplicate
      </Button>
    </Flex>
    </div>
  );
};

export default ButtonGroup;

const iconStyle = {
  fontSize: Sizes.iconFontSize,
  fontWeight: Sizes.iconFontWeight,
  marginRight: Sizes.base / 2,
};
