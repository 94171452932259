import {
  CalendarMonthOutlined,
  FilterAltOffOutlined,
  FilterAltOutlined,
} from "@mui/icons-material";
import {
  Select,
  Space,
  Typography,
  DatePicker,
  Row,
  Col,
  Flex,
  Button,
  Tooltip,
} from "antd";
import { getTagsRequest } from "api-rest/tags";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import ArrowIcon from "studio/icons/ArrowIcon";
import { Colors } from "studio/theme/colors.theme";
import { Sizes } from "studio/theme/Sizes.theme";
import dayjs, { Dayjs } from "dayjs";
import { SeparatorIcon } from "studio/icons/SeparatorIcon";
// nothing

const { Text } = Typography;
const { RangePicker } = DatePicker;
type DateRange = [Dayjs | null, Dayjs | null];

const FilterQuestions = ({
  getTableData,
  handleChange,
  filterData,
  onReset,
}: {
  handleChange: (field: string, value: string | number) => void;
  filterData: any;
  getTableData: (newFilterData: any) => void;
  onReset: () => void;
}) => {
  const [filterActive, setFilterActive] = useState(false);
  const subjects = useSelector(
    (state: RootState) => state.subjects.dropSubjects
  );
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedDates, setSelectedDates] = useState<DateRange | null>(null);

  const onRangeChange = (
    dates: DateRange | null,
    dateStrings: [string, string]
  ) => {
    setSelectedDates(dates);
    if (dates) {
      handleChange("start_date", dateStrings[0]);
      handleChange("end_date", dateStrings[1]);
      setFilterActive(false);
    } else {
      handleChange("start_date", "");
      handleChange("end_date", "");
    }
  };

  const handleTagChange = (values) => {
    const tagIds = values.filter((value) => value !== "all");
    handleChange("tags", tagIds);
  };

  useEffect(() => {
    if (selectedSubject && filterData.grade_id) {
      getTagsRequest({
        subjectId: selectedSubject.id,
        gradeId: filterData.grade_id,
      })
        .then((res) => {
          let tagsList = res?.data.items;
          tagsList = tagsList.map((item) => ({
            ...item,
            value: item.id,
            label: item?.name,
          }));
          setTags(tagsList);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedSubject, filterData?.grade_id]);

  console.log("filter data =", filterData);
  const hasData = Object.values(filterData).some((value) => {
    return (
      value !== "" &&
      value !== "all" &&
      value !== null &&
      !(Array.isArray(value) && value.length === 0)
    );
  });
  const isFilterBtnDisabled = () => {
    let result = true;
    Object.keys(filterData).forEach((k) => {
      if (typeof filterData[k] === "string" && filterData[k] != "") {
        result = false;
      } else if (Array.isArray(filterData[k]) && filterData[k].length) {
        result = false;
      } else if (typeof filterData[k] === "number" && filterData[k] > 0) {
        result = false;
      }
    });
    return result;
  };
  const standardOptions = [
    {
      label: (
        <span className="text-sm font-bold text-[#333333]">
          Next Generation Science Standard (NGSS)
        </span>
      ),
      title: "NGSS",
      options: [
        {
          label: (
            <span className="text-xs font-normal w-[300px] text-wrap text-[#333333] mb-2 block">
              NGSS.MS.ETS1.1 - Define the criteria and constraints of a design
              problem with sufficient ...
            </span>
          ),
          value: "NGSS.MS.ETS1.1",
        },
        {
          label: (
            <span className="text-xs font-normal w-[300px] text-wrap text-[#333333] mb-2 block">
              NGSS.MS.LS1.4 - Use argument based on empirical evidence and
              scientific reasoning to ...
            </span>
          ),
          value: "NGSS.MS.ETS1.2",
        },
      ],
    },
    {
      label: (
        <span className="text-sm font-bold text-[#333333]">
          Common Core State Standard (CCSS)
        </span>
      ),
      title: "CCSS",
      options: [
        {
          label: (
            <span className="text-xs font-normal w-[300px] text-wrap text-[#333333] mb-2 block">
              CCSS.ELA-Literacy.RH.6-8.1 - Cite specific textual evidence to
              support analysis of primary and secondary.
            </span>
          ),
          value: "CCSS1",
        },
        {
          label: (
            <span className="text-xs font-normal w-[300px] text-wrap text-[#333333] mb-2 block">
              CCSS.ELA-Literacy.RH.6-8.1 - Cite specific textual evidence to
              support analysis of primary and secondary.
            </span>
          ),
          value: "CCSS2",
        },
      ],
    },
  ];

  return (
    <Row gutter={16} align="bottom">
      <Col flex={1} className="max-w-[1648px]">
        <Row wrap={true} gutter={[16, 16]} justify="start">
          <Col xs={12} md={3}>
            <Space direction="vertical" className="w-full">
              <Text>Subject</Text>
              <Select
                size="large"
                className="w-full"
                value={filterData?.subject_id}
                defaultValue="All"
                suffixIcon={<ArrowIcon />}
                onChange={(id: number) => {
                  const selectedSub = subjects.find((sub) => sub.id == id);
                  if (selectedSub) {
                    setSelectedSubject(selectedSub);
                    handleChange("subject_id", id);
                  } else {
                    setSelectedSubject(null);
                    handleChange("subject_id", "");
                  }
                }}
                options={[{ id: "", value: "", label: "All" }, ...subjects]}
                placeholder="All"
                virtual={false}
              />
            </Space>
          </Col>
          <Col xs={12} md={3}>
            <Space className="w-full" direction="vertical">
              <Text>Grade</Text>
              <Select
                size="large"
                suffixIcon={<ArrowIcon />}
                value={filterData?.grade_id}
                onChange={(id: number) => {
                  handleChange("grade_id", id);
                }}
                defaultValue="All"
                className="w-full"
                disabled={!selectedSubject || !filterData.subject_id}
                options={
                  selectedSubject && selectedSubject?.grades
                    ? [
                        { id: "", value: "", label: "All" },
                        ...selectedSubject.grades,
                      ]
                    : [{ id: "", value: "", label: "All" }]
                }
                placeholder="All"
                virtual={false}
              />
            </Space>
          </Col>
          <Col xs={12} md={3}>
            <Space className="w-full" direction="vertical">
              <Text>Tag</Text>
              <Select
                placeholder="Tag"
                className="w-full"
                size="large"
                suffixIcon={<ArrowIcon />}
                value={filterData.tags || ""}
                onChange={handleTagChange}
                mode="multiple"
                virtual={false}
                allowClear
                disabled={!selectedSubject || !filterData?.grade_id}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    styles={{ root: { pointerEvents: "none" } }}
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span>Hover Me</span>
                  </Tooltip>
                )}
              >
                <Select.Option value="all">All</Select.Option>
                {tags?.map((tag: any) => (
                  <Select.Option key={tag.value} value={tag.value}>
                    {tag.label}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          </Col>
          <Col xs={12} md={3}>
            <Space direction="vertical" className="w-full">
              <Text
                ellipsis
                style={{ whiteSpace: "nowrap", overflow: "hidden" }}
              >
                Depth of knowledge
              </Text>
              <Select
                suffixIcon={<ArrowIcon />}
                size="large"
                className="w-full"
                defaultValue="All"
                options={[
                  { id: "1", value: "", label: "DOK 1" },
                  { id: "2", value: "", label: "DOK 2" },
                  { id: "3", value: "", label: "DOK 3" },
                  { id: "4", value: "", label: "DOK 4" },
                ]}
                placeholder="select it"
              />
            </Space>
          </Col>
          <Col xs={12} md={3}>
            <Space direction="vertical" className="w-full">
              <Text>Standard</Text>
              <Select
                suffixIcon={<ArrowIcon />}
                size="large"
                value={filterData.status}
                defaultValue={"All"}
                // dropdownMatchSelectWidth={false}
                className="w-full"
                dropdownStyle={{ width: "400px" }}
                onChange={(id: number) => {
                  handleChange("status", id);
                }}
                // options={statusOptionsAll}
                options={standardOptions}
                placeholder="select it"
              />
            </Space>
          </Col>

          <Col xs={12} md={9}>
            <Space direction="vertical" className="w-full">
              <Text>Create date</Text>
              <RangePicker
                className="w-full"
                size="large"
                placeholder={["From", "To"]}
                style={{ color: "black" }}
                value={selectedDates}
                onChange={onRangeChange}
                suffixIcon={
                  <CalendarMonthOutlined
                    style={{
                      color: Colors.iconBlack,
                      fontSize: Sizes.iconFontSize,
                      stroke: "ButtonShadow",
                    }}
                  />
                }
                separator={<Text>-</Text>}
              />
            </Space>
          </Col>
        </Row>
      </Col>

      <Col>
        <Flex gap={6}>
          <Space direction="vertical">
            <Text style={{ opacity: 0 }}>.</Text>
            <Button
              size="large"
              // disabled={isFilterBtnDisabled()}
              // onClick={() => {
              //   setFilterActive(!hasData);
              // }}
              style={{
                backgroundColor: Colors.textBlack,
                opacity: 0.37,
              }}
              icon={
                hasData ? (
                  <FilterAltOffOutlined
                    style={{ color: "white" }}
                    onClick={() => {
                      onReset();
                      setSelectedDates(null);
                    }}
                  />
                ) : (
                  <FilterAltOutlined
                    style={{ color: "white" }}
                    onClick={(_) => getTableData(filterData)}
                  />
                )
              }
            />
          </Space>
        </Flex>
      </Col>
    </Row>
  );
};

export default FilterQuestions;
