import React, { useCallback } from 'react';
import { FontSize } from './types';
import { BasicGridded } from './BasicGridded';
import { FloatingDecimalGridded } from './FloatingDecimalGridded';
import { PlusMinusGridded } from './PlusMinusGridded';
import { FractionSlashGridded } from './FractionSlashGridded';

interface GriddedTableProps {
    columns: number;
    range?: { min: number; max: number };
    options: {
        range?: { min: number; max: number };
        plusMinus?: boolean;
        fractionSlash?: boolean;
        fixedDecimal?: boolean;
        floatingDecimal?: boolean;
        decimalColumn: number | null;
    };
    fontSize?: FontSize;
    onChange: (column: number, value: string) => void;
    disabled?: boolean;
    selectedValues?: string[];
}

export const GriddedTable: React.FC<GriddedTableProps> = React.memo(({
    columns,
    range = { min: 0, max: 9 },
    options,
    fontSize = 'medium',
    onChange,
    disabled,
    selectedValues = [],
    }) => {
    const handleChange = useCallback((column: number, value: string) => {
        onChange(column, value);
    }, [onChange]);

    // Handle plus/minus with fraction slash
    if (options.plusMinus && options.fractionSlash) {
        return (
            <PlusMinusGridded
                columns={columns}
                range={range}
                fontSize={fontSize}
                onChange={handleChange}
                disabled={disabled}
                selectedValues={selectedValues}
                hasPlusMinus={options.plusMinus}
                hasFloatingDecimal={options.floatingDecimal}
                hasFixedDecimal={options.fixedDecimal}
                hasFractionSlash={true}
                decimalColumn={options.decimalColumn}
            />
        );
    }

    // Handle plus/minus without fraction slash
    if (options.plusMinus) {
        return (
            <PlusMinusGridded
                columns={columns}
                range={range}
                fontSize={fontSize}
                onChange={handleChange}
                disabled={disabled}
                selectedValues={selectedValues}
                hasPlusMinus={options.plusMinus}
                hasFloatingDecimal={options.floatingDecimal}
                hasFixedDecimal={options.fixedDecimal}
                hasFractionSlash={false}
                decimalColumn={options.decimalColumn}
            />
        );
    }

    // Handle fraction slash without plus/minus
    if (options.fractionSlash && !options.fixedDecimal) {
        return (
            <FractionSlashGridded
                columns={columns}
                range={range}
                fontSize={fontSize}
                onChange={handleChange}
                disabled={disabled}
                selectedValues={selectedValues}
                hasFloatingDecimal={options.floatingDecimal}
                hasFixedDecimal={options.fixedDecimal}
                decimalColumn={options.decimalColumn}
            />
        );
    }

    // Basic grid with optional fixed decimal
    return (
        <BasicGridded
            columns={columns}
            range={range}
            fontSize={fontSize}
            onChange={handleChange}
            disabled={disabled}
            selectedValues={selectedValues}
            hasFloatingDecimal={options.floatingDecimal}
            hasFixedDecimal={options.fixedDecimal}
            decimalColumn={options.decimalColumn}
        />
    );
});
