const OneTwoThreeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="11.826"
    viewBox="0 0 32 11.826"
  >
    <path
      id="_123_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
      data-name="123_24dp_E8EAED_FILL0_wght300_GRAD-25_opsz24"
      d="M172.408-579.594v-9.423h-3.1v-2.4h5.5v11.826Zm7.35,0v-5.1a1.734,1.734,0,0,1,.515-1.277,1.734,1.734,0,0,1,1.277-.515h4.617v-2.529h-6.409v-2.4h7.016a1.74,1.74,0,0,1,1.277.515,1.727,1.727,0,0,1,.518,1.277v3.31a1.734,1.734,0,0,1-.515,1.277,1.734,1.734,0,0,1-1.277.515H182.16V-582h6.409v2.4Zm12.741,0V-582h6.409v-2.529h-4.286v-1.963h4.286v-2.529H192.5v-2.4h7.016a1.741,1.741,0,0,1,1.277.515,1.727,1.727,0,0,1,.518,1.277v8.241a1.733,1.733,0,0,1-.515,1.277,1.734,1.734,0,0,1-1.277.515Z"
      transform="translate(-169.31 591.42)"
      fill="#333"
    />
  </svg>
);

export default OneTwoThreeIcon;
